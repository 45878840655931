import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { DisplayTreesOptions } from '../../../components/Navbar/DisplayModes';
import { useTracking } from '../../../analytics/useTracking';
import { Checkbox, OverflowMenu, RadioButton, RadioButtonGroup, Toggle } from '@carbon/react';
import { Layers } from '@carbon/icons-react';

export enum MapStyle {
  Default = 'default',
  Satellite = 'satellite',
  Panoramic = 'panoramic'
}

export default function MapStyleSelector(props: {
  setHideCanopy?: (boolean) => void,
  hideCanopy?: boolean,
  setPointcloudVisibility?: (boolean) => void,
  showPointcloud?: boolean
}) {
  const { t } = useTranslation();
  const { urlContext } = useContext(DependencyInjectionContext);
  const { events, track } = useTracking();
  const [isOpen, setIsOpen] = useState(false);
  const [displayISO, setDisplayISO] = useState(urlContext.isIsoMapEnabled());
  const [displayValues, setDisplayValues] = useState(urlContext.areTreeMarkersVisible());

  const views = [
    { title: MapStyle.Default, isDisabled: false },
    { title: MapStyle.Satellite, isDisabled: false },
    { title: MapStyle.Panoramic, isDisabled: !urlContext.getTreeId() && urlContext.getStyle() !== MapStyle.Panoramic }
  ];

  const handleViewSelect = view => {
    track(events.MAP_STYLE_CHANGE, { style: view.title });
    if (view.title === MapStyle.Default || view.title === MapStyle.Satellite) {
      urlContext.setCapturePointId('');
    }
    urlContext.setStyle(view.title);
  };

  const displayTreeDropdownOptions = [
    { id: 'markers', translationKey: 'displayModes.displayTreesAsMarkers' },
    { id: 'canopy', translationKey: 'displayModes.displayTreesAsCanopy' }
  ];

  const handleDisplayTreesChange = (item: DisplayTreesOptions) => {
    track(events.DISPLAY_MODES_DISPLAY_TREES_AS_CHANGE, { displayTreesAs: item });
    urlContext.setDisplayMode(item);
  };

  const handleISOChange = () => {
    const enabled = !displayISO;
    track(events.DISPLAY_MODES_DISPLAY_ISO_MAP, { enabled });
    setDisplayISO(enabled);
    urlContext.setIsoMapEnabled(enabled);
  };

  const handleDisplayValueChange = () => {
    const enabled = !displayValues;
    track(events.DISPLAY_MODES_DISPLAY_VALUES, { enabled });
    setDisplayValues(enabled);
    urlContext.setDisplayValuesDisabled(!enabled);
  };

  return (
    <div>
      <OverflowMenu
        className="bg-[#22272ACC] hover:bg-[#22272A]"
        direction="top"
        flipped
        size="lg"
        renderIcon={Layers}
        menuOptionsClass="w-[238px]"
        iconClass={isOpen ? '' : '!fill-greehill-00'}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        iconDescription={t('displayModes.title')}
      >
        <RadioButtonGroup className="w-full" name="map-style" orientation={'vertical'}>
          {views.map(view => (
            <RadioButton key={view.title} checked={urlContext.getStyle() === view.title} onClick={() => handleViewSelect(view)} className="flex items-center w-[238px] px-4 h-12 hover:bg-[var(--cds-layer-hover)]" labelText={t('viewSelector.' + view.title)} value={view.title} disabled={view.isDisabled}/>
          ))}
        </RadioButtonGroup>
        <div className="ml-10">
          <Checkbox
            id="show-point-cloud"
            labelText={t('details.views.showPointcloud') || ''}
            checked={props.showPointcloud}
            onChange={(_, { checked }) => props.setPointcloudVisibility?.(checked)}
            className="pb-2 text-nowrap pt-1"
            disabled={urlContext.getStyle() !== MapStyle.Panoramic}
          />
          <RadioButtonGroup
            name="street-point-cloud-radio-group"
            orientation="vertical"
            defaultSelected={props.hideCanopy ? 'true' : 'false'}
            onChange={value => props.setHideCanopy?.(value === 'true')}
            disabled={urlContext.getStyle() !== MapStyle.Panoramic || !props.showPointcloud}
          >
            <RadioButton
              labelText={t('details.views.entireTree')}
              id="street-point-cloud-false"
              value="false"
              className="py-2"
            />
            <RadioButton
              labelText={t('details.views.trunkAndBranchesOnly')}
              id="street-point-cloud-true"
              value="true"
              className="py-2"
            />
          </RadioButtonGroup>
        </div>
        <RadioButtonGroup className="w-full border-solid border-0 border-t border-t-[var(--cds-border-subtle)]" name="marker-type" orientation={'vertical'}>
          {displayTreeDropdownOptions.map(option => (
            <RadioButton key={option.id} checked={urlContext.getDisplayMode() === option.id} onClick={() => handleDisplayTreesChange(option.id as DisplayTreesOptions)} className="flex items-center w-[238px] px-4 h-12 hover:bg-[var(--cds-layer-hover)]" labelText={t(option.translationKey)} value={option.id}/>
          ))}
        </RadioButtonGroup>
        <div className="w-full border-solid border-0 border-t border-t-[var(--cds-border-subtle)]">
          <Toggle toggled={displayValues} className="flex items-center w-[238px] px-4 h-12 hover:bg-[var(--cds-layer-hover)]" id="toggle1" labelA={t('displayModes.displayValues')} labelB={t('displayModes.displayValues')} onToggle={handleDisplayValueChange}/>
          <Toggle toggled={displayISO} className="flex items-center w-[238px] px-4 h-12 hover:bg-[var(--cds-layer-hover)]" id="toggle2" labelA={t('displayModes.displayIso')} labelB={t('displayModes.displayIso')} onToggle={handleISOChange}/>
        </div>
      </OverflowMenu>
    </div>
  );
}
