import { EcologyBook, LongArrowDownRight, NavArrowRight, ProfileCircle, TaskList } from 'iconoir-react';
import { useContext } from 'react';
import { useCurrentAccount } from '../../../account/useAccounts';
import { Features } from '../../../switches/Features';
import { AuthContext } from '../../../auth/AuthContext';
import { useMatch, useNavigate } from 'react-router-dom';
import { TaskManagerView } from '../../../routes/TaskManager/TaskManagerView';
import { useTranslation } from 'react-i18next';
import { useAreaFilteringAsSearchParam } from '../../../routes/TaskManager/create/useAreaFilteringAsSearchParam';
import { useWorkOrders } from '../../../task-manager/useWorkOrders';
import { useTaskTemplates } from '../../../routes/TaskManager/create/useTaskTemplates';
import DependencyInjectionContext from '../../../DependencyInjectionContext';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const OPEN_WIDTH = '300px';
const CLOSED_WIDTH = '48px';
const selectedMainItemStyle = 'bg-outer-space-700 border-r-[3px] border-r-greehill-400';

export default function Sidebar() {
  const { urlContext } = useContext(DependencyInjectionContext);
  const match = useMatch('/organizations/:organizationId/:currentItem/:currentSubItem/*');
  const subSubItemMatch = useMatch('/organizations/:organizationId/:currentItem/:currentSubItem/:currentSubSubItem');
  const remoteInspectionMatch = useMatch('/organizations/:organizationId/remote-inspections/:workOrderId');
  const currentItem = remoteInspectionMatch ? 'task-manager' : (match?.params.currentItem ?? 'inventory');
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const account = useCurrentAccount();
  const { t } = useTranslation();

  const isSideBarOpen = urlContext.getSidebarOpen();
  const width = isSideBarOpen ? OPEN_WIDTH : CLOSED_WIDTH;

  const isTaskManagerOpen = currentItem === 'task-manager';
  const currentSubItem = match?.params.currentSubItem ?? '';
  const taskTemplateId = match?.params.currentSubItem === TaskManagerView.CREATE ? subSubItemMatch?.params.currentSubSubItem : null;
  const workOrderId = match?.params.currentSubItem === TaskManagerView.MANAGE ? subSubItemMatch?.params.currentSubSubItem : null;

  const { taskTemplates } = useTaskTemplates(account.organization.id);
  const { workOrders } = useWorkOrders();

  const inventorySearchParams = new URLSearchParams(localStorage.getItem('inventorySearchParams') ?? '');
  inventorySearchParams.set('s', isSideBarOpen ? 'true' : 'false');
  const sidebarParam = isSideBarOpen ? 's=true' : '';
  const areaFilteringAsSearchParam = [useAreaFilteringAsSearchParam(), sidebarParam].join('&');
  const currentTemplate = taskTemplates.find(it => it.id === taskTemplateId);
  const currentWorkOrder = workOrders.find(it => it.id === workOrderId);

  const toggleSidebar = () => urlContext.setSidebarOpen(!isSideBarOpen);

  return (
    <div
      style={{ minWidth: width }}
      className="twp flex flex-col z-40 left-0 top-0 transition-[min-width] h-full rounded-tr-lg overflow-y-auto bg-outer-space-800 text-greehill-00 drop-shadow-lg group"
    >
      <div className="relative h-9" onClick={toggleSidebar}>
        <NavArrowRight id="sidebarArrow" className={classNames(isSideBarOpen ? 'rotate-180' : '', 'absolute right-0 h-9 cursor-pointer invisible group-hover:visible')} />
      </div>
      <nav className="flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul>
              <li>
                <button
                  onClick={() => navigate({
                    pathname: `/organizations/${account.organization.id}/inventory`,
                    search: inventorySearchParams.toString()
                  })}
                  className={classNames(
                    currentItem === 'inventory' ? selectedMainItemStyle : '',
                    'group flex w-full gap-x-3 p-2 text-sm leading-6 hover:bg-outer-space-700'
                  )}
                >
                  <EcologyBook className="h-6 w-6 shrink-0" aria-hidden="true" />
                  {isSideBarOpen && t('mainMenu.insights')}
                </button>
                {account.organization.isFeatureEnabled(Features.taskManager) && (
                  <div>
                    <button
                      className={classNames(
                        isTaskManagerOpen ? selectedMainItemStyle : '',
                        'flex w-full items-center gap-x-3 p-2 text-left text-sm leading-6 hover:bg-outer-space-700'
                      )}
                      onClick={() => navigate({
                        pathname: `/organizations/${account.organization.id}/task-manager/${TaskManagerView.MANAGE}`,
                        search: sidebarParam
                      })}
                    >
                      <TaskList className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true"/>
                      {isSideBarOpen && t('mainMenu.taskManager')}
                    </button>
                    {isTaskManagerOpen && isSideBarOpen && <ul className="pl-2">
                      {remoteInspectionMatch?.params.workOrderId &&
                        <li>
                          <button
                            className="text-greehill-400 flex items-center gap-x-3 py-2 pl-2 w-full text-sm leading-6"
                            onClick={() => {
                            }}
                          >
                            <SelectableArrow selected={(currentSubItem === TaskManagerView.MANAGE) && !workOrderId} />
                            {t('taskManager.moduleConnection.remoteInspection')}
                          </button>
                        </li>
                      }
                      {account.canCreateTaskManagerJobs() &&
                        <li>
                          <button
                            className={classNames(
                              (currentSubItem === TaskManagerView.CREATE && !taskTemplateId) ? 'text-greehill-400' : '',
                              'flex items-center gap-x-3 py-2 pl-2 w-full text-sm leading-6'
                            )}
                            onClick={() => navigate({
                              pathname: `/organizations/${account.organization.id}/task-manager/${TaskManagerView.CREATE}`,
                              search: areaFilteringAsSearchParam
                            })}
                          >
                            <SelectableArrow selected={currentSubItem === TaskManagerView.CREATE && !taskTemplateId} />
                            {t('mainMenu.taskManagerCreate')}
                          </button>
                          {currentTemplate && <button
                            className="text-greehill-400 flex items-center gap-x-3 py-2 pl-8 w-full text-sm leading-6"
                            onClick={() => navigate({
                              pathname: `/organizations/${account.organization.id}/task-manager/${TaskManagerView.CREATE}/${taskTemplateId}`,
                              search: areaFilteringAsSearchParam
                            })}
                          >
                            <SelectableArrow selected={currentSubItem === TaskManagerView.CREATE} />
                            {currentTemplate.name}
                          </button>}
                        </li>
                      }
                      <li>
                        <button
                          className={classNames(
                            (currentSubItem === TaskManagerView.MANAGE && !workOrderId) ? 'text-greehill-400' : '',
                            'flex items-center gap-x-3 py-2 pl-2 w-full text-sm leading-6'
                          )}
                          onClick={() => navigate({
                            pathname: `/organizations/${account.organization.id}/task-manager/${TaskManagerView.MANAGE}`,
                            search: sidebarParam
                          })}
                        >
                          <SelectableArrow selected={(currentSubItem === TaskManagerView.MANAGE) && !workOrderId} />
                          {t('mainMenu.taskManagerManage')}
                        </button>
                        {currentWorkOrder && <button
                          className="text-greehill-400 flex items-center gap-x-3 py-2 pl-8 w-full text-sm leading-6"
                          onClick={() => {
                            if (currentSubItem === TaskManagerView.MANAGE) return;
                            navigate({
                              pathname: `/organizations/${account.organization.id}/task-manager/${TaskManagerView.CREATE}/${workOrderId}`,
                              search: areaFilteringAsSearchParam
                            });
                          }}
                        >
                          <SelectableArrow selected={currentSubItem === TaskManagerView.CREATE} />
                          {currentWorkOrder.name || currentWorkOrder.code || 'Unnamed task'}
                        </button>}
                      </li>
                    </ul>}
                  </div>
                )}
              </li>
            </ul>
          </li>
          {isSideBarOpen && <li className="mt-auto">
            <div
              onClick={() => navigate(`/organizations/${account.organization.id}/settings`)}
              className="items-center gap-x-4 px-6 pb-3 text-sm leading-6 hover:bg-outer-space-700 cursor-pointer"
            >
              <div className="border-t border-outer-space-400 pt-3" />
              <table>
                <tbody>
                  <tr>
                    <td className="pr-2">
                      <ProfileCircle className="h-6 w-6" />
                    </td>
                    <td>
                      <div className="font-bold">{user.getName()}</div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <div>{user.email}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>}
        </ul>
      </nav>
    </div>
  );
}

function SelectableArrow({ selected }) {
  return (
    <LongArrowDownRight
      className={classNames(
        selected ? 'text-greehill-400' : 'text-outer-space-500',
        'h-4 w-4 shrink-0'
      )}
      aria-hidden="true"
    />
  );
}
