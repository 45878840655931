import { AxiosInstance } from 'axios';

export default class GenusService {
  constructor(private readonly http: AxiosInstance) {}

  async listGenera(organizationId: string): Promise<string[]> {
    const result = await this.http.get<string[]>(`/v1/organizations/${organizationId}/genera`);
    return result.data;
  }

  async listAllGenera(): Promise<string[]> {
    const result = await this.http.get<string[]>('/v1/genera');
    return result.data;
  }

  async listGeneraFromTse(organizationId: string, searchKey: string): Promise<string[]> {
    const result = await this.http.get<string[]>(`/v1/organizations/${organizationId}/listGeneraFromTse?searchKey=${searchKey}`);
    return result.data;
  }

  async listSpeciesFromTse(organizationId: string, searchKey: string, genus: string): Promise<string[]> {
    const result = await this.http.get<string[]>(`/v1/organizations/${organizationId}/listSpeciesFromTse?searchKey=${searchKey}&genus=${genus}`);
    return result.data;
  }
}
