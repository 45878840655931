import {
  BackSide,
  DoubleSide,
  Group,
  MathUtils,
  Mesh,
  MeshBasicMaterial,
  SphereGeometry,
  Texture,
  Vector3
} from 'three';
import { generateUUID } from 'three/src/math/MathUtils';
import PanoramaImageSphere from './PanoramaImageSphere';
import { loadImage } from './loadImage';

export class SpherePanoramaObject extends Group {
  public image: PanoramaImageSphere | undefined;
  public layerMeta: any;
  public session = '';
  public items: Mesh[] = [];

  private signal: AbortController = new AbortController();

  public async loadTexture(url: string) {
    const img = await loadImage(url, this.signal.signal);

    const texture = new Texture();
    texture.image = img;
    texture.needsUpdate = true;

    return texture;
  }

  public createCamParams() {
    if (!this.image) return;

    const vectors = this.image.createTileVectors(0.000001);

    const [a, b, c, d] = vectors;
    const ca = a.clone().sub(c.clone()).normalize();
    const cd = d.clone().sub(c.clone()).normalize();
    const cross = ca.clone().cross(cd.clone());
    const center = a.clone().add(d.clone()).divideScalar(2);

    return { center, cross, a, b, c, d };
  }

  private async downloadLayerMetadata(image: PanoramaImageSphere) {
    try {
      const jsonUrl = image.url
        .replace('panoramas', 'panorama-tiles')
        .replace(/\.(jpeg|jpg)/, '/tiles.json');

      const response = await fetch(jsonUrl, {
        signal: this.signal.signal,
        credentials: 'include'
      });

      if (!response.ok) {
        return null;
      }

      return await response.json();
    } catch (err) {
      return null;
    }
  }

  public async load(image: PanoramaImageSphere, render: () => void, firstLoaded?: any) {
    this.signal = new AbortController();

    this.session = generateUUID();
    const currentSession = this.session;

    this.image = image;
    this.layerMeta = await this.downloadLayerMetadata(image);

    const pc = this.createCamParams();

    if (!pc) return;

    this.scale.x = -1;
    const centerOfCamZero = pc.cross.clone();

    this.up.copy(image.directionVector.clone().normalize().cross(image.upVector.clone().normalize()).applyAxisAngle(new Vector3(1, 0, 0), -Math.PI / 2));
    this.lookAt(centerOfCamZero.clone().applyAxisAngle(new Vector3(1, 0, 0), -Math.PI / 2));

    if (this.up.clone().normalize().dot(new Vector3(0, 1, 0)) < 0) {
      this.rotateZ(MathUtils.degToRad(180));
    }

    return new Promise((resolve, reject) => {
      try {
        this.layerMeta
          ? this.loadLayers(currentSession, resolve, render)
          : this.loadFallback(currentSession, firstLoaded).then(resolve);
      } catch (e) {
        reject(e);
      }
    });
  }

  public async loadLayers(currentSession: string, firstLoaded: any, render: () => void) {
    const oL = this.layerMeta.layers.reverse();

    const oLGroups = [[oL[0]], oL.slice(1)];

    for (const lG of oLGroups) {
      for (const layer of lG) {
        const deltaRotationX = ((Math.PI * 2) / layer.nx);
        const deltaRotationY = ((Math.PI) / layer.ny);

        await Promise.all(layer.items.map(async (item: any) => {
          const prefix = this.image?.url
            ?.replace('panoramas', 'panorama-tiles')
            .replace(/\.(jpeg|jpg)/, `/${item.path}`);

          const url = `${prefix}`;
          const texture = await this.loadTexture(url);

          if (currentSession !== this.session) return;

          const geometry = new SphereGeometry(
            200 + layer.layer,
            100, // layer.nx * 100,
            100, // layer.ny * 100,
            Math.PI * (3 / 2) + (deltaRotationX * item.x),
            deltaRotationX,
            (deltaRotationY * item.y),
            deltaRotationY
          );

          const material = new MeshBasicMaterial({
            map: texture,
            side: BackSide,
            visible: false
          });

          // This is necessary to prevent blinking (layer fight)
          requestAnimationFrame(() => {
            material.visible = true;
          });

          this.add(new Mesh(geometry, material));
          render();
        }));
      }

      firstLoaded?.();
    }
  }

  public async loadFallback(currentSession: string, firstLoaded: any) {
    const texture = await this.loadTexture(this.image?.url as string);

    if (currentSession !== this.session) return;

    const geometry = new SphereGeometry(200, 1000, 1000, Math.PI * (3 / 2));

    const material = new MeshBasicMaterial({
      map: texture,
      side: DoubleSide
    });

    this.add(new Mesh(geometry, material));

    firstLoaded?.();
  }

  public unload() {
    this.signal.abort();
    this.remove(...this.children);
  }
}
