import styles from '../TableViewer.module.scss';
import AbsoluteModal from '../../../../components/Modal/absolute-modal/AbsoluteModal';
import ModalDialog from '../../../../components/Modal/absolute-modal/ModalDialog';
import { FunctionButton } from '../../../../components/UI/Button/LegacyButton';
import { ArrowDown, ArrowUp, Filter, MoreVert, Pin } from 'iconoir-react';
import { DisplayableTreeProperty, Tree } from '../../../../tree/Tree';
import Select from '../../../../components/Settings/Select/Select';
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import {
  AdvancedFilterByProperty,
  AdvancedFilterConfigurationBuilder
} from '../advanced-filter/AdvancedFilterConfigurationBuilder';
import { AdvancedFilter, AdvancedFilterConfiguration } from '../advanced-filter/AdvancedFilter';
import { TFunction, useTranslation } from 'react-i18next';
import {
  ColumnConfiguration,
  columnConfiguration,
  ColumnSettings,
  defaultColumnConfiguration,
  Property
} from '../TableViewer';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { TreeListHeader } from '../../../../tree/TreeService';
import { useCurrentAccount } from '../../../../account/useAccounts';
import PropertyConfiguration from '../../../../properties/PropertyConfiguration';
import { useTracking } from '../../../../analytics/useTracking';
import { Flippers } from '../../../../switches/Flippers';
import { LegacyAdvancedFilterModal } from './LegacyAdvancedFilterModal';
import { AdvancedFilterModal } from './AdvancedFilterModal';
import Tooltip from '../../../../components/UI/Tooltip/Tooltip';

interface TableHeaderProps {
  columnSettings: Map<Property, ColumnSettings>,
  properties: Property[],
  onSortDirectionChange: (sortProperty: string | null) => unknown,
  hidePlaceholderColumn: boolean,
  pinnedTreesCount: number,
  scrollToTableTop: () => void,
  setColumnSettings: Dispatch<SetStateAction<Map<Property, ColumnSettings>>>,
  managedAreas: { id: string, code: string }[],
  species: string[],
  header: TreeListHeader[],
  windSpeed: number,
  propertyConfigs: PropertyConfiguration[] | undefined,
  statuses: string[],
  vitalityVigorOptions: string[]
}

export default function TableHeader(props: TableHeaderProps) {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const { events, track } = useTracking();

  const selectedTreeProperty = urlContext.getSelectedTreeProperty();

  const [advancedNumericProperty, setAdvancedNumericProperty] = useState<DisplayableTreeProperty | 'externalId' | 'species' | 'managedAreaId' | ''>('');
  const [advancedNumericPosition, setAdvancedNumericPosition] = useState<{ top: string, left: string }>({
    top: '0',
    left: '0'
  });
  const [editableAdvancedFilter, setEditableAdvancedFilter] = useState<AdvancedFilterConfiguration>(urlContext.getAdvancedFilterConfiguration());

  const currentAdvancedFilters = useMemo(() => {
    return AdvancedFilterByProperty.fromAdvancedFilterConfiguration(editableAdvancedFilter);
  }, [editableAdvancedFilter]);

  const advancedPropertyConfig = (props.propertyConfigs || []).find(config => config.property === (advancedNumericProperty === 'safetyFactors' ? `safetyFactors-${props.windSpeed}` : advancedNumericProperty));

  useEffect(() => {
    currentAdvancedFilters.forEach(advancedFilter => {
      if (!props.properties.includes(advancedFilter.property as DisplayableTreeProperty)) {
        deleteAdvancedFilter(advancedFilter.property);
      }
    });
    setEditableAdvancedFilter(urlContext.getAdvancedFilterConfiguration());
  }, [props.properties]);

  const setAdvancedFilters = (property, min, max) => {
    const builder = AdvancedFilterConfigurationBuilder.from(editableAdvancedFilter);
    if (!min && !max) {
      builder.removeProperty(property);
    }

    const setOnlyMin = min !== null && max === null;
    const setOnlyMax = min === null && max !== null;

    builder.removePropertyConfig(property);
    builder.removeCohort(property);
    if (setOnlyMin) builder.removeMax(property);
    if (setOnlyMax) builder.removeMin(property);
    if (min !== null) builder.addMin(property, min);
    if (max !== null) builder.addMax(property, max);
    setEditableAdvancedFilter(builder.build());
  };

  const setAdvancedEnumFilters = (property, values) => {
    const builder = AdvancedFilterConfigurationBuilder.from(editableAdvancedFilter);

    if (property === 'managedAreaId') {
      values = props.managedAreas.filter(it => values.includes(it.code)).map(it => it.id);
    }

    if (Tree.isViBooleanProperty(property)) {
      values = values.map(it => it === 'true');
    }

    builder.removeIncludeOnly(property);
    if (values.length > 0) {
      builder.addIncludeOnly(property, values);
    }
    setEditableAdvancedFilter(builder.build());
  };

  const setAdvancedRangeFilters = (property, rangeIndices) => {
    const builder = AdvancedFilterConfigurationBuilder.from(editableAdvancedFilter);
    if (!advancedPropertyConfig) return;
    builder.removeProperty(property);
    if (rangeIndices.length > 0) {
      builder.addPropertyConfig(property, advancedPropertyConfig.ranges, rangeIndices);
    }
    setEditableAdvancedFilter(builder.build());
  };

  const setAdvancedCohortFilter = (property, sigmaBoundaries) => {
    const builder = AdvancedFilterConfigurationBuilder.from(editableAdvancedFilter);
    if (!advancedPropertyConfig) return;
    builder.removeProperty(property);
    if (sigmaBoundaries.length > 0) {
      builder.addCohort(property, sigmaBoundaries);
    }
    setEditableAdvancedFilter(builder.build());
  };

  const updateFilters = (advancedFilter: AdvancedFilterConfiguration | null = null) => {
    const filterToUpdate = advancedFilter ? advancedFilter : editableAdvancedFilter;
    urlContext.setAdvancedFilterConfiguration(filterToUpdate);
  };

  const deleteAdvancedFilter = property => {
    const builder = AdvancedFilterConfigurationBuilder.from(editableAdvancedFilter);
    builder.removeProperty(property);
    setEditableAdvancedFilter(builder.build());
    updateFilters(builder.build());
    setAdvancedNumericProperty('');
  };

  const applyAdvancedFilter = () => {
    updateFilters();
    setAdvancedNumericProperty('');
  };

  return (
    <>
      <AbsoluteModal
        isVisible={advancedNumericProperty !== ''}
        onHide={applyAdvancedFilter}
      >
        {advancedNumericProperty !== '' &&
          <ModalDialog
            style={{
              top: `${Number(advancedNumericPosition.top) + 15}px`,
              left: `${Number(advancedNumericPosition.left) - 92}px`
            }}
            classNames={styles.advancedModal}
          >
            <div className={styles.advancedModal}>
              <div className={styles.greenModalTitle}>{t('treeList.advancedFiltering.advancedFilter')}</div>
              <FunctionButton
                className={`${styles.sortButton} ${props.columnSettings.get(advancedNumericProperty)?.sort === 'asc' && styles.selectedSortButton}`}
                icon={<ArrowUp />}
                onClick={toggleSort(advancedNumericProperty, 'asc')}>{t('treeList.advancedFiltering.sortAscending')}</FunctionButton>
              <FunctionButton
                className={`${styles.sortButton} ${props.columnSettings.get(advancedNumericProperty)?.sort === 'desc' && styles.selectedSortButton}`}
                icon={<ArrowDown />}
                onClick={toggleSort(advancedNumericProperty, 'desc')}>{t('treeList.advancedFiltering.sortDescending')}</FunctionButton>
              {
                account.organization.isEnabled(Flippers.dashboardRedesign) ?
                  <AdvancedFilterModal
                    advancedProperty={advancedNumericProperty}
                    currentAdvancedFilter={currentAdvancedFilters.find(it => it.property === advancedNumericProperty) || null}
                    currentPropertyConfig={advancedPropertyConfig || null}
                    onNumericFilterChange={(property, min, max) => setAdvancedFilters(property, min, max)}
                    onEnumFilterChange={(property, values) => setAdvancedEnumFilters(property, values)}
                    species={props.species}
                    managedAreas={props.managedAreas}
                    onRangeFilterChange={(property, rangeIndices) => setAdvancedRangeFilters(property, rangeIndices)}
                    onCohortFilterChange={(property, sigmaBoundaries) => setAdvancedCohortFilter(property, sigmaBoundaries)}
                    statuses={props.statuses}
                    vitalityVigorOptions={props.vitalityVigorOptions}
                  />
                  :
                  <LegacyAdvancedFilterModal
                    advancedProperty={advancedNumericProperty}
                    currentAdvancedFilter={currentAdvancedFilters.find(it => it.property === advancedNumericProperty) || null}
                    currentPropertyConfig={advancedPropertyConfig || null}
                    onNumericFilterChange={(property, min, max) => setAdvancedFilters(property, min, max)}
                    onEnumFilterChange={(property, values) => setAdvancedEnumFilters(property, values)}
                    species={props.species}
                    managedAreas={props.managedAreas}
                    onRangeFilterChange={(property, rangeIndices) => setAdvancedRangeFilters(property, rangeIndices)}
                    onCohortFilterChange={(property, sigmaBoundaries) => setAdvancedCohortFilter(property, sigmaBoundaries)}
                    statuses={props.statuses}
                    vitalityVigorOptions={props.vitalityVigorOptions}
                  />
              }
              <button
                className={`${styles.advancedModalButton} ${styles.applyButton}`}
                onClick={applyAdvancedFilter}
              >
                {t('treeList.advancedFiltering.apply')}
              </button>
              <button
                className={`${styles.advancedModalButton} ${styles.deleteButton}`}
                onClick={() => deleteAdvancedFilter(advancedNumericProperty)}>{t('treeList.advancedFiltering.delete')}
              </button>
            </div>
          </ModalDialog>}
      </AbsoluteModal>
      <div className={styles.headers} style={{ gridTemplateColumns: `repeat(${props.properties.length}, 200px) 1fr` }}>
        {props.properties.map(property => {
          const configuration = getColumnConfiguration(property);
          const aggregationOptions = getAggregationOptions(t, configuration);
          const settings = props.columnSettings.get(property) ?? { sort: null, aggregateFunction: null };
          const unit = Tree.getUnit(property, account.organization);
          const translatedUnit = unit ? ` [${t(`units.${unit}`)}]` : '';
          const columnTitle = t(`tree.${property}`) + translatedUnit;
          const handleAdvancedFilterClick = e => {
            setAdvancedNumericProperty(property);
            track(events.ADVANCED_FILTER_FROM_COLUMN_HEADER, { property });
            const button = e.target.getBoundingClientRect();
            const tooLeft = window.innerWidth - button.right < 100;
            const tooDown = button.bottom > window.innerHeight - 420;
            if (tooLeft && tooDown) {
              return setAdvancedNumericPosition({ top: `${button.top - 320}`, left: `${e.pageX - 100}` });
            }
            if (tooDown) {
              setAdvancedNumericPosition({ top: `${button.top - 320}`, left: e.pageX });
            } else if (tooLeft) {
              setAdvancedNumericPosition({ top: button.top, left: `${e.pageX - 100}` });
            } else {
              setAdvancedNumericPosition({ top: e.pageY, left: e.pageX });
            }
          };
          const activeAdvancedFilter = new AdvancedFilter(urlContext.getAdvancedFilterConfiguration()).hasProperty(property);

          return (
            <div
              key={`header-${property}`}
              className={`${styles.header} ${property === selectedTreeProperty && styles.selectedHeader}`}>
              <div className={styles.headerContent}>
                <div className={styles.titleContainer}>
                  <Tooltip
                    overlay={columnTitle}
                    overlayClassName={styles.tooltip}>
                    <div className={styles.headerTitle}>
                      {columnTitle}
                    </div>
                  </Tooltip>

                  {configuration.sort && (
                    <Tooltip overlay={t('treeList.advancedFiltering.newAdvancedRule')}>
                      <div className={styles.sortingControl}>
                        <FunctionButton
                          icon={account.organization.isEnabled(Flippers.dashboardRedesign) ? <MoreVert /> : <Filter /> }
                          className={`${styles.advancedFilteringButton} ${activeAdvancedFilter ? styles.active : ''}`}
                          onClick={handleAdvancedFilterClick} />
                      </div>
                    </Tooltip>
                  )}
                </div>

                {property === 'externalId' && props.pinnedTreesCount > 0 &&
                  <span className={styles.pinnedCounter} onClick={props.scrollToTableTop}>
                    {t('treeList.pinnedLabel', { count: props.pinnedTreesCount }).toUpperCase()} <Pin />
                  </span>}

                <div className={styles.headerControls}>
                  {aggregationOptions.length > 0 && (
                    <div className={styles.aggregateSelector}>
                      <Select
                        compact
                        label=""
                        placeholder={t('treeList.aggregate')}
                        value={settings.aggregateFunction || aggregationOptions[0].value}
                        options={aggregationOptions as any}
                        stylesOverride={{
                          control: { fontSize: '0.5rem' },
                          dropdownIndicator: { color: 'white' }
                        }}
                        onChange={onAggregateControlChange(props.setColumnSettings, property, track, events)}
                        isSearchable={false}
                      />
                    </div>
                  )}

                  {settings.aggregateFunction && (
                    <div
                      className={styles.aggregatedValue}>{getAggregatedValue(settings.aggregateFunction, property, props.header).toFixed(2)}</div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        <div
          key={'header-placeholder'}
          className={`${styles.header} ${styles.headerPlaceholder}`}
          hidden={props.hidePlaceholderColumn}>
          <div className={styles.headerContent}></div>
        </div>
      </div>
    </>
  );

  function toggleSort(property: DisplayableTreeProperty | 'externalId' | 'species' | 'managedAreaId', direction?: 'desc' | 'asc' | null) {
    return async () => {
      const original = props.columnSettings.get(property) ?? { sort: null, aggregateFunction: null };
      const updated = new Map<Property, ColumnSettings>(
        Array.from(props.columnSettings, it => [it[0], { ...it[1], sort: null }])
      );
      let value: 'desc' | 'asc' | null = original.sort === null ? 'asc' : original.sort === 'desc' ? null : 'desc';
      if (direction) {
        value = original.sort === direction ? null : direction;
      }
      updated.set(property, { sort: value, aggregateFunction: original.aggregateFunction });
      let sortProperty: string = property;
      if (property === 'managedAreaId') {
        sortProperty = 'managedAreaName';
      } else if (property === 'species') {
        sortProperty = 'scientificName';
      }
      const prefix = value === 'desc' ? '-' : '';
      const sortPropertyWithDirection = value === null ? null : prefix + sortProperty;
      await props.onSortDirectionChange(sortPropertyWithDirection);
      track(events.TABLE_SORT_COLUMN, { property, direction, ...updated.get(property) });
      props.setColumnSettings(updated);
    };
  }
}

function getColumnConfiguration(property: Property) {
  return columnConfiguration.get(property) ?? defaultColumnConfiguration;
}

function getAggregationOptions(t: TFunction, configuration: ColumnConfiguration) {
  return [
    { label: t('treeList.min'), value: 'min' as const },
    { label: t('treeList.max'), value: 'max' as const },
    { label: t('treeList.sum'), value: 'sum' as const },
    { label: t('treeList.avg'), value: 'avg' as const },
    { label: t('treeList.med'), value: 'med' as const }
  ].filter(it => configuration[it.value]);
}

function onAggregateControlChange(
  setColumnSettings: (value: SetStateAction<Map<Property, ColumnSettings>>) => void,
  property: Property,
  track,
  events
) {
  return (value: ColumnSettings['aggregateFunction']) => {
    setColumnSettings(settings => {
      const updated = new Map(settings);
      const original = settings.get(property) ?? { sort: null, aggregateFunction: null };
      updated.set(property, { sort: original.sort, aggregateFunction: value });
      return updated;
    });
    track(events.TABLE_AGGREGATE_CONTROL_CHANGE, { property, aggregateFunction: value });
  };
}

type AggregateFunction = 'min' | 'max' | 'avg' | 'med' | 'sum';

function getAggregatedValue(fn: AggregateFunction, property: Property, headers: TreeListHeader[]) {
  if (['externalId', 'species', 'managedAreaId'].includes(property)) {
    return 0;
  }

  return headers.find(it => it.name === property)?.aggregates?.[fn] || 0;
}
