import styles from './LanguageSelector.module.scss';
import Dropdown from '../components/UI/Dropdown/Dropdown';
import { useState } from 'react';
import { UserUpdateDto } from '../users/UserUpdateDto';
import { useTranslation } from 'react-i18next';

export const languages = [
  {
    translationKey: 'English',
    id: 'en'
  }, {
    translationKey: 'Deutsch',
    id: 'de'
  }, {
    translationKey: 'Français',
    id: 'fr'
  },
  {
    translationKey: 'Svenska',
    id: 'sv'
  },
  {
    translationKey: 'Ελληνικά',
    id: 'el'
  },
  {
    translationKey: 'Čeština',
    id: 'cs'
  },
  {
    translationKey: 'Italiano',
    id: 'it'
  },
  {
    translationKey: 'Dansk',
    id: 'da'
  },
  {
    translationKey: 'Español',
    id: 'es'
  },
  {
    translationKey: 'Nederlands',
    id: 'nl'
  },
  {
    translationKey: 'Polski',
    id: 'pl'
  },
  {
    translationKey: 'Melayu',
    id: 'ms'
  }
].sort((a, b) => a.id.localeCompare(b.id));

export default function LanguageSelector(props: { onLanguageChange: (changes: UserUpdateDto) => unknown, initialLanguage: string }) {
  const [currentLanguage, setCurrentLanguage] = useState(languages.find(it => it.id === props.initialLanguage) || languages[0]);
  const translation = useTranslation();

  const updateLanguage = language => {
    setCurrentLanguage(language as { translationKey: string, id: string });
    props.onLanguageChange({ language: language.id });
    translation.i18n.changeLanguage(language.id);
    localStorage.setItem('languageSetBeforeLogin', language.id || '');
  };

  return (
    <div className={styles.languageSelector}>
      <div className={styles.languageChangeTitle}>{translation.t('translations.changeLanguage')}</div>
      <Dropdown
        value={currentLanguage}
        items={languages.filter(language => language.id !== currentLanguage.id)}
        fieldClassName={styles.dropdown}
        className={styles.dropdownContainer}
        menuClassname={styles.dropdownMenu}
        onSelect={updateLanguage} />
    </div>
  );
}
