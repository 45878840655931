import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import useRightPanelContent, { RightPanelContent } from '../../../components/UI/Page/carbon/useRightPanelContent';
import { useTaskGroups } from '../tasks/useTaskGroups';
import { useCurrentAccount } from '../../../account/useAccounts';
import PageLayout from '../../../components/UI/Page/carbon/PageLayout';
import { Button, IconButton } from '@carbon/react';
import { Checkmark, CloseLarge } from '@carbon/icons-react';
import FilterConfig from '../../../filter/FilterConfig';
import { CarbonToastContext } from '../../../components/UI/Toast/CarbonToastWrapper';
import { useQueryClient } from 'react-query';
import { StaticQueryKeys } from '../../../StaticQueryKeys';

export interface AssignTaskDto {
  managedAreaIds: string[],
  reverseMASelection: boolean,
  reversedSelection: boolean,
  selectedTreeIds: string[],
  filterConfig?: FilterConfig
}

export default function TaskSelectorPanel(props: { assignTaskDto: AssignTaskDto, treeCount: number, clearTreeSelection: () => void }) {
  const { t } = useTranslation();
  const { urlContext, taskManagerService } = useContext(DependencyInjectionContext);
  const rightPanelContent = useRightPanelContent();
  const { taskGroups } = useTaskGroups();
  const { organization } = useCurrentAccount();
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
  const { showToast } = useContext(CarbonToastContext);
  const queryClient = useQueryClient();

  const selectTask = (taskId: string) => {
    if (selectedTaskId === taskId) return setSelectedTaskId(null);
    setSelectedTaskId(taskId);
  };

  const assignTreesToTask = async () => {
    if (!selectedTaskId) return;
    try {
      const result = await taskManagerService.assignTreesToTask(organization.id, selectedTaskId, props.assignTaskDto);
      await queryClient.invalidateQueries(StaticQueryKeys.TREE_DETAILS);
      if (result.rejectedCount && result.rejectedCount > 0) {
        showToast({ kind: 'info', subtitle: `${result.assignedCount} ${t('taskManager.assignToTaskSuccess')} ${result.rejectedCount} ${t('taskManager.assignToTaskRejected')}` });
      } else {
        showToast({ kind: 'success', subtitle: `${result.assignedCount} ${t('taskManager.assignToTaskSuccess')}` });
      }
    } catch (e) {
      showToast({ kind: 'error', subtitle: t('taskManager.assignToTaskError') });
    }
    setSelectedTaskId(null);
    props.clearTreeSelection();
    urlContext.setTaskSelectorPanelOpen(false);
  };

  if (rightPanelContent !== RightPanelContent.TASK_SELECTOR_PANEL) return null;

  const isAssignButtonDisabled = !selectedTaskId || (props.assignTaskDto.reversedSelection ? props.assignTaskDto.selectedTreeIds.length === props.treeCount : props.assignTaskDto.selectedTreeIds.length === 0);
  return (
    <PageLayout.RightPanel>
      <PageLayout.RightPanelContent>
        <div>
          <div className="flex items-center justify-between pl-4 pt-2 pr-2">
            <h3 className="m-0 text-xl text-[var(--cds-text-primary)] font-normal">{t('taskManager.assignToTask')}</h3>
            <IconButton onClick={() => urlContext.setTaskSelectorPanelOpen(false)} label={t('filter.close')} align="left" kind="ghost"><CloseLarge/></IconButton>
          </div>
          <div className="text-sm">
            {taskGroups.map(((taskGroup, idx) => (
              <div key={taskGroup.id}>
                <div className="font-bold p-4">{taskGroup.name}</div>
                <ul>
                  {taskGroup.tasks.map(task => (
                    <li
                      key={task.name}
                      className={`py-3 px-4 hover:bg-[var(--cds-layer-hover)] flex items-center justify-between cursor-pointer ${selectedTaskId === task.id ? 'bg-[var(--cds-layer-selected)]' : ''}`}
                      onClick={() => selectTask(task.id)}
                    >
                      {task.name}
                      {selectedTaskId === task.id && <Checkmark />}
                    </li>
                  ))}
                </ul>
                {taskGroups.length - 1 !== idx && <div className="bg-[var(--cds-border-subtle-00)] h-[1px] w-full"/>}
              </div>
            )))}
          </div>
        </div>
      </PageLayout.RightPanelContent>
      <PageLayout.RightPanelActions>
        <Button size="xl" className="w-1/2" kind="secondary" onClick={() => urlContext.setTaskSelectorPanelOpen(false)}>{t('cancel')}</Button>
        <Button
          size="xl"
          className="w-1/2"
          disabled={isAssignButtonDisabled}
          onClick={assignTreesToTask}>{t('taskManager.assign')}</Button>
      </PageLayout.RightPanelActions>
    </PageLayout.RightPanel>
  );
}
