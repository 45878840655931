import React, { useContext, useEffect, useMemo, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useMatch } from 'react-router-dom';
import useManagedAreas from '../../../managed-area/useManagedAreaList';
import useRightPanelContent, { RightPanelContent } from '../../../components/UI/Page/carbon/useRightPanelContent';
import { useTranslation } from 'react-i18next';
import { DisplayableTreeProperty } from '../../../tree/Tree';
import { Button, Header, IconButton, Modal } from '@carbon/react';
import { Close } from '@carbon/icons-react';
import PageLayout from '../../../components/UI/Page/carbon/PageLayout';
import CarbonFilterSelector from '../../CarbonInsights/components/CarbonFilterSelector';
import SelectableTreeTable, { CarbonDropdownItem } from './SelectableTreeTable';
import { useTaskGroups } from '../tasks/useTaskGroups';
import FluidTextInput from '@carbon/react/es/components/FluidTextInput/FluidTextInput';
import FluidTextArea from '@carbon/react/es/components/FluidTextArea/FluidTextArea';
import FluidDropdown from '@carbon/react/es/components/FluidDropdown/FluidDropdown';
import FluidDatePicker from '@carbon/react/es/components/FluidDatePicker/FluidDatePicker';
import FluidDatePickerInput from '@carbon/react/es/components/FluidDatePickerInput/FluidDatePickerInput';
import { usePossibleAssignees } from '../../../task-manager/usePossibleAssignees';
import { useWorkOrders } from '../../../task-manager/useWorkOrders';
import { StaticQueryKeys } from '../../../StaticQueryKeys';
import { useQueryClient } from 'react-query';
import { WorkOrderStatus } from '../../../task-manager/WorkOrder';

export default function CreateWorkOrder() {
  const { urlContext, taskManagerService } = useContext(DependencyInjectionContext);
  const match = useMatch('/organizations/:organizationId/*');
  const organizationId = match?.params?.organizationId || '';
  const { managedAreaList } = useManagedAreas(organizationId);
  const rightPanelContent = useRightPanelContent();
  const { t } = useTranslation();
  const { taskGroups } = useTaskGroups();
  const { possibleAssignees } = usePossibleAssignees(organizationId);
  const { workOrders } = useWorkOrders();
  const queryClient = useQueryClient();
  const workOrderId = urlContext.getWorkOrderId();
  const workOrder = workOrderId ? workOrders.find(it => it.id === urlContext.getWorkOrderId()) : null;

  const taskId = urlContext.getTaskId();

  const task = taskGroups.flatMap(taskGroup => taskGroup.tasks).find(task => task.id === taskId);

  const priorityOptions = [
    { id: 'low', label: t('taskManager.workOrderProperties.low') },
    { id: 'medium', label: t('taskManager.workOrderProperties.medium') },
    { id: 'high', label: t('taskManager.workOrderProperties.high') }
  ];

  const assigneeOptions = possibleAssignees.map(it => ({ id: it.id, label: it.getName() }));

  const statusOptions = [
    { id: WorkOrderStatus.TO_DO },
    { id: WorkOrderStatus.IN_PROGRESS },
    { id: WorkOrderStatus.COMPLETED },
    { id: WorkOrderStatus.COMPLETED_AND_ACCEPTED }
  ].map(it => ({ id: it.id, label: t(`taskManager.workOrderStatus.${it.id}`) }));

  const [workOrderTitle, setWorkOrderTitle] = useState<string>(workOrder?.name ?? '');
  const [description, setDescription] = useState<string>(workOrder?.description ?? '');
  const [assignee, setAssignee] = useState<CarbonDropdownItem | null>(assigneeOptions.find(it => it.id === workOrder?.assignee?.id) || null);
  const [priority, setPriority] = useState<CarbonDropdownItem | null>(priorityOptions.find(it => it.id === workOrder?.priority) || null);
  const [deadline, setDeadline] = useState<Date | null>(workOrder?.deadline ? new Date(workOrder.deadline) : null);
  const [archiving, setArchiving] = useState(false);
  const [status, stStatus] = useState<CarbonDropdownItem | null>(statusOptions.find(it => it.id === workOrder?.status) || null);

  const handleArchive = async () => {
    if (!workOrderId) return;
    await taskManagerService.updateWorkOrder(organizationId, workOrderId, {
      status: WorkOrderStatus.ARCHIVED
    });
    await queryClient.invalidateQueries(StaticQueryKeys.TREE_DETAILS);
    setArchiving(false);
    urlContext.setFullScreenModalOpen(null);
  };

  const selectedManagedAreas = useMemo(() => {
    const ManagedAreasInURL = urlContext.getManagedAreaIds();
    return (managedAreaList ?? []).filter(it => urlContext.getReverseMASelection() ? !ManagedAreasInURL.includes(it.id) : ManagedAreasInURL.includes(it.id));
  }, [JSON.stringify(urlContext.getReverseMASelection()), managedAreaList, JSON.stringify(urlContext.getManagedAreaIds())]);

  const selectedManagedAreaIds = useMemo(() => selectedManagedAreas.map(it => it.id), [selectedManagedAreas]);

  // useEffects
  useEffect(() => {
    if (urlContext.getVisibleTableProperties() === null || urlContext.getVisibleTableProperties()?.filter(it => it !== DisplayableTreeProperty.ExternalId).length === 0) {
      urlContext.setVisibleTableProperties([DisplayableTreeProperty.Genus, DisplayableTreeProperty.Species, DisplayableTreeProperty.Height, DisplayableTreeProperty.Status, DisplayableTreeProperty.TrunkDiameter], { replace: true });
    }
  }, []);

  const minDate = new Date();
  minDate.setHours(0, 0, 0, 0);
  return (
    <div>
      <Header className="flex justify-between pl-4 text-sm font-semibold" aria-label={workOrderId ? t('taskManager.editWorkOrder') : t('taskManager.createWorkOrder')}>
        {workOrderId ? t('taskManager.editWorkOrder') : t('taskManager.createWorkOrder')}
        <IconButton onClick={() => urlContext.setFullScreenModalOpen(null)} label={t('tooltips.close')} kind="ghost"><Close/></IconButton>
      </Header>
      <div className='grid grid-cols-[320px_1fr] pl-8 py-8'>
        <div className="col-start-1 col-end-2 flex flex-col gap-4 max-h-fit">
          <div className="flex flex-col">
            <span className="text-[var(--cds-text-secondary)] text-sm">{t('taskManager.workOrderId')}</span>
            <span className="text-[var(--cds-text-primary)] text-normal">{workOrder?.code ?? '-'}</span>
          </div>
          <div className="flex flex-col">
            <span className="text-[var(--cds-text-secondary)] text-sm">{t('taskManager.task')}</span>
            <span className="text-[var(--cds-text-primary)] text-normal">{task?.name}</span>
          </div>
          <div className="flex flex-col">
            <span className="text-[var(--cds-text-secondary)] text-sm">{t('taskManager.taskTypeLabel')}</span>
            <span
              className="text-[var(--cds-text-primary)] text-normal">{taskGroups.find(it => it.tasks.find(it => it.id === taskId))?.name}</span>
          </div>
          <div className="bg-[var(--cds-border-subtle-00)] h-[1px] w-full" />
          <div>
            <FluidTextInput
              id="workOrderTitle"
              labelText={t('taskManager.workOrderTitle')}
              value={workOrderTitle}
              onChange={e => setWorkOrderTitle(e.target.value)}
            />
          </div>
          <FluidTextArea
            id="description"
            labelText={t('taskManager.description')}
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          <div>
            <FluidDropdown
              id="assignee"
              titleText={t('taskManager.assignee')}
              label=""
              items={assigneeOptions}
              value={assignee}
              initialSelectedItem={assignee}
              onChange={it => setAssignee(it.selectedItem)}
              itemToString={item => item?.label || t('taskManager.unassigned')}
            />
          </div>
          <div>
            <FluidDropdown
              id="priority"
              titleText={t('taskManager.priority')}
              label=""
              items={priorityOptions}
              value={priority}
              initialSelectedItem={priority}
              onChange={it => setPriority(it.selectedItem)}
              itemToString={item => item?.label || ''}
            />
          </div>
          <div>
            <FluidDatePicker
              className="max-w-none w-full [&>div>div]:w-full"
              minDate={minDate.getTime()}
              datePickerType="single"
              onChange={e => setDeadline(e[0])}
              value={deadline}
            >
              <FluidDatePickerInput id="deadline" labelText={t('taskManager.deadline')} />
            </FluidDatePicker>
          </div>
          {workOrderId &&
            <>
              <div>
                <FluidDropdown
                  id="status"
                  titleText={t('taskManager.status')}
                  label=""
                  items={statusOptions}
                  value={status}
                  initialSelectedItem={status}
                  onChange={it => stStatus(it.selectedItem)}
                  itemToString={item => item?.label || ''}
                />
              </div>
              {workOrder?.status === WorkOrderStatus.COMPLETED_AND_ACCEPTED && <div className="flex justify-end">
                <Button
                  onClick={() => setArchiving(true)}
                  label={t('taskManager.archive')}
                  kind="secondary"
                >
                  {t('taskManager.archive')}
                </Button>
                <Modal
                  open={archiving}
                  onRequestClose={() => setArchiving(false)}
                  onRequestSubmit={handleArchive}
                  modalHeading={t('taskManager.archive')}
                  primaryButtonText={t('taskManager.archive')}
                  secondaryButtonText={t('taskManager.cancel')}
                >
                  {t('taskManager.archiveMessage')}
                </Modal>
              </div>}
            </>
          }
        </div>
        <div className="col-start-2 col-end-5">
          <SelectableTreeTable
            areaFilterIsSelected={selectedManagedAreaIds.length > 0}
            open={true}
            workOrderTitle={workOrderTitle}
            description={description}
            assignee={assignee}
            priority={priority}
            deadline={deadline}
            selectedTreeIds={workOrder?.treeIds ?? null}
            status={status}
          />
        </div>
      </div>
      {rightPanelContent === RightPanelContent.FILTER_PANEL &&
        <PageLayout.RightPanel>
          <CarbonFilterSelector />
        </PageLayout.RightPanel>
      }
    </div>
  );
}
