import PageLayout from '../../components/UI/Page/carbon/PageLayout';
import { useMatch, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../account/useAccounts';
import { AccountType } from '../../account/AccountType';
import React, { useContext, useMemo } from 'react';
import Cockpit from './cockpit/Cockpit';
import { FullScreenModal } from './FullScreenModal';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import TaskGroups from './tasks/TaskGroups';
import ManualAssignment from './manual-assignment/ManualAssignment';
import CreateWorkOrder from './work-orders/CreateWorkOrder';
import WorkOrderArchive from './work-orders/WorkOrderArchive';

export default function CarbonTaskManager() {
  const match = useMatch('/organizations/:organizationId/task-manager/:subItem/*');
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const navigate = useNavigate();
  const { urlContext } = useContext(DependencyInjectionContext);

  const title = useMemo(() => {
    if (match?.params.subItem === 'cockpit') return t('mainMenu.taskManager');
    if (match?.params.subItem === 'tasks') return t('mainMenu.manageTasks');
    if (match?.params.subItem === 'manual-assignment') return t('mainMenu.manualAssignment');
    return t('mainMenu.taskManager');
  }, [match?.params.subItem]);

  if (account.type === AccountType.Client) {
    navigate('/organizations/' + match?.params.organizationId + '/inventory');
    return null;
  }

  return (
    <PageLayout pageTitle={title}>
      <PageLayout.Content>
        {!urlContext.getFullScreenModalOpen() && <Cockpit />}
        {urlContext.getFullScreenModalOpen() === FullScreenModal.Tasks && <TaskGroups />}
        {urlContext.getFullScreenModalOpen() === FullScreenModal.ManualAssignment && <ManualAssignment />}
        {urlContext.getFullScreenModalOpen() === FullScreenModal.CreateWorkOrder && urlContext.getTaskId() && <CreateWorkOrder />}
        {urlContext.getFullScreenModalOpen() === FullScreenModal.EditWorkOrder && urlContext.getWorkOrderId() && <CreateWorkOrder />}
        {urlContext.getFullScreenModalOpen() === FullScreenModal.WorkOrderArchive && <WorkOrderArchive />}
      </PageLayout.Content>
    </PageLayout>
  );
}
