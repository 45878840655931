import React, { useContext, useEffect, useMemo, useState } from 'react';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import ManagedAreaSelector from '../CarbonInsights/components/ManagedAreaSelector';
import { Button, IconButton } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import ColumnSelector from '../CarbonInsights/components/ColumnSelector';
import { DisplayableTreeProperty } from '../../tree/Tree';
import { ArrowsHorizontal, CloseLarge, Filter } from '@carbon/icons-react';
import PageLayout from '../../components/UI/Page/carbon/PageLayout';
import { SortableItem, SortableList } from '../CarbonInsights/components/SortableList';
import useRightPanelContent, { RightPanelContent } from '../../components/UI/Page/carbon/useRightPanelContent';

interface TableDashboardProps {
  children: React.ReactNode
}

export default function TableDashboard({ children }: TableDashboardProps) {
  const { t } = useTranslation();
  const { urlContext } = useContext(DependencyInjectionContext);
  const missingAreaSelection = useMemo(() => (!urlContext.getReverseMASelection() && urlContext.getManagedAreaIds().length === 0),
    [JSON.stringify(urlContext.getManagedAreaIds()), JSON.stringify(urlContext.getReverseMASelection())]);
  const [items, setItems] = useState<SortableItem[]>([]);
  const rightPanelContent = useRightPanelContent();

  useEffect(() => {
    setItems((urlContext.getVisibleTableProperties() ?? []).map(item => ({ id: item, content: t(`tree.${item}`) })));
  }, [urlContext.getVisibleTableProperties()?.join(',')]);

  return (
    <div className={`flex justify-between m-8 mt-2 gap-2 ${urlContext.isFilterPanelOpen() ? 'flex-col' : ''}`} data-testid="table-dashboard">
      <div className="flex items-center justify-between w-full">
        <div className='flex gap-0.5'>
          <ManagedAreaSelector />
          <ColumnSelector disabled={missingAreaSelection} />
          <IconButton
            label={t('tooltips.filter')}
            kind="ghost"
            style={urlContext.isFilterPanelOpen() ? { backgroundColor: 'var(--cds-button-secondary)' } : {}}
            onClick={() => {
              urlContext.setFilterPanelOpen(!urlContext.isFilterPanelOpen());
            }}
            disabled={missingAreaSelection}
          >
            <Filter style={urlContext.isFilterPanelOpen() ? { fill: 'white' } : {}} />
          </IconButton>
          <IconButton
            label={'Order columns'}
            kind="ghost"
            onClick={() => urlContext.setIsColumnSorterOpen(!urlContext.isColumnSorterOpen())}
            disabled={missingAreaSelection}
          >
            <ArrowsHorizontal />
          </IconButton>
        </div>
        <div>
          {children}
        </div>
      </div>
      {rightPanelContent === RightPanelContent.COLUMN_ORDERING_PANEL &&
        <PageLayout.RightPanel>
          <PageLayout.RightPanelContent>
            <div className="pl-4 pt-2 pr-2">
              <div className="flex items-center justify-between">
                <h3 className="m-0 text-xl text-[var(--cds-text-primary)] font-normal">Ordering Columns</h3>
                <IconButton onClick={() => urlContext.setIsColumnSorterOpen(false)} label={t('filter.close')} align="left" kind="ghost"><CloseLarge/></IconButton>
              </div>
              <p className="text-xs text-[var(--cds-text-secondary)]">Drag and drop  items to change the order of columns</p>
              <SortableList items={items} setItems={setItems}/>
            </div>
          </PageLayout.RightPanelContent>
          <PageLayout.RightPanelActions>
            <Button size="xl" className="w-1/2" kind="secondary" onClick={() => urlContext.setIsColumnSorterOpen(false)}>{t('cancel')}</Button>
            <Button
              size="xl"
              className="w-1/2"
              onClick={() => {
                urlContext.setVisibleTableProperties(items.map(item => item.id as DisplayableTreeProperty));
                urlContext.setIsColumnSorterOpen(false);
              }}>{t('apply')}</Button>
          </PageLayout.RightPanelActions>
        </PageLayout.RightPanel>
      }
    </div>
  );
}
