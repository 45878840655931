import PageLayout from '../../components/UI/Page/carbon/PageLayout';
import { CheckCircle, Circle, NavArrowLeft, NavArrowRight, TaskList, WarningTriangle, Xmark } from 'iconoir-react';
import { TFunction, useTranslation } from 'react-i18next';
import Header from './Header';
import { Navigate, useLocation, useMatch, useNavigate } from 'react-router-dom';
import useTree from '../../tree/useTree';
import DataCollection, { DataCollectionSections } from './DataCollection/DataCollection';
import { DisplayableTreeProperty, TreeDto } from '../../tree/Tree';
import { TrackableTreeProperty } from '../../tree/TrackableTreeProperty';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import TreeDisplayTile from './TreeDisplayTile/TreeDisplayTile';
import DetailedTree, { ViStatus } from '../../tree/DetailedTree';
import Button, { ButtonVariant } from '../../components/UI/Button/Button';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import CircularIconButton from '../../components/UI/Button/CircularIconButton';
import { useWorkOrder } from '../../task-manager/useWorkOrder';
import { ContextFields } from '../Explore/UrlContext';
import AbsoluteModal from '../../components/Modal/absolute-modal/AbsoluteModal';
import useConfirmationModal from '../../components/UI/ConfirmationModal/useConfirmationModal';
import CurrentState from './CurrentState/CurrentState';
import Static from './Static/Static';
import { dismissibleErrorToast, dismissibleSuccessToast } from '../../components/UI/Toast/DismissibleToast';
import { useCurrentAccount } from '../../account/useAccounts';
import { Flippers } from '../../switches/Flippers';
import TreeDeleteModal from './TreeDeleteModal';
import { RiskRating } from '../../property-enums/RiskRating';
import { useTaskTemplates } from '../TaskManager/create/useTaskTemplates';
import { TaskTemplateDedicatedFor } from '../TaskManager/create/TaskTemplate';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button as CarbonButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Tooltip
} from '@carbon/react';
import { CaretLeft, CaretRight, TrashCan } from '@carbon/icons-react';
import { TreeDisplays } from '../LegacyDetails/constants';
import MonitorOverTime from './MonitorOverTime/MonitorOverTime';
import FAQPanel from '../../components/UI/Carbon/FAQPanel/FAQPanel';
import { Features } from '../../switches/Features';

export type ExtraObject = DisplayableTreeProperty.CanopyHeight
  | DisplayableTreeProperty.CanopyWidth
  | DisplayableTreeProperty.TrunkDiameter
  | DisplayableTreeProperty.TrunkHeight
  | DisplayableTreeProperty.Height
  | DisplayableTreeProperty.TreeProtectionZone
  | DisplayableTreeProperty.CriticalRootZone
  | DisplayableTreeProperty.StructuralCriticalRootZone
  | 'all-dimension'
  | 'safetyFactor'
  | 'leaningAngle'
  | 'all-safety';

export enum ClearanceTypes {
  Wire = 'wire',
  Road = 'road',
  Building = 'building',
  TrafficSign = 'trafficSign',
  Visibility = 'visibility'
}

export type CohortValues = {
  [key in TrackableTreeProperty]: {
    mu: number,
    sigma: number
  }
};

export type Cohort = {
  cohortValues: CohortValues,
  genus: string,
  id: string,
  maxTrunkDiameter: number,
  minTrunkDiameter: number,
  name: string,
  organizationId: string
};

type DetailsContextPredicate = (value: ExtraObject[]) => ExtraObject[];
type DetailsContextType = {
  rulers: ExtraObject[],
  setRulers: (extra: ExtraObject[] | DetailsContextPredicate) => void,
  clearances: ClearanceTypes[],
  setClearances: (clearance: ClearanceTypes[]) => void,
  riskOverlayIsVisible: boolean,
  setRiskOverlayIsVisible: (value: boolean) => void,
  showRootZone: boolean,
  showTPZ: boolean,
  setTPZVisibility: (value: boolean) => void,
  showCRZ: boolean,
  setCRZVisibility: (value: boolean) => void,
  showSCRZ: boolean,
  setSCRZVisibility: (value: boolean) => void,
  setRootZoneVisibility: (value: boolean) => void
};
export const DetailsContext = createContext<DetailsContextType>({
  rulers: [],
  setRulers: () => {
  },
  clearances: [],
  setClearances: () => {
  },
  riskOverlayIsVisible: false,
  setRiskOverlayIsVisible: () => {
  },
  showRootZone: false,
  showTPZ: false,
  showCRZ: false,
  showSCRZ: false,
  setRootZoneVisibility: () => {
  },
  setTPZVisibility: () => {
  },
  setCRZVisibility: () => {
  },
  setSCRZVisibility: () => {
  }
});

const initializeTreeWithDefaultObservations = (tree: DetailedTree | null, t: TFunction): DetailedTree | null => {
  if (!tree) return null;
  if (!tree.observations.map(it => it.name).includes(DisplayableTreeProperty.LeaningAngle)) {
    tree.observations.push({
      name: DisplayableTreeProperty.LeaningAngle,
      mitigations: [],
      photoUrls: [],
      affectedTreePart: 'trunk',
      conditionOfConcern: false,
      note: `${t('parameters.leaningAngle')}: ${tree.leaningAngle?.toFixed(2)}`
    });
  }
  if (!tree.observations.map(it => it.name).includes(DisplayableTreeProperty.LiveCrownRatio) && tree.liveCrownRatio) {
    tree.observations.push({
      name: DisplayableTreeProperty.LiveCrownRatio,
      mitigations: [],
      photoUrls: [],
      affectedTreePart: 'crown',
      conditionOfConcern: false,
      note: `${t('tree.liveCrownRatio')}: ${tree.liveCrownRatio?.toFixed(2)}`
    });
  }
  return tree;
};

export default function CarbonDetails() {
  const { t } = useTranslation();
  const { urlContext, treeService } = useContext(DependencyInjectionContext);
  const inventoryUrl = useMatch('/organizations/:organizationId/inventory/trees/:treeId/*');
  const workOrderUrl = useMatch('/organizations/:organizationId/remote-inspections/:workOrderId/*');
  const workOrderId = workOrderUrl?.params?.workOrderId || '';
  const organizationId = (workOrderId ? workOrderUrl?.params?.organizationId : inventoryUrl?.params?.organizationId) || '';
  const treeId = (workOrderId ? urlContext.getTreeId() : inventoryUrl?.params?.treeId) || '';
  const { tree, isTreeLoading } = useTree(organizationId, treeId);
  const { workOrder, refetch } = useWorkOrder(organizationId, workOrderId);
  const { confirm, modal } = useConfirmationModal();
  const { taskTemplates } = useTaskTemplates(organizationId);
  const primaryTile = urlContext.getDetailsPrimary() || TreeDisplays.TWIN_VIEW;
  const secondaryTile = urlContext.getDetailsSecondary() || TreeDisplays.NULL;

  const navigate = useNavigate();
  const location = useLocation();

  const [editingSection, setEditingSection] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [editableTree, setEditableTree] = useState<DetailedTree | null>(initializeTreeWithDefaultObservations(tree, t));
  const [alertOpen, setAlertOpen] = useState(false);
  const [isCaptureModeActive, setCaptureModeActive] = useState(false);
  const [showRootZone, setRootZoneVisibility] = useState(false);
  const [showTPZ, setTPZVisibility] = useState(false);
  const [showCRZ, setCRZVisibility] = useState(false);
  const [showSCRZ, setSCRZVisibility] = useState(false);
  const [rulers, setRulers] = useState<ExtraObject[]>([]);
  const [clearances, setClearances] = useState<ClearanceTypes[]>([]);
  const [riskOverlayIsVisible, setRiskOverlayIsVisible] = useState<boolean>(false);
  const [deletingTree, setDeletingTree] = useState(false);
  const [expandedViewer, setExpandedViewer] = useState(false);
  const account = useCurrentAccount();
  const { organization } = account;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabChange = evt => {
    setSelectedTabIndex(evt.selectedIndex);
  };

  const hasExternalSystem = () => !!organization?.metaData?.externalSystemAccess;
  const getExternalSystemLink = () => {
    if (!hasExternalSystem()) return null;
    const systemUrl: string = organization?.metaData?.externalSystemAccess?.url;
    const systemName: string = organization?.metaData?.externalSystemAccess?.name;
    const identifier: string = tree?.[organization?.metaData?.externalSystemAccess?.identifierProperty]?.toString();
    if (!systemUrl || !identifier) return null;
    return {
      url: systemUrl.replace('$identifier', identifier),
      name: systemName
    };
  };

  const currentTreeIndex = workOrder?.treeIds.findIndex(it => it === treeId) || 0;
  const { isPrevTree, isNextTree } = useMemo(() => {
    if (!workOrder) return { isPrevTree: false, isNextTree: false };
    return { isPrevTree: currentTreeIndex > 0, isNextTree: currentTreeIndex < workOrder.treeIds.length - 1 };
  }, [currentTreeIndex, workOrder]);
  const isTreeEdited = JSON.stringify(tree) !== JSON.stringify(editableTree);

  const neighbouringTrees = useMemo(() => {
    if (workOrder) return null;
    const saved = localStorage.getItem('loadedTableTrees');
    const trees = saved ? JSON.parse(saved) : [];
    if (trees.length < 1) return null;
    const currentIndex = trees.findIndex(it => it.id === treeId);
    if (isNaN(currentIndex)) return null;
    return {
      prev: currentIndex === 0 ? null : trees[currentIndex - 1],
      next: currentIndex === trees.length - 1 ? null : trees[currentIndex + 1]
    };
  }, [tree]);

  // Do not clear tree (and screen) on switch to another tree
  useEffect(() => {
    if (tree) {
      setEditableTree(tree);
    }
  }, [tree]);

  useEffect(() => {
    setExpandedViewer(window.innerWidth > 1440);
    const handleResize = () => {
      setExpandedViewer(window.innerWidth > 1440);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const treeIsUnchanged = JSON.stringify(tree) === JSON.stringify(editableTree);

  const handleSave = async () => {
    if (!editableTree || !tree || !workOrder) return;
    const changedProperties = Object.keys(editableTree).filter(key => editableTree[key] !== tree[key]);

    setLoading(true);
    const payload = {} as Partial<TreeDto>;
    changedProperties.forEach(property => {
      if (property === 'potentialTargets') {
        payload[property] = editableTree[property]!.filter(it => it.targetDescription.length > 0);
      } else if (property === 'limbs') {
        payload[property] = editableTree[property].filter(it => it.diameter !== undefined);
      } else {
        payload[property] = editableTree[property];
      }
    });
    payload.viStatus = ViStatus.TO_BE_SCHEDULED;

    try {
      await treeService.update(organizationId, treeId, payload);
      dismissibleSuccessToast(t('treeDetails.treeUpdated'));
      setEditingSection(null);
    } catch (e: unknown) {
      dismissibleErrorToast(t('genericError'));
    } finally {
      setLoading(false);
      const newWorkOrder = await refetch();
      const { id, nextTodoTreeId } = newWorkOrder.data || {};
      if (id && nextTodoTreeId) {
        navigate(`/organizations/${organizationId}/remote-inspections/${id}?${ContextFields.TreeId}=${nextTodoTreeId}`);
      }
    }
  };

  const handleDiscard = async () => {
    const config = {
      message: t('treeDetails.areYouSureYouWantToDiscard'),
      confirmText: t('treeDetails.discard'),
      cancelText: t('treeDetails.cancel')
    };

    if (await confirm(config)) {
      const editingObservationSection = editingSection && [DataCollectionSections.Trunk, DataCollectionSections.RootsAndRootCollar, DataCollectionSections.CrownAndBranches].includes(editingSection as DataCollectionSections);
      if (!editingObservationSection) setEditingSection(null);
      if (!editableTree || !tree) return;
      setEditableTree(tree);
      dismissibleSuccessToast(t('treeDetails.changesDiscarded'));
    }
  };

  // eslint-disable-next-line @typescript-eslint/comma-dangle
  const handleChange = <T, >(property: string, value: T) => {
    setEditableTree(prev => ({
      ...prev,
      [property]: value
    } as DetailedTree));
  };

  const toggleOnSiteRequest = () => {
    if (editableTree?.mitigations?.filter(it => it.name === 'On-site inspection').length) {
      handleChange('mitigations', editableTree?.mitigations?.filter(it => it.name !== 'On-site inspection') || []);
    } else {
      handleChange(
        'mitigations',
        [
          ...(editableTree?.mitigations || []),
          {
            name: 'On-site inspection',
            residualRisk: RiskRating.LOW,
            taskTemplateId: taskTemplates?.find(it => it.dedicatedFor === TaskTemplateDedicatedFor.ON_SITE_INSPECTION)?.id,
            workOrderId: workOrder?.id
          }
        ]
      );
    }
  };

  const onSiteRequested = useMemo(() => !!editableTree?.mitigations?.filter(it => it.name === 'On-site inspection').length, [editableTree]);

  const inventorySearchParams = new URLSearchParams(localStorage.getItem('inventorySearchParams') ?? '');
  inventorySearchParams.set('s', urlContext.getSidebarOpen() ? 'true' : 'false');

  if (organization.id && !organization.isEnabled(Flippers.workspace)) {
    return <Navigate to={`/organizations/${organizationId}/trees/${treeId}/details`}/>;
  }

  const tabs = [{
    name: 'currentState',
    panel: <TabPanel className="p-0"><CurrentState
      tree={editableTree}
      handleChange={handleChange}
      disableEdit={!workOrderId}
      workOrderId={workOrderId}/></TabPanel>
  }, {
    name: 'static',
    panel: <TabPanel className="p-0">
      <Static tree={editableTree}/>
      {!isLoading && !workOrder && account.isAdminOrAbove() && !organization.isEnabled(Flippers.sprint77) &&
        <div className="flex justify-end">
          <CarbonButton
            kind="danger--ghost"
            onClick={() => setDeletingTree(true)}
            className="flex justify-center gap-2 items-center"
          >
            {t('treeDetails.deleteTree.button')} <TrashCan/>
          </CarbonButton>
        </div>}
    </TabPanel>
  }];

  if (!organization.isFeatureEnabled(Features.davey)) {
    tabs.push({
      name: 'dataCollection',
      panel: <TabPanel className="p-0">
        <DataCollection
          key={editableTree?.id}
          tree={editableTree}
          handleChange={handleChange}
          editingSection={editingSection}
          setEditingSection={setEditingSection}
          isCaptureModeActive={isCaptureModeActive}
          setCaptureModeActive={setCaptureModeActive}
        />
      </TabPanel>
    });
  }

  if ((tree?.tseTreeScans?.length ?? 0) > 1) {
    tabs.push({
      name: 'monitorOverTime',
      panel: <TabPanel className="p-0">
        <MonitorOverTime tree={tree} />
      </TabPanel>
    });
  }

  return (
    <DetailsContext.Provider
      value={{
        rulers,
        setRulers,
        clearances,
        setClearances,
        riskOverlayIsVisible,
        setRiskOverlayIsVisible,
        showRootZone,
        setRootZoneVisibility,
        showTPZ,
        setTPZVisibility,
        showCRZ,
        setCRZVisibility,
        showSCRZ,
        setSCRZVisibility
      }}>
      <PageLayout
        pageTitle={`${organization.isFeatureEnabled(Features.davey) ? 'Smart Tree' : t('treeDetails.tree')} ${tree?.externalId || ''}`}
        externalSystemLink={getExternalSystemLink()}>
        <PageLayout.Content>
          <div className={`${(!workOrder && neighbouringTrees) ? '' : 'pt-4'} px-6 flex flex-col gap-3 w-full h-full`}>
            <div className="flex items-center justify-between">
              <div className={'flex flex-row gap-3 items-center'}>
                <Breadcrumb noTrailingSlash>
                  <BreadcrumbItem
                    href={`/organizations/${account.organization.id}/inventory?${inventorySearchParams.toString()}`}>{t('mainMenu.insights')}</BreadcrumbItem>
                  <BreadcrumbItem
                    isCurrentPage>{`${organization.isFeatureEnabled(Features.davey) ? 'Smart Tree' : t('treeDetails.tree')} ${editableTree?.externalId || ''}`}</BreadcrumbItem>
                </Breadcrumb>
                {(tree?.userUpdatedProperties && tree.userUpdatedProperties.length > 0) && <Tag type={'magenta'}>
                  {t('details.manuallyUpdated')}
                </Tag>}
              </div>
              {(!workOrder && neighbouringTrees) && (
                <div className="flex gap-4">
                  <Tooltip label={t('tooltips.previousTree')} align="bottom">
                    <CarbonButton
                      kind="ghost"
                      className="flex items-center gap-4 p-0 px-4 text-[var(--cds-text-primary)]"
                      onClick={() => {
                        const searchParams = location.search ? new URLSearchParams(location.search) : new URLSearchParams();
                        if (searchParams.get(ContextFields.CapturePointId)) searchParams.delete(ContextFields.CapturePointId);
                        navigate(`/organizations/${organizationId}/inventory/trees/${neighbouringTrees?.prev.id}?${searchParams.toString()}`);
                      }}
                      disabled={!neighbouringTrees?.prev}
                    >
                      <CaretLeft/>
                      {neighbouringTrees?.prev?.externalId}
                    </CarbonButton>
                  </Tooltip>
                  <Tooltip label={t('tooltips.nextTree')} align="bottom">
                    <CarbonButton
                      kind="ghost"
                      className="flex items-center gap-4 p-0 px-4 text-[var(--cds-text-primary)]"
                      onClick={() => {
                        const searchParams = location.search ? new URLSearchParams(location.search) : new URLSearchParams();
                        if (searchParams.get(ContextFields.CapturePointId)) searchParams.delete(ContextFields.CapturePointId);
                        navigate(`/organizations/${organizationId}/inventory/trees/${neighbouringTrees?.next.id}?${searchParams.toString()}`);
                      }}
                      disabled={!neighbouringTrees?.next}
                    >
                      {neighbouringTrees?.next?.externalId}
                      <CaretRight/>
                    </CarbonButton>
                  </Tooltip>
                </div>
              )}
            </div>

            <Header tree={editableTree} workOrder={workOrder} isTreeEdited={isTreeEdited}/>
            <div
              className="flex gap-6 w-full flex-1 h-[85%]"> {/* I don't know why does the scrolling work with h-[85%] */}
              <div
                className="flex-1 relative"
                style={{
                  flexBasis: `${expandedViewer ? '66%' : '50%'}`
                }}
              >
                <div className="absolute inset-0 flex flex-row gap-6">
                  {tree && <TreeDisplayTile
                    tree={tree}
                    isCaptureModeActive={isCaptureModeActive}
                    setCaptureModeActive={setCaptureModeActive}
                    primaryTile={primaryTile}
                    secondaryTile={secondaryTile}
                    expandedViewer={expandedViewer}
                  />}
                </div>
              </div>
              <div
                className="max-w-[600px] basis-[50%] relative overflow-hidden"
                style={{
                  flexBasis: `${expandedViewer ? '33%' : '50%'}`
                }}
              >
                <Tabs onChange={handleTabChange} selectedIndex={selectedTabIndex}>
                  <nav>
                    <TabList aria-label="List of tabs" contained>
                      {tabs.map((tab, index) => (
                        <Tab
                          key={index}
                        >
                          {t('treeDetails.tabs.' + tab.name)}
                        </Tab>
                      ))}
                    </TabList>
                  </nav>
                  <TabPanels>
                    {tabs.map((tab, i) => <div
                      className={`overflow-y-auto scrollbar-width-none ${selectedTabIndex === i ? 'h-[calc(100%-48px)]' : ''}  bg-[var(--cds-layer-01)]`}
                      key={tab.name}>
                      {tab.panel}
                    </div>)}
                  </TabPanels>
                </Tabs>
              </div>
            </div>
            {workOrder ? (
              <div
                className={`flex ${(workOrder.treeIds || []).length > 1 ? 'justify-between' : 'justify-end'} mb-3 items-center`}>
                {(workOrder.treeIds || []).length > 1 && (
                  <div className="bg-outer-space-700 flex items-center gap-4 p-1 rounded-full">
                    <CircularIconButton
                      variant={ButtonVariant.Secondary}
                      icon={<NavArrowLeft className="w-5 h-5"/>}
                      disabled={!isPrevTree}
                      onClick={() => {
                        if (isTreeEdited) return setAlertOpen(true);
                        navigate(`/organizations/${organizationId}/remote-inspections/${workOrder.id}?${ContextFields.TreeId}=${workOrder.treeIds[currentTreeIndex - 1]}`);
                      }}
                    />
                    <div className="flex items-center gap-1">
                      <TaskList className="w-5 h-5"/>
                      <span>
                        <span className="text-xl">{currentTreeIndex + 1}</span> / <span
                          className="text-base">{workOrder.treeIds.length ?? ''}</span>
                      </span>
                    </div>
                    <CircularIconButton
                      variant={ButtonVariant.Secondary}
                      icon={<NavArrowRight className="w-5 h-5"/>}
                      disabled={!isNextTree}
                      onClick={() => {
                        if (isTreeEdited) return setAlertOpen(true);
                        navigate(`/organizations/${organizationId}/remote-inspections/${workOrder.id}?${ContextFields.TreeId}=${workOrder.treeIds[currentTreeIndex + 1]}`);
                      }}
                    />
                  </div>
                )}
                <div className="flex gap-5 items-center">
                  <div className="text-sm w-44">
                    <Button
                      variant={ButtonVariant.Secondary}
                      onClick={handleDiscard}
                      disabled={isTreeLoading || isLoading || treeIsUnchanged}
                      className="flex items-center justify-center gap-2 py-2"
                    >
                      <Xmark className="size-5"/>
                      Discard
                    </Button>
                  </div>
                  <SignButton
                    onClick={handleSave}
                    disabled={isTreeLoading || isLoading || (tree?.viStatus === ViStatus.TO_BE_SCHEDULED && !isTreeEdited)}
                    toggleOnSiteRequest={toggleOnSiteRequest}
                    onSiteRequested={onSiteRequested}
                  />
                </div>
              </div>
            ) : <div className="mb-4"></div>}
          </div>
          <AbsoluteModal isVisible={deletingTree} onHide={() => setDeletingTree(false)}>
            <TreeDeleteModal setDeletingTree={setDeletingTree} treeId={treeId}/>
          </AbsoluteModal>
          <AbsoluteModal isVisible={alertOpen} onHide={() => setAlertOpen(false)}>
            <section
              className="bg-outer-space-700 rounded-md p-10 max-w-sm flex flex-col gap-5 justify-center items-center">
              <div className="rounded-full bg-danger-600 w-fit p-3 flex justify-center items-center">
                <WarningTriangle/>
              </div>
              <span className="text-center">
                {t('remoteInspection.pleaseSignOrDiscard')}
              </span>
              <div className="flex gap-5 w-full mt-3">
                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={() => setAlertOpen(false)}
                >
                  {t('ok')}
                </Button>
              </div>
            </section>
          </AbsoluteModal>
          {modal}
        </PageLayout.Content>
        {urlContext.getFAQPanelOpen() &&
          <PageLayout.RightPanel>
            <FAQPanel />
          </PageLayout.RightPanel>
        }
      </PageLayout>
    </DetailsContext.Provider>
  );
}

function SignButton(props: {
  onClick: () => void,
  disabled: boolean,
  onSiteRequested: boolean,
  toggleOnSiteRequest: () => void
}) {
  const [isOpen, setOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const handleClick = () => {
    if (!isOpen) return setOpen(true);
    if (isOpen && confirmed) {
      props.onClick();
      setOpen(false);
      setConfirmed(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    event.stopPropagation();
    const container = document.getElementById('sign-tree-button');
    if (!container?.contains(event.target as Node) && isOpen) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen]);

  return (
    <div className="twp relative text-sm" id="sign-tree-button">
      <div className="flex items-center">
        <button
          onClick={props.toggleOnSiteRequest}
          disabled={props.disabled}
          className="rounded-l-full text-nowrap flex items-center justify-center gap-2 py-2 px-4 z-20 border border-outer-space-400"
        >
          {props.onSiteRequested ? <CheckCircle className="size-4"/> : <Circle className="size-4"/>}
          Request on-site inspection
        </button>
        <button
          onClick={handleClick}
          disabled={(isOpen && !confirmed) || props.disabled}
          className="rounded-r-full w-44 text-nowrap flex items-center justify-center gap-2 py-2 z-20 bg-greehill-600 hover:bg-greehill-800 border border-greehill-600 disabled:bg-outer-space-400"
        >
          <CheckCircle className="size-4"/>
          Sign Inspection
        </button>
      </div>
      {isOpen && (
        <div
          className="absolute right-0 bottom-1/2 flex gap-2 p-3 pb-8 rounded-2xl max-w-44 bg-greehill-00 z-10 rounded-b text-black-haze-950">
          <div className="mt-1"><Checkbox checked={confirmed} onChange={() => setConfirmed(prev => !prev)}/></div>
          <div>Please check to confirm before signing</div>
        </div>
      )}
    </div>
  );
}
