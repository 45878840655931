import { Fragment, PropsWithChildren, useCallback, useContext, useEffect } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import getRuntimeConfig from '../../RuntimeConfig';
import axios from 'axios';
import { useCurrentAccount } from '../../account/useAccounts';

export const SASTokenProvider = (props: PropsWithChildren<any>) => {
 const { user } = useContext(AuthContext);

 const updateSASToken = useCallback(async () => {
  try {
   const { apiUrl } = getRuntimeConfig();
   await axios.get(`${apiUrl}/v1/cdnToken`, { withCredentials: true }).then(response => {
    localStorage.setItem('BlobEndpoint', response.data?.url);
    localStorage.setItem('SASToken', response.data?.token);
   });
  } catch (e) {
   console.error('SASToken Provider Error: Cannot receive SASToken');
  }
 }, []);

 useEffect(() => {
  if (!user) {
   console.error('SASToken Provider Error: Unknown user');
   return;
  }

  updateSASToken();

  const intervalId = setInterval(updateSASToken, 5 * 60 * 1000);

  return () => clearInterval(intervalId);
 }, [updateSASToken, user]);

 return <Fragment>
  {props.children}
 </Fragment>;
};
