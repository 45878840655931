import styles from '../Inventory/BaseAttributes.module.scss';
import { useTranslation } from 'react-i18next';
import DetailedTree from '../../../../tree/DetailedTree';
import { DisplayableTreeProperty, Tree, TreeDto } from '../../../../tree/Tree';
import { Organization } from '../../../../organization/Organization';
import { TrackableTreeProperty } from '../../../../tree/TrackableTreeProperty';
import EditingFieldWrapper from '../components/EditingFieldWrapper/EditingFieldWrapper';
import DataGroupHeader from '../components/DataGroupHeader/DataGroupHeader';
import { useContext, useState } from 'react';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import Input from '../../../../components/UI/Input/Input';
import { disabledTreeValueMethods, TreeValueMethod } from '../../../../property-enums/TreeValueMethod';
import Dropdown, { Item } from '../../../../components/UI/Dropdown/Dropdown';
import { NoData } from '../Inventory/BaseAttributes';

const treeValueMethods = Object.values(TreeValueMethod).map(key => ({
  id: key,
  translationKey: 'treeDetails.treeValueMethod.' + key,
  disabled: disabledTreeValueMethods.includes(key)
}));
export default function BenefitsTab(props: BenefitsTabProps) {
  const { t } = useTranslation();
  const { treeService } = useContext(DependencyInjectionContext);
  const [editing, setEditing] = useState(false);
  const [selectedTreeValueMethod, setSelectedTreeValueMethod] = useState<Item>(treeValueMethods.find(method => method.id === props.organization.treeValueMethod) || treeValueMethods[0]);

  const initialEconomicalValues = {
    treeValueCavat: props.tree.treeValueCavat,
    treeValueKoch: props.tree.treeValueKoch,
    treeValueRado: props.tree.treeValueRado,
    ctla: props.tree.ctla,
    vat19: props.tree.vat19,
    alnarpModel: props.tree.alnarpModel,
    normaGranada: props.tree.normaGranada
  };

  const [economicalValues, setEconomicalValues] = useState(initialEconomicalValues);

  const handleSave = async () => {
    const updatedValues: Partial<TreeDto> = {};
    Object.keys(economicalValues).forEach(key => {
      if (economicalValues[key] !== props.tree[key] && economicalValues[key] !== '') {
        updatedValues[key] = economicalValues[key];
      }
    });

    if (Object.keys(updatedValues).length !== 0) {
      await treeService.update(props.organization.id, props.tree.id, updatedValues);
    }
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
    setEconomicalValues(initialEconomicalValues);
  };

  return (
    <div className={styles.content}>
      <div className={styles.dataGroup}>
        <h4 className={styles.benefitsSubtitle}>{t('treeDetails.tabs.subTitles.waterManagement')}</h4>
        <div
          className={`
            ${styles.dataGroupGrid}
            ${props.singleColumn ? styles.singleColumn : ''}
            ${styles.benefitsData} 
            ${props.organization.isMetric ? styles.isMetric : ''}
          `}
        >
          <EditingFieldWrapper
            title={t('details.properties.avoidedWaterRunoff')}
            value={props.tree.avoidedRunoff}
            unit={Tree.getUnit(DisplayableTreeProperty.AvoidedRunoff, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.AvoidedRunoff}
          />
          <EditingFieldWrapper
            title={t('details.properties.evaporation')}
            value={props.tree.evaporation}
            unit={Tree.getUnit(DisplayableTreeProperty.Evaporation, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.Evaporation}
          />
          <EditingFieldWrapper
            title={t('details.properties.potentialEvapotranspiration')}
            value={props.tree.potentialEvapotranspiration}
            unit={Tree.getUnit(DisplayableTreeProperty.PotentialEvapotranspiration, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.PotentialEvapotranspiration}
          />
          <EditingFieldWrapper
            title={t('details.properties.transpiration')}
            value={props.tree.transpiration}
            unit={Tree.getUnit(DisplayableTreeProperty.Transpiration, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.Transpiration}
          />
          <EditingFieldWrapper
            title={t('details.properties.waterIntercepted')}
            value={props.tree.waterIntercepted}
            unit={Tree.getUnit(DisplayableTreeProperty.WaterIntercepted, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.WaterIntercepted}
          />
        </div>
      </div>
      <div className={styles.dataGroup}>
        <h4 className={styles.benefitsSubtitle}>{t('treeDetails.tabs.subTitles.ecosystemServices')}</h4>
        <div
          className={`
            ${styles.dataGroupGrid}
            ${props.singleColumn ? styles.singleColumn : ''}
            ${styles.benefitsData} 
            ${props.organization.isMetric ? styles.isMetric : ''}
          `}
        >
          <EditingFieldWrapper
            title={t('details.properties.carbonStorage')}
            value={props.tree.carbonStorage}
            unit={Tree.getUnit(DisplayableTreeProperty.CarbonStorage, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.CarbonStorage}
          />
          <EditingFieldWrapper
            title={t('tree.co')}
            value={props.tree.co}
            unit={Tree.getUnit(DisplayableTreeProperty.CO, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.CO}
          />
          <EditingFieldWrapper
            title={t('details.properties.grossCarbonSequestration')}
            value={props.tree.grossCarbonSequestration}
            unit={Tree.getUnit(DisplayableTreeProperty.GrossCarbonSequestration, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.GrossCarbonSequestration}
          />
          <EditingFieldWrapper
            title={t('details.properties.no2Reduction')}
            value={props.tree.no2}
            unit={Tree.getUnit(DisplayableTreeProperty.NO2, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.NO2}
          />
          <EditingFieldWrapper
            title={t('details.properties.oxygenProduction')}
            value={props.tree.oxygenProduction}
            unit={Tree.getUnit(DisplayableTreeProperty.OxygenProduction, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.OxygenProduction}
          />
          <EditingFieldWrapper
            title={t('details.properties.o3Reduction')}
            value={props.tree.o3}
            unit={Tree.getUnit(DisplayableTreeProperty.O3, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.O3}
          />
          <EditingFieldWrapper
            title={t('details.properties.pm25Reduction')}
            value={props.tree.pm25}
            unit={Tree.getUnit(DisplayableTreeProperty.PM25, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.PM25}
          />
          <EditingFieldWrapper
            title={t('details.properties.so2Reduction')}
            value={props.tree.so2}
            unit={Tree.getUnit(DisplayableTreeProperty.SO2, props.organization)}
            chartPropertyOnHover={TrackableTreeProperty.SO2}
          />
        </div>
      </div>
      <div className={styles.dataGroup}>
        <DataGroupHeader
          editing={editing}
          setEditing={() => setEditing(true)}
          handleCancel={handleCancel}
          handleSave={handleSave}
          title={t('treeDetails.tabs.subTitles.economicalValue')}
        />
        <div
          className={`
            ${styles.dataGroupGrid}
            ${props.singleColumn ? styles.singleColumn : ''}
            ${styles.benefitsData} 
            ${props.organization.isMetric ? styles.isMetric : ''}
          `}
        >
          <div className={`${styles.field} ${styles.noBorder}`}>
            <Dropdown
              items={treeValueMethods}
              value={selectedTreeValueMethod}
              onSelect={item => setSelectedTreeValueMethod(item)}
            />
            <div
              className={`${styles.valueContainer}`}
            >
              {editing
                ? <Input
                  label={''}
                  type="number"
                  value={selectedTreeValueMethod.id ? (economicalValues[selectedTreeValueMethod.id] || '') : ''}
                  onChange={event => {
                    if (selectedTreeValueMethod.id) setEconomicalValues(prev => ({
                      ...prev, [selectedTreeValueMethod.id!]: Number(event.target.value)
                    }));
                  }}
                  autoFocus={true}
                  className={`${styles.editingInput} ${styles.editingNumberInput}`}
                />
                :
                <div className={styles.valueContent}>
                  {selectedTreeValueMethod.id && economicalValues[selectedTreeValueMethod.id]
                    ? <p className={`${styles.value} `}>
                      <span>{economicalValues[selectedTreeValueMethod.id].toFixed(2)}</span>
                      {Tree.getUnit(selectedTreeValueMethod.id, props.organization) &&
                      <span>{' ' + t('units.' + Tree.getUnit(selectedTreeValueMethod.id, props.organization))}</span>}
                    </p>
                    : <NoData />}
                </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface BenefitsTabProps {
  tree: DetailedTree,
  singleColumn: boolean,
  organization: Organization
}
