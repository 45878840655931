import * as THREE from 'three';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import SphereControls from '../../../components/Panorama/SphereControls';
import PanoramicSphere from './PanoramicSphere';
import { generateMultiResolutionMeshFromImage, Image } from './meshGenerator';
import MultiResolutionMesh from './MultiResolutionMesh';
import { LoadablePointCloud } from '../../../point-cloud/LoadablePointCloud';
import { Tree } from '../../../tree/Tree';
import { Measurement } from '../../../measurement/Measurement';
import { removeObjectFromScene } from '../../../utils/ThreeJsHelpers';
import { CSS3DObject, CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { METER_TO_FEET } from '../../../components/PointCloud/unitConstants';
import { SpherePanoramaObject } from '../../../components/Panorama/FullPanoramaSphere/SpherePanoramaObject';
import PanoramaImageSphere from '../../../components/Panorama/FullPanoramaSphere/PanoramaImageSphere';

export class PanoramicView {
  static create(canvas: HTMLCanvasElement, styles: Record<string, string>, isMetric: boolean): PanoramicView {
    const renderer = new THREE.WebGLRenderer({ canvas });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor('#293336');

    const htmlRenderer = new CSS2DRenderer();
    htmlRenderer.domElement.style.position = 'absolute';
    htmlRenderer.domElement.style.top = '0px';
    htmlRenderer.domElement.style.left = '0px';
    const css3DRenderer = new CSS3DRenderer();
    css3DRenderer.domElement.style.position = 'absolute';
    css3DRenderer.domElement.style.top = '0px';
    css3DRenderer.domElement.style.left = '0px';

    canvas.parentElement!.appendChild(htmlRenderer.domElement);
    canvas.parentElement!.appendChild(css3DRenderer.domElement);

    const controls = new SphereControls();

    const camera = new THREE.PerspectiveCamera(controls.MAX_FOV, canvas.clientWidth / canvas.clientHeight, 1, 1100);
    camera.rotation.order = 'YXZ';
    camera.updateProjectionMatrix();

    const scene = new THREE.Scene();

    const measurement = new Measurement(styles, isMetric);

    return new PanoramicView(canvas, renderer, htmlRenderer, css3DRenderer, controls, camera, scene, measurement);
  }

  private sphere: PanoramicSphere | null = null;
  private newSphere: SpherePanoramaObject | null = null;
  private capturePointMarkers: CSS3DObject[] = [];
  private pointCloud: THREE.Group | null = null;
  public originalPointCloud: LoadablePointCloud | null = null;
  private raycaster = new THREE.Raycaster();
  public centerPosition: THREE.Vector3 = new THREE.Vector3(0, 0, 0);

  constructor(
    private readonly canvas: HTMLCanvasElement,
    readonly renderer: THREE.WebGLRenderer,
    readonly htmlRenderer: CSS2DRenderer,
    readonly css3DRenderer: CSS3DRenderer,
    private readonly controls: SphereControls,
    readonly camera: THREE.PerspectiveCamera,
    readonly scene: THREE.Scene,
    readonly measurement: Measurement
  ) {}

  private rotationCallback: () => void = () => {};
  private resizeListener: (() => void) = () => {};
  private renderListener: (() => void) = () => {};

  resetScene() {
    this.htmlRenderer.domElement.innerHTML = '';
  }

  render() {
    this.renderer.render(this.scene, this.camera);
    this.htmlRenderer.render(this.scene, this.camera);
    this.css3DRenderer.render(this.scene, this.camera);
  }

  dispose() {
    this.scene.children.forEach(it => removeObjectFromScene(it, this.scene));
    this.scene.clear();
    if (this.css3DRenderer.domElement.parentElement === this.canvas.parentElement) {
      this.canvas.parentElement?.removeChild(this.css3DRenderer.domElement);
    }
    if (this.htmlRenderer.domElement.parentElement === this.canvas.parentElement) {
      this.canvas.parentElement?.removeChild(this.htmlRenderer.domElement);
    }
    this.renderer.dispose();
  }

  private handleMouseMoveInMeasurementMode = (event: MouseEvent) => {
    if (!this.scene.visible) {
      return;
    }
    const pickedPoint = this.pickPointFromPointCloud(event);
    if (pickedPoint) {
      this.measurement.setCursor(pickedPoint);
      this.render();
      this.changeCursorHover();
    } else {
      this.changeCursorDefault();
    }
  };

  private handleMouseClickInMeasurementMode = (event: MouseEvent) => {
    event.stopPropagation();

    const mouseDownAt = new THREE.Vector2(event.pageX, event.pageY);

    const mouseUpHandler = (event: Event) => {
      event.stopPropagation();
      const minimalDragDistance = 6;
      const mouseUpAt = new THREE.Vector2((event as MouseEvent).pageX, (event as MouseEvent).pageY);
      const isDrag = mouseDownAt.manhattanDistanceTo(mouseUpAt) > minimalDragDistance;
      if (!isDrag) {
        this.measurement.handleClick();
        this.render();
      }

      event.target?.removeEventListener('mouseup', mouseUpHandler);
    };
    event.target?.addEventListener('mouseup', mouseUpHandler);
  };

  private pickPointFromPointCloud(event: MouseEvent) {
    if (!this.originalPointCloud) return;

    const canvasPosition = this.canvas.getBoundingClientRect();
    const mouse = new THREE.Vector2(
      ((event.clientX - canvasPosition.x) / canvasPosition.width) * 2 - 1,
      1 - ((event.clientY - canvasPosition.y) / canvasPosition.height) * 2
    );

    this.raycaster.setFromCamera(mouse, this.camera);

    const intersects = this.raycaster.intersectObject(this.originalPointCloud! as any, true);
    return (
      intersects
        .filter(it => it.distanceToRay! < 0.1)
        .sort((a, b) => a.distanceToRay! - b.distanceToRay!)
        .at(0)?.point ?? null
    );
  }

  enableLineMeasure() {
    this.measurement.reset();
    this.scene.add(...this.measurement.getElements());
    this.css3DRenderer.domElement.addEventListener('mousedown', this.handleMouseClickInMeasurementMode);
    this.css3DRenderer.domElement.addEventListener('mousemove', this.handleMouseMoveInMeasurementMode);
  }

  disableLineMeasure() {
    this.measurement.reset();
    this.scene.remove(...this.measurement.getElements());
    this.css3DRenderer.domElement.removeEventListener('mousedown', this.handleMouseClickInMeasurementMode);
    this.css3DRenderer.domElement.removeEventListener('mousemove', this.handleMouseMoveInMeasurementMode);
  }

  listen(callback: (angle: number) => void) {
    this.rotationCallback = () => callback(this.getNorthAngle());
    this.renderListener = this.render.bind(this);
    this.controls.listen(this.camera, this.css3DRenderer.domElement as HTMLCanvasElement, [this.renderListener, this.rotationCallback]);

    this.resizeListener = this.setSize.bind(this);
    window.addEventListener('resize', this.resizeListener);
  }

  getNorthAngle() {
    const northTarget = new THREE.Vector3(0, 0, 1);
    const v = new THREE.Vector3(0, 0, 1);
    v.applyQuaternion(this.camera.quaternion);
    v.setY(0);
    return THREE.MathUtils.radToDeg(this.convertToFullAngle(v, northTarget));
  }

  private convertToFullAngle(v1, v2) {
    const o = v1.x * v2.z - v1.z * v2.x;
    let angle = v1.angleTo(v2);
    if (o > 0) {
      angle = 2 * Math.PI - angle;
    }

    return angle;
  }

  clearListeners() {
    this.controls.dispose(this.camera, this.canvas, [this.renderListener, this.rotationCallback]);
    window.removeEventListener('resize', this.resizeListener);
  }

  setSize() {
    if (!this.canvas) {
      return;
    }

    const width = this.canvas.parentElement?.clientWidth ?? 0;
    const height = this.canvas.parentElement?.clientHeight ?? 0;
    this.canvas.style.width = width + 'px';
    this.canvas.style.height = height - 4 + 'px';

    this.camera.aspect = width / height;

    this.htmlRenderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
    this.css3DRenderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
    this.renderer.setPixelRatio(window.devicePixelRatio);

    this.camera.updateProjectionMatrix();
    this.render();
  }

  async setSphereAndPutCameraThere(images: Image[], preloadedMeshes: MultiResolutionMesh[] | null, selectedTree: Tree | null) {
    if (images.length > 0 && !isNaN(images[0].cx)) {
      await this.drawSphereFromSingleImages(images, preloadedMeshes, selectedTree);
    } else {
      await this.drawSphereFromFullPanoImage(images[0], selectedTree);
    }
  }

  private async drawSphereFromSingleImages(images: Image[], preloadedMeshes: MultiResolutionMesh[] | null, selectedTree: Tree | null) {
    let meshes;
    if (!preloadedMeshes) {
      meshes = this.imagesToMeshes(images);
    } else {
      meshes = preloadedMeshes;
    }

    this.centerPosition = new THREE.Vector3(...images[0].origin.coordinates).applyAxisAngle(
      new THREE.Vector3(1, 0, 0),
      -Math.PI / 2
    );

    if (selectedTree) {
      this.setPointcloudRelativeToCenter(this.centerPosition, selectedTree);
    }

    if (this.sphere) {
      this.sphere.dispose();
      this.scene.remove(this.sphere);
    }
    this.sphere = new PanoramicSphere(meshes, new THREE.Vector3(0, 0, 0));
    await this.sphere.loadLowResolutionImages();
    this.scene.add(this.sphere);

    this.camera.position.copy(new THREE.Vector3(0, 0, 0));
    this.measurement.setOrigin(new THREE.Vector3(0, 0, 0));

    this.sphere.loadHighResolutionImages().then(this.render.bind(this));
  }

  private async drawSphereFromFullPanoImage(image: Image, selectedTree: Tree | null) {
    this.centerPosition = new THREE.Vector3(...image.origin.coordinates).applyAxisAngle(
      new THREE.Vector3(1, 0, 0),
      -Math.PI / 2
    );

    if (selectedTree) {
      this.setPointcloudRelativeToCenter(this.centerPosition, selectedTree);
    }

    if (this.newSphere) {
      this.newSphere.unload();
      this.scene.remove(this.newSphere);
    }
    this.newSphere = new SpherePanoramaObject();
    await this.newSphere.load(PanoramaImageSphere.createFromServerResponse(image), this.render.bind(this));
    this.scene.add(this.newSphere);

    this.camera.position.copy(new THREE.Vector3(0, 0, 0));
    this.measurement.setOrigin(new THREE.Vector3(0, 0, 0));
  }

  imagesToMeshes(images: Image[]): MultiResolutionMesh[] {
    if (isNaN(images[0].nx)) return [];
    return images.map(it => generateMultiResolutionMeshFromImage(it));
  }

  setPointCloud(pointCloud: LoadablePointCloud, selectedTree: Tree) {
    this.originalPointCloud = pointCloud;
    const standingObject = new THREE.Group().rotateX(-Math.PI / 2).add(pointCloud as any);
    this.pointCloud = standingObject;
    this.setPointcloudRelativeToCenter(this.centerPosition, selectedTree);
    this.scene.add(standingObject);
  }

  setPointcloudRelativeToCenter(centerPosition: THREE.Vector3, tree: Tree) {
    if (this.pointCloud) {
      this.pointCloud.position.copy(
        new THREE.Vector3(...tree.localizedLocation).applyAxisAngle(new THREE.Vector3(1, 0, 0), -Math.PI / 2)
      ).sub(centerPosition);
    }
  }

  hideTreePointCloud() {
    if (!this.pointCloud) return;
    this.pointCloud.visible = false;
  }

  showTreePointCloud() {
    if (!this.pointCloud) return;
    this.pointCloud.visible = true;
  }

  removePointCloud() {
    removeObjectFromScene(this.pointCloud, this.scene);
    this.pointCloud = null;
    this.originalPointCloud = null;
  }

  setCapturePointsAsCss3D(currentCapturePoints: { id: string, location: [number, number, number] }[],
    onClick: (capturePoint: { id: string, location: [number, number, number] }, camera: THREE.Camera) => void,
    onHover: (capturePoint: { id: string, location: [number, number, number] }) => void) {
    this.capturePointMarkers.forEach(it => this.scene.remove(it));
    this.capturePointMarkers = currentCapturePoints.map(it => {
      const el = document.createElement('div');
      el.innerHTML = '<div class="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] w-[7px] h-[7px] rounded-full bg-[rgba(255,255,255,0.8)]"></div>';
      el.classList.add(
        'relative', 'w-[50px]', 'h-[50px]', 'rounded-full', 'bg-[rgba(255,255,255,0.3)]', 'border-[rgba(255,255,255,0.6)]', 'cursor-pointer', 'opacity-60', 'hover:opacity-100'
      );
      el.onclick = () => onClick(it, this.camera);
      el.onmouseover = () => onHover(it);

      const obj = new CSS3DObject(el);
      const position = new THREE.Vector3(...it.location).applyAxisAngle(new THREE.Vector3(1, 0, 0), -Math.PI / 2).sub(this.centerPosition!);
      obj.rotateX(-Math.PI / 2);
      obj.position.copy(position.clone().add(new THREE.Vector3(0, -3.2, 0)));
      obj.renderOrder = 1;
      obj.scale.multiplyScalar(0.03);
      return obj;
    });

    this.capturePointMarkers.forEach(it => this.scene.add(it));
  }

  lookAtTree(tree: Tree, isMetric: boolean) {
    const height = isMetric ? tree.height : tree.height / METER_TO_FEET;
    const treePosition = [...tree.localizedLocation];
    treePosition[2] += height / 2;
    const treePositionVector = new THREE.Vector3(...treePosition).applyAxisAngle(
      new THREE.Vector3(1, 0, 0),
      -Math.PI / 2
    ).sub(this.centerPosition!);
    this.camera.lookAt(treePositionVector);
    this.controls.synchronizeTo(this.camera);
  }

  applyRotation(cameraRotation: [number, number, number]) {
    this.camera.rotation.set(...cameraRotation);
    this.controls.synchronizeTo(this.camera);
  }

  zoomIn() {
    this.controls.zoomIn();
  }

  zoomOut() {
    this.controls.zoomOut();
  }

  resetZoom() {
    this.controls.reset();
  }

  private changeCursorHover() {
    this.css3DRenderer.domElement.style.cursor = 'pointer';
  }

  private changeCursorDefault() {
    this.css3DRenderer.domElement.style.cursor = 'default';
  }
}
