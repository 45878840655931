import { Quantity } from '../routes/Explore/reports/ReportData';

export class NumericFormat {
  static currency(unit: string, locale = navigator.language, translation = key => key) {
    return new NumericFormat(unit, true, locale, translation);
  }

  static iso(unit: Quantity['unit'], locale = navigator.language, translation = key => key) {
    return new NumericFormat(unit, false, locale, translation);
  }

  static range(from: number, to: number, locale = navigator.language, translation = key => key) {
    return [
      new NumericFormat('', false, locale, translation).format(from),
      new NumericFormat('', false, locale, translation).format(to)
    ].join(' - ');
  }

  static getCurrencyCode(currency: string) {
    return {
      '£': 'GBP',
      '€': 'EUR',
      'Ft': 'HUF'
    }[currency] || 'EUR';
  }

  private constructor(
    private readonly unit: string,
    private readonly isCurrency = false,
    private readonly locale = navigator.language,
    private readonly translation: (key: string) => string
  ) {}

  format(value: number): string {
    if (this.isCurrency) return this.formatAsCurrency(value);
    return this.formatAsUnit(value);
  }

  private formatAsCurrency(value: number) {
    const options = { style: 'currency', currency: this.unit, maximumFractionDigits: 0 };
    return new Intl.NumberFormat(this.locale, options).format(value);
  }

  private formatAsUnit(value: number) {
    const options = { maximumFractionDigits: 2 };
    return `${new Intl.NumberFormat(this.locale, options).format(value)} ${this.isoUnit}`.trim();
  }

  getFormattedValue(value: number, numberFormatOptions?: Intl.NumberFormatOptions) {
    const shouldShowFractionDigit = value <= 100;
    const options = numberFormatOptions || { maximumFractionDigits: shouldShowFractionDigit ? 1 : 0 };
    return `${new Intl.NumberFormat(this.locale, options).format(value)}`;
  }

  getUnit() {
    return `${this.isoUnit}`;
  }

  private get isoUnit(): string {
    if (this.unit === '') return '';
    if (this.unit === 'kg') return 'kg';
    return this.unit === 'm3' ? 'm³' : 'kg/' + this.translation('units.year');
  }
}
