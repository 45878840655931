import { useTranslation } from 'react-i18next';
import styles from './PieChart.module.scss';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Tree } from '../../../../../tree/Tree';
import { graphModalColors } from '../GraphModal';
import { PercentOutlineIcon, TreeOutlineIcon } from '../../../../../components/UI/Icon/Icon';
import { getTranslation } from './labelTranslation';

export type PieChartData = { key: string, value: number, percentage: number }[];
const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer?.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');

    legendContainer?.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item, index) => {
      if (!chart.data.datasets[0].data[index]) return;
      const { percentageLabel, id } = chart.data.datasets[0].data[index];
      const li = document.createElement('li');
      li.id = id;

      li.onmouseover = () => {
        Array.from(document.querySelectorAll(`.${styles.graphLegendContainer} ul li`)).forEach(it => it.classList.add(styles.passive));
        li.classList.remove(styles.passive);

        if (chart.getActiveElements().length > 0) {
          const prev = chart.getActiveElements().find(it => it.index === index);
          if (prev) {
            return;
          }
          chart.setActiveElements([]);
        }
        chart.setActiveElements([{ datasetIndex: 0, index }]);
        chart.update();
      };

      li.onmouseleave = () => {
        Array.from(document.querySelectorAll('.graphLegendContainer ul li')).forEach(it => it.classList.remove(styles.passive));

        chart.setActiveElements([]);
        chart.update();
      };

      const colorCircle = document.createElement('span');
      colorCircle.style.background = item.fillStyle;
      colorCircle.style.borderColor = item.strokeStyle;
      colorCircle.style.borderWidth = item.lineWidth + 'px';

      // Text
      const textContainer = document.createElement('p');
      textContainer.classList.add(styles.textContainer);
      textContainer.style.color = item.fontColor;
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const percentageContainer = document.createElement('div');
      percentageContainer.classList.add(styles.percentageContainer);
      percentageContainer.style.color = item.fontColor;
      percentageContainer.style.fontWeight = '600';
      percentageContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      const percentageText = document.createTextNode(percentageLabel);
      textContainer.appendChild(text);
      percentageContainer.appendChild(percentageText);

      const indicatorWithTitle = document.createElement('div');
      indicatorWithTitle.appendChild(colorCircle);
      indicatorWithTitle.appendChild(textContainer);
      li.appendChild(indicatorWithTitle);
      li.appendChild(percentageContainer);
      ul.appendChild(li);
    });
  }
};

const middleLabelPlugin = {
  id: 'middleLabel',
  afterDatasetDraw(chart) {
    const ctx = chart.ctx as CanvasRenderingContext2D;
    const width = chart.width;
    const height = chart.height;
    const total = chart.data.datasets[0].data.reduce((acc, it) => acc + it.value, 0);

    const fontSize = (height / 200).toFixed(2);
    ctx.font = fontSize + 'em Inter';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = 'white';

    const text = total.toLocaleString().replaceAll(',', '.');

    const textX = Math.round((width - ctx.measureText(text).width) / 2);
    const textY = height / 2;
    ctx.fillText(text, textX, textY);

    drawTree(ctx, textX, textY);
  }
};

type PieChartProps = {
  data: PieChartData,
  property: string,
  displayPercentage: boolean
};

function drawTree(ctx: CanvasRenderingContext2D, x: number, y: number) {
  const treeCrown = new Path2D('M9.75156 4.91509L9.27143 5.05462L9.36372 5.3722L9.69209 5.41154L9.75156 4.91509ZM8.57649 9.63456L8.75625 9.16799L8.48147 9.06212L8.25563 9.25109L8.57649 9.63456ZM5.43493 9.63536L5.75559 9.25172L5.53003 9.06318L5.2556 9.16862L5.43493 9.63536ZM4.2613 4.91516L4.32091 5.41159L4.64918 5.37218L4.74144 5.05468L4.2613 4.91516ZM9.69209 5.41154C10.658 5.52726 11.4071 6.3503 11.4071 7.34756H12.4071C12.4071 5.83797 11.2736 4.59386 9.81104 4.41864L9.69209 5.41154ZM11.4071 7.34756C11.4071 8.42452 10.534 9.29756 9.45705 9.29756V10.2976C11.0863 10.2976 12.4071 8.9768 12.4071 7.34756H11.4071ZM9.45705 9.29756C9.2091 9.29756 8.97308 9.25153 8.75625 9.16799L8.39673 10.1011C8.72645 10.2282 9.08424 10.2976 9.45705 10.2976V9.29756ZM8.25563 9.25109C7.9169 9.53451 7.48159 9.70468 7.00523 9.70468V10.7047C7.72482 10.7047 8.38539 10.4464 8.89735 10.018L8.25563 9.25109ZM7.00523 9.70468C6.52922 9.70468 6.09421 9.53476 5.75559 9.25172L5.11426 10.019C5.62606 10.4468 6.28618 10.7047 7.00523 10.7047V9.70468ZM5.2556 9.16862C5.03922 9.25176 4.80377 9.29756 4.55645 9.29756V10.2976C4.92831 10.2976 5.28523 10.2285 5.61426 10.1021L5.2556 9.16862ZM4.55645 9.29756C3.47949 9.29756 2.60645 8.42452 2.60645 7.34756H1.60645C1.60645 8.9768 2.92721 10.2976 4.55645 10.2976V9.29756ZM2.60645 7.34756C2.60645 6.35048 3.35524 5.52754 4.32091 5.41159L4.2017 4.41872C2.73951 4.59429 1.60645 5.83823 1.60645 7.34756H2.60645ZM4.74144 5.05468C5.02678 4.07276 5.93354 3.3559 7.00645 3.3559V2.3559C5.47683 2.3559 4.18727 3.37811 3.78117 4.77564L4.74144 5.05468ZM7.00645 3.3559C8.07932 3.3559 8.98608 4.07273 9.27143 5.05462L10.2317 4.77555C9.82557 3.37807 8.53603 2.3559 7.00645 2.3559V3.3559Z');
  const treeTrunk = new Path2D('M7.00537 10.2075V13.3575');

  const scale = y / 100;
  const translation = { x: x - 18 * scale, y: y - 9 * scale };
  ctx.translate(translation.x, translation.y);
  ctx.scale(scale, scale);
  ctx.strokeStyle = 'white';
  ctx.fill(treeCrown);
  ctx.lineCap = 'round';
  ctx.stroke(treeTrunk);
  ctx.scale(1 / scale, 1 / scale);
  ctx.translate(-translation.x, -translation.y);
}

export default function PieChart(props: PieChartProps) {
  const { t } = useTranslation();
  const THRESHOLD = 2.99;

  const colorMap = [...graphModalColors, '#222D31'];
  colorMap[props.data.findIndex(it => it.key === 'noData')] = '#222D31';
  const data = {
    labels: props.data.map(it => it.key),
    datasets: [
      {
        data: props.data.map(it => ({
          ...it,
          id: Math.random().toString(16).slice(2),
          percentageLabel: props.displayPercentage ? (it.percentage * 100).toFixed(2) + '%' : it.value,
          onChartLabel: props.displayPercentage
            ? (it.percentage * 100 < THRESHOLD) ? '' : (it.percentage * 100).toFixed(2) + '%'
            : (it.percentage * 100 < THRESHOLD) ? '' : it.value
        })),
        backgroundColor: colorMap,
        borderColor: colorMap,
        borderWidth: 1,
        hoverOffset: 30
      }
    ],
    options: {
      layout: {
        padding: 20
      },
      color: 'white',
      plugins: {
        tooltip: {
          enabled: false,
          external: ctx => {
            const allSpeciesElement = Array.from(document.querySelectorAll('.graphLegendContainer ul li'));
            allSpeciesElement.forEach(it => it.classList.remove(styles.passive));

            const el = document.getElementById(ctx.chart.data.datasets?.at(0).data[ctx.tooltip.dataPoints?.at(0).dataIndex]?.id);
            if (!el) return;

            if (ctx.tooltip.opacity === 0) {
              allSpeciesElement.forEach(it => it.classList.remove(styles.passive));
            } else {
              allSpeciesElement.forEach(it => it.classList.add(styles.passive));
              el.classList.remove(styles.passive);
            }
          }
        },
        parsing: {
          key: 'value'
        },
        datalabels: {
          formatter: it => it.onChartLabel,
          color: '#fff',
          font: {
            weight: 'bold' as const
          }
        },
        htmlLegend: {
          containerID: 'legend-container'
        },
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
            generateLabels(chart) {
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                const { labels: { pointStyle } } = chart.legend.options;

                return data.labels.map((label: string | string [], i: number) => {
                  const meta = chart.getDatasetMeta(0);
                  const style = meta.controller.getStyle(i);
                  const translation = getTranslation(label, t, props.property);

                  return {
                    text: translation,
                    fillStyle: style.backgroundColor,
                    strokeStyle: style.borderColor,
                    lineWidth: style.borderWidth,
                    pointStyle,
                    hidden: !chart.getDataVisibility(i),

                    // Extra data used for toggling the correct item
                    index: i
                  };
                });
              }
              return [];
            }
          }
        }
      }
    }
  };

  return (
    <div>
      <div className={styles.chartContainer}>
        <div style={{ width: '50%' }}>
          <Doughnut
            data={data}
            options={data.options}
            plugins={[htmlLegendPlugin, middleLabelPlugin, ChartDataLabels as unknown as any]}
          />
        </div>
        <div className={styles.graphLegendWrapper}>
          <h4>{t(Tree.getTKeyForProperty(props.property))}
            <span className={styles.icon}>
              {props.displayPercentage ? <PercentOutlineIcon /> : <TreeOutlineIcon />}
            </span>
          </h4>
          <div className={styles.graphLegendContainer} id={'legend-container'}>
          </div>
        </div>
      </div>
    </div>
  );
}
