import React, { useContext, useEffect, useRef, useState } from 'react';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import { usePropertyConfigurations } from '../../../properties/usePropertyConfigurations';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { useCurrentAccount } from '../../../account/useAccounts';
import TableDashboard from '../TableDashboard';
import { useTranslation } from 'react-i18next';
import { useFetchTreeList } from '../../CarbonInsights/useFetchTreeList';
import { Button, Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@carbon/react';
import PartialTree from '../../../tree/PartialTree';
import { SortButton, SortingDirection } from '../../CarbonInsights/SortButton';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import useManagedAreas from '../../../managed-area/useManagedAreaList';
import Tooltip from '../../../components/UI/Tooltip/Tooltip';
import GraphModal from '../../CarbonInsights/components/GraphModal';
import PropertyColorConfiguration from '../../../properties/PropertyColorConfiguration';
import { StaticQueryKeys } from '../../../StaticQueryKeys';
import { useQueryClient } from 'react-query';
import { WorkOrderStatus } from '../../../task-manager/WorkOrder';

export default function SelectableTreeTable(props: TreeTableProps) {
  const propertyConfigs = usePropertyConfigurations();
  const account = useCurrentAccount();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { managedAreaList } = useManagedAreas(account.organization.id);

  const [trees, setTrees] = useState<PartialTree[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const { urlContext, taskManagerService } = useContext(DependencyInjectionContext);

  const [graphModalProperty, setGraphModalProperty] = useState<DisplayableTreeProperty | null>(null);

  const selectedProperties = [ DisplayableTreeProperty.ExternalId, ...(urlContext.getVisibleTableProperties() ?? [])];
  const workOrderId = urlContext.getWorkOrderId();

  const { treeList, firstPageIsLoading } = useFetchTreeList(0, urlContext.getTreeTableSortingString(), selectedProperties, undefined, urlContext.getTaskId() || undefined, workOrderId || undefined);
  const [loading, setLoading] = useState(false);

  const [selectedTreeIds, setSelectedTreeIds] = useState<string[]>(props.selectedTreeIds ?? []);
  const [reversedSelection, setReversedSelection] = useState(false);

  const fullSizedContainerRef = useRef<HTMLDivElement | null>(null);

  const selectedTreeProperty = urlContext.getSelectedTreeProperty();
  const getPropertyConfigColor = (property: DisplayableTreeProperty, value: number | string) => {
    if (propertyConfigs.isLoading) return '255, 255, 255';
    const propertyConfig = propertyConfigs.data.find(it => it.property === property);
    if (propertyConfig) {
      const index = propertyConfig.getRangeIndexForValue(value, Boolean(propertyConfig.ranges[0].value));
      const colors = PropertyColorConfiguration.getColorsForConfig(propertyConfig);
      return colors[index];
    }
    return '255, 255, 255';
  };

  useEffect(() => {
    if (!treeList) return;
    setLoading(true);
    treeList.getRange(0, (pageCount + 1) * 100).then(trees => {
      localStorage.setItem(
        'loadedTableTrees',
        JSON.stringify(trees.map(it => ({ id: it.id, externalId: it.externalId })))
      );
      setTrees(trees);
      setLoading(false);
    });
    return () => {
      treeList?.cancel();
      setLoading(false);
    };
  }, [treeList, pageCount]);

  useEffect(() => {
    if (!fullSizedContainerRef.current || !treeList) return;

    const onScroll = e => {
      const isScrolledToBottom = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight;
      if (isScrolledToBottom) {
        setPageCount(pageCount => pageCount + 1);
      }
    };

    fullSizedContainerRef.current.getElementsByTagName('table')[0].addEventListener('scroll', onScroll);

    return () => {
      if (!fullSizedContainerRef.current) return;
      fullSizedContainerRef.current.getElementsByTagName('table')[0].removeEventListener('scroll', onScroll);
    };
  }, [treeList]);

  function toggleSort(property: DisplayableTreeProperty | 'externalId') {
    return () => {
      const direction = (urlContext.getTreeTableSorting()?.property === property && urlContext.getTreeTableSorting()?.direction) || null;
      urlContext.setTreeTableSorting(property, direction === SortingDirection.ASCENDING ? SortingDirection.DESCENDING : SortingDirection.ASCENDING);
    };
  }

  const handleTableTreeSelect = id => {
    urlContext.setTreeId(id);
    urlContext.setCapturePointId(null);
    urlContext.setCameraRotation(null);
  };

  const handleTreeSelect = (treeId: string) => {
    if (reversedSelection) {
      setSelectedTreeIds(prev => prev.includes(treeId) ? prev.filter(id => id !== treeId) : [...prev, treeId]);
    } else {
      setSelectedTreeIds(prev => prev.includes(treeId) ? prev.filter(id => id !== treeId) : [treeId, ...prev]);
    }
  };

  const handleSelectAll = () => {
    if (reversedSelection && selectedTreeIds.length > 0) {
      setSelectedTreeIds([]);
    } else {
      setSelectedTreeIds([]);
      setReversedSelection(prev => !prev);
    }
  };

  const taskId = urlContext.getTaskId();
  const handleCreateWorkOrder = async () => {
    if (!taskId) return;
    await taskManagerService.createWorkOrder(account.organization.id, {
      taskId,
      treeIds: selectedTreeIds,
      reversedSelection,
      filterConfig: urlContext.getFilterConfiguration(),
      priority: props.priority?.id,
      name: props.workOrderTitle,
      description: props.description,
      assigneeUserId: props.assignee?.id,
      deadline: props.deadline,
      managedAreaIds: urlContext.getManagedAreaIds(),
      reverseMASelection: urlContext.getReverseMASelection()
    });
    await queryClient.invalidateQueries(StaticQueryKeys.TREE_DETAILS);
    urlContext.setFullScreenModalOpen(null);
  };

  const handleUpdateWorkOrder = async () => {
    if (!workOrderId) return;
    await taskManagerService.updateWorkOrder(account.organization.id, workOrderId, {
      priority: props.priority?.id,
      name: props.workOrderTitle,
      description: props.description,
      assigneeUserId: props.assignee?.id,
      deadline: props.deadline,
      status: props.status?.id
    });
    await queryClient.invalidateQueries(StaticQueryKeys.TREE_DETAILS);
    urlContext.setFullScreenModalOpen(null);
  };

  if (propertyConfigs.isLoading) return <Spinner />;
  const selectedCount = reversedSelection ? ((treeList?.getNotFilteredCount() ?? 0) - selectedTreeIds.length) : selectedTreeIds.length;
  return <>
    {props.open && (
      <div className="h-[calc(100vh-120px)]">
        <TableDashboard>
          {workOrderId
            ? <Button onClick={handleUpdateWorkOrder}>Save</Button>
            : <Button
              onClick={handleCreateWorkOrder}
              disabled={reversedSelection ? selectedTreeIds.length === treeList?.getNotFilteredCount() : selectedTreeIds.length === 0}>
              Create Work Order
            </Button>}
        </TableDashboard>
        <div className="mx-8 h-[calc(100%-78px)]">
          <div className="text-sm leading-[18px] tracking-tight mb-1">
            {`${t('numberOfItems')}: ${treeList?.getNotFilteredCount() || 0}`}
            {(selectedCount > 0 && props.selectedTreeIds === null) && ` / ${t('selected')}: ${selectedCount}`}
          </div>
          {props.areaFilterIsSelected ?
            <div
              ref={fullSizedContainerRef}
              className="h-full max-w-full overflow-y-clip [&>section]:h-full [&>section>div]:h-full [&>section>div]:border-none [&>section>div:focus]:outline-none"
              style={{ maxHeight: (trees.length * 48) + 48 + 'px', width: (selectedProperties.length * 200) + 55 + 12 + 'px' }}
            >
              <Table className="max-h-full border-none bg-[var(--cds-layer)]" size="lg" useZebraStyles={false} stickyHeader={true}>
                <TableHead style={{ width: (selectedProperties.length * 200) + 55 + 7 + 'px', overflow: 'hidden' }}>
                  <TableRow>
                    <td style={{ width: '55px', minInlineSize: '55px' }}>
                      <Checkbox
                        id='select-all'
                        labelText=''
                        checked={reversedSelection || props.selectedTreeIds !== null}
                        onClick={handleSelectAll}
                        indeterminate={props.selectedTreeIds === null && selectedTreeIds.length > 0}
                        disabled={props.selectedTreeIds !== null}
                      />
                    </td>
                    {selectedProperties.map(property => {
                      const unit = Tree.getUnit(property, account.organization);
                      const translatedUnit = unit ? ` [${t(`units.${unit}`)}]` : '';
                      const columnTitle = Tree.renderPropertyName(property, t, account.organization) + translatedUnit;

                      return <td id={property} key={property} className="h-12 flex items-center justify-between p-0" style={{ paddingBlockStart: '0px', paddingInlineEnd: '0px' }}>
                        <Tooltip overlay={columnTitle}>
                          <div className={`overflow-x-clip text-ellipsis whitespace-nowrap shrink-[2] ${hasMoreOptions(property) || isSortableProperty(property) ? 'w-[86px]' : 'w-full'}`}>{columnTitle}</div>
                        </Tooltip>
                        <div className="flex shrink-1">
                          {isSortableProperty(property) && (
                            <Tooltip overlay={t('tooltips.sort')}>
                              <div>
                                <SortButton
                                  sortingDirection={urlContext.getTreeTableSorting()?.property === property ? urlContext.getTreeTableSorting()?.direction : null}
                                  onClick={toggleSort(property)}
                                />
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </td>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody style={{ width: (selectedProperties.length * 200) + 55 + 7 + 'px' }}>
                  {trees.map(tree =>
                    <TableRow key={tree.id} onClick={() => handleTableTreeSelect(tree.id)} isSelected={urlContext.getTreeId() === tree.id}>
                      <TableCell style={{ width: '55px', minInlineSize: '55px' }}>
                        <Checkbox
                          id={tree.id}
                          labelText=''
                          checked={reversedSelection ? !selectedTreeIds.includes(tree.id) : selectedTreeIds.includes(tree.id)}
                          onClick={() => handleTreeSelect(tree.id)}
                          disabled={props.selectedTreeIds !== null}
                        />
                      </TableCell>
                      {selectedProperties.map(property => {
                        const value = tree.getValue(property);

                        return <TableCell key={property} className={`${Tree.isItalicProperty(property) ? 'italic' : ''} relative`}>
                          <span className="w-full overflow-x-clip text-ellipsis whitespace-nowrap" style={selectedTreeProperty === property ? { color: `rgb(${getPropertyConfigColor(property, value)})` } : {}}>
                            {Tree.renderPropertyValue(property, value, t)}
                          </span>
                          <div className="absolute left-0 top-0 bottom-0 w-[1px] bg-[var(--cds-border-subtle-00)]"></div>
                        </TableCell>;
                      })}
                    </TableRow>)}
                </TableBody>
              </Table>
            </div>
            : <EmptyState/>}
          {(firstPageIsLoading || loading) && <Spinner/>}
        </div>
      </div>
    )}
    {graphModalProperty && <GraphModal
      onClose={() => setGraphModalProperty(null)}
      property={graphModalProperty}
      managedAreas={managedAreaList}
    />}
  </>;
}

function EmptyState() {
  const { t } = useTranslation();

  return <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
    <h2 className="text-gray-400 text-lg font-semibold">{t('workspace.emptyState')}</h2>
  </div>;
}

export type Property = DisplayableTreeProperty | 'externalId';

const propertiesWithoutOptions: string[] = [
  DisplayableTreeProperty.DisplayableWorldCoordinates,
  DisplayableTreeProperty.CustomerTreeId,
  DisplayableTreeProperty.CustomerTagId,
  DisplayableTreeProperty.CustomerSiteId,
  DisplayableTreeProperty.StreetAddress,
  DisplayableTreeProperty.ExternalId,
  DisplayableTreeProperty.CultivarOrVariety,
  DisplayableTreeProperty.Cultivar,
  DisplayableTreeProperty.Infraspecies,
  DisplayableTreeProperty.Owner,
  DisplayableTreeProperty.AddressFromParcel,
  DisplayableTreeProperty.CommonName,
  DisplayableTreeProperty.ParkName,
  DisplayableTreeProperty.OnStreetName,
  DisplayableTreeProperty.GrowSpaceSize,
  DisplayableTreeProperty.FurtherInspectionNeeded,
  DisplayableTreeProperty.RecordingDate,
  DisplayableTreeProperty.LastUpdatedAt
];

const nonSortableProperties: string[] = [
  DisplayableTreeProperty.DisplayableWorldCoordinates
];

const isSortableProperty = (property: Property) => !nonSortableProperties.includes(property);

const hasMoreOptions = (property: Property) => !propertiesWithoutOptions.includes(property);

interface TreeTableProps {
  areaFilterIsSelected: boolean,
  open: boolean,
  workOrderTitle: string,
  description: string,
  assignee: CarbonDropdownItem | null,
  priority: CarbonDropdownItem | null,
  deadline: Date | null,
  selectedTreeIds: string[] | null,
  status: CarbonDropdownItem | null
}

export type CarbonDropdownItem = {
  id: string,
  label: string
};
