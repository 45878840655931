import { Tree } from '../tree/Tree';
import { Filter, isDateRangeCondition, isEnumCondition, isNumericCondition, NumericCondition } from './FilterConfig';

export abstract class FilterCondition {
  static fromConfig(config: Filter): FilterCondition {
    if (isNumericCondition(config.condition)) {
      return new NumericFilterCondition(config.property, config.condition, config.value as number);
    }
    if (isEnumCondition(config.condition)) {
      return new EnumFilterCondition(config.property, config.value as string[]);
    }
    if (isDateRangeCondition(config.condition)) {
      return new DateRangeFilterCondition(config.property, config.value as [string, string]);
    }
    return new StringFilterCondition(config.property, config.value as string);
  }

  constructor(
    readonly property: keyof Tree
  ) {
  }

  abstract apply(tree: Partial<Tree>): boolean;

  protected isNotApplicable(tree: Partial<Tree>): boolean {
    const propertyValue = ['assignedTasks', 'workOrders'].includes(this.property) ? tree.tasksOfTree : tree[this.property];
    return propertyValue === undefined || propertyValue === null;
  }
}

export class DateRangeFilterCondition extends FilterCondition {
  constructor(property: keyof Tree, private readonly value: [string, string]) {
    super(property);
  }
  apply(tree: Partial<Tree>): boolean {
    if (this.isNotApplicable(tree)) return false;
    return Date.parse(tree[this.property] as string) >= Date.parse(this.value[0]) &&
      Date.parse(tree[this.property] as string) <= Date.parse(this.value[1]);
  }
}

export class EnumFilterCondition extends FilterCondition {
  constructor(property: keyof Tree, private readonly value: string[]) {
    super(property);
  }
  apply(tree: Partial<Tree>): boolean {
    if (this.isNotApplicable(tree)) return false;
    if (this.property === 'assignedTasks' as keyof Tree) {
      return tree.tasksOfTree!.some(it => this.value.includes(it.task?.name));
    }
    if (this.property === 'workOrders' as keyof Tree) {
      return tree.tasksOfTree!.some(it => this.value.includes(it.workOrder?.code));
    }
    const property = tree[this.property]!.toString();
    return (this.value as string[]).includes(property);
  }
}

export class StringFilterCondition extends FilterCondition {
  constructor(property: keyof Tree, private readonly value: string) {
    super(property);
  }
  apply(tree: Partial<Tree>): boolean {
    if (this.isNotApplicable(tree)) return false;
    const property = tree[this.property] as string;
    return property.toLowerCase().includes(this.value.toLowerCase());
  }
}

export class NumericFilterCondition extends FilterCondition {
  constructor(property: keyof Tree, private readonly condition: NumericCondition, private readonly value: number) {
    super(property);
  }

  apply(tree: Partial<Tree>): boolean {
    if (this.isNotApplicable(tree)) return false;
    const property = (Math.round(100 * (tree[this.property] as number)) / 100);
    switch (this.condition) {
      case NumericCondition.EQUALS:
        return property === this.value;
      case NumericCondition.GREATER_THAN:
        return property > this.value;
      case NumericCondition.LESS_THAN:
        return property < this.value;
      default:
        return false;
    }
  }
}
