import React, { useContext, useEffect, useMemo } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import useManagedAreas from '../../../managed-area/useManagedAreaList';
import { useMatch } from 'react-router-dom';
import { DisplayableTreeProperty } from '../../../tree/Tree';
import SelectableTreeTable from './SelectableTreeTable';
import useRightPanelContent, { RightPanelContent } from '../../../components/UI/Page/carbon/useRightPanelContent';
import PageLayout from '../../../components/UI/Page/carbon/PageLayout';
import CarbonFilterSelector from '../../CarbonInsights/components/CarbonFilterSelector';
import { Header, IconButton } from '@carbon/react';
import { Close } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';

export default function ManualAssignment() {
  const { urlContext } = useContext(DependencyInjectionContext);
  const match = useMatch('/organizations/:organizationId/*');
  const organizationId = match?.params?.organizationId || '';
  const { managedAreaList } = useManagedAreas(organizationId);
  const rightPanelContent = useRightPanelContent();
  const { t } = useTranslation();

  const selectedManagedAreas = useMemo(() => {
    const ManagedAreasInURL = urlContext.getManagedAreaIds();
    return (managedAreaList ?? []).filter(it => urlContext.getReverseMASelection() ? !ManagedAreasInURL.includes(it.id) : ManagedAreasInURL.includes(it.id));
  }, [JSON.stringify(urlContext.getReverseMASelection()), managedAreaList, JSON.stringify(urlContext.getManagedAreaIds())]);

  const selectedManagedAreaIds = useMemo(() => selectedManagedAreas.map(it => it.id), [selectedManagedAreas]);

  // useEffects
  useEffect(() => {
    if (urlContext.getVisibleTableProperties() === null || urlContext.getVisibleTableProperties()?.filter(it => it !== DisplayableTreeProperty.ExternalId).length === 0) {
      urlContext.setVisibleTableProperties([DisplayableTreeProperty.Genus, DisplayableTreeProperty.Species, DisplayableTreeProperty.Height, DisplayableTreeProperty.Status, DisplayableTreeProperty.TrunkDiameter], { replace: true });
    }
  }, []);

  return (
    <div className="pt-8">
      <Header className="flex justify-between pl-4 text-sm font-semibold" aria-label={t('mainMenu.manualAssignment')}>
        {t('mainMenu.manualAssignment')}
        <IconButton onClick={() => urlContext.setFullScreenModalOpen(null)} label={t('tooltips.close')} kind="ghost"><Close/></IconButton>
      </Header>
      <SelectableTreeTable
        areaFilterIsSelected={selectedManagedAreaIds.length > 0}
        open={true}
      />
      {rightPanelContent === RightPanelContent.FILTER_PANEL &&
        <PageLayout.RightPanel>
          <CarbonFilterSelector />
        </PageLayout.RightPanel>
      }
    </div>
  );
}
