import { CSSProperties, memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowRightCircle,
  DropletHalf,
  HealthShield,
  NavArrowDown,
  NavArrowUp,
  RulerArrows,
  ShieldCheck,
  Xmark
} from 'iconoir-react';
import styles from './TreeInfo.module.scss';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import FullScreenImageViewer from '../../../components/FullScreenImageViewer/FullScreenImageViewer';
import { DetailsBackUrl } from '../../../utils/DetailsBackUrl';
import { useCurrentAccount } from '../../../account/useAccounts';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import ScientificNameInItalic from '../workspace/components/ScientificNameInItalic';
import { useTreeInfoProperties } from '../../../properties/usePropertyConfigurations';
import { Flippers } from '../../../switches/Flippers';
import { Features } from '../../../switches/Features';

export default function TreeInfo({ tree, isTreeLoading, isOpen }: {
  tree: Tree | null,
  isTreeLoading: boolean,
  isOpen: boolean
}) {
  const urlContext = useContext(DependencyInjectionContext).urlContext;

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleWindowResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  if (tree === null && !isTreeLoading) return <></>;

  const unselect = () => {
    urlContext.setTreeId('');
  };

  return <div className={styles.treeInfo}>
    {
      isOpen && windowHeight > 725
        ? <LargeTreeInfo tree={tree} unselect={unselect}/>
        : <SmallTreeInfo tree={tree} unselect={unselect}/>
    }
  </div>;
}

function LargeTreeInfo({ tree, unselect }: { tree: Tree | null, unselect: () => void }) {
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const closeImageViewer = () => setSelectedImageUrl('');

  const thumbnailUrl = tree?.getMainImageThumbnailURL();

  const [imageUri, setImageUri] = useState<string | null>(null);

  useEffect(() => {
    if (!tree) return;
    if (!thumbnailUrl) return;

    tree.getMainImageThumbnailFromLayersURL?.()
      .then((i: any) => setImageUri(i))
      .catch(() => setImageUri(thumbnailUrl as string));
  }, [tree?.id, thumbnailUrl]);

  const imageContainerStyle: CSSProperties = tree?.hasImages()
    ? { backgroundImage: `url('${imageUri}')` }
    : {};

  return <div className={styles.large}>
    <div
      data-testid='large-tree-info-close-button'
      className={styles.largeCloseButton}
      onClick={unselect}
    >
      <Xmark/>
    </div>
    <div
      className={styles.image}
      data-testid='tree-info-main-image'
      style={imageContainerStyle}
      onClick={() => {
        return setSelectedImageUrl(tree?.getMainImageURL() ?? '');
      }
      }/>

    <Main tree={tree}/>
    {/*<Gallery tree={tree}/>*/}
    <Data tree={tree}/>

    {selectedImageUrl !== '' && <FullScreenImageViewer url={selectedImageUrl} onClose={closeImageViewer}/>}
  </div>;
}

function SmallTreeInfo({ tree, unselect }: { tree: Tree | null, unselect: () => void }) {
  return <div className={styles.small}>
    <div
      className={styles.smallCloseButton}
      onClick={unselect}
      data-testid='small-tree-info-close-button'
    >
      <Xmark/>
    </div>
    {/*<Gallery tree={tree}/>*/}
    <Main tree={tree}/>
  </div>;
}

function Main({ tree }: { tree: Tree | null }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const account = useCurrentAccount();
  const organization = account.organization;

  const onDetailsButtonClick = (tree: Tree) => {
    if (organization.isEnabled(Flippers.workspace)) {
      navigate(`/organizations/${organization.id}/inventory/trees/${tree.id}`);
    } else {
      DetailsBackUrl.store(tree.id, location);
      let url = `/organizations/${account.organization.id}/trees/${tree.id}/`;
      url += account.organization.isDemo ? 'analytics/general' : 'details';
      navigate(url);
    }
  };

  return <div className={styles.mainContainer}>
    <div className={styles.mainHeader}>
      <div className={styles.mainExternalId}>{tree?.externalId}</div>
      <button onClick={() => tree ? onDetailsButtonClick(tree) : null} className={styles.detailsButton}>
        {t('treeInfo.details')} <ArrowRightCircle/>
      </button>
    </div>
    <div className={styles.mainSpecies}>
      <ScientificNameInItalic scientificName={tree?.scientificName || ''} />
    </div>
    {!organization.isFeatureEnabled(Features.davey) &&
        <div className={styles.mainManagedArea}>{tree?.managedArea.getName()}</div>}
    {!organization.isFeatureEnabled(Features.davey) &&
        <div className={styles.mainCoordinates}>{tree?.getWorldCoordinates().join(', ')}</div>}
  </div>;
}

const Data = memo(function ({ tree }: { tree: Tree | null }) {
  const { t } = useTranslation();
  const account = useCurrentAccount();

  const { dimensionProperties, ecosystemProperties, healthProperties, safetyProperties } = useTreeInfoProperties();
  const propertySections: { title: string, properties: DisplayableTreeProperty[], icon: JSX.Element }[] = [
    { title: 'treePropertyGroups.dimensions', properties: dimensionProperties, icon: <RulerArrows/> },
    { title: 'treePropertyGroups.ecosystemServices', properties: ecosystemProperties, icon: <DropletHalf/> },
    {
      title: 'treePropertyGroups.healthIndication',
      properties: healthProperties,
      icon: <HealthShield/>
    },
    { title: 'treePropertyGroups.safety', properties: safetyProperties, icon: <ShieldCheck/> }
  ];

  const caret = <div>
    <NavArrowDown className={styles.accordionCaretDown}/>
    <NavArrowUp className={styles.accordionCaretUp}/>
  </div>;

  const openSection = e => {
    (e.currentTarget!.parentElement!.parentElement as HTMLElement).querySelectorAll(`.${styles.open}`).forEach(it => {
      if (e.currentTarget.parentElement !== it) {
        it.classList.remove(styles.open);
      }
    });
    e.currentTarget!.parentElement!.classList.toggle(styles.open);
  };

  return <div>
    {propertySections.map(propertySection => {
      return (
        <section key={propertySection.title}>
          <button className={styles.accordion} onClick={openSection}>
            <div>
              {propertySection.icon}
              <span className={styles.accordionTitle}>{t(propertySection.title)}</span>
            </div>

            {caret}
          </button>
          <div className={styles.panel}>
            {propertySection.properties.map((property, index) => {
              const unit = Tree.getUnit(property, account.organization);
              const value =
                  property === DisplayableTreeProperty.SafetyFactors
                    ? tree?.[Tree.getDefaultSafetyFactorProperty(account)]
                    : tree?.[property];
              return (
                <div key={`tree-${index}-${property}`} className={styles.row}>
                  <div className={styles.leftContainer}>
                    <div>{t(`tree.${property}`)}</div>
                    <div className={styles.propertyValue}>
                      {typeof value === 'number' ? value!.toFixed(2) : value || '-'}
                    </div>
                  </div>
                  <div className={styles.rightContainer}>
                    {unit && <>{t('units.' + unit)}</>}
                  </div>
                </div>
              );
            }
            )}
          </div>
        </section>
      );
    })}
  </div>;
});
