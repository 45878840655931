import { ContextMenu } from '../../../components/UI/ContextMenu/ContextMenu';
import { ContextMenuTrigger } from '../../../components/UI/ContextMenu/ContextMenuTrigger';
import { ContextMenuContent } from '../../../components/UI/ContextMenu/ContextMenuContent';
import React, { useContext, useEffect, useState } from 'react';
import { Filter as FilterIcon, NavArrowDown } from 'iconoir-react';
import { useTranslation } from 'react-i18next';
import FilterConfigEditor from '../../TaskManager/create/FilterConfigEditor';
import FilterConfig, { getProperties, isValid } from '../../../filter/FilterConfig';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useCurrentAccount } from '../../../account/useAccounts';
import { DisplayableTreeProperty } from '../../../tree/Tree';

export default function FilterSelector(props: FilterSelectorProps) {
  const { t } = useTranslation();
  const { treeService, urlContext } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();
  const [speciesLists, setSpeciesLists] = useState({ scientificNames: [], genus: [], species: [] });
  const [filterConfig, setFilterConfig] = useState<FilterConfig>(urlContext.getFilterConfiguration());

  useEffect(() => {
    (async () => {
      const list = await treeService.getFilterPropertyOptionListsByManagedAreas(organization.id, urlContext.getManagedAreaIds(), urlContext.getReverseMASelection());
      setSpeciesLists(list);
    })();
  }, [organization.id, urlContext.getManagedAreaIds().join(','), treeService]);

  const trimDraftFilters = (filterConfig: FilterConfig) => {
    return {
      filters: filterConfig.filters.filter(isValid),
      filterGroups: filterConfig.filterGroups.map(group => ({
        filters: group.filters.filter(isValid),
        filterGroupOperator: group.filterGroupOperator
      })),
      topLevelOperator: filterConfig.topLevelOperator
    };
  };

  const validFilterConfig = trimDraftFilters(filterConfig);

  return (<ContextMenu
    className="inline-flex"
    onClose={() => {
      urlContext.setFilterConfiguration(validFilterConfig);
      urlContext.setVisibleTableProperties(Array.from(new Set([...(urlContext.getVisibleTableProperties() ?? []), ...getProperties(validFilterConfig) as DisplayableTreeProperty[]])));
    }}
    disableSearch
  >
    <ContextMenuTrigger className="inline-flex" disabled={props.disabled}>
      <span className={`flex items-center gap-2 ml-4 ${props.disabled ? 'opacity-20' : ''}`}>
        <FilterIcon />{t('taskManager.filters')}<NavArrowDown />
      </span>
    </ContextMenuTrigger>

    <ContextMenuContent className="rounded-lg p-3 bg-outer-space-700 shadow-xl">
      <h3 className="text-greehill-00 font-bold m-3 mb-6">{t('sidebarFilter.selectTreeFilters')}</h3>
      <FilterConfigEditor
        filterConfig={filterConfig}
        speciesLists={speciesLists}
        setFilterConfig={setFilterConfig}
      />
    </ContextMenuContent>
  </ContextMenu>);
}

interface FilterSelectorProps {
  disabled: boolean
}
