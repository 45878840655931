import { useState } from 'react';
import { t } from 'i18next';
import { TreeDisplays } from '../TreeDisplays';
import { Checkbox, OverflowMenu, RadioButton, RadioButtonGroup } from '@carbon/react';
import { Layers } from '@carbon/icons-react';

export default function ViewSelector(props: ViewSelectorProps) {
  const [open, setOpen] = useState(false);

  return (
    <OverflowMenu
      className="bg-[#22272ACC] hover:bg-[#22272A]"
      direction="top"
      size="lg"
      renderIcon={Layers}
      iconClass={open ? '' : '!fill-greehill-00'}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      menuOptionsClass="w-64"
      iconDescription={t('viewSelector.displayMode')}
    >
      <div className="w-full pt-1">
        <ViewSelectorRadioButton className="p-4" value={TreeDisplays.STREET_VIEW} />
        <div className="ml-10">
          <Checkbox
            id="show-point-cloud"
            labelText={t('details.views.showPointcloud') || ''}
            checked={props.showPointcloud}
            onChange={(_, { checked }) => props.setPointcloudVisibility ? props.setPointcloudVisibility(checked) : () => {
            }}
            className="pb-2 text-nowrap pt-1"
            disabled={props.view !== TreeDisplays.STREET_VIEW}
          />
          <RadioButtonGroup
            name="street-point-cloud-radio-group"
            orientation="vertical"
            defaultSelected={props.hideCanopy ? 'true' : 'false'}
            onChange={value => props.setHideCanopy ? props.setHideCanopy(value === 'true') : () => {
            }}
            disabled={props.view !== TreeDisplays.STREET_VIEW || !props.showPointcloud}
          >
            <RadioButton
              labelText={t('details.views.entireTree')}
              id="street-point-cloud-false"
              value="false"
              className="py-2"
            />
            <RadioButton
              labelText={t('details.views.trunkAndBranchesOnly')}
              id="street-point-cloud-true"
              value="true"
              className="py-2"
            />
          </RadioButtonGroup>
        </div>
        <div className="bg-[var(--cds-border-subtle-00)] h-[1px] w-full mt-4"></div>
        <ViewSelectorRadioButton className="p-4" value={TreeDisplays.TWIN_VIEW} />

        <div className="ml-10">
          <Checkbox
            id="show-environment"
            labelText={t('details.views.showEnvironment') || ''}
            checked={props.view !== TreeDisplays.TWIN_VIEW ? true : props.showEnvironment}
            onChange={(_, { checked }) => props.setEnvironmentVisibility ? props.setEnvironmentVisibility(checked) : () => {
            }}
            className="pb-2 pt-1 text-nowrap"
            disabled={props.view !== TreeDisplays.TWIN_VIEW}
          />
          <RadioButtonGroup
            name="twin-point-cloud-radio-group"
            orientation="vertical"
            defaultSelected={props.hideCanopy ? 'true' : 'false'}
            disabled={props.view !== TreeDisplays.TWIN_VIEW}
          >
            <RadioButton
              onClick={() => props.setHideCanopy?.(false)}
              name="twin-point-cloud-radio"
              labelText={t('details.views.entireTree')}
              value="false"
              className="py-2"
            />
            <RadioButton
              onClick={() => props.setHideCanopy?.(true)}
              name="twin-point-cloud-radio"
              labelText={t('details.views.trunkAndBranchesOnly')}
              value="true"
              className="py-2"
            />
          </RadioButtonGroup>
        </div>
        <div className="bg-[var(--cds-border-subtle-00)] h-[1px] w-full mt-4"></div>
        <ViewSelectorRadioButton className="p-4" value={TreeDisplays.IMAGES} />
      </div>
    </OverflowMenu>
  );

  function ViewSelectorRadioButton({ className, value }: { className: string, value: TreeDisplays }) {
    return (<RadioButton
      className={className}
      name="view-selector-radio"
      labelText={t(`details.views.${value}`)}
      value={value}
      onClick={() => props.setView(value)}
      checked={props.view === value}
    />);
  }
}

interface ViewSelectorProps {
  view: TreeDisplays,
  setView: (view: TreeDisplays) => unknown,
  showPointcloud?: boolean,
  setPointcloudVisibility?: (visible: boolean) => void,
  hideCanopy?: boolean,
  setHideCanopy?: (hide: boolean) => void,
  showEnvironment?: boolean,
  setEnvironmentVisibility?: (visible: boolean) => void
}
