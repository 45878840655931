import LegacyPageLayout from '../../components/UI/Page/LegacyPageLayout';
import useAccounts, { useCurrentAccount } from '../../account/useAccounts';
import { Link, Navigate, useMatch } from 'react-router-dom';
import Spinner from '../../components/UI/Spinner/Spinner';
import useTree from '../../tree/useTree';
import styles from './LegacyDetails.module.scss';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, CloudXmark } from 'iconoir-react';
import { DetailsBackUrl } from '../../utils/DetailsBackUrl';
import { DisplayableTreeProperty } from '../../tree/Tree';
import DataPanel from './DataPanel/DataPanel';
import TreeDisplayTile from './TreeDisplayTile/TreeDisplayTile';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { TreeDisplays } from './constants';
import { TrackableTreeProperty } from '../../tree/TrackableTreeProperty';
import { Flippers } from '../../switches/Flippers';
import LegacyDataPanel from './LegacyDataPanel/LegacyDataPanel';
import DaveyDataPanel from './DaveyDataPanel/DaveyDataPanel';
import { Features } from '../../switches/Features';

export type ExtraObject = DisplayableTreeProperty.CanopyHeight
  | DisplayableTreeProperty.CanopyWidth
  | DisplayableTreeProperty.TrunkDiameter
  | DisplayableTreeProperty.TrunkHeight
  | DisplayableTreeProperty.Height
  | 'all-dimension'
  | 'safetyFactor'
  | 'leaningAngle'
  | 'all-safety';

export type CohortValues = {
  [key in TrackableTreeProperty]: {
    mu: number,
    sigma: number
  }
};

export type Cohort = {
  cohortValues: CohortValues,
  genus: string,
  id: string,
  maxTrunkDiameter: number,
  minTrunkDiameter: number,
  name: string,
  organizationId: string
};

type DetailsContextPredicate = (value: ExtraObject[]) => ExtraObject[];
type DetailsContextType = {
  rulers: ExtraObject[],
  setRulers: (extra: ExtraObject[] | DetailsContextPredicate) => void,
  setHover: (target: ExtraObject | null) => void,
  rulersAndHover: ExtraObject[],
  cohort: Cohort | null
};
export const DetailsContext = createContext<DetailsContextType>({
  rulers: [],
  setRulers: () => {
  },
  setHover: () => {
  },
  rulersAndHover: [],
  cohort: null
});

export default function LegacyDetails() {
  const match = useMatch('/organizations/:organizationId/trees/:treeId/details');
  const organizationId = match?.params.organizationId ?? '';
  const treeId = match?.params.treeId ?? '';
  const { t } = useTranslation();
  const { isLoadingAccounts } = useAccounts();
  const currentAccount = useCurrentAccount();
  const organization = currentAccount.organization;
  const { tree, isTreeLoading } = useTree(organizationId, treeId);
  const { urlContext, cohortService } = useContext(DependencyInjectionContext);

  const [rulers, setRulers] = useState<ExtraObject[]>([]);
  const [hover, setHover] = useState<ExtraObject | null>(null);

  const primaryTile = urlContext.getDetailsPrimary() || TreeDisplays.TWIN_VIEW;
  const secondaryTile = urlContext.getDetailsSecondary() || TreeDisplays.NULL;
  const isExpandedPrimary = urlContext.getDetailsIsExpanded();

  const rulersAndHover = useMemo(() => {
    if (hover) return Array.from(new Set([...rulers, hover]));
    return rulers;
  }, [rulers, hover]);

  const [cohort, setCohort] = useState(null);

  useEffect(() => {
    if (tree?.cohortId) {
      cohortService.getCohortById(organizationId, tree.cohortId).then(it => {
        setCohort(it);
      });
    }
  }, [organizationId, tree]);

  if (organization.id && organization.isEnabled(Flippers.workspace)) {
    return <Navigate to={`/organizations/${organizationId}/inventory/trees/${treeId}`}/>;
  }

  if (!isLoadingAccounts && !currentAccount.exists()) {
    return <Navigate to="/"/>;
  }

  if (isLoadingAccounts || isTreeLoading) {
    return <Loader/>;
  }

  if (tree === null) {
    return <EmptyState/>;
  }

  const backUrl = DetailsBackUrl.restore(tree, organizationId);

  const expandedDataPanel = !isExpandedPrimary && secondaryTile === TreeDisplays.NULL;

  const renderDataPanel = () => {
    if (organization.isFeatureEnabled(Features.davey)) {
      return <DaveyDataPanel
        tree={tree}
        organization={organization}
        isExpanded={expandedDataPanel}
      />;
    }
    if (organization.isEnabled(Flippers.newPlatformProperties)) {
      return <DataPanel
        tree={tree}
        organization={organization}
        isExpanded={expandedDataPanel}
      />;
    }
    return <LegacyDataPanel
      tree={tree}
      organization={organization}
      isExpanded={expandedDataPanel}
    />;
  };

  return (
    <DetailsContext.Provider value={{ rulers, setRulers, setHover, rulersAndHover, cohort }}>
      <LegacyPageLayout borderless>
        <LegacyPageLayout.LeftNavExtensions>
          <Link to={backUrl} className={styles.backButton}>
            <ArrowLeft/><span>{t('treeDetails.map').toUpperCase()}</span>
          </Link>
        </LegacyPageLayout.LeftNavExtensions>
        <LegacyPageLayout.CenterNavExtensions>
          {renderTreeTitle(organization, tree, t)}
        </LegacyPageLayout.CenterNavExtensions>
        <LegacyPageLayout.Content>
          <div className={styles.wrapper}>
            <section className={styles.main}>
              <div
                className={styles.treeDisplayTileContainer}
                style={{
                  width: `${expandedDataPanel ? '50%' : '66%'}`
                }}>
                <TreeDisplayTile
                  tree={tree}
                  primaryTile={primaryTile}
                  secondaryTile={secondaryTile}
                  isExpanded={isExpandedPrimary}
                />
              </div>
              <div
                style={{
                  width: `${expandedDataPanel ? '50%' : '33%'}`
                }}>
                {renderDataPanel()}
              </div>
            </section>
          </div>
        </LegacyPageLayout.Content>
      </LegacyPageLayout>
    </DetailsContext.Provider>
  );
}

function renderTreeTitle(organization, tree, translation) {
  if (isTreeKeeperLink(organization, tree)) {
    return <h2
      onClick={() => (window.open(`${organization?.metaData?.treeKeeperCredentials?.treeKeeperUrl}?siteID=${tree?.customerSiteId}`))}
      style={{ cursor: 'pointer' }}
      className={styles.title}>
      {translation(organization.isFeatureEnabled(Features.davey) ? 'treeDetails.smartTree' : 'treeDetails.tree') + ' ' + tree!.externalId}
    </h2>;
  }
  return <h2
    className={styles.title}>
    {translation(organization.isFeatureEnabled(Features.davey) ? 'treeDetails.smartTree' : 'treeDetails.tree') + ' ' + tree!.externalId}
  </h2>;
}

function isTreeKeeperLink(organization, tree) {
  return organization.isFeatureEnabled(Features.davey) && !!tree?.customerSiteId && !!organization?.metaData?.treeKeeperCredentials?.treeKeeperUrl;
}

function EmptyState() {
  const { t } = useTranslation();
  return (
    <LegacyPageLayout>
      <LegacyPageLayout.Content>
        <div className={styles.emptyState}>
          <CloudXmark strokeWidth={0.5} fontSize="4rem"/>

          <p>{t('tree.notFound')}</p>
        </div>
      </LegacyPageLayout.Content>
    </LegacyPageLayout>
  );
}

function Loader() {
  return (
    <LegacyPageLayout>
      <LegacyPageLayout.Content>
        <div
          style={{
            display: 'flex',
            width: '100vw',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'var(--panel-background)'
          }}>
          <Spinner/>
        </div>
      </LegacyPageLayout.Content>
    </LegacyPageLayout>
  );
}
