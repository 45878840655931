import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { DetailsGroup } from '../../../components/UI/DetailsGroup/DetailsGroup';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import DetailedTree from '../../../tree/DetailedTree';
import { TFunction, useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../../account/useAccounts';
import { ConsequencesOfFailure, LikelihoodOfFailure, LikelihoodOfImpact } from './RiskCategorization';
import Dropdown from '../../../components/UI/Dropdown/Dropdown';
import { DataStatus } from '../../../tree/DataStatus';
import Toggle from '../../../components/UI/Toggle/Toggle';
import IsaRiskStandards, { IsaLikelihood, IsaRiskRating } from './isaRiskStandards';
import { InspectionLimitation } from '../../../tree/InspectionLimitation';
import { RiskRating } from '../../../property-enums/RiskRating';
import { InfoCircle, PlusCircle, Trash } from 'iconoir-react';
import { TreeValueMethod } from '../../../property-enums/TreeValueMethod';
import { ClearanceTypes, DetailsContext, ExtraObject } from '../CarbonDetails';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { TreeDisplays } from '../TreeDisplayTile/TreeDisplays';
import { JobColumnName } from '../../TaskManager/create/JobColumnName';
import { useTaskTemplates } from '../../TaskManager/create/useTaskTemplates';
import { Flippers } from '../../../switches/Flippers';
import {
  Accordion,
  AccordionItem,
  StructuredListBody,
  StructuredListCell,
  StructuredListRow,
  StructuredListWrapper,
  Tag,
  Tooltip
} from '@carbon/react';
import FluidDropdown from '@carbon/react/es/components/FluidDropdown/FluidDropdown';
import FluidTextInput from '@carbon/react/es/components/FluidTextInput/FluidTextInput';
import {
  CheckmarkFilled,
  CloseFilled,
  ErrorFilled,
  RowCollapse,
  RowExpand,
  View,
  ViewFilled
} from '@carbon/icons-react';
import { ChartCalculator } from './ChartCalculator';
import { Organization } from '../../../organization/Organization';
import PolarChart from './PolarChart';
import useCarbonThemes, { CarbonTheme } from '../../../components/UI/Carbon/useCarbonThemes';
import { Features } from '../../../switches/Features';

export const riskColorMap = new Map<string, string>(
  [
    ['low', 'bg-greehill-500'],
    ['moderate', 'bg-summer-yellow-500'],
    ['high', 'bg-warn-chardonnay-500'],
    ['extreme', 'bg-danger-500']
  ]
);

export const colorConfig = {
  [CarbonTheme.g100]: {
    noDataBackground: '#3f3f3f',
    negativeBackground: '#925554',
    poitiveBackground: '#42BE63',
    borderColor: '#262626'
  },
  [CarbonTheme.g90]: {
    noDataBackground: '#4d4d4d',
    negativeBackground: '#9C5F5E',
    poitiveBackground: '#24A145',
    borderColor: '#393939'
  },
  [CarbonTheme.g10]: {
    noDataBackground: '#e8e8e8',
    negativeBackground: '#F4BDBC',
    poitiveBackground: '#24A145',
    borderColor: '#FFFFFF'
  },
  [CarbonTheme.white]: {
    noDataBackground: '#dfdfdf',
    negativeBackground: '#ECB5B4',
    poitiveBackground: '#24A145',
    borderColor: '#F4F4F4'
  }
};

export default function CurrentState({ tree, handleChange, disableEdit, workOrderId }: {
  tree: DetailedTree | null,
  handleChange: <T>(property: string, value: T) => void,
  disableEdit: boolean,
  workOrderId: string
}) {
  const {
    setRulers,
    rulers,
    setRiskOverlayIsVisible,
    showTPZ,
    setTPZVisibility,
    showCRZ,
    setCRZVisibility,
    showSCRZ,
    setSCRZVisibility
  } = useContext(DetailsContext);
  const { urlContext } = useContext(DependencyInjectionContext);
  const { t } = useTranslation();

  const [selectedSectionIdx, setSelectedSectionIdx] = useState(0);
  const [openAccordionItem, setOpenAccordionItem] = useState<string | null>(null);
  const [openClearanceRow, setOpenClearanceRow] = useState<string | null>(null);
  const [showClearanceView, setShowClearanceView] = useState<string | null>(null);
  const [focusedElement, setFocusedElement] = useState<string | null>(null);
  const { organization } = useCurrentAccount();
  const { taskTemplates } = useTaskTemplates(organization.id);
  const mitigationOptions = taskTemplates
    .filter(template => template.jobColumnName !== JobColumnName.Inspection)
    .map(template => ({ id: template.id, translationKey: template.name }));
  const remainingMitigationOptions = mitigationOptions
    .filter(template => (tree?.mitigations || []).every(mitigation => mitigation.taskTemplateId !== template.id));

  const toggleProperty = useCallback((property: ExtraObject) => {
    if (rulers.includes(property)) {
      const index = rulers.indexOf(property);
      rulers.splice(index, 1);
      setRulers([...rulers]);
    } else {
      setRulers((prev: ExtraObject[]) => [...prev, property]);
    }
  }, [rulers]);

  useEffect(() => {
    if (openAccordionItem === 'Structural assessment') {
      setRiskOverlayIsVisible(true);
    } else {
      setRiskOverlayIsVisible(false);
    }
  }, [openAccordionItem]);

  const getAccordionItemClassName = useCallback((label: string) => {
    return (openAccordionItem === label ? '[&>button]:text-[var(--cds-link-primary-hover)]' : '[&>button>div]:text-[var(--cds-text-secondary)]') +
      ' [&>button]:h-12 [&>button>div]:font-semibold' +
      ` ${openAccordionItem === label ? '!border-t-[var(--cds-interactive)] !border-b-[var(--cds-interactive)] !border-t-2 !border-b-2' : ''}`;
  }, [openAccordionItem]);

  if (!tree) return null;

  const potentialTargetsChange = (index: number, property: string, value: string) => {
    handleChange('potentialTargets',
      (tree?.potentialTargets || []).map((target, i) => i === index ? {
        ...target,
        [property]: value
      } : target)
    );
  };

  const riskRatings = tree.potentialTargets?.map(it =>
    IsaRiskStandards.getRiskRating(
      Object.values(LikelihoodOfFailure).indexOf(it.likelihoodOfFailure),
      Object.values(LikelihoodOfImpact).indexOf(it.likelihoodOfImpact),
      Object.values(ConsequencesOfFailure).indexOf(it.consequences)
    )
  ) || [IsaRiskRating.low];
  const maxRiskRating = 'high'; //IsaRiskRating[Math.max(...riskRatings)];

  const toggleAllProperties = () => {
    if (rulers.length === 5 && showTPZ && showCRZ && showSCRZ) {
      setRulers([]);
      setTPZVisibility(false);
      setCRZVisibility(false);
      setSCRZVisibility(false);
    } else {
      setRulers([
        DisplayableTreeProperty.CanopyHeight,
        DisplayableTreeProperty.CanopyWidth,
        DisplayableTreeProperty.TrunkDiameter,
        DisplayableTreeProperty.TrunkHeight,
        DisplayableTreeProperty.Height
      ]);
      setTPZVisibility(true);
      setCRZVisibility(true);
      setSCRZVisibility(true);
    }
  };

  const getDimensionsAction = () => {
    if (urlContext.getDetailsTreeDisplay() === TreeDisplays.IMAGES || openAccordionItem !== 'Dimensions') return null;
    if (rulers.length === 5 && showTPZ && showCRZ && showSCRZ) {
      return <ViewFilled
        onClick={e => {
          e.stopPropagation();
          toggleAllProperties();
        }}/>;
    }
    return <View
      onClick={e => {
        e.stopPropagation();
        toggleAllProperties();
      }}/>;
  };

  const handleToggle = (id: string) => {
    setOpenAccordionItem(prev => (prev === id ? null : id));
  };

  const handleClearanceToggle = (id: string) => {
    setOpenClearanceRow(prev => (prev === id ? null : id));
  };

  const handleClearanceViewToggle = (id: string) => {
    setShowClearanceView(prev => (prev === id ? null : id));
  };

  const likelyHoodOfFailureOptions = Object.values(LikelihoodOfFailure).filter(it => typeof it === 'string').map(likelihood => ({
    id: likelihood,
    translationKey: likelihood
  }));

  const consequencesOfFailureOptions = Object.values(ConsequencesOfFailure).filter(it => typeof it === 'string').map(consequence => ({
    id: consequence,
    translationKey: consequence
  }));

  const impactOptions = Object.values(LikelihoodOfImpact).filter(it => typeof it === 'string').map(impact => ({
    id: impact,
    translationKey: impact
  }));

  return (
    <DetailsGroup className="twp">
      {!organization.isFeatureEnabled(Features.davey) &&
      <div className="flex flex-col gap-12 border-b-0">
        <StructuredListWrapper>
          <StructuredListBody>
            <StructuredListRow head className="grid grid-cols-3">
              <StructuredListCell className="px-4 py-3.5 col-start-1 col-end-2">
                {t('details.properties.condition')}
              </StructuredListCell>
              <StructuredListCell className="px-4 py-3.5 text-[var(--cds-text-primary)] col-start-2 col-end-4">
                {tree.condition ? t('details.properties.conditionTypes.' + tree.condition) : '-'}
              </StructuredListCell>
            </StructuredListRow>
            <StructuredListRow head={organization.isFeatureEnabled(Features.davey)} className="grid grid-cols-3">
              <StructuredListCell className="px-4 py-3.5 col-start-1 col-end-2">
                {t('taskManager.currentState.overallTreeRiskRating')}
              </StructuredListCell>
              <StructuredListCell
                className="px-4 py-3.5 text-[var(--cds-text-primary)] col-start-2 col-end-4 flex items-center gap-4">
                {maxRiskRating ? t(`treeDetails.riskRating.isaRiskRating.${maxRiskRating}`) : '-'}
                <div className={`size-3 rounded-full ${riskColorMap.get(maxRiskRating)}`}/>
              </StructuredListCell>
            </StructuredListRow>
          </StructuredListBody>
        </StructuredListWrapper>
      </div>
      }
      {organization.isEnabled(Flippers.snowflakeChart) ? <div className="m-auto pb-8">
        <PolarChart
          data={new ChartCalculator(tree, organization.isFeatureEnabled(Features.clearances), t).getData()}
          selectedSectionIdx={selectedSectionIdx}
          setSelectedSectionIdx={setSelectedSectionIdx}
        />
      </div>
        : ''}
      <Accordion>
        <AccordionItem
          title={<div className="flex justify-between">
            <div>{t('taskManager.currentState.dimensions')}</div>
            <Tooltip label={t(`tooltips.${rulers.length === 5 ? 'hideAll' : 'showAll'}`)} align="left">
              <div className="mr-4 text-[var(--cds-text-primary)]">{getDimensionsAction()}</div>
            </Tooltip>
          </div>}
          open={openAccordionItem === t('taskManager.currentState.dimensions')}
          onHeadingClick={() => handleToggle(t('taskManager.currentState.dimensions'))}
          className={getAccordionItemClassName(t('taskManager.currentState.dimensions'))}
        >
          <PropertyFieldGroup>
            <PropertyField
              property={DisplayableTreeProperty.Height}
              value={tree?.height}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.Height)}
              action={<RulerToggle
                property={DisplayableTreeProperty.Height}
                toggleProperty={toggleProperty}
                selected={rulers.includes(DisplayableTreeProperty.Height)}
              />}
            />
            <PropertyField
              property={DisplayableTreeProperty.TrunkHeight}
              value={tree?.trunkHeight}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.TrunkHeight)}
              action={<RulerToggle
                property={DisplayableTreeProperty.TrunkHeight}
                toggleProperty={toggleProperty}
                selected={rulers.includes(DisplayableTreeProperty.TrunkHeight)}
              />}
            />
            <PropertyField
              property={DisplayableTreeProperty.TrunkDiameter}
              value={tree?.trunkDiameter}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.TrunkDiameter)}
              action={<RulerToggle
                property={DisplayableTreeProperty.TrunkDiameter}
                toggleProperty={toggleProperty}
                selected={rulers.includes(DisplayableTreeProperty.TrunkDiameter)}
              />}
            />
            <PropertyField
              property={DisplayableTreeProperty.CanopyHeight}
              value={tree?.canopyHeight}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.CanopyHeight)}
              action={<RulerToggle
                property={DisplayableTreeProperty.CanopyHeight}
                toggleProperty={toggleProperty}
                selected={rulers.includes(DisplayableTreeProperty.CanopyHeight)}
              />}
            />
            <PropertyField
              property={DisplayableTreeProperty.CanopyWidth}
              value={tree?.canopyWidth}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.CanopyWidth)}
              action={<RulerToggle
                property={DisplayableTreeProperty.CanopyWidth}
                toggleProperty={toggleProperty}
                selected={rulers.includes(DisplayableTreeProperty.CanopyWidth)}
              />}
            />
            <PropertyField
              property={DisplayableTreeProperty.TreeProtectionZone}
              value={tree?.treeProtectionZone}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.TreeProtectionZone)}
              action={urlContext.getDetailsTreeDisplay() === TreeDisplays.STREET_VIEW ? <RulerToggle
                property={DisplayableTreeProperty.TreeProtectionZone}
                toggleProperty={() => setTPZVisibility(!showTPZ)}
                selected={showTPZ}
              /> : null}
            />
            <PropertyField
              property={DisplayableTreeProperty.CriticalRootZone}
              value={tree?.criticalRootZone}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.CriticalRootZone)}
              action={urlContext.getDetailsTreeDisplay() === TreeDisplays.STREET_VIEW ? <RulerToggle
                property={DisplayableTreeProperty.CriticalRootZone}
                toggleProperty={() => setCRZVisibility(!showCRZ)}
                selected={showCRZ}
              /> : null}
            />
            <PropertyField
              property={DisplayableTreeProperty.StructuralCriticalRootZone}
              value={tree?.structuralCriticalRootZone}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.StructuralCriticalRootZone)}
              action={urlContext.getDetailsTreeDisplay() === TreeDisplays.STREET_VIEW ? <RulerToggle
                property={DisplayableTreeProperty.StructuralCriticalRootZone}
                toggleProperty={() => setSCRZVisibility(!showSCRZ)}
                selected={showSCRZ}
              /> : null}
            />
            <PropertyField
              property={DisplayableTreeProperty.NumberOfStems}
              value={tree?.numberOfStems}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.NumberOfStems)}
              action={<div className="size-6"/>}
            />
          </PropertyFieldGroup>
        </AccordionItem>
        <AccordionItem
          title={t('taskManager.currentState.structuralAssessment')}
          open={openAccordionItem === t('taskManager.currentState.structuralAssessment')}
          onHeadingClick={() => handleToggle(t('taskManager.currentState.structuralAssessment'))}
          className={getAccordionItemClassName(t('taskManager.currentState.structuralAssessment'))}
        >
          <PropertyFieldGroup title={t('taskManager.currentState.stability')}>
            <PropertyField
              property={DisplayableTreeProperty.Slenderness}
              value={tree?.slenderness}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.Slenderness)}
            />
            {!organization.isFeatureEnabled(Features.davey) &&
              <PropertyField
                property={DisplayableTreeProperty.SafetyFactorAt80Kmh}
                value={tree?.safetyFactorAt80Kmh}
                userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.SafetyFactorAt80Kmh)}
              />
            }
            <PropertyField
              property={DisplayableTreeProperty.CriticalWindSpeed}
              value={tree?.criticalWindSpeed}
              userUpdated={false}
            />
            <PropertyField
              property={DisplayableTreeProperty.AbsoluteWeakestPoint}
              value={tree?.safetyFactors?.at(13)?.weakestPoint}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.AbsoluteWeakestPoint)}
            />
            <PropertyField
              property={DisplayableTreeProperty.LeaningAngle}
              value={tree?.leaningAngle}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.LeaningAngle)}
            />
          </PropertyFieldGroup>
          <PropertyFieldGroup title={t('taskManager.currentState.limbs')}>
            <div className="flex items-center gap-2 p-4">
              <div className="w-1/3 text-[var(--cds-text-secondary)] text-sm">{t('details.properties.numberOfLimbs')}</div>
              <div className="w-2/3 font-medium">
                {tree.limbs.length ?
                  tree.limbs.map((limb, index) => (
                    <div className="flex items-end gap-1" key={`limb-${index + limb.diameter}`}>
                      <div>Limb #{index + 1}: {limb.diameter}</div>
                      <div className="text-[13px] text-[var(--cds-text-secondary)] font-normal">
                        {Tree.getUnit(DisplayableTreeProperty.LimbDiameter, organization)}
                      </div>
                    </div>
                  ))
                  :
                  <span className="text-outer-space-500">-</span>
                }
              </div>
            </div>
            <PropertyField
              property={DisplayableTreeProperty.CoDominantStems}
              value={tree?.coDominantStems}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.CoDominantStems)}
            />
            <PropertyField
              property={DisplayableTreeProperty.IncludedBark}
              value={tree?.includedBark}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.IncludedBark)}
            />
            <PropertyField
              property={'fork'}
              value={tree?.fork}
              userUpdated={tree?.userUpdatedProperties?.includes('fork')}
            />
            <PropertyField
              property={'crossSectionalShape'}
              value={tree?.crossSectionalShape}
              userUpdated={tree?.userUpdatedProperties?.includes('crossSectionalShape')}
            />
          </PropertyFieldGroup>
        </AccordionItem>
        <AccordionItem
          title={t('taskManager.currentState.healthAndVitality')}
          open={openAccordionItem === t('taskManager.currentState.healthAndVitality')}
          onHeadingClick={() => handleToggle(t('taskManager.currentState.healthAndVitality'))}
          className={getAccordionItemClassName(t('taskManager.currentState.healthAndVitality'))}
        >
          <PropertyFieldGroup title={t('taskManager.currentState.conditionAndVigor')}>
            <PropertyField
              property={DisplayableTreeProperty.Dieback}
              value={tree.dieback}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.Dieback)}
            />
            <PropertyField
              property={DisplayableTreeProperty.DeadBranchesRatio}
              value={tree.deadBranchesRatio}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.DeadBranchesRatio)}
            />
            <PropertyField
              property={'basalAreaIncrement' as DisplayableTreeProperty}
              value={undefined}
              userUpdated={tree?.userUpdatedProperties?.includes('basalAreaIncrement')}
            />
            {!organization.isFeatureEnabled(Features.davey) &&
              <PropertyField
                property={DisplayableTreeProperty.Condition}
                value={tree?.condition}
                userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.Condition)}
              />}
            <PropertyField
              property={DisplayableTreeProperty.NDVI}
              value={tree?.ndvi}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.NDVI)}
            />
            <PropertyField
              property={DisplayableTreeProperty.CrownTransparency}
              value={tree?.crownTransparency}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.CrownTransparency)}
            />
          </PropertyFieldGroup>
          <PropertyFieldGroup title={t('taskManager.currentState.foliage')}>
            <PropertyField
              property={DisplayableTreeProperty.LeafArea}
              value={tree?.leafArea}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.LeafArea)}
            />
            <PropertyField
              property={DisplayableTreeProperty.LeafAreaIndex}
              value={tree?.leafAreaIndex}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.LeafAreaIndex)}
            />
            <PropertyField
              property={DisplayableTreeProperty.CrownVolume}
              value={tree?.crownVolume}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.CrownVolume)}
            />
            <PropertyField
              property={DisplayableTreeProperty.LeafBiomass}
              value={tree?.leafBiomass}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.LeafBiomass)}
            />
          </PropertyFieldGroup>
        </AccordionItem>
        {organization.isFeatureEnabled(Features.clearances) && <AccordionItem
          title={t('taskManager.currentState.clearances', 'Clearances')}
          open={openAccordionItem === t('taskManager.currentState.clearances')}
          onHeadingClick={() => handleToggle(t('taskManager.currentState.clearances'))}
          className={getAccordionItemClassName(t('taskManager.currentState.clearances'))}
        >
          <div className={'flex flex-col mb-4 divide-y divide-[var(--cds-border-subtle-00)]'}>
            <ClearanceRow
              type={ClearanceTypes.Wire}
              handleOpen={() => handleClearanceToggle(ClearanceTypes.Wire)}
              handleToggleClearanceView={() => handleClearanceViewToggle(ClearanceTypes.Wire)}
              isOpen={openClearanceRow === ClearanceTypes.Wire}
              isShowClearanceView={showClearanceView === ClearanceTypes.Wire}
              title={'Wire'}
              issues={tree?.aggByClassWireClearances.map(d => ({
                title: d.wireName,
                properties: [
                  { property: 'clearances.wire.clearanceVolume', value: d.clearanceVolume || null },
                  { property: 'clearances.wire.clearanceVolumeRatio', value: d.clearanceVolumeRatio || null },
                  { property: 'clearances.wire.minimumDistance', value: d.minimumDistance || null },
                  { property: 'clearances.wire.minimumHeight', value: d.minimumHeight || null }
                ]
              }))}
            />
            <ClearanceRow
              type={ClearanceTypes.Road}
              handleOpen={() => handleClearanceToggle(ClearanceTypes.Road)}
              handleToggleClearanceView={() => handleClearanceViewToggle(ClearanceTypes.Road)}
              isOpen={openClearanceRow === ClearanceTypes.Road}
              isShowClearanceView={showClearanceView === ClearanceTypes.Road}
              title={'Road'}
              issues={tree?.roadClearances.map(d => ({
                properties: [
                  { property: 'clearances.road.minimumHeight', value: d.minimumHeight },
                  { property: 'clearances.road.minimumDistance', value: d.minimumDistance },
                  { property: 'clearances.road.clearanceVolume', value: d.clearanceVolume },
                  { property: 'clearances.road.clearanceVolumeRatio', value: d.clearanceVolumeRatio }
                ]
              }))}
            />
            {tree.buildingClearanceIssueDetected !== undefined ? <ClearanceRow
              type={ClearanceTypes.Building}
              handleOpen={() => handleClearanceToggle(ClearanceTypes.Building)}
              handleToggleClearanceView={() => handleClearanceViewToggle(ClearanceTypes.Building)}
              isOpen={openClearanceRow === ClearanceTypes.Building}
              isShowClearanceView={showClearanceView === ClearanceTypes.Building}
              title={'Building'}
              issues={tree?.buildingClearances.map(d => ({
                properties: [
                  { property: 'clearances.building.minimumHeight', value: d.minimumHeight },
                  { property: 'clearances.building.minimumDistance', value: d.minimumDistance },
                  { property: 'clearances.building.clearanceVolume', value: d.clearanceVolume },
                  { property: 'clearances.building.clearanceVolumeRatio', value: d.clearanceVolumeRatio }
                ]
              }))}
            /> : <NoDataClearanceRow title={'Building'} type={ClearanceTypes.Building} />}
            {tree.visibilityClearanceIssueDetected !== undefined ? <ClearanceRow
              type={ClearanceTypes.Visibility}
              handleOpen={() => handleClearanceToggle(ClearanceTypes.Visibility)}
              handleToggleClearanceView={() => handleClearanceViewToggle(ClearanceTypes.Visibility)}
              isOpen={openClearanceRow === ClearanceTypes.Visibility}
              isShowClearanceView={showClearanceView === ClearanceTypes.Visibility}
              title={'Visibility'}
              issues={tree?.visibilityClearances.map(d => ({
                properties: [
                  { property: 'clearances.visibility.minimumHeight', value: d.minimumHeight },
                  { property: 'clearances.visibility.clearanceVolume', value: d.clearanceVolume },
                  { property: 'clearances.visibility.clearanceVolumeRatio', value: d.clearanceVolumeRatio }
                ]
              }))}
            /> : <NoDataClearanceRow title={'Visibility'} type={ClearanceTypes.Visibility}/>}
            {tree.trafficSignClearanceIssueDetected !== undefined ? <ClearanceRow
              type={ClearanceTypes.TrafficSign}
              handleOpen={() => handleClearanceToggle(ClearanceTypes.TrafficSign)}
              handleToggleClearanceView={() => handleClearanceViewToggle(ClearanceTypes.TrafficSign)}
              isOpen={openClearanceRow === ClearanceTypes.TrafficSign}
              isShowClearanceView={showClearanceView === ClearanceTypes.TrafficSign}
              title={'Traffic sign'}
              issues={(tree.trafficSignClearanceIssueDetected === 'false' ? [] : ['']) as unknown as Issue[]}
            /> : <NoDataClearanceRow title={'Traffic sign'} type={ClearanceTypes.TrafficSign} />}
          </div>
        </AccordionItem>}
        <AccordionItem
          title={t('taskManager.currentState.benefits')}
          open={openAccordionItem === t('taskManager.currentState.benefits')}
          onHeadingClick={() => handleToggle(t('taskManager.currentState.benefits'))}
          className={getAccordionItemClassName(t('taskManager.currentState.benefits'))}
        >
          <PropertyFieldGroup title={t('taskManager.currentState.waterManagement')}>
            <PropertyField
              property={DisplayableTreeProperty.AvoidedRunoff}
              value={tree?.avoidedRunoff}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.AvoidedRunoff)}
            />
            <PropertyField
              property={DisplayableTreeProperty.PotentialEvapotranspiration}
              value={tree?.potentialEvapotranspiration}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.PotentialEvapotranspiration)}
            />
            <PropertyField
              property={DisplayableTreeProperty.WaterIntercepted}
              value={tree?.waterIntercepted}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.WaterIntercepted)}
            />
            <PropertyField
              property={DisplayableTreeProperty.Evaporation}
              value={tree?.evaporation}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.Evaporation)}
            />
            <PropertyField
              property={DisplayableTreeProperty.Transpiration}
              value={tree?.transpiration}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.Transpiration)}
            />
          </PropertyFieldGroup>
          <PropertyFieldGroup title={t('taskManager.currentState.ecosystemServices')}>
            <PropertyField
              property={DisplayableTreeProperty.CarbonStorage}
              value={tree?.carbonStorage}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.CarbonStorage)}
            />
            <PropertyField
              property={DisplayableTreeProperty.GrossCarbonSequestration}
              value={tree?.grossCarbonSequestration}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.GrossCarbonSequestration)}
            />
            <PropertyField
              property={DisplayableTreeProperty.OxygenProduction}
              value={tree?.oxygenProduction}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.OxygenProduction)}
            />
            <PropertyField
              property={DisplayableTreeProperty.PM25}
              value={tree?.pm25}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.PM25)}
            />
            <PropertyField
              property={DisplayableTreeProperty.CO}
              value={tree?.co}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.CO)}
            />
            <PropertyField
              property={DisplayableTreeProperty.NO2}
              value={tree?.no2}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.NO2)}
            />
            <PropertyField
              property={DisplayableTreeProperty.O3}
              value={tree?.o3}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.O3)}
            />
            <PropertyField
              property={DisplayableTreeProperty.SO2}
              value={tree?.so2}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.SO2)}
            />
          </PropertyFieldGroup>
          {organization.isFeatureEnabled(Features.davey) ? '' : (
            <PropertyFieldGroup title={t('taskManager.currentState.economicalValue')}>
              {organization.treeValueMethod === TreeValueMethod.CAVAT ?
                <PropertyField
                  property={DisplayableTreeProperty.TreeValueCavat}
                  value={tree?.treeValueCavat}
                  userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.TreeValueCavat)}
                /> : ''}
              {organization.treeValueMethod === TreeValueMethod.KOCH ?
                <PropertyField
                  property={DisplayableTreeProperty.TreeValueKoch}
                  value={tree?.treeValueKoch}
                  userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.TreeValueKoch)}
                /> : ''}
              {organization.treeValueMethod === TreeValueMethod.RADO ?
                <PropertyField
                  property={DisplayableTreeProperty.TreeValueRado}
                  value={tree?.treeValueRado}
                  userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.TreeValueRado)}
                /> : ''}
              {organization.treeValueMethod === TreeValueMethod.CTLA ?
                <PropertyField
                  property={'ctla'}
                  value={tree?.ctla}
                  userUpdated={tree?.userUpdatedProperties?.includes('ctla')}
                /> : ''}
              {organization.treeValueMethod === TreeValueMethod.NORMA_GRANADA ?
                <PropertyField
                  property={'normaGranada'}
                  value={tree?.normaGranada}
                  userUpdated={tree?.userUpdatedProperties?.includes('normaGranada')}
                /> : ''}
              {organization.treeValueMethod === TreeValueMethod.VAT19 ?
                <PropertyField
                  property={'vat19'}
                  value={tree?.vat19}
                  userUpdated={tree?.userUpdatedProperties?.includes('vat19')}
                /> : ''}
              {organization.treeValueMethod === TreeValueMethod.ALNARP_MODEL ?
                <PropertyField
                  property={'alnarpModel'}
                  value={tree?.alnarpModel}
                  userUpdated={tree?.userUpdatedProperties?.includes('alnarpModel')}
                /> : ''}
            </PropertyFieldGroup>
          )}
        </AccordionItem>
        <AccordionItem
          title={t('details.outlierInformation')}
          open={openAccordionItem === t('details.outlierInformation')}
          onHeadingClick={() => handleToggle(t('details.outlierInformation'))}
          className={getAccordionItemClassName(t('details.outlierInformation'))}
        >
          <PropertyFieldGroup>
            <PropertyField
              property={DisplayableTreeProperty.OutlierHeightPerCrownVolume}
              value={tree?.outlierHeightPerCrownVolume}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.OutlierHeightPerCrownVolume)}
            />
            <PropertyField
              property={DisplayableTreeProperty.OutlierHeightPerLeafArea}
              value={tree?.outlierHeightPerLeafArea}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.OutlierHeightPerLeafArea)}
            />
            <PropertyField
              property={DisplayableTreeProperty.OutlierLeafAreaPerCrownVolume}
              value={tree?.outlierLeafAreaPerCrownVolume}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.OutlierLeafAreaPerCrownVolume)}
            />
            <PropertyField
              property={DisplayableTreeProperty.OutlierTrunkDiameterPerCrownVolume}
              value={tree?.outlierTrunkDiameterPerCrownVolume}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.OutlierTrunkDiameterPerCrownVolume)}
            />
            <PropertyField
              property={DisplayableTreeProperty.OutlierTrunkDiameterPerHeight}
              value={tree?.outlierTrunkDiameterPerHeight}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.OutlierTrunkDiameterPerHeight)}
            />
            <PropertyField
              property={DisplayableTreeProperty.OutlierTrunkDiameterPerLeafArea}
              value={tree?.outlierTrunkDiameterPerLeafArea}
              userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.OutlierTrunkDiameterPerLeafArea)}
            />
          </PropertyFieldGroup>
        </AccordionItem>
        {!organization.isFeatureEnabled(Features.davey) && (
          <>
            <div className="h-12 w-full border-solid border-0 border-t border-[var(--cds-border-subtle)]"/>

            <AccordionItem
              title={t('taskManager.currentState.conclusions')}
              open={openAccordionItem === t('taskManager.currentState.conclusions')}
              onHeadingClick={() => handleToggle(t('taskManager.currentState.conclusions'))}
              className={getAccordionItemClassName(t('taskManager.currentState.conclusions'))}
            >
              <PropertyField
                property={DisplayableTreeProperty.FurtherInspectionNeeded}
                value={tree.furtherInspectionNeeded ? new Date(tree.furtherInspectionNeeded).toLocaleDateString() : null}
                userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.FurtherInspectionNeeded)}
              />
              <PropertyField
                property={DisplayableTreeProperty.ViStatus}
                value={t('virtualInspection.status.' + tree?.viStatus).toString()}
                userUpdated={tree?.userUpdatedProperties?.includes(DisplayableTreeProperty.ViStatus)}
              />
            </AccordionItem>
            <AccordionItem
              title={t('taskManager.currentState.riskCategorization')}
              open={openAccordionItem === t('taskManager.currentState.riskCategorization')}
              onHeadingClick={() => handleToggle(t('taskManager.currentState.riskCategorization'))}
              className={getAccordionItemClassName(t('taskManager.currentState.riskCategorization'))}
            >
              <div className="flex flex-col gap-8 py-3">
                {tree.potentialTargets && tree.potentialTargets.map((it, index) => {
                  const likelihood = IsaLikelihood[IsaRiskStandards.getLikelihood(
                    Object.values(LikelihoodOfFailure).indexOf(it.likelihoodOfFailure),
                    Object.values(LikelihoodOfImpact).indexOf(it.likelihoodOfImpact)
                  )];
                  const riskRating = IsaRiskRating[IsaRiskStandards.getRiskRating(
                    Object.values(LikelihoodOfFailure).indexOf(it.likelihoodOfFailure),
                    Object.values(LikelihoodOfImpact).indexOf(it.likelihoodOfImpact),
                    Object.values(ConsequencesOfFailure).indexOf(it.consequences)
                  )];

                  if (disableEdit) return (<div className="mx-4">
                    <strong>Target {index + 1}</strong>
                    <div className="flex flex-row mt-4 gap-8">
                      <div className="w-1/3 text-[var(--cds-text-secondary)] flex flex-col gap-1 text-nowrap">
                        <span>Description</span>
                        <span>Target zone</span>
                        <span>Tree part</span>
                        <span>Conditions of concern</span>
                        <span>Likelihood of failure</span>
                        <span>Likelihood of impact</span>
                        <span>Consequences of failure</span>
                        <span>Failure and impact</span>
                        <span>Risk rating</span>
                      </div>
                      <div className="w-2/3 flex flex-col gap-1">
                        <span>{it.targetDescription || '-'}</span>
                        <span>{it.targetZone || '-'}</span>
                        <span>{it.treePart || '-'}</span>
                        <span>{it.conditionOfConcern || '-'}</span>
                        <span>{it.likelihoodOfFailure || '-'}</span>
                        <span>{it.likelihoodOfImpact || '-'}</span>
                        <span>{it.consequences || '-'}</span>
                        <span>{likelihood || '-'}</span>
                        <span>{riskRating || '-'}</span>
                      </div>
                    </div>
                  </div>);

                  return (
                    <div className="flex flex-col gap-1 divide-y divide-[var(--cds-border-subtle-00)]" key={it.id || index}>
                      <div className="flex items-center gap-2">
                        <div className="w-1/3 text-[var(--cds-text-secondary)]">Target number</div>
                        <div className="w-2/3 font-medium py-3">
                          {index + 1}
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="w-1/3 text-[var(--cds-text-secondary)]">Target description</div>
                        <div className="w-2/3 font-medium py-3">
                          {it.targetDescription}
                        </div>
                      </div>
                      <div className="flex gap-0.5">
                        <div className="flex flex-col gap-1">
                          <FluidTextInput
                            labelText="Tree part"
                            value={it.treePart || ''}
                            onChange={e => potentialTargetsChange(index, 'treePart', e.target.value)}
                            onFocus={() => setFocusedElement(`${index}-treePart`)}
                            onBlur={() => setFocusedElement(null)}
                            autoFocus={focusedElement === `${index}-treePart`}
                          />
                          <FluidDropdown
                            titleText="Likelyhood of failure"
                            label={t('settings.chooseAnOption')}
                            value={it.likelihoodOfFailure ? likelyHoodOfFailureOptions.find(option => option.id === it.likelihoodOfFailure) : likelyHoodOfFailureOptions[0]}
                            onChange={value => potentialTargetsChange(index, 'likelihoodOfFailure', value.selectedItem.id)}
                            items={likelyHoodOfFailureOptions}
                            itemToString={item => likelyHoodOfFailureOptions.find(it => it.id === item.id)?.translationKey || ''}
                          />
                          <FluidDropdown
                            titleText="Consequences of failure"
                            label={t('settings.chooseAnOption')}
                            value={it.consequences ? consequencesOfFailureOptions.find(option => option.id === it.consequences) : consequencesOfFailureOptions[0]}
                            onChange={value => potentialTargetsChange(index, 'consequences', value.selectedItem.id)}
                            items={consequencesOfFailureOptions}
                            itemToString={item => consequencesOfFailureOptions.find(it => it.id === item.id)?.translationKey || ''}
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          <FluidTextInput
                            labelText="Conditions of concern"
                            value={it.conditionOfConcern || ''}
                            onChange={e => potentialTargetsChange(index, 'conditionOfConcern', e.target.value)}
                            onFocus={() => setFocusedElement(`${index}-conditionOfConcern`)}
                            onBlur={() => setFocusedElement(null)}
                            autoFocus={focusedElement === `${index}-conditionOfConcern`}
                          />
                          <FluidDropdown
                            titleText="Impact of failure"
                            label={t('settings.chooseAnOption')}
                            value={it.likelihoodOfImpact ? impactOptions.find(option => option.id === it.likelihoodOfImpact) : impactOptions[0]}
                            onChange={value => potentialTargetsChange(index, 'likelihoodOfImpact', value.selectedItem.id)}
                            items={impactOptions}
                            itemToString={item => impactOptions.find(it => it.id === item.id)?.translationKey || ''}
                          />
                          <div className="py-4">
                            <div>
                              <span>{t('taskManager.risk.failureAndImpact')} </span>
                              <span>{t(`treeDetails.riskRating.isaLikelihood.${likelihood}`)}</span>
                            </div>
                            <div className="flex gap-0.5 items-center">
                              <span>{t('treeDetails.riskRating.riskRating')}:</span>
                              <span className="flex gap-0.5 items-center">
                                <span>
                                  <div className={`size-3 rounded-full ${riskColorMap.get(riskRating)}`}/>
                                </span>
                                <span>{t(`treeDetails.riskRating.isaRiskRating.${riskRating}`)}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>);
                })}
              </div>
            </AccordionItem>
            <AccordionItem
              title={t('taskManager.currentState.notesAndMitigations')}
              open={openAccordionItem === t('taskManager.currentState.notesAndMitigations')}
              onHeadingClick={() => handleToggle(t('taskManager.currentState.notesAndMitigations'))}
              className={getAccordionItemClassName(t('taskManager.currentState.notesAndMitigations'))}
            >
              {disableEdit
                ? <>
                  <section className="p-4 text-sm border-t border-t-[var(--cds-border-subtle-00)]">
                    <span className="font-semibold">{t('taskManager.currentState.tasks')}</span>
                    {(tree.tasksOfTree || []).map(it => <div key={it.task.id} className="m-2">{it.task.name}</div>)}
                  </section>
                  <section className="p-4 text-sm border-t border-t-[var(--cds-border-subtle-00)]">
                    <span className="font-semibold">{t('taskManager.currentState.workOrders')}</span>
                    {(tree.tasksOfTree.filter(it => it.workOrder) || []).map(it => (
                      <div key={it.workOrder.id} className="m-2">{it.workOrder.name}</div>
                    ))}
                  </section>
                  <section className="p-4 text-sm border-t border-t-[var(--cds-border-subtle-00)]">
                    <span className="font-semibold">{t('taskManager.currentState.limitations')}</span>
                    <div className="mt-4">
                      <div className="flex gap-8">
                        <div className="w-3/5 text-[var(--cds-text-secondary)]">{t('taskManager.currentState.data')}</div>
                        <div>{tree.dataStatus || '-'}</div>
                      </div>
                      <div className="flex gap-8">
                        <div
                          className="w-3/5 text-[var(--cds-text-secondary)]">{t('taskManager.currentState.advancedAssessment')}</div>
                        <div>{tree.advancedAssessmentNeeded || '-'}</div>
                      </div>
                      <div className="flex gap-8">
                        <div className="w-3/5  text-[var(--cds-text-secondary)]">{t('taskManager.currentState.reason')}</div>
                        <div>{tree.advancedAssessmentReason || '-'}</div>
                      </div>
                      <div className="flex gap-8">
                        <div
                          className="w-3/5  text-[var(--cds-text-secondary)]">{t('taskManager.currentState.recommendedInspectionInterval')}</div>
                        <div>{tree.recommendedInspectionInterval || '-'}</div>
                      </div>
                      <div className="flex gap-8">
                        <div
                          className="w-3/5  text-[var(--cds-text-secondary)]">{t('taskManager.currentState.inspectionLimitations')}</div>
                        <div>{tree.inspectionLimitation || '-'}</div>
                      </div>
                    </div>
                  </section>
                  <section className="p-4 text-sm border-t border-t-[var(--cds-border-subtle-00)]">
                    <span className="font-semibold">{t('taskManager.currentState.notes')}</span>
                    <div className="mt-4">{tree.note || '-'}</div>
                  </section>
                </>
                : <>
                  <PropertyFieldGroup>
                    <div className="flex items-center gap-2 px-3 py-2">
                      <div className="w-1/3 text-[var(--cds-text-secondary)]">
                        {t('details.properties.overallTreeRiskRating')}
                      </div>
                      <div className="w-2/3 font-medium flex items-center gap-2">
                        <div className={`size-3 rounded-full ${riskColorMap.get(tree?.overallTreeRiskRating || '')}`}/>
                        {tree?.overallTreeRiskRating ? tree?.overallTreeRiskRating :
                          <span className="text-outer-space-500">-</span>}
                      </div>
                    </div>
                    <div className="flex items-center gap-2 px-3 py-2">
                      <div className="w-1/3 text-[var(--cds-text-secondary)]">
                        {t('details.properties.overallResidualRisk')}
                      </div>
                      <div className="w-2/3 font-medium flex items-center gap-2">
                        <div className={`size-3 rounded-full ${riskColorMap.get(tree?.overallResidualRisk || '')}`}/>
                        {tree?.overallResidualRisk ? tree?.overallResidualRisk :
                          <span className="text-outer-space-500">-</span>}
                      </div>
                    </div>
                    <div className="flex items-center gap-2 px-3 py-2">
                      <div className="w-1/3 text-[var(--cds-text-secondary)]">
                        {t('tree.dataStatus')}
                      </div>
                      <div className="w-2/3 font-medium">
                        <div className="w-full">
                          <Dropdown
                            value={{ id: tree?.dataStatus, translationKey: tree?.dataStatus }}
                            items={Object.values(DataStatus).map(status => ({ id: status, translationKey: status }))}
                            className="bg-outer-space-950 text-[var(--cds-text-secondary)] rounded-[4px]"
                            fieldClassName="w-[calc(100%-1rem)] rounded-[4px] px-2 py-2 border-none"
                            menuClassname="w-[calc(100%-0.5rem)] bg-outer-space-700 p-1 border-none shadow-dropdown"
                            onSelect={item => handleChange('dataStatus', item.id as string)}
                            disabled={disableEdit}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 px-3 py-2">
                      <div className="w-1/3 text-[var(--cds-text-secondary)]">
                        {t('tree.recommendedInspectionInterval')}
                      </div>
                      <div className="w-2/3 font-medium">
                        <div className="w-full">
                          <input
                            name={'recommendedInspectionInterval'}
                            className={'w-[calc(100%-1rem)] bg-outer-space-950 rounded-[4px] px-2 py-2 text-[var(--cds-text-secondary)]'}
                            type="text"
                            defaultValue={tree?.recommendedInspectionInterval || ''}
                            onBlur={event => handleChange('recommendedInspectionInterval', event.target.value)}
                            disabled={disableEdit}
                          />
                        </div>
                      </div>
                    </div>
                  </PropertyFieldGroup>
                  <div>
                    <div
                      className="pl-3 py-1 uppercase text-[var(--cds-text-secondary)] text-xs border-b border-b-[var(--cds-border-subtle-00)]">
                      {t('taskManager.currentState.mitigationOptions')}
                    </div>
                    {(tree.mitigations || []).map((mitigation, index) => (
                      <div
                        className={`p-3 ${index === 0 ? '' : 'border-t border-t-[var(--cds-border-subtle-00)]'}`}
                        key={`${mitigation.name} + ${index}`}>
                        <div className="flex justify-between items-center text-[var(--cds-text-secondary)]">
                          <div>
                            {t('taskManager.currentState.name')}
                          </div>
                          <div>
                            <Trash
                              className="text-danger-500 size-4 fill-none cursor-pointer p-2 rounded hover:bg-greehill-00/10"
                              onClick={() => {
                                handleChange('mitigations', tree?.mitigations.filter((_, idx) => idx !== index));
                                handleChange('observations', tree?.observations
                                  .map(observation => ({
                                    ...observation,
                                    mitigations: observation.mitigations.filter(mit => mit.taskTemplateId !== mitigation.taskTemplateId)
                                  })));
                              }}
                            />
                          </div>
                        </div>
                        <Dropdown
                          value={mitigationOptions.find(it => it.id === mitigation.taskTemplateId) || {
                            id: mitigation.name,
                            translationKey: mitigation.name
                          }}
                          items={remainingMitigationOptions}
                          className="w-full bg-outer-space-950 text-[var(--cds-text-secondary)] rounded-[4px]"
                          fieldClassName="w-[calc(100%-1rem)] rounded-[4px] px-2 py-2 border-none"
                          menuClassname="w-[calc(100%-0.5rem)] bg-outer-space-700 p-1 border-none shadow-dropdown"
                          disabled={!!mitigation.taskTemplateId}
                          onSelect={item => {
                            handleChange('mitigations', tree?.mitigations.map((it, i) => index === i ? {
                              ...it,
                              name: item.translationKey,
                              taskTemplateId: item.id,
                              workOrderId
                            } : it));
                          }}
                        />
                        <div
                          className="mt-4 mb-2 text-[var(--cds-text-secondary)]">{t('taskManager.currentState.residualRisk')}</div>
                        <Dropdown
                          value={{ id: mitigation?.residualRisk, translationKey: mitigation?.residualRisk }}
                          items={Object.values(RiskRating).map(rating => ({ id: rating, translationKey: rating }))}
                          className="w-full bg-outer-space-950 text-[var(--cds-text-secondary)] rounded-[4px]"
                          fieldClassName="w-[calc(100%-1rem)] rounded-[4px] px-2 py-2 border-none"
                          menuClassname="w-[calc(100%-0.5rem)] bg-outer-space-700 p-1 border-none shadow-dropdown"
                          valueRenderer={item => (<div className="flex items-center gap-2"><span
                            className={`size-3 inline-block rounded-full ${riskColorMap.get(item.id || '')}`}/><span>{item.translationKey}</span>
                          </div>)}
                          onSelect={item => {
                            handleChange('mitigations', tree?.mitigations.map((it, i) => index === i ? {
                              ...it,
                              residualRisk: item.id
                            } : it));
                          }}
                        />
                      </div>
                    ))}
                    <div>
                      <button
                        className="flex gap-2 items-center m-auto rounded-full border border-[var(--cds-border-subtle-00)] py-1 px-3 text-sm mt-2"
                        disabled={disableEdit || tree.mitigations?.length === 3}
                        onClick={() => {
                          handleChange('mitigations', [...tree.mitigations || [], {
                            name: '',
                            residualRisk: RiskRating.LOW
                          }]);
                        }}>
                        <PlusCircle/> {t('taskManager.add')}
                      </button>
                    </div>
                  </div>
                  <div className="my-8">
                    <div
                      className="pl-3 py-1 uppercase text-[var(--cds-text-secondary)] text-xs border-b border-b-[var(--cds-border-subtle-00)]">
                      {t('taskManager.currentState.advancedAssessment')}
                    </div>
                    <div className="flex flex-col rounded-xl overflow-hidden divide-y divide-[var(--cds-border-subtle-00)]">
                      <div className="px-3 py-3 text-[var(--cds-text-secondary)]">
                        <Toggle
                          checked={!!tree?.advancedAssessmentNeeded}
                          onChange={() => handleChange('advancedAssessmentNeeded', !tree.advancedAssessmentNeeded)}
                          disabled={disableEdit}
                        >
                          {t('taskManager.currentState.needed')}
                        </Toggle>
                      </div>
                      <div className="px-3 py-2 flex flex-col gap-2 text-[var(--cds-text-secondary)]">
                        <div>{t('taskManager.currentState.typeOrReason')}</div>
                        <textarea
                          className="bg-outer-space-950 rounded-[4px] px-2 py-2 text-[var(--cds-text-secondary)]"
                          defaultValue={tree?.advancedAssessmentReason}
                          placeholder={t('treeDetails.dataCollection.notePlaceholder')}
                          onBlur={event => handleChange('advancedAssessmentReason', event.target.value)}
                          disabled={!tree?.advancedAssessmentNeeded || disableEdit}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="my-8">
                    <div
                      className="pl-3 py-1 uppercase text-[var(--cds-text-secondary)] text-xs border-b border-b-[var(--cds-border-subtle-00)]">
                      {t('taskManager.currentState.inspectionLimitation')}
                    </div>
                    <div className="flex flex-col gap-1 divide-y divide-[var(--cds-border-subtle-00)]">
                      <div className="w-full px-3 py-2">
                        <Dropdown
                          value={{ id: tree?.inspectionLimitation, translationKey: tree?.inspectionLimitation }}
                          items={
                            Object.values(InspectionLimitation)
                              .map(status => ({
                                id: status,
                                translationKey: status === InspectionLimitation.ROOT_COLLAR_BURIED ? 'Root collar buried' : status
                              }))
                          }
                          className="w-[calc(100%-1.5rem)] bg-outer-space-950 text-[var(--cds-text-secondary)] rounded-[4px]"
                          fieldClassName="w-[calc(100%-1rem)] rounded-[4px] px-2 py-2 border-none"
                          menuClassname="w-[calc(100%-0.5rem)] bg-outer-space-700 p-1 border-none shadow-dropdown"
                          onSelect={item => handleChange('inspectionLimitation', item.id as string)}
                          disabled={disableEdit}
                        />
                      </div>
                      <div className="px-3 py-2 flex flex-col gap-2 text-[var(--cds-text-secondary)]">
                        <div>{t('taskManager.currentState.description')}</div>
                        <textarea
                          className="bg-outer-space-950 rounded-[4px] px-2 py-2 text-[var(--cds-text-secondary)]"
                          defaultValue={tree?.inspectionLimitationDescription}
                          placeholder={t('treeDetails.dataCollection.notePlaceholder')}
                          onBlur={event => handleChange('inspectionLimitationDescription', event.target.value)}
                          disabled={tree?.inspectionLimitation === InspectionLimitation.NONE || disableEdit}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="my-8 text-[var(--cds-text-secondary)]">
                    <div className="pl-3 py-1 uppercase text-xs border-b border-b-[var(--cds-border-subtle-00)]">
                      {t('taskManager.currentState.notesTitle')}
                    </div>
                    <div className="flex flex-col gap-1 overflow-hidden px-3 py-2">
                      <textarea
                        className="bg-outer-space-950 rounded-[4px] px-2 py-2 text-[var(--cds-text-secondary)]"
                        defaultValue={tree?.note}
                        placeholder={t('treeDetails.dataCollection.notePlaceholder')}
                        onBlur={event => handleChange('note', event.target.value)}
                        disabled={disableEdit}
                      />
                    </div>
                  </div>
                </>}
            </AccordionItem>
          </>
        )}
      </Accordion>
    </DetailsGroup>
  );
}

interface PropertyFieldProps {
  property: DisplayableTreeProperty | keyof DetailedTree,
  value: number | string | boolean | null | undefined,
  userUpdated: boolean,
  action?: ReactNode | ReactNode[]
}

function PropertyField(props: PropertyFieldProps) {
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();
  const unit = Tree.getUnit(props.property, organization);
  const value = Tree.renderPropertyValue(props.property, props.value, t);
  const hasValue = props.value !== null && props.value !== undefined;

  return (
    <div className={'flex items-center gap-2 px-4 py-3.5'}>
      <div className="w-1/3 flex gap-2 items-center text-[var(--cds-text-secondary)] text-sm font-normal">
        {t('tree.' + props.property, { defaultValue: t('details.properties.' + props.property) })}
      </div>
      <div className="w-2/3 font-normal text-sm text-[var(--cds-text-primary)] flex justify-between">
        {hasValue ?
          <div className={`flex items-baseline ${unit === '%' ? '' : 'gap-1'}`}>
            {value}
            {unit && <span>{t(`units.${unit}`)}</span>}
          </div>
          :
          <span className="text-outer-space-500">-</span>}
        <div className="flex items-center gap-2">
          {props.userUpdated && (
            <span className="bg-warn-chardonnay-600 flex items-center rounded-full">
              <Tooltip label={t('details.manuallyOverwrittenData')} align="left">
                <InfoCircle/>
              </Tooltip>
            </span>
          )}
          {props.action}
        </div>
      </div>
    </div>
  );
}

function PropertyFieldGroup({ children, title }: { children: ReactNode | ReactNode[], title?: string }) {
  return (
    <div>
      {title && (
        <h3 className="font-semibold mt-8 ml-4 text-sm">
          {title}
        </h3>
      )}
      <div className="flex flex-col mb-4 divide-y divide-[var(--cds-border-subtle-00)]">
        {children}
      </div>
    </div>
  );
}

function ClearanceIssuePropertyField(props: IssueProperty, t: TFunction, organization: Organization) {
  let value = props.value;
  if (typeof props.value === 'boolean') {
    value = t(`details.properties.booleanLabels.${props.value}`) as string;
  } else if (typeof props.value === 'number') {
    value = props.value.toLocaleString('en-US', { maximumFractionDigits: 2 });
  }
  const unit = Tree.getUnit(props.property.split('.').at(-1) || '', organization);

  return (
    <div key={props.property} className={'flex items-center my-1'}>
      <div className="flex-1 flex items-center text-[var(--cds-text-secondary)] text-sm font-normal">
        {t('tree.' + props.property, { defaultValue: t('details.properties.' + props.property) })}
      </div>
      <div className="flex-1 font-normal text-sm text-[var(--cds-text-primary)]">
        {value ?
          <div className={`flex items-baseline ${ unit === '%' ? '' : 'gap-1'}`}>
            {value}
            {unit && <span>{t(`units.${unit}`)}</span>}
          </div>
          :
          <span className="text-outer-space-500">-</span>}
      </div>
    </div>
  );
}

type IssueProperty = { property: string, value: number | string | boolean | null | undefined };
type Issue = {
  title?: string,
  properties: IssueProperty[]
};

function ClearanceIssueField(props: Issue & { organization: Organization }) {
  const { t } = useTranslation();
  return (
    <div className={'flex flex-col py-2 mb-4'}>
      <div className="w-1/3 flex items-center mb-2">
        {props.title && <h3 className={'font-semibold text-[var(--cds-text-primary)] text-sm'}>{t(props.title)}</h3>}
      </div>
      <div>
        {(props.properties || []).map(p => ClearanceIssuePropertyField(p, t, props.organization))}
      </div>
    </div>
  );
}

interface ClearanceRowProps {
  title: string,
  type: ClearanceTypes,
  issues: Issue[],
  handleToggleClearanceView: () => void,
  isShowClearanceView: boolean,
  handleOpen: () => void,
  isOpen: boolean
}

function ClearanceRow(props: ClearanceRowProps) {
  const { setClearances, clearances } = useContext(DetailsContext);
  const { t } = useTranslation();
  const { theme } = useCarbonThemes();
  const { organization } = useCurrentAccount();

  return (
    <div className={'flex flex-col items-center'}>
      <div className="w-full flex items-center text-[var(--cds-text-secondary)] text-sm font-normal">
        <div className={'w-1/4 px-4 py-3.5'}>
          {t(props.title)}
        </div>
        <div className={'w-3/4 flex flex-row'}>
          {props?.issues.length ? <div className={'flex-1 flex flex-row items-center'}>
            <CloseFilled className={'mr-1'} color={colorConfig[theme].negativeBackground}/>
            {props.type === ClearanceTypes.Wire ? 'Present and Conflicting' : 'Yes'}
          </div> : <div className={'flex-1 flex flex-row items-center'}>
            <CheckmarkFilled className={'mr-1'} color={colorConfig[theme].poitiveBackground}/>
            No
          </div>}
          <div className={'flex flex-row'}>
            {props.type !== ClearanceTypes.TrafficSign && <>
              {!!props?.issues?.length && <div className={'flex items-center content-center mr-2'}>
                <Tag>{props?.issues?.length}</Tag>
              </div>}
              <Tooltip label={t(`tooltips.${clearances.includes(props.type) ? 'hide' : 'show'}`)} align="left">
                <button
                  className={'flex items-center gap-2 px-3.5 py-3.5 hover:bg-greehill-00/10 disabled:opacity-50'}
                  disabled={!props?.issues.length}
                  onClick={() => setClearances(clearances.includes(props.type) ? clearances.filter(it => it !== props.type) : [...clearances, props.type])}>
                  {clearances.includes(props.type) ? <ViewFilled /> : <View />}
                </button>
              </Tooltip>
              <Tooltip label={t(`tooltips.${props.isOpen ? 'hide' : 'show'}`)} align="left">
                <button
                  className={'flex items-center gap-2 px-3.5 py-3.5 hover:bg-greehill-00/10 disabled:opacity-50'}
                  disabled={!props?.issues.length}
                  onClick={() => {
                    if (props.handleOpen) props?.handleOpen();
                  }}>
                  <>{props.isOpen ? <RowExpand /> : <RowCollapse />}</>
                </button>
              </Tooltip>
            </>}
          </div>
        </div>
      </div>
      <div className={'w-full flex flex-row justify-end'}>
        {props.isOpen && <div
          className={'w-3/4 flex flex-col border-solid border-0 border-t border-[var(--cds-border-subtle)] divide-y divide-[var(--cds-border-subtle-00)]'}>
          {(props.issues || []).map((it, i) => <ClearanceIssueField key={i} {...it} organization={organization}/>)}
        </div>}
      </div>
    </div>
  );
}

function NoDataClearanceRow(props: { title: string, type: ClearanceTypes }) {
  const { t } = useTranslation();
  const { theme } = useCarbonThemes();

  return (
    <div className={'flex flex-col items-center'}>
      <div className="w-full flex items-center text-[var(--cds-text-secondary)] text-sm font-normal">
        <div className={'w-1/4 px-4 py-3.5'}>
          {t(props.title)}
        </div>
        <div className={'w-3/4 flex flex-row'}>
          <div className={'flex-1 flex flex-row items-center'}>
            <ErrorFilled className={'mr-1'} fill={colorConfig[theme].noDataBackground} />
            N/A
          </div>
          <div className={'flex flex-row'}>
            {props.type !== ClearanceTypes.TrafficSign && <>
              <button
                className={'flex items-center gap-2 px-3.5 py-3.5 hover:bg-greehill-00/10 disabled:opacity-50'}
                disabled
              >
                <View />
              </button>
              <button
                className={'flex items-center gap-2 px-3.5 py-3.5 hover:bg-greehill-00/10 disabled:opacity-50'}
                disabled
              >
                <RowCollapse />
              </button>
            </>}
          </div>
        </div>
      </div>
    </div>
  );
}

interface RulerToggleProps {
  property: ExtraObject,
  toggleProperty: (property: ExtraObject) => void,
  selected: boolean
}

function RulerToggle(props: RulerToggleProps) {
  const { urlContext } = useContext(DependencyInjectionContext);
  const { t } = useTranslation();

  if (urlContext.getDetailsTreeDisplay() === TreeDisplays.IMAGES) return null;

  return (
    <div onClick={() => props.toggleProperty(props.property)} className="cursor-pointer">
      <Tooltip label={t(`tooltips.${props.selected ? 'hide' : 'show'}`)} align="left">
        {props.selected ? <ViewFilled/> : <View/>}
      </Tooltip>
    </div>
  );
}
