import { useContext } from 'react';
import { QueryObserverResult, useQuery } from 'react-query';
import DependencyInjectionContext from '../DependencyInjectionContext';
import { WorkOrder } from './WorkOrder';
import { useLocation } from 'react-router-dom';

export function useWorkOrder(organizationId: string, workOrderId?: string): { workOrder: WorkOrder | null, isLoading: boolean, refetch: () => Promise<QueryObserverResult<WorkOrder | null, unknown>> } {
  const { taskManagerService } = useContext(DependencyInjectionContext);
  const location = useLocation();
  const isTasksPage = location.pathname.includes('task-manager/manage');

  const cacheKey = `${organizationId}-task-${workOrderId}`;
  const { data, isLoading, refetch } = useQuery(
    [cacheKey],
    () => {
      if (!workOrderId) return Promise.resolve(null);
      return taskManagerService.getWorkOrder(organizationId, workOrderId);
    },
    {
      initialData: null,
      retry: 1,
      retryOnMount: false,
      refetchInterval: isTasksPage ? 10000 : false
    }
  );

  return {
    workOrder: data as (WorkOrder | null),
    isLoading,
    refetch
  };
}
