import { useQuery } from 'react-query';
import { useContext } from 'react';
import DependencyInjectionContext from '../DependencyInjectionContext';
import { useCurrentAccount } from '../account/useAccounts';
import { StaticQueryKeys } from '../StaticQueryKeys';

const CACHE_TTL_MS = 300000;

export default function useTree(organizationId: string, treeId: string) {
  const { treeService } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();

  const cacheKey = `tree-${organization.id}-${treeId}`;
  const { data, isLoading } = useQuery(
    [cacheKey, StaticQueryKeys.TREE_DETAILS],
    () => {
      if (!treeId || !organization.id) {
        return null;
      }

      return treeService.find(organization, treeId);
    },
    { staleTime: CACHE_TTL_MS, retry: 1, retryOnMount: false }
  );

  return { tree: data ?? null, isTreeLoading: isLoading };
}

export function useTreeStatistics(organizationId: string, treeId: string) {
  const { treeService } = useContext(DependencyInjectionContext);

  const cacheKey = `tree-${organizationId}-${treeId}-statistics`;
  const { data, isLoading } = useQuery(
    [cacheKey],
    () => {
      if (!treeId || !organizationId) {
        return null;
      }

      return treeService.getStatistics(organizationId, treeId);
    },
    { staleTime: CACHE_TTL_MS, retry: 1, retryOnMount: false }
  );

  return { statistics: data ?? null, isLoadingStatistics: isLoading };
}
