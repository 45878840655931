import { useTranslation } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import useCarbonThemes from '../../../../../components/UI/Carbon/useCarbonThemes';

export type PieChartData = { key: string, value: number }[];
const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer?.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');

    legendContainer?.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item, index) => {
      if (!chart.data.datasets[0].data[index]) return;
      const { percentageLabel, id } = chart.data.datasets[0].data[index];
      const li = document.createElement('li');
      li.id = id;
      li.classList.add('grid', 'grid-cols-4', 'items-center', 'gap-2', 'py-1');

      const colorCircle = document.createElement('span');
      colorCircle.style.background = item.fillStyle;
      colorCircle.style.borderColor = item.strokeStyle;
      colorCircle.style.borderWidth = item.lineWidth + 'px';
      colorCircle.classList.add('w-2', 'h-2', 'rounded-full', 'block', 'pl-0.5');

      // Text
      const textContainer = document.createElement('p');
      textContainer.classList.add('py-0', 'my-0', 'px-2');

      const percentageContainer = document.createElement('div');
      percentageContainer.classList.add('text-right', 'col-start-4', 'col-end-5', 'px-2');

      const text = document.createTextNode(item.text);
      const percentageText = document.createTextNode(percentageLabel);
      textContainer.appendChild(text);
      percentageContainer.appendChild(percentageText);

      const indicatorWithTitle = document.createElement('div');
      indicatorWithTitle.classList.add('col-start-1', 'col-end-4', 'flex', 'items-center');
      indicatorWithTitle.appendChild(colorCircle);
      indicatorWithTitle.appendChild(textContainer);
      li.appendChild(indicatorWithTitle);
      li.appendChild(percentageContainer);
      ul.appendChild(li);
    });
  }
};

type PieChartProps = {
  data: PieChartData
};

export default function CockpitPieChart(props: PieChartProps) {
  const { t } = useTranslation();
  const { colors } = useCarbonThemes();

  const colorMap = [
    colors.supportError,
    colors.supportCautionMinor,
    colors.supportInfo,
    colors.supportSuccess
  ];
  const data = {
    labels: props.data.map(it => it.key),
    datasets: [
      {
        data: props.data.map(it => ({
          ...it,
          id: Math.random().toString(16).slice(2),
          percentageLabel: it.value,
          onChartLabel: it.value
        })),
        backgroundColor: colorMap,
        borderColor: colorMap,
        borderWidth: 0
      }
    ],
    options: {
      cutout: '60%',
      color: 'white',
      plugins: {
        tooltip: {
          enabled: false,
        },
        parsing: {
          key: 'value'
        },
        datalabels: {
          display: false
        },
        htmlLegend: {
          containerID: 'legend-container'
        },
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
            generateLabels(chart) {
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                const { labels: { pointStyle } } = chart.legend.options;

                return data.labels.map((label: string | string [], i: number) => {
                  const meta = chart.getDatasetMeta(0);
                  const style = meta.controller.getStyle(i);

                  return {
                    text: label,
                    fillStyle: style.backgroundColor,
                    strokeStyle: style.borderColor,
                    lineWidth: style.borderWidth,
                    pointStyle,
                    hidden: !chart.getDataVisibility(i),

                    // Extra data used for toggling the correct item
                    index: i
                  };
                });
              }
              return [];
            }
          }
        }
      }
    }
  };

  return (
    <div className="grid grid-cols-[224px_1fr] gap-12">
      <div className="h-[224px] col-start-1 col-end-2">
        <Doughnut
          data={data}
          options={data.options}
          plugins={[htmlLegendPlugin, ChartDataLabels as unknown as any]}
        />
      </div>
      <div className="col-start-2 col-end-3">
        <div className="grid grid-cols-3 py-2 text-xl weight-400 text-[var(--cds-text-primary)] border-solid border-0 border-b border-[var(--cds-border-subtle)]">
          <div className="col-start-1 col-end-3 px-2">{t('taskManager.treesInMyArea')}</div>
          <div className="col-start-3 col-end-4 px-2 text-right">{props.data.reduce((acc, it) => acc + Number(it.value), 0)}</div>
        </div>
        <div className="text-sm weight-400 text-[var(--cds-text-primary)]" id={'legend-container'}>
        </div>
      </div>
    </div>
  );
}
