import { Tree } from '../../tree/Tree';
import { useTranslation } from 'react-i18next';
import { DetailsButtonHelpers } from './Analytics';
import {
  DetailsButton,
  ExtraDetailsButton,
  PageContainer,
  PageContent,
  PageHeader,
  PropertyListItem,
  ResetViewButton,
  TreeComparisonView,
  TreePropertyList,
  TreeView
} from './components/Page';
import { useMemo, useRef, useState } from 'react';
import PointCloudViewerWithSafetyFactor from '../../components/PointCloud/PointCloudViewerWithSafetyFactor';
import styles from './Safety.module.scss';
import PanoramaView from '../../components/Panorama/PanoramaView';
import SphereControls from '../../components/Panorama/SphereControls';
import Slider from 'rc-slider';
import { MultiOrbitControl } from '../../components/PointCloud/MultiOrbitControl';
import { DateSelector, DateSelectorContainer } from './components/ChartControls';
import { AVAILABLE_DATES, NOW } from './fakes';
import { Organization } from '../../organization/Organization';
import { useCurrentAccount } from '../../account/useAccounts';
import { useTracking } from '../../analytics/useTracking';
import { AnalyticsView } from './AnalyticsView';

const properties: Array<'all' | 'safetyFactor' | 'leaningAngle'> = ['all', 'safetyFactor', 'leaningAngle'];

export default function SafetyAnalytics(props: SafetyAnalyticsProps) {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const { events, track } = useTracking();
  const [selectedProperty, setSelectedProperty] = useState(properties[1]);
  const [windSpeed, setWindSpeed] = useState(account.getDefaultWindSpeed());
  const showSafetyFactor = ['safetyFactor', 'all'].includes(selectedProperty);
  const showLeaningAngle = ['leaningAngle', 'all'].includes(selectedProperty);
  const [dateA, setDateA] = useState(NOW);
  const [dateB, setDateB] = useState(AVAILABLE_DATES.at(-1)!);
  const [seed, setSeed] = useState(Math.random().toString(16).slice(-7));
  const updateSeed = () => setSeed(Math.random().toString(16).slice(-7));

  const sphereControls = useMemo(() => new SphereControls(), []);

  const resetView = () => {
    updateSeed();
    sphereControls.reset();
  };

  const multiControlsRef = useRef(new MultiOrbitControl());

  const maxWindSpeed = props.tree.getMaxWindSpeed();
  const minWindSpeed = props.tree.getMinWindSpeed();

  const updateWindSpeed = (windSpeed: number) => {
    setWindSpeed(windSpeed);
    track(events.SAFETY_FACTORY_WIND_SPEED_CHANGED_IN_ANALYTICS, { windSpeed, view: props.view });
  };

  const updateSelectedProperty = selectedProperty => {
    setSelectedProperty(selectedProperty);
    track(events.SAFETY_FACTORY_MARKERS_CHANGED_IN_ANALYTICS, { marker: selectedProperty, view: props.view });
  };

  return (
    <PageContainer>
      <PageHeader title={t('analytics.navigation.safety')}>
        <TreePropertyList>
          {properties.map(it => (
            <PropertyListItem
              key={it}
              onClick={() => updateSelectedProperty(it)}
              active={selectedProperty === it}>
              {t('analytics.properties.' + it)}
            </PropertyListItem>
          ))}
        </TreePropertyList>
      </PageHeader>

      <PageContent tree>
        {props.view === AnalyticsView.Twin && (
          <>
            {!props.compare && (
              <TreeView pointCloud>
                {showSafetyFactor && <SafetyFactorBadge safetyFactor={props.tree.getSafetyFactor(windSpeed)} />}
                <PointCloudViewerWithSafetyFactor
                  seed={seed}
                  type={selectedProperty}
                  tree={props.tree}
                  showEnvironment={props.detailsHelper.showDetails}
                  windSpeed={windSpeed}
                  multiControlsRef={multiControlsRef}
                />
              </TreeView>
            )}

            {props.compare && (
              <TreeComparisonView>
                <TreeView pointCloud>
                  {showSafetyFactor && <SafetyFactorBadge safetyFactor={props.tree.getSafetyFactor(windSpeed)} />}
                  <PointCloudViewerWithSafetyFactor
                    seed={seed}
                    type={selectedProperty}
                    tree={props.tree}
                    showEnvironment={props.detailsHelper.showDetails}
                    windSpeed={windSpeed}
                    multiControlsRef={multiControlsRef}
                  />
                </TreeView>

                <TreeView pointCloud>
                  {showSafetyFactor && <SafetyFactorBadge safetyFactor={props.tree.getSafetyFactor(windSpeed)} />}
                  <PointCloudViewerWithSafetyFactor
                    seed={seed}
                    type={selectedProperty}
                    tree={props.tree}
                    showEnvironment={props.detailsHelper.showDetails}
                    windSpeed={windSpeed}
                    multiControlsRef={multiControlsRef}
                  />
                </TreeView>
              </TreeComparisonView>
            )}
          </>
        )}

        {props.view === AnalyticsView.Panorama && (
          <>
            {!props.compare && (
              <TreeView>
                {showSafetyFactor && (
                  <SafetyFactorBadge safetyFactor={props.tree.getSafetyFactor(windSpeed)} rightAlign={true} />
                )}
                <PanoramaView
                  tree={props.tree}
                  organizationId={props.organization.id}
                  controls={sphereControls}
                  showPointCloud={props.detailsHelper.showDetails}
                  showEnvironmentPointCloud={props.detailsHelper.showExtraDetails}
                  rulers={[]}
                  showSafetyFactor={showSafetyFactor}
                  windSpeed={windSpeed}
                  showLeaningAngle={showLeaningAngle}
                />
              </TreeView>
            )}

            {props.compare && (
              <TreeComparisonView>
                <TreeView>
                  {showSafetyFactor && (
                    <SafetyFactorBadge safetyFactor={props.tree.getSafetyFactor(windSpeed)} rightAlign={true} />
                  )}
                  <PanoramaView
                    tree={props.tree}
                    organizationId={props.organization.id}
                    controls={sphereControls}
                    showPointCloud={props.detailsHelper.showDetails}
                    showEnvironmentPointCloud={props.detailsHelper.showExtraDetails}
                    rulers={[]}
                    showSafetyFactor={showSafetyFactor}
                    windSpeed={windSpeed}
                    showLeaningAngle={showLeaningAngle}
                  />
                </TreeView>

                <TreeView>
                  {showSafetyFactor && (
                    <SafetyFactorBadge safetyFactor={props.tree.getSafetyFactor(windSpeed)} rightAlign={true} />
                  )}
                  <PanoramaView
                    tree={props.tree}
                    organizationId={props.organization.id}
                    controls={sphereControls}
                    showPointCloud={props.detailsHelper.showDetails}
                    showEnvironmentPointCloud={props.detailsHelper.showExtraDetails}
                    rulers={[]}
                    showSafetyFactor={showSafetyFactor}
                    windSpeed={windSpeed}
                    showLeaningAngle={showLeaningAngle}
                  />
                </TreeView>
              </TreeComparisonView>
            )}
            <ExtraDetailsButton isOn={props.detailsHelper.showExtraDetails} toggle={props.detailsHelper.toggleExtraDetails} />
          </>
        )}

        <DetailsButton showDetails={props.detailsHelper.showDetails} toggleDetails={props.detailsHelper.toggleDetails} />
        <ResetViewButton onClick={resetView} />

        {props.tree.safetyFactors.length > 0 && showSafetyFactor && (
          <div className={styles.windSpeedSliderContainer}>
            <div className={styles.sliderContainer}>
              <div className={styles.sliderTitle}>{t('analytics.setWindSpeed')}</div>

              <div className={styles.sliderLabels}>
                <div>{minWindSpeed} {Tree.getWindSpeedUnit(props.organization)}</div>
                <div>{maxWindSpeed} {Tree.getWindSpeedUnit(props.organization)}</div>
              </div>

              <Slider
                handleStyle={{
                  background: 'var(--chart-positive-value)',
                  border: 'none',
                  opacity: 1
                }}
                activeDotStyle={{ border: 'none', boxShadow: 'none' }}
                trackStyle={{ background: 'var(--chart-positive-value)' }}
                value={windSpeed}
                min={minWindSpeed}
                max={maxWindSpeed}
                step={5}
                onChange={value => updateWindSpeed(value as number)}
              />

              <div className={styles.windSpeedInputContainer}>
                <input
                  type="number"
                  value={windSpeed}
                  onChange={event => event.target.checkValidity() && updateWindSpeed(parseInt(event.target.value))}
                  step={5}
                  min={minWindSpeed}
                  max={maxWindSpeed}
                  className={styles.windSpeedInput}
                />{' '}
                {Tree.getWindSpeedUnit(props.organization)}
              </div>
            </div>
          </div>
        )}
        {props.compare && (
          <DateSelectorContainer floating>
            <DateSelector
              variant="current"
              options={AVAILABLE_DATES}
              onSelect={setDateA}
              selected={dateA} />

            <DateSelector
              variant="reference"
              options={AVAILABLE_DATES}
              onSelect={setDateB}
              selected={dateB} />
          </DateSelectorContainer>
        )}
      </PageContent>
    </PageContainer>
  );
}

function SafetyFactorBadge({ safetyFactor, rightAlign }: { safetyFactor: number | null, rightAlign?: boolean }) {
  const { t } = useTranslation();
  if (!safetyFactor) return <></>;

  const classes = [styles.safetyFactorBadge];
  if (safetyFactor < Tree.SAFETY_FACTOR_THRESHOLD) classes.push(styles.safetyFactorBadgeDanger);
  if (rightAlign) classes.push(styles.safetyFactorBadgeRight);

  return (
    <div className={classes.join(' ')}>
      <span>{safetyFactor.toFixed(2)}</span>
      <span>{t('parameters.safetyFactor')}</span>
    </div>
  );
}

interface SafetyAnalyticsProps {
  tree: Tree,
  view: AnalyticsView,
  compare: boolean,
  organization: Organization,
  detailsHelper: DetailsButtonHelpers
}
