import { useTaskGroups } from './useTaskGroups';
import { Button, Header, IconButton, TextInput } from '@carbon/react';
import React, { FocusEvent, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Add, Checkmark, Close, TrashCan } from '@carbon/icons-react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useCurrentAccount } from '../../../account/useAccounts';
import { Task, TaskGroup } from './TaskGroup';

export default function TaskGroups() {
  const { taskGroups } = useTaskGroups();
  const { taskManagerService, urlContext } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();

  const { t } = useTranslation();

  const [creatingTaskGroup, setCreatingTaskGroup] = useState(false);
  const [taskGroupName, setTaskGroupName] = useState('');
  const [editingTaskGroup, setEditingTaskGroup] = useState<string | null>(null);

  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const deleteButtonRef = useRef<HTMLButtonElement>(null);

  const createNewTaskGroup = async () => {
    if (taskGroupName === '') return;
    await taskManagerService.addTaskGroup(organization.id, { name: taskGroupName });
    setTaskGroupName('');
    setCreatingTaskGroup(false);
  };

  const cancelCreatingTaskGroup = () => {
    setTaskGroupName('');
    setCreatingTaskGroup(false);
  };

  const cancelCreate = (event: FocusEvent) => {
    if (event.relatedTarget === saveButtonRef.current || event.relatedTarget === deleteButtonRef.current) return;
    setTaskGroupName('');
    setCreatingTaskGroup(false);
  };

  return (
    <section className="twp px-8 pt-4 w-[320px]">
      <Header aria-label={t('mainMenu.taskManagerManage')} className="flex justify-between pl-4 text-sm font-semibold">
        {t('mainMenu.taskManagerManage')}
        <IconButton onClick={() => urlContext.setFullScreenModalOpen(null)} label={t('tooltips.close')} kind="ghost"><Close/></IconButton>
      </Header>
      <ul className="flex flex-col divide-y divide-[var(--cds-border-subtle-00)]">
        {taskGroups.map(taskGroup => (
          <TaskGroupRow
            key={taskGroup.id}
            taskGroup={taskGroup}
            editingTaskGroup={editingTaskGroup}
            setEditingTaskGroup={setEditingTaskGroup}
          />
        ))}
      </ul>
      {creatingTaskGroup
        ? <div className="flex">
          <TextInput
            id='new-task-group'
            labelText=''
            value={taskGroupName}
            onChange={e => setTaskGroupName(e.target.value)}
            onBlur={cancelCreate}
            autoFocus={true}
            onKeyUp={e => {
              if (e.key === 'Enter') createNewTaskGroup();
              if (e.key === 'Escape') cancelCreatingTaskGroup();
            }}
          />
          <IconButton
            onClick={createNewTaskGroup}
            label={t('taskManager.save')}
            align="bottom"
            kind="primary"
            size='md'
            ref={saveButtonRef}
          >
            <Checkmark />
          </IconButton>
          <IconButton
            onClick={cancelCreatingTaskGroup}
            label={t('taskManager.delete')}
            align='bottom'
            kind='secondary'
            size='md'
            ref={deleteButtonRef}
          >
            <TrashCan />
          </IconButton>
        </div>
        : <div className="w-fit m-auto">
          <Button
            onClick={() => setCreatingTaskGroup(true)}
            kind="tertiary"
            size="lg"
            renderIcon={Add}
            disabled={Boolean(editingTaskGroup) || creatingTaskGroup}
          >
            {t('taskManager.addTaskGroup')}
          </Button>
        </div>}
    </section>
  );
}

interface TaskGroupRowProps {
  taskGroup: TaskGroup,
  editingTaskGroup: string | null,
  setEditingTaskGroup: (id: string | null) => void
}

const TaskGroupRow = (props: TaskGroupRowProps) => {
  const { t } = useTranslation();
  const { taskManagerService } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();

  const [taskGroupName, setTaskGroupName] = useState<string>(props.taskGroup.name);
  const [editingGroup, setEditingGroup] = useState(false);

  const [creatingTask, setCreatingTask] = useState(false);
  const [taskName, setTaskName] = useState('');

  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const deleteButtonRef = useRef<HTMLButtonElement>(null);
  const taskSaveButtonRef = useRef<HTMLButtonElement>(null);
  const taskDeleteButtonRef = useRef<HTMLButtonElement>(null);

  const updateTaskGroup = async () => {
    await taskManagerService.updateTaskGroup(organization.id, props.taskGroup.id, { name: taskGroupName });
    setEditingGroup(false);
    props.setEditingTaskGroup(null);
  };

  const deleteTaskGroup = async () => {
    await taskManagerService.deleteTaskGroup(organization.id, props.taskGroup.id);
    setEditingGroup(false);
    props.setEditingTaskGroup(null);
  };

  const startEditingGroup = () => {
    if (props.taskGroup.isProtected || props.editingTaskGroup) return;
    setEditingGroup(true);
    props.setEditingTaskGroup(props.taskGroup.id);
  };

  const cancelEditing = (event: FocusEvent) => {
    if (event.relatedTarget === saveButtonRef.current || event.relatedTarget === deleteButtonRef.current) return;
    setEditingGroup(false);
    props.setEditingTaskGroup(null);
    setTaskGroupName(props.taskGroup.name);
  };

  const createNewTask = async () => {
    if (taskName === '') return;
    await taskManagerService.addTask(organization.id, {
      name: taskName,
      taskGroupId: props.taskGroup.id
    });
    setTaskName('');
    setCreatingTask(false);
  };

  const cancelCreatingTask = () => {
    setTaskName('');
    setCreatingTask(false);
  };

  const cancelCreate = (event: FocusEvent) => {
    if (event.relatedTarget === taskSaveButtonRef.current || event.relatedTarget === taskDeleteButtonRef.current) return;
    setTaskName('');
    setCreatingTask(false);
  };

  return (
    <li key={props.taskGroup.id} className="flex flex-col gap-4 p-4">
      <div className="flex items-center justify-between">
        {editingGroup
          ? <div className="flex">
            <TextInput
              id={`editing-${props.taskGroup.id}`}
              labelText=""
              value={taskGroupName}
              onChange={e => setTaskGroupName(e.target.value)}
              onBlur={cancelEditing}
              autoFocus={true}
              onKeyUp={e => {
                if (e.key === 'Enter') updateTaskGroup();
                if (e.key === 'Escape') {
                  setEditingGroup(false);
                  props.setEditingTaskGroup(null);
                  setTaskGroupName(props.taskGroup.name);
                }
              }}
            />
            <IconButton
              onClick={updateTaskGroup}
              label={t('taskManager.save')}
              align="bottom"
              kind="primary"
              size="md"
              ref={saveButtonRef}
            >
              <Checkmark />
            </IconButton>
            <IconButton
              onClick={deleteTaskGroup}
              label={t('taskManager.delete')}
              align="bottom"
              kind="secondary"
              size="md"
              ref={deleteButtonRef}
            >
              <TrashCan />
            </IconButton>
          </div>
          : <div
            className="text-xl font-normal leading-tight tracking-tight"
            onClick={startEditingGroup}
          >
            {props.taskGroup.name}
          </div>}
        {!props.taskGroup.isProtected && <IconButton
          onClick={() => setCreatingTask(true)}
          label={t('taskManager.addTask')}
          align="right"
          kind="ghost"
          disabled={Boolean(props.editingTaskGroup || creatingTask)}
        >
          <Add />
        </IconButton>}
      </div>
      <ul className="flex flex-col gap-4">
        {props.taskGroup.tasks.map(task => <TaskRow key={task.id} {...task} />)}
        {creatingTask
          ? <div className="flex">
            <TextInput
              id="new-task"
              labelText=""
              value={taskName}
              onChange={e => setTaskName(e.target.value)}
              onBlur={cancelCreate}
              autoFocus={true}
              onKeyUp={e => {
                if (e.key === 'Enter') createNewTask();
                if (e.key === 'Escape') cancelCreatingTask();
              }}
            />
            <IconButton
              onClick={createNewTask}
              label={t('taskManager.save')}
              align="bottom"
              kind="primary"
              size="md"
              ref={taskSaveButtonRef}
            >
              <Checkmark />
            </IconButton>
            <IconButton
              onClick={cancelCreatingTask}
              label={t('taskManager.delete')}
              align="bottom"
              kind="secondary"
              size="md"
              ref={taskDeleteButtonRef}
            >
              <TrashCan />
            </IconButton>
          </div>
          : <></>}
      </ul>
    </li>
  );
};

const TaskRow = (task: Task) => {
  const { t } = useTranslation();
  const { taskManagerService } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();

  const [editingTask, setEditingTask] = useState<string | null>(null);
  const [taskName, setTaskName] = useState(task.name);

  const taskUpdateButtonRef = useRef<HTMLButtonElement>(null);
  const taskDeleteButtonRef = useRef<HTMLButtonElement>(null);

  const startEditingTask = () => {
    if (task.isProtected || editingTask) return;
    setEditingTask(task.id);
  };

  const cancelEditingTask = (event: FocusEvent) => {
    if (event.relatedTarget === taskUpdateButtonRef.current || event.relatedTarget === taskDeleteButtonRef.current) return;
    setEditingTask(null);
    setTaskName(task.name);
  };

  const updateTask = async () => {
    setEditingTask(null);
    await taskManagerService.updateTask(organization.id, task.id, { name: taskName });
  };

  const deleteTask = async () => {
    setEditingTask(null);
    await taskManagerService.deleteTask(organization.id, task.id);
  };

  return (
    editingTask === task.id
      ? <div className="flex" key={task.id}>
        <TextInput
          id={`editing-${task.id}`}
          labelText=""
          value={taskName}
          onChange={e => setTaskName(e.target.value)}
          onBlur={cancelEditingTask}
          autoFocus={true}
          onKeyUp={e => {
            if (e.key === 'Enter') updateTask();
            if (e.key === 'Escape') {
              setEditingTask(null);
              setTaskName(task.name);
            }
          }}
        />
        <IconButton
          onClick={updateTask}
          label={t('taskManager.save')}
          align="bottom"
          kind="primary"
          size="md"
          ref={taskUpdateButtonRef}
        >
          <Checkmark />
        </IconButton>
        <IconButton
          onClick={deleteTask}
          label={t('taskManager.delete')}
          align="bottom"
          kind="secondary"
          size="md"
          ref={taskDeleteButtonRef}
        >
          <TrashCan />
        </IconButton>
      </div>
      :
      <li
        key={task.id}
        className="text-sm font-normal leading-tight tracking-tight py-2"
        onClick={startEditingTask}
      >
        {task.name}
      </li>
  );
};
