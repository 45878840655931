import { User } from '../users/User';
import { ConnectedModule } from '../routes/TaskManager/ConnectedModule';
import { JobColumnName } from '../routes/TaskManager/create/JobColumnName';
import { Task } from '../routes/CarbonTaskManager/tasks/TaskGroup';
import FilterConfig from '../filter/FilterConfig';

export enum LegacyWorkOrderStatus {
  DRAFT = 'draft',
  ASSIGNED = 'assigned',
  UNASSIGNED = 'unassigned',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  STARTED = 'started',
  COMPLETED = 'completed',
  APPROVED = 'approved',
  ARCHIVED = 'archived',
  REVOKED = 'revoked',
  PAUSED = 'paused'
}

export enum WorkOrderStatus {
  TO_DO = 'toDo',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
  COMPLETED_AND_ACCEPTED = 'completedAndAccepted',
  ARCHIVED = 'archived'
}

export enum WorkOrderType {
  REMOTE = 'remote',
  ON_SITE = 'onSite'
}

export enum WorkOrderPriority {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high'
}

export interface WorkOrderDto {
  id: string,
  code: string,
  area: string,
  organizationId: string,
  assignee: User | null,
  status: LegacyWorkOrderStatus,
  deadline: string | null,
  type: WorkOrderType,
  priority: WorkOrderPriority,
  treeIds: string[],
  connectedModule: ConnectedModule | null,
  progress: number,
  nextTodoTreeId: string | null,
  name: string | null,
  jobColumnName: JobColumnName,
  comments: WorkOrderComment[],
  logs: WorkOrderLog[],
  createdAt: string | null,
  createdBy: User | null,
  usersToNotify: string[],
  task: Task,
  description: string | null
}

export interface WorkOrderUpdateDto {
  name?: string | null,
  deadline?: Date | null,
  assigneeUserId?: string | null,
  status?: string,
  priority?: string,
  description?: string
}

export interface WorkOrderCreateDto {
  taskId: string,
  treeIds: string[],
  reversedSelection: boolean,
  filterConfig: FilterConfig,
  priority?: string,
  name?: string | null,
  description?: string | null,
  deadline?: Date | null,
  assigneeUserId?: string | null,
  managedAreaIds: string[],
  reverseMASelection: boolean
}

export interface WorkOrderComment {
  id: string,
  content: string,
  workOrderId: string,
  createdAt: Date,
  user: {
    id: string,
    firstName: string,
    lastName: string,
    email: string
  }
}

export interface WorkOrderLog {
  id: string,
  prevValue: WorkOrderLogContent,
  nextValue: WorkOrderLogContent,
  user: {
    id: string,
    firstName: string,
    lastName: string,
    email: string
  },
  createdAt: Date
}

type WorkOrderLogContent = {
  status: LegacyWorkOrderStatus,
  assigneeUserId?: string,
  deadline?: Date | null,
  assignee?: User | null
};

export class WorkOrder {
  static fromDto(dto: WorkOrderDto): WorkOrder {
    return new WorkOrder(
      dto.id,
      dto.code,
      dto.area,
      dto.organizationId,
      dto.assignee ? User.fromDto(dto.assignee) : null,
      dto.status,
      dto.deadline ? new Date(dto.deadline) : null,
      dto.type,
      dto.priority,
      dto.treeIds,
      dto.connectedModule,
      dto.progress,
      dto.nextTodoTreeId,
      dto.name,
      dto.jobColumnName,
      dto.comments ? dto.comments.map(comment => ({
        id: comment.id,
        content: comment.content,
        workOrderId: comment.workOrderId,
        createdAt: new Date(comment.createdAt),
        user: comment.user
      })) : [],
      dto.logs ? dto.logs.map(log => ({
        id: log.id,
        prevValue: {
          status: log.prevValue.status,
          deadline: log.prevValue.deadline ? new Date(log.prevValue.deadline) : null,
          assignee: log.prevValue.assignee ? User.fromDto(log.prevValue.assignee) : null
        },
        nextValue: {
          status: log.nextValue.status,
          deadline: log.nextValue.deadline ? new Date(log.nextValue.deadline) : null,
          assignee: log.nextValue.assignee ? User.fromDto(log.nextValue.assignee) : null
        },
        user: log.user,
        createdAt: new Date(log.createdAt)
      })) : [],
      dto.createdAt ? new Date(dto.createdAt) : null,
      dto.createdBy ? User.fromDto(dto.createdBy) : null,
      dto.usersToNotify,
      dto.task,
      dto.description
    );
  }

  constructor(
    readonly id: string,
    readonly code: string,
    readonly area: string,
    readonly organizationId: string,
    readonly assignee: User | null,
    readonly status: LegacyWorkOrderStatus | WorkOrderStatus,
    readonly deadline: Date | null,
    readonly type: WorkOrderType,
    readonly priority: WorkOrderPriority,
    readonly treeIds: string[],
    readonly connectedModule: ConnectedModule | null,
    readonly progress: number,
    readonly nextTodoTreeId: string | null,
    readonly name: string | null,
    readonly jobColumnName: JobColumnName,
    readonly comments: WorkOrderComment[],
    readonly logs: WorkOrderLog[],
    readonly createdAt: Date | null,
    readonly createdBy: User | null,
    readonly usersToNotify: string[],
    readonly task: Task,
    readonly description: string | null
  ) {
  }

  getStartingTreeId(): string {
    return this.treeIds[0];
  }
}
