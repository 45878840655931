import styles from './Auth.module.scss';

import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import LoginScreen from './LoginScreen';
import api from '../../services/api';
import { User } from '../../users/User';
import { AuthContext } from '../../auth/AuthContext';
import { AuthService } from '../../auth/AuthService';
import { EntityKey, EntityStorage } from '../../EntityStorage';
import { useQueryClient } from 'react-query';
import { UserService } from '../../users/UserService';
import Spinner from '../UI/Spinner/Spinner';
import getRuntimeConfig from '../../RuntimeConfig';

export default function Auth({ children }: { children: () => ReactNode }) {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const authService = AuthService.make();
  const userCache = EntityStorage.local(EntityKey.LoggedInUser, User);
  const { t, i18n } = useTranslation();
  const [loginError, setLoginError] = useState('');
  const [user, setUser] = useState<User>(userCache.getOrElse(User.anonymous()));
  const logout = useCallback(async () => {
    setUser(User.anonymous());
    userCache.delete();
    queryClient.clear();
    await authService.logout();
  }, [authService, queryClient, userCache]);

  const [params, setParams] = useSearchParams();
  const externalAccessToken = useMemo(() => params.get('externalAccessToken'), []);
  const [externalAccessTokenAuthorizationError, setExternalAccessTokenAuthorizationError] = useState(false);

  const authContext = useContext(AuthContext);

  useEffect(() => {
    const interceptor = api.interceptors.response.use(
      response => response,
      error => {
        if (!axios.isAxiosError(error) || error.response?.status !== 403) {
          return Promise.reject(error);
        }

        queryClient.clear();
        setUser(User.anonymous());
        userCache.delete();
      }
    );
    return () => {
      api.interceptors.response.eject(interceptor);
    };
  }, []);

  useEffect(() => {
    if (user.language) {
      i18n.changeLanguage(user.language);
      localStorage.setItem('i18nextLng', user.language);
    }
  }, [user.language]);

  const onLogin = useCallback(
    async (email: string, password: string) => {
      setLoginError('');
      try {
        const user = await authService.login(email, password);
        setUser(user);
        userCache.set(user);

        const languageSetBeforeLogin = localStorage.getItem('languageSetBeforeLogin');
        localStorage.removeItem('languageSetBeforeLogin');

        if (languageSetBeforeLogin && languageSetBeforeLogin !== user.language) {
          const updated = await UserService.make().updateSelf({ language: languageSetBeforeLogin });
          authContext.update(updated);
          setUser(updated);
        } else {
          await i18n.changeLanguage(user.language);
        }
      } catch (error: any) {
        userCache.delete();
        if (error.message === 'Unauthorized') {
          setLoginError(t('login.invalidPasswordOrEmail'));
        } else {
          setLoginError(t('login.genericError'));
        }
      }
    }, [userCache, t, authService]
  );

  const onLoginExternal = useCallback(
    async () => {
      setLoginError('');
      try {
        const user = await authService.loginExternal(externalAccessToken);
        setUser(user);
        userCache.set(user);
        i18n.changeLanguage(user.language);
        setParams({});
      } catch (error: any) {
        userCache.delete();
        setExternalAccessTokenAuthorizationError(true);

        if (error.message === 'Unauthorized') {
          setLoginError(t('login.invalidPasswordOrEmail'));
        } else {
          setLoginError(t('login.genericError'));
        }
      }
    }, [userCache, t, authService, setParams]
  );

  const update = useCallback(
    (user: User) => {
      setUser(user);
      userCache.set(user);
    },
    [userCache]
  );

  useEffect(() => {
    if (!externalAccessToken) {
      return;
    }

    if (user.isLoggedIn()) {
      return;
    }

    onLoginExternal();
  }, []);

  if (!externalAccessTokenAuthorizationError && externalAccessToken && !user.isLoggedIn()) {
    return <div className={styles.wrapper}>
      <Spinner/>
    </div>;
  }

  if (user.isLoggedIn()) {
    return <AuthContext.Provider value={{ user, logout, update }}>{children()}</AuthContext.Provider>;
  }

  return <LoginScreen
    onLogin={onLogin}
    error={loginError}
    signedUpUsing={searchParams.get('signedUpUsing')}/>;
}
