import { MutableRefObject, useEffect, useRef, useState } from 'react';
import Spinner from '../UI/Spinner/Spinner';
import styles from './PointCloudViewer.module.scss';
import * as THREE from 'three';
import { Tree } from '../../tree/Tree';
import { PointCloudView } from './PointCloudView';
import { LoadablePointCloud } from '../../point-cloud/LoadablePointCloud';
import { useTranslation } from 'react-i18next';
import SafetyFactorMarkerGroup from './SafetyFactorMarkerGroup';
import { LeaningAngleGroup } from './LeaningAngleGroup';
import { MultiOrbitControl } from './MultiOrbitControl';
import { MarkerGroupWithOffset } from './MarkerGroupWithOffset';
import { useCurrentAccount } from '../../account/useAccounts';
import { METER_TO_FEET } from './unitConstants';

export default function PointCloudViewerWithSafetyFactor(props: PointCloudViewerWithSafetyFactorProps) {
  const { t } = useTranslation();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const viewRef = useRef(new PointCloudView());
  const treePointCloud = useRef<THREE.Group>(new THREE.Group());
  const environmentPointCloud = useRef<THREE.Group>(new THREE.Group());
  const account = useCurrentAccount();

  const [absoluteWeakestPointMarker, setAbsoluteWeakestPointMarker] = useState<MarkerGroupWithOffset | null>(null);
  const [safetyFactorCircle, setSafetyFactorCircle] = useState<SafetyFactorMarkerGroup | null>(null);
  const [leaningVector, setLeaningVector] = useState<LeaningAngleGroup | null>(null);

  const [isViewInitialized, setIsViewInitialized] = useState(false);

  const showSafetyFactorFeatures = ['all', 'safetyFactor'].includes(props.type);
  const showLeaningAngle = ['all', 'leaningAngle'].includes(props.type);
  const windDirection = props.tree?.getWindDirectionAngle(props.windSpeed) || 0;

  useEffect(() => {
    if (canvasRef.current === null || props.multiControlsRef.current === null) return;

    const view = viewRef.current;
    view.init(canvasRef.current, props.multiControlsRef.current);

    setIsViewInitialized(true);

    return () => {
      view.dispose();
    };
  }, [canvasRef.current, props.multiControlsRef.current]);

  useEffect(() => {
    if (!props.tree) return;
    const halfHeight = account.organization.getIsMetrical()
      ? props.tree.height / 2
      : props.tree.height / METER_TO_FEET / 2;
    viewRef.current.resetTo(halfHeight, props.tree.canopyDirection);
  }, [isViewInitialized, props.tree, props.seed, account.organization]);

  const [isPointCloudLoading, setIsPointCloudLoading] = useState(true);
  const [tree, setTree] = useState<Tree | null>(null);
  useEffect(() => {
    if (props.tree === null) return;

    setTree(props.tree);

    const environmentPointSize = 1.6;
    const pos = props.tree!.localizedLocation;
    Promise.all([
      new LoadablePointCloud(props.tree!.getPointCloudUrl(account.organization)).loadInto(pos, false).then(pc => treePointCloud.current.add(pc)),
      new LoadablePointCloud(props.tree!.getEnvironmentPointCloudUrl(account.organization), environmentPointSize)
        .loadInto(pos, true)
        .then(pc => environmentPointCloud.current.add(pc))
    ])
      .then(() => {
        if (props.tree!.safetyFactors.length === 0) return;

        const { weakestPoint, safetyFactor: sf } = props.tree!.safetyFactors.find(it => it.windSpeed === account.getDefaultWindSpeed())!;
        setAbsoluteWeakestPointMarker(
          new MarkerGroupWithOffset(
            new THREE.Vector3(0, weakestPoint, 0),
            t('analytics.properties.absoluteWeakestPointLabel', { weakestPoint, safetyFactor: sf.toFixed(2) }),
            new THREE.Vector2(-4, -1.5),
            viewRef.current.render
          )
        );
        setSafetyFactorCircle(
          new SafetyFactorMarkerGroup(2, 0.1, 50, props.tree?.getWindDirectionAngle(account.getDefaultWindSpeed()) || 0, props.tree!.height / 6, viewRef.current.render)
        );
        setLeaningVector(
          new LeaningAngleGroup(
            props.tree?.height || 0,
            new THREE.Vector3(0, 0, 0),
            new THREE.Vector3(...(props.tree?.leaningVector || [0, 0, 0])),
            t('analytics.properties.leaningAngle'),
            viewRef.current.render,
            false
          )
        );
      })
      .then(() => setIsPointCloudLoading(false));
  }, [props.tree, t, account.organization]);

  useEffect(() => {
    if (tree === null || isPointCloudLoading || !isViewInitialized) return;

    viewRef.current.addEventListeners();
    viewRef.current.clear();
    viewRef.current.addPointClouds(
      props.showEnvironment ? [treePointCloud.current, environmentPointCloud.current] : [treePointCloud.current]
    );
    if (showSafetyFactorFeatures) {
      if (absoluteWeakestPointMarker) {
        viewRef.current.addToScene(absoluteWeakestPointMarker);
      }
      if (safetyFactorCircle) {
        viewRef.current.addToScene(safetyFactorCircle);
        safetyFactorCircle.setWindDirection(windDirection);
      }
    }
    if (showLeaningAngle && leaningVector) {
      viewRef.current.addToScene(leaningVector);
    }
    viewRef.current.addGrid();
    viewRef.current.setCanvasSize();

    return () => {
      viewRef.current.removeEventListeners();
    };
  }, [
    isPointCloudLoading,
    isViewInitialized,
    tree,
    props.showEnvironment,
    showLeaningAngle,
    showSafetyFactorFeatures,
    t,
    windDirection
  ]);

  return (
    <div className={styles.container}>
      <canvas ref={canvasRef} className={styles.canvas} />

      {isPointCloudLoading && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
    </div>
  );
}

interface PointCloudViewerWithSafetyFactorProps {
  tree: Tree | null,
  showEnvironment: boolean,
  type: 'all' | 'safetyFactor' | 'leaningAngle',
  windSpeed: number,
  multiControlsRef: MutableRefObject<MultiOrbitControl>,
  seed: string
}
