import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { TaskGroup } from './TaskGroup';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useCurrentAccount } from '../../../account/useAccounts';
import { Features } from '../../../switches/Features';

export function useTaskGroups(): { taskGroups: TaskGroup[], isLoading: boolean } {
  const { taskManagerService } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();
  const location = useLocation();
  const isTasksPage = location.pathname.includes('task-manager/tasks');

  const cacheKey = `${organization.id}-task-groups`;
  const { data, isLoading } = useQuery(
    [cacheKey],
    () => {
      if (!organization.isFeatureEnabled(Features.taskManager)) return [];
      return taskManagerService.listTaskGroups(organization.id);
    },
    {
      retry: 1,
      retryOnMount: false,
      refetchInterval: isTasksPage ? 10000 : false
    }
  );

  return {
    taskGroups: data ?? [],
    isLoading
  };
}
