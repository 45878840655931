import styles from './RiskOverlay.module.scss';
import WindSpeedSlider from './WindSpeedSlider';
import { useTranslation } from 'react-i18next';
import { Tree } from '../../../../tree/Tree';
import { MutableRefObject, ReactNode, useEffect, useMemo, useState } from 'react';
import { useCurrentAccount } from '../../../../account/useAccounts';
import * as THREE from 'three';
import SafetyFactorMarkerGroup from '../twin-view/SafetyFactorMarkerGroup';
import { LeaningAngleGroup } from '../../../../components/PointCloud/LeaningAngleGroup';
import { PointCloudView } from '../../../../components/PointCloud/PointCloudView';
import { CentroidsGroup } from '../../../../components/PointCloud/CentroidsGroup';
import { LabelWithPointingLine } from '../../../../components/PointCloud/LabelWithPointingLine';
import DetailedTree from '../../../../tree/DetailedTree';
import { cardinalDirectionToAngle } from '../../../../utils/getCardinalDirectionFromAngle';
import { Flippers } from '../../../../switches/Flippers';

enum SafetyFactorViewOptions {
  SafetyFactor = 'safetyFactor',
  LeaningAngle = 'leaningAngle',
  // Centroids = 'centroids',
  All = 'all'
}

export default function RiskOverlay(props: RiskOverlayProps) {
  const { tree } = props;
  const { t } = useTranslation();

  const account = useCurrentAccount();
  const prevailingWindDirection = tree?.prevailingWindDirection || account.organization.prevailingWindDirection;

  const [selectedProperty, setSelectedProperty] = useState<SafetyFactorViewOptions | null>(SafetyFactorViewOptions.SafetyFactor);
  const showSafetyFactor = selectedProperty === SafetyFactorViewOptions.All || selectedProperty === SafetyFactorViewOptions.SafetyFactor;
  const showLeaningAngle = selectedProperty === SafetyFactorViewOptions.All || selectedProperty === SafetyFactorViewOptions.LeaningAngle;
  const showCentroids = false; // selectedProperty === SafetyFactorViewOptions.All || selectedProperty === SafetyFactorViewOptions.Centroids;
  const [windSpeed, setWindSpeed] = useState(account.getDefaultWindSpeed());
  const updateSelectedProperty = selectedProperty => {
    setSelectedProperty(selectedProperty);
  };

  const updateWindSpeed = (windSpeed: number) => {
    setWindSpeed(windSpeed);
  };

  const safetyFactorCircle = useMemo(() => {
    const scalingFactor = props.tree!.metricHeight / 6;
    return new SafetyFactorMarkerGroup(2, scalingFactor * 0.025, 50, cardinalDirectionToAngle(prevailingWindDirection), scalingFactor);
  }, [props.tree, prevailingWindDirection]);

  useEffect(() => {
    if (tree === null || !props.isViewInitialized || props.isPointCloudLoading || !props.treePointCloud.current) return;

    props.viewRef.current.addEventListeners();
    props.viewRef.current.clear();
    props.viewRef.current.addPointClouds(
      props.showDetails ? [props.treePointCloud.current, props.environmentPointCloud.current] : [props.treePointCloud.current]
    );

    const windDirectionMarker = new LabelWithPointingLine(
      new THREE.Vector3(safetyFactorCircle?.arrow?.position.x || 0, 0, -(safetyFactorCircle?.arrow?.position.y || 0)),
      `${t('details.properties.prevailingWindDirection')}: ${t('treeDetails.risk.windDirections.' + prevailingWindDirection)}`,
      new THREE.Vector2(props.tree!.metricHeight / 2, -2)
    );
    if (prevailingWindDirection) props.viewRef.current.addToScene(windDirectionMarker);
    props.viewRef.current.addToScene(safetyFactorCircle);

    if (showCentroids) {
      props.viewRef.current.addToScene(new CentroidsGroup(props.tree));
    }
    if (showLeaningAngle) {
      props.viewRef.current.addToScene(
        new LeaningAngleGroup(
          props.tree?.metricHeight || 0,
          new THREE.Vector3(0, 0, 0),
          new THREE.Vector3(...(props.tree?.leaningVector || [0, 0, 0])),
          t('analytics.properties.leaningAngle'),
          props.viewRef.current.render,
          false
        )
      );
    }

    props.viewRef.current.addGrid();
    props.viewRef.current.setCanvasSize();

    return () => {
      props.viewRef.current.clear();
      props.viewRef.current.removeEventListeners();
    };
  }, [
    props.isViewInitialized,
    props.isPointCloudLoading,
    tree,
    props.showDetails,
    t,
    selectedProperty,
    props.treePointCloud.current,
    props.environmentPointCloud.current,
    safetyFactorCircle
  ]);

  return (<>
    <TreePropertyList>
      {Object.values(SafetyFactorViewOptions).map(it => (
        <PropertyListItem
          key={it}
          onClick={() => updateSelectedProperty(it)}
          active={selectedProperty === it}>
          {t('analytics.properties.' + it)}
        </PropertyListItem>
      ))}
    </TreePropertyList>
    {showCentroids &&
      <ul className={styles.centroidLegends}>
        {props.tree.canopyCentroid && (<li><span className={styles.greenCircle} />{t('treeDetails.risk.crownCentroid')}</li>)}
        <li><span className={styles.cyanCircle} />{t('treeDetails.risk.firstBifurcation')}</li>
        <li><span className={styles.pinkCircle} />{t('treeDetails.risk.trunkBase')}</li>
      </ul>
    }
    {showSafetyFactor &&
      <div className={styles.badgeAndSliderContainer}>
        {(account.organization.isEnabled(Flippers.windspeedSlider) && tree.safetyFactors.length > 0) && (
          <WindSpeedSlider
            tree={tree}
            windSpeed={windSpeed}
            updateWindSpeed={updateWindSpeed}
          />
        )}
        <SafetyFactorBadge safetyFactor={tree.getSafetyFactor(windSpeed)} />
      </div>}
  </>);
}

function SafetyFactorBadge({ safetyFactor }: { safetyFactor: number | null }) {
  const { t } = useTranslation();
  if (!safetyFactor) return <></>;

  const classes = [styles.safetyFactorBadge];
  if (safetyFactor < Tree.SAFETY_FACTOR_THRESHOLD) classes.push(styles.safetyFactorBadgeDanger);

  return (
    <div className={classes.join(' ')}>
      <span>{t('parameters.tsi')}</span>
      <span>{safetyFactor.toFixed(2)}</span>
    </div>
  );
}

function TreePropertyList({ children }: { children?: ReactNode }) {
  return <ul className={styles.propertyList}>{children}</ul>;
}

function PropertyListItem(props: PropertyListItemProps | DisabledPropertyListItemProps) {
  const className = styles.propertyListItemButton.concat(
    ' ',
    'active' in props && props.active ? styles.active : '',
    ' ',
    props.disabled ? styles.disabled : ''
  );

  const onClick = () => {
    if ('onClick' in props) {
      props.onClick();
    }
  };

  return (
    <li className={styles.propertyListItem}>
      <button onClick={onClick} className={className}>
        {props.children}
      </button>
    </li>
  );
}

interface PropertyListItemProps {
  children: ReactNode,
  onClick: () => unknown,
  active: boolean,
  disabled?: true
}

interface DisabledPropertyListItemProps {
  children: ReactNode,
  disabled: true
}

interface RiskOverlayProps {
  tree: DetailedTree,
  viewRef: MutableRefObject<PointCloudView>,
  treePointCloud: MutableRefObject<THREE.Group>,
  environmentPointCloud: MutableRefObject<THREE.Group>,
  isViewInitialized: boolean,
  showDetails: boolean,
  isPointCloudLoading: boolean
}
