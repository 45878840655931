import { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import { useTranslation } from 'react-i18next';
import { Filter, NavArrowDown, ViewGrid } from 'iconoir-react';
import WorkOrderTable from './WorkOrderTable';
import React, { useEffect, useState } from 'react';
import { WorkOrder } from '../../../task-manager/WorkOrder';
import { useMatch } from 'react-router-dom';
import { useWorkOrders } from '../../../task-manager/useWorkOrders';
import WorkOrderSlideIn from './WorkOrderSlideIn';

export default function ManageTasks() {
  const { t } = useTranslation();
  const match = useMatch('/organizations/:organizationId/*');
  const organizationId = match?.params?.organizationId || '';
  const { workOrders } = useWorkOrders();

  const [selectedWorkOrder, setSelectedWorkOrder] = useState<WorkOrder | null>(null);

  useEffect(() => {
    if (selectedWorkOrder) setSelectedWorkOrder(workOrders.find(workOrder => workOrder.id === selectedWorkOrder.id) || null);
  }, [workOrders]);

  return (
    <section className="w-full">
      <div className="twp">
        <div className="flex my-4">
          <h2 className="text-2xl font-semibold">{t('taskManager.manageTasks')}</h2>
        </div>
        <div className="flex items-center gap-4">
          <FunctionButton
            variant={'rounded'}
            icon={<ViewGrid />}
            disabled={true}
          >
            <span className="flex items-center">{t('taskManager.mainView')} <NavArrowDown /></span>
          </FunctionButton>
          <FunctionButton
            variant={'rounded'}
            icon={<Filter />}
            disabled={true}
          >
            <span className="flex items-center">{t('taskManager.filters')} <NavArrowDown /></span>
          </FunctionButton>
        </div>
      </div>
      <div className="my-4 text-greehill-00">
        <WorkOrderTable
          workOrders={workOrders}
          setSelectedWorkOrder={setSelectedWorkOrder}
        />
      </div>
      {selectedWorkOrder &&
        <WorkOrderSlideIn
          workOrder={selectedWorkOrder}
          organizationId={organizationId}
          setSlideInOpen={() => setSelectedWorkOrder(null)}
        />}
    </section>
  );
}
