import { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircle, XmarkCircle } from 'iconoir-react';
import FilterRow from './FilterRow';
import FilterConfig, { EnumCondition, Filter, FilterGroup as FilterGroupType, Operator } from '../../../filter/FilterConfig';
import FilterGroup from './FilterGroup';
import { Tree } from '../../../tree/Tree';
import { SpeciesLists } from './SpeciesList';

export default function FilterConfigEditor(props: FilterConfigEditorProps) {
  const { t } = useTranslation();

  const handleAddFilter = () => {
    props.setFilterConfig({
      filters: [
        ...props.filterConfig.filters || [],
        { property: '' as unknown as keyof Tree, condition: '' as unknown as EnumCondition, value: [] }
      ],
      filterGroups: props.filterConfig.filterGroups || [],
      topLevelOperator: props.filterConfig.topLevelOperator || Operator.AND
    });
  };

  const handleDeleteFilter = (filterIndex: number) => {
    props.setFilterConfig({
      filters: props.filterConfig.filters.filter((_, i) => i !== filterIndex) || [],
      filterGroups: props.filterConfig.filterGroups || [],
      topLevelOperator: props.filterConfig.topLevelOperator || Operator.AND
    });
  };

  const handleAddFilterGroup = () => {
    props.setFilterConfig({
      filters: props.filterConfig.filters || [],
      filterGroups: [
        ...props.filterConfig.filterGroups || [],
        { filters: [{ property: '' as unknown as keyof Tree, condition: '' as unknown as EnumCondition, value: [] }], filterGroupOperator: Operator.AND }
      ],
      topLevelOperator: props.filterConfig.topLevelOperator || Operator.AND
    });
  };

  const handleDeleteGroup = (groupIndex: number) => {
    props.setFilterConfig({
      filters: props.filterConfig.filters || [],
      filterGroups: props.filterConfig.filterGroups.filter((_, i) => i !== groupIndex) || [],
      topLevelOperator: props.filterConfig.topLevelOperator || Operator.AND
    });
  };

  const updateFilter = (filterIndex: number, filter: Filter) => {
    const newFilters = props.filterConfig.filters.map((f, index) => index === filterIndex ? filter : f) || [];
    props.setFilterConfig({
      filters: newFilters,
      filterGroups: props.filterConfig.filterGroups || [],
      topLevelOperator: props.filterConfig.topLevelOperator || Operator.AND
    });
  };

  const updateTopLevelOperator = (operator: Operator) => {
    props.setFilterConfig({
      filters: props.filterConfig.filters || [],
      filterGroups: props.filterConfig.filterGroups || [],
      topLevelOperator: operator
    });
  };

  const updateFilterGroup = (groupIndex: number, group: FilterGroupType) => {
    const newGroups = props.filterConfig.filterGroups.map((g, index) => index === groupIndex ? group : g) || [];
    props.setFilterConfig({
      filters: props.filterConfig.filters || [],
      filterGroups: newGroups,
      topLevelOperator: props.filterConfig.topLevelOperator || Operator.AND
    });
  };

  const handleClearAll = () => {
    props.setFilterConfig({
      filters: [],
      filterGroups: [],
      topLevelOperator: Operator.AND
    });
  };

  return (
    <>
      {props.filterConfig.filters.map((filter, index) =>
        <FilterRow
          key={`${filter.property}-${index}`}
          handleDeleteFilter={() => handleDeleteFilter(index)}
          filter={filter}
          filterIndex={index}
          update={updateFilter}
          updateOperator={updateTopLevelOperator}
          speciesLists={props.speciesLists}
          topLevelOperator={props.filterConfig.topLevelOperator}
        />)}
      {props.filterConfig.filterGroups.map(((group, index) => (
        <FilterGroup
          key={`filter-group-${index}`}
          handleDelete={() => handleDeleteGroup(index)}
          group={group}
          groupIndex={index}
          update={updateFilterGroup}
          speciesLists={props.speciesLists}
          topLevelOperator={props.filterConfig.topLevelOperator}
          filterGroupIndex={index}
          updateOperator={updateTopLevelOperator}
          simpleFiltersCount={props.filterConfig.filters.length}
        />
      )))}
      <div className="flex gap-2 mt-6">
        <FunctionButton
          onClick={handleAddFilter}
          className="py-1 px-2"
        >
          <div className="flex items-center gap-2">
            <PlusCircle height={20} width={20}/>
            {t('taskManager.newFilter')}
          </div>
        </FunctionButton>
        <FunctionButton
          onClick={handleAddFilterGroup}
          className="py-1 px-2"
        >
          <div className="flex items-center gap-2">
            <PlusCircle height={20} width={20}/>
            {t('taskManager.newGroup')}
          </div>
        </FunctionButton>
        <FunctionButton
          onClick={handleClearAll}
          className="py-1 px-2"
        >
          <div className="flex items-center gap-2">
            <XmarkCircle height={20} width={20}/>
            {t('taskManager.clearAll')}
          </div>
        </FunctionButton>
      </div>
    </>
  );
}

interface FilterConfigEditorProps {
  filterConfig: FilterConfig,
  speciesLists: SpeciesLists,
  setFilterConfig: (filterConfig: FilterConfig) => void
}
