import { FunctionButton } from '../../../components/UI/Button/LegacyButton';
import { Filter, NavArrowDown, ViewGrid } from 'iconoir-react';
import Spinner from '../../../components/UI/Spinner/Spinner';
import TreeTable from '../create/TreeTable';
import WorkOrderSlideIn from './WorkOrderSlideIn';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortingDirection } from '../../../components/UI/SortButton/SortButton';
import { DisplayableTreeProperty } from '../../../tree/Tree';
import { useTreesByIds } from './useTreesByIds';
import { useMatch } from 'react-router-dom';
import { useWorkOrders } from '../../../task-manager/useWorkOrders';
import jobColumnColorMap from '../jobColumnColorMap';
import { JobColumnName } from '../create/JobColumnName';

export default function ManageTaskTrees() {
  const { t } = useTranslation();
  const match = useMatch('/organizations/:organizationId/task-manager/manage/:workOrderId');
  const organizationId = match?.params?.organizationId || '';
  const { workOrders } = useWorkOrders();
  const workOrder = workOrders.find(workOrder => workOrder.id === match?.params?.workOrderId) || null;

  const [sortProperty, setSortProperty] = useState<string | null>(null);
  const [sortingDirection, setSortingDirection] = useState<SortingDirection | null>(null);
  const [selectedFields, setSelectedFields] = useState<DisplayableTreeProperty[]>([
    DisplayableTreeProperty.Height, DisplayableTreeProperty.TrunkHeight, DisplayableTreeProperty.TrunkDiameter, DisplayableTreeProperty.CanopyCircumference, DisplayableTreeProperty.ExternalId
  ]);
  const [isSlideInOpen, setSlideInOpen] = useState(false);
  const { trees, isLoading } = useTreesByIds(organizationId, workOrder?.treeIds ?? [], selectedFields, sortProperty, sortingDirection);
  const [selectedTreeIds, setSelectedTreeIds] = useState<string[]>(trees.map(tree => tree.id) ?? []);

  const handleSort = (property: string) => {
    if (sortProperty !== property) {
      setSortProperty(property);
      setSortingDirection(SortingDirection.ASCENDING);
    } else {
      if (sortingDirection === SortingDirection.ASCENDING) {
        setSortingDirection(SortingDirection.DESCENDING);
      } else {
        setSortProperty(null);
        setSortingDirection(null);
      }
    }
  };

  const handleTreeSelection = (event: ChangeEvent<HTMLInputElement>, treeId: string) => {
    if (event.target.checked) {
      setSelectedTreeIds([...selectedTreeIds, treeId]);
    } else {
      setSelectedTreeIds(selectedTreeIds.filter(id => id !== treeId));
    }
  };

  if (!workOrder) {
    return <Spinner />;
  }

  return (
    <section className="w-full">
      <div className="twp">
        <div className="flex gap-4 my-6">
          <h2
            className="text-2xl font-semibold cursor-pointer select-none"
            onClick={() => setSlideInOpen(prev => !prev)}
          >
            {workOrder.name || workOrder.code}
          </h2>
          <span
            className={`bg-${jobColumnColorMap[JobColumnName.Inspection].primary} px-2 flex items-center text-greehill-00 rounded-md`}>{workOrder.jobColumnName}</span>
        </div>
        <div className="flex items-center gap-4">
          <FunctionButton
            variant={'rounded'}
            icon={<ViewGrid />}
            disabled={true}
          >
            <span className="flex items-center">{t('taskManager.mainView')} <NavArrowDown /></span>
          </FunctionButton>
          <FunctionButton
            variant={'rounded'}
            icon={<Filter />}
            disabled={true}
          >
            <span className="flex items-center">{t('taskManager.filters')} <NavArrowDown /></span>
          </FunctionButton>
        </div>
      </div>
      <div className="my-4 text-greehill-00">
        {isLoading
          ? <Spinner />
          : <TreeTable
            trees={trees}
            selectedTreeIds={selectedTreeIds}
            setSelectedTreeIds={setSelectedTreeIds}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            handleTreeSelection={handleTreeSelection}
            sortProperty={sortProperty}
            sortingDirection={sortingDirection}
            handleSort={handleSort}
            taskOrTaskTemplateId={workOrder.id}
          />}
      </div>
      {isSlideInOpen && <WorkOrderSlideIn
        workOrder={workOrder}
        organizationId={organizationId}
        setSlideInOpen={setSlideInOpen}
      />}
    </section>
  );
}
