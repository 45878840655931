import { useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../../account/useAccounts';
import { useContext } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useNavigate } from 'react-router-dom';
import { WorkOrder, LegacyWorkOrderStatus } from '../../../task-manager/WorkOrder';
import DropdownButtonGroup, { DropdownButton } from '../../../components/UI/DrowdownButton/DropdownButtonGroup';
import Button from '../../../components/UI/Button/Button';
import { ConnectedModule } from '../ConnectedModule';
import { ContextFields } from '../../Explore/UrlContext';
import { AuthContext } from '../../../auth/AuthContext';

export default function ActionButtons(props: ActionButtonsProps) {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const { taskManagerService } = useContext(DependencyInjectionContext);
  const user = useContext(AuthContext).user;

  const navigate = useNavigate();

  const changeWorkOrderStatusTo = async (status: LegacyWorkOrderStatus) => {
    await taskManagerService.updateWorkOrder(props.workOrder.organizationId, props.workOrder.id, { status });
  };

  const startTask = async () => {
    await changeWorkOrderStatusTo(LegacyWorkOrderStatus.STARTED);
    if (props.workOrder.connectedModule === ConnectedModule.remoteInspection)
      navigate(`/organizations/${account.organization.id}/remote-inspections/${props.workOrder.id}?${ContextFields.TreeId}=${props.workOrder.getStartingTreeId()}`);
  };

  const employeeActions = {
    [LegacyWorkOrderStatus.ASSIGNED]: <PendingAcceptanceButtonGroup changeWorkOrderStatusTo={changeWorkOrderStatusTo}/>,
    [LegacyWorkOrderStatus.ACCEPTED]: <Button onClick={startTask}>{t('taskManager.taskTable.actionButtons.start')}</Button>,
    [LegacyWorkOrderStatus.STARTED]: <StartedTaskButtonGroupForEmployee workOrder={props.workOrder} changeTaskStatusTo={changeWorkOrderStatusTo}/>,
    [LegacyWorkOrderStatus.PAUSED]: <Button onClick={() => changeWorkOrderStatusTo(LegacyWorkOrderStatus.STARTED)}>{t('taskManager.taskTable.actionButtons.resume')}</Button>
  };

  const managerActions = {
    [LegacyWorkOrderStatus.DRAFT]: <AssignButton workOrder={props.workOrder} changeStatusToAssigned={() => changeWorkOrderStatusTo(LegacyWorkOrderStatus.ASSIGNED)} />,
    [LegacyWorkOrderStatus.ASSIGNED]: <Button onClick={() => changeWorkOrderStatusTo(LegacyWorkOrderStatus.REVOKED)}>{t('taskManager.taskTable.actionButtons.revoke')}</Button>,
    [LegacyWorkOrderStatus.REJECTED]: <AssignButton workOrder={props.workOrder} changeStatusToAssigned={() => changeWorkOrderStatusTo(LegacyWorkOrderStatus.ASSIGNED)} />,
    [LegacyWorkOrderStatus.UNASSIGNED]: <AssignButton workOrder={props.workOrder} changeStatusToAssigned={() => changeWorkOrderStatusTo(LegacyWorkOrderStatus.ASSIGNED)} />,
    [LegacyWorkOrderStatus.ACCEPTED]: <Button onClick={() => changeWorkOrderStatusTo(LegacyWorkOrderStatus.REVOKED)}>{t('taskManager.taskTable.actionButtons.revoke')}</Button>,
    [LegacyWorkOrderStatus.STARTED]: <Button onClick={() => changeWorkOrderStatusTo(LegacyWorkOrderStatus.PAUSED)}>{t('taskManager.taskTable.actionButtons.pause')}</Button>,
    [LegacyWorkOrderStatus.PAUSED]: <PausedButtonGroupForManager workOrder={props.workOrder} changeTaskStatusTo={changeWorkOrderStatusTo}/>,
    [LegacyWorkOrderStatus.COMPLETED]: <Button onClick={() => changeWorkOrderStatusTo(LegacyWorkOrderStatus.APPROVED)}>{t('taskManager.taskTable.actionButtons.approve')}</Button>,
    [LegacyWorkOrderStatus.APPROVED]: <Button onClick={() => changeWorkOrderStatusTo(LegacyWorkOrderStatus.ARCHIVED)}>{t('taskManager.taskTable.actionButtons.archive')}</Button>,
    [LegacyWorkOrderStatus.REVOKED]: <AssignButton workOrder={props.workOrder} changeStatusToAssigned={() => changeWorkOrderStatusTo(LegacyWorkOrderStatus.ASSIGNED)} />
  };

  const selfAssignedActions = {
    [LegacyWorkOrderStatus.DRAFT]: <AssignButton workOrder={props.workOrder} changeStatusToAssigned={() => changeWorkOrderStatusTo(LegacyWorkOrderStatus.ASSIGNED)} />,
    [LegacyWorkOrderStatus.REJECTED]: <AssignButton workOrder={props.workOrder} changeStatusToAssigned={() => changeWorkOrderStatusTo(LegacyWorkOrderStatus.ASSIGNED)} />,
    [LegacyWorkOrderStatus.UNASSIGNED]: <AssignButton workOrder={props.workOrder} changeStatusToAssigned={() => changeWorkOrderStatusTo(LegacyWorkOrderStatus.ASSIGNED)} />,
    [LegacyWorkOrderStatus.ASSIGNED]: <PendingAcceptanceButtonGroup changeWorkOrderStatusTo={changeWorkOrderStatusTo}/>,
    [LegacyWorkOrderStatus.ACCEPTED]: <Button onClick={startTask}>{t('taskManager.taskTable.actionButtons.start')}</Button>,
    [LegacyWorkOrderStatus.STARTED]: <StartedTaskButtonGroupForEmployee workOrder={props.workOrder} changeTaskStatusTo={changeWorkOrderStatusTo}/>,
    [LegacyWorkOrderStatus.PAUSED]: <PausedButtonGroupForManager workOrder={props.workOrder} changeTaskStatusTo={changeWorkOrderStatusTo}/>,
    [LegacyWorkOrderStatus.COMPLETED]: <Button onClick={() => changeWorkOrderStatusTo(LegacyWorkOrderStatus.APPROVED)}>{t('taskManager.taskTable.actionButtons.approve')}</Button>,
    [LegacyWorkOrderStatus.APPROVED]: <Button onClick={() => changeWorkOrderStatusTo(LegacyWorkOrderStatus.ARCHIVED)}>{t('taskManager.taskTable.actionButtons.archive')}</Button>
  };

  const getActions = () => {
    if (props.workOrder.assignee?.id === props.workOrder.createdBy?.id) return selfAssignedActions;
    if (props.workOrder.createdBy?.id === user.id) return managerActions;
    return employeeActions;
  };

  return (
    <div>
      {getActions()[props.workOrder.status]}
    </div>
  );
}

interface ActionButtonsProps {
  workOrder: WorkOrder
}

const AssignButton = (props: { workOrder: WorkOrder, changeStatusToAssigned: () => void }) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={props.changeStatusToAssigned}
      disabled={!props.workOrder.assignee?.id || !props.workOrder.deadline}
    >
      {t('taskManager.taskTable.actionButtons.assign')}
    </Button>
  );
};

const PendingAcceptanceButtonGroup = (props: { changeWorkOrderStatusTo: (status: LegacyWorkOrderStatus) => void }) => {
  const { t } = useTranslation();

  return (
    <DropdownButtonGroup
      label={t('taskManager.taskTable.actionButtons.accept')}
      onClick={() => props.changeWorkOrderStatusTo(LegacyWorkOrderStatus.ACCEPTED)}
    >
      <DropdownButton
        onClick={() => props.changeWorkOrderStatusTo(LegacyWorkOrderStatus.REJECTED)}
        label={t('taskManager.taskTable.actionButtons.reject')}
      />
    </DropdownButtonGroup>
  );
};

const PausedButtonGroupForManager = (props: { workOrder: WorkOrder, changeTaskStatusTo: (status: LegacyWorkOrderStatus) => void }) => {
  const { taskManagerService } = useContext(DependencyInjectionContext);
  const { t } = useTranslation();

  const unassignTask = async () => {
    await taskManagerService.updateWorkOrder(
      props.workOrder.organizationId,
      props.workOrder.id,
      { status: LegacyWorkOrderStatus.UNASSIGNED, assigneeUserId: null }
    );
  };

  return (
    <DropdownButtonGroup
      label={t('taskManager.taskTable.actionButtons.resume')}
      onClick={() => props.changeTaskStatusTo(LegacyWorkOrderStatus.STARTED)}
    >
      <DropdownButton
        onClick={unassignTask}
        label={t('taskManager.taskTable.actionButtons.unassign')}
      />
    </DropdownButtonGroup>
  );
};

const StartedTaskButtonGroupForEmployee = (props: { workOrder: WorkOrder, changeTaskStatusTo: (status: LegacyWorkOrderStatus) => void }) => {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const navigate = useNavigate();

  const canBeCompleted = props.workOrder.progress === props.workOrder.treeIds.length || props.workOrder.connectedModule === null;

  const completeTask = () => {
    props.changeTaskStatusTo(LegacyWorkOrderStatus.COMPLETED);
  };

  const continueTask = () => {
    if (props.workOrder.connectedModule === ConnectedModule.remoteInspection)
      navigate(`/organizations/${account.organization.id}/remote-inspections/${props.workOrder.id}?${ContextFields.TreeId}=${props.workOrder.nextTodoTreeId}`);
  };

  return (
    <DropdownButtonGroup
      onClick={canBeCompleted ? completeTask : continueTask}
      label={t(`taskManager.taskTable.actionButtons.${canBeCompleted ? 'complete' : 'continue'}`)}
    >
      <DropdownButton
        onClick={() => props.changeTaskStatusTo(LegacyWorkOrderStatus.PAUSED)}
        label={t('taskManager.taskTable.actionButtons.pause')}
      />
    </DropdownButtonGroup>
  );
};
