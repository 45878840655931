import React, { useContext, useEffect, useMemo, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import LazyTreeList from '../LazyTreeList';
import { useCurrentAccount } from '../../../account/useAccounts';
import ManagedAreaSelector from './ManagedAreaSelector';
import { Button, IconButton, OverflowMenu, OverflowMenuItem } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import ColumnSelector from './ColumnSelector';
import { downloadBlob } from '../../Explore/table-view/components/FileDownloadButton';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import proj4 from 'proj4';
import { Property } from '../../Explore/workspace/TreeTable';
import { ArrowsHorizontal, CloseLarge, Download, Filter, ReportData } from '@carbon/icons-react';
import Spinner from '../../../components/UI/Spinner/Spinner';
import PageLayout from '../../../components/UI/Page/carbon/PageLayout';
import { SortableItem, SortableList } from './SortableList';
import useRightPanelContent, { RightPanelContent } from '../../../components/UI/Page/carbon/useRightPanelContent';

type ExtendedProperty = Property | 'x' | 'y';

export default function TableDashboard(props: TableDashboardProps) {
  const { t } = useTranslation();
  const { urlContext, treeService } = useContext(DependencyInjectionContext);
  const account = useCurrentAccount();
  const { organization } = account;
  const missingAreaSelection = useMemo(() => (!urlContext.getReverseMASelection() && urlContext.getManagedAreaIds().length === 0),
    [JSON.stringify(urlContext.getManagedAreaIds()), JSON.stringify(urlContext.getReverseMASelection())]);
  const [items, setItems] = useState<SortableItem[]>([]);
  const rightPanelContent = useRightPanelContent();

  useEffect(() => {
    setItems((urlContext.getVisibleTableProperties() ?? []).map(item => ({ id: item, content: t(`tree.${item}`) })));
  }, [urlContext.getVisibleTableProperties()?.join(',')]);

  const generateReport = () => {
    urlContext.setReportOpen(true);
  };
  const [loadingCsv, setLoadingCsv] = useState(false);

  const handleShpExport = async e => {
    e.stopPropagation();
    const response = await treeService.getShpExport(organization.id, urlContext.getReverseMASelection(), urlContext.getManagedAreaIds(), urlContext.getTreeFilterIds(), urlContext.getVisibleTableProperties() || [], urlContext.getFilterConfiguration());
    const contentType = response.headers['content-type'];
    const fileName = response.headers['content-disposition'].match(/filename="([^"]*)"/)?.[1] || 'trees';

    downloadBlob(response.data, contentType, fileName);
  };

  const handleDownload = e => {
    setLoadingCsv(true);
    e.stopPropagation();

    props.treeList?.getAll().then(trees => {
      const header = [...props.properties];
      header.splice(3, 0, 'x', 'y');
      const alternativeExportEPSGConvertParams = account.organization?.metaData?.alternativeExportEPSGConvertParams;
      const csv = [
        header.map(it => {
          let title = Tree.renderPropertyName(it, t, organization);
          if (it === 'safetyFactors') {
            title = title.concat(t('treeList.windSpeedValue', {
              value: props.windSpeed,
              unit: Tree.getWindSpeedUnit(account.organization),
              interpolation: { escapeValue: false }
            }));
          }
          if (it === 'x' || it === 'y') title = it;
          return title;
        }),
        ...trees.map(tree => header.map(it => {
          if (tree.localizedLocation && alternativeExportEPSGConvertParams?.from && alternativeExportEPSGConvertParams?.to){
            const x = tree.localizedLocation ? tree.localizedLocation.coordinates[0] : -1;
            const y = tree.localizedLocation ? tree.localizedLocation.coordinates[1] : -1;
            const newCoordinates = proj4(alternativeExportEPSGConvertParams?.from, alternativeExportEPSGConvertParams?.to, [x, y]);
            if (it === 'x' as Property) {
              return tree.localizedLocation ? newCoordinates[0] : -1;
            }
            if (it === 'y' as Property) {
              return tree.localizedLocation ? newCoordinates[1] : -1;
            }
          }
          if (it === 'safetyFactors') {
            return tree.getSafetyFactor(props.windSpeed)?.toFixed(3);
          }
          if (it === 'managedAreaId') {
            return tree.managedAreaName;
          }
          if (it === 'x' as Property) {
            return tree.localizedLocation ? tree.localizedLocation.coordinates[0] : -1;
          }
          if (it === 'y' as Property) {
            return tree.localizedLocation ? tree.localizedLocation.coordinates[1] : -1;
          }
          if (tree[it] instanceof Array) {
            return tree[it].map(valueItem => t(Tree.getTKeyForProperty(it, valueItem.name))).join(', ');
          }
          if (typeof tree[it] === 'number') return tree[it].toFixed(3);
          return Tree.renderPropertyValue(it, tree[it], t);
        }))
      ]
        .map(row => row.map(it => JSON.stringify(it || '')).join(','))
        .join('\r\n');

      const filename = trees.length === 1
        ? `${account.organization.name}_${trees[0].externalId}_export.csv`
        : `${account.organization.name}_export.csv`;

      downloadBlob(csv, 'data:text/csv;charset=utf-8', filename);
      setLoadingCsv(false);
    });
  };

  return (
    <div className={`flex justify-between m-8 mt-2 gap-2 ${urlContext.isFilterPanelOpen() ? 'flex-col' : ''}`} data-testid="table-dashboard">
      {loadingCsv && <Spinner withOverlay/>}
      <div className='flex gap-0.5'>
        <ManagedAreaSelector />
        <ColumnSelector disabled={missingAreaSelection} />
        <IconButton
          label={t('tooltips.filter')}
          kind="ghost"
          style={urlContext.isFilterPanelOpen() ? { backgroundColor: 'var(--cds-button-secondary)' } : {}}
          onClick={() => {
            urlContext.setFilterPanelOpen(!urlContext.isFilterPanelOpen());
          }}
          disabled={missingAreaSelection}
          className="border-0 border-r border-[var(--cds-border-strong)]"
        >
          <Filter style={urlContext.isFilterPanelOpen() ? { fill: 'white' } : {}} />
        </IconButton>
        <IconButton
          label={'Order columns'}
          kind="ghost"
          onClick={() => urlContext.setIsColumnSorterOpen(!urlContext.isColumnSorterOpen())}
          disabled={missingAreaSelection}
        >
          <ArrowsHorizontal />
        </IconButton>
        <OverflowMenu
          className="p-6"
          menuOptionsClass="w-fit"
          renderIcon={() => <Download />}
          disabled={missingAreaSelection}
          iconDescription={t('treeList.download')}
        >
          <OverflowMenuItem
            itemText={t('treeList.download')}
            onClick={handleDownload}
          />
          <OverflowMenuItem
            itemText={t('treeList.viewDownloadDropdown.downloadAsShp')}
            onClick={handleShpExport}
          />
        </OverflowMenu>
        <IconButton
          label={t('reporting.generateReport')}
          kind="ghost"
          onClick={generateReport}
          disabled={missingAreaSelection}
        >
          <ReportData />
        </IconButton>
      </div>
      {rightPanelContent === RightPanelContent.COLUMN_ORDERING_PANEL &&
        <PageLayout.RightPanel>
          <PageLayout.RightPanelContent>
            <div className="pl-4 pt-2 pr-2">
              <div className="flex items-center justify-between">
                <h3 className="m-0 text-xl text-[var(--cds-text-primary)] font-normal">Ordering Columns</h3>
                <IconButton onClick={() => urlContext.setIsColumnSorterOpen(false)} label={t('filter.close')} align="left" kind="ghost"><CloseLarge/></IconButton>
              </div>
              <p className="text-xs text-[var(--cds-text-secondary)]">Drag and drop  items to change the order of columns</p>
              <SortableList items={items} setItems={setItems}/>
            </div>
          </PageLayout.RightPanelContent>
          <PageLayout.RightPanelActions>
            <Button size="xl" className="w-1/2" kind="secondary" onClick={() => urlContext.setIsColumnSorterOpen(false)}>{t('cancel')}</Button>
            <Button
              size="xl"
              className="w-1/2"
              onClick={() => {
                urlContext.setVisibleTableProperties(items.map(item => item.id as DisplayableTreeProperty));
                urlContext.setIsColumnSorterOpen(false);
              }}>{t('apply')}</Button>
          </PageLayout.RightPanelActions>
        </PageLayout.RightPanel>
      }
    </div>
  );
}

interface TableDashboardProps {
  treeList: LazyTreeList | null,
  windSpeed: number,
  properties: ExtendedProperty[]
}
