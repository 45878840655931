import { AxiosInstance } from 'axios';
import { Job, JobDto } from './Job';

export default class JobService {
  constructor(private readonly http: AxiosInstance) {}

  async listJobsForOrganization(organizationId: string): Promise<JobDto[]> {
    const twentyFourHoursAgo = new Date();
    twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);
    const response = await this.http.get(`/v1/organizations/${organizationId}/jobs?since=${twentyFourHoursAgo}`);
    return response.data.map(jobDto => Job.fromDto(jobDto));
  }

  async listJobs(since: Date): Promise<JobDto[]> {
    const response = await this.http.get(`/v1/jobs?since=${since}`);
    return response.data.map(jobDto => Job.fromDto(jobDto));
  }

  async sendJobsToQueue(organizationId: string, payload: {
    managedAreas: string[],
    loadBestImages: boolean,
    loadAllScanIntervals: boolean
  }) {
    await this.http.post(`/v1/organizations/${organizationId}/jobs`, payload);
  }
}
