import { LegacyWorkOrderStatus, WorkOrderStatus } from '../../../task-manager/WorkOrder';
import { useTranslation } from 'react-i18next';
import { Tag } from '@carbon/react';

export default function WorkOrderStatusPill({ status }: { status: WorkOrderStatus }) {
  const { t } = useTranslation();

  const getTaskStatusPillColors = () => {
    switch (status) {
    case WorkOrderStatus.IN_PROGRESS:
      return 'bg-[#684e00] text-[#fddc69]';
    case WorkOrderStatus.COMPLETED:
      return 'bg-[#0e6025] text-[#a7f0b9]';
    default:
      return '';
    }
  };

  return <Tag className={getTaskStatusPillColors()}>
    {t(`taskManager.workOrderStatus.${status}`)}
  </Tag>;
}
