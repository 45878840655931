import { createContext, ReactNode, useState } from 'react';
import CarbonToastNotification from './CarbonToastNotification';
import { useTranslation } from 'react-i18next';

interface CarbonToast {
  kind: 'success' | 'error' | 'info',
  title: string,
  subtitle?: string
}

interface CarbonToastContextType {
  toast: CarbonToast | null,
  setToast: (toast: CarbonToast | null) => void,
  showToast: (params: {kind: 'success' | 'error' | 'info', title?: string, subtitle?: string}) => void
}

export const CarbonToastContext = createContext<CarbonToastContextType>({
  toast: null,
  setToast: (toast: CarbonToast | null) => {},
  showToast: (params: {kind: 'success' | 'error' | 'info', title?: string, subtitle?: string}) => {}
});

export default function CarbonToastWrapper(props: { children: ReactNode }) {
  const { t } = useTranslation();
  const [toast, setToast] = useState<{
    kind: 'success' | 'error' | 'info',
    title: string,
    subtitle?: string
  } | null>(null);

  function showToast(params: {kind: 'success' | 'error' | 'info', title?: string, subtitle?: string}) {
    setToast({ kind: params.kind, title: params.title || t('notifications.' + params.kind), subtitle: params.subtitle });
  }

  return (
    <CarbonToastContext.Provider value={{ toast, setToast, showToast }}>
      {props.children}
      <CarbonToastNotification />
    </CarbonToastContext.Provider>
  );
}
