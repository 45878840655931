import { ViStatus } from './DetailedTree';
import { Fork, TaskOfTree, TreeDto } from './Tree';
import { ManagedArea } from '../managed-area/ManagedArea';
import { Cohort } from '../routes/LegacyDetails/LegacyDetails';
import { CrossSectionalShape } from '../property-enums/CrossSectionalShape';

export interface GeoJSONTree {
  geometry: {
    coordinates: [number, number, number]
  },
  properties: {
    id: string,
    managedAreaId: string,
    height: number,
    trunkHeight: number,
    trunkWidth: number,
    trunkDiameter: number,
    trunkEllipseRadiusA: number,
    trunkEllipseRadiusB: number,
    trunkCircumference: number,
    canopyHeight: number,
    canopyWidth: number,
    canopyEllipseRadiusA: number,
    canopyEllipseRadiusB: number,
    canopyCircumference: number,
    leafArea: number,
    leafBiomass: number,
    leafAreaIndex: number,
    carbonStorage: number,
    grossCarbonSequestration: number,
    no2: number,
    so2: number,
    pm25: number,
    co: number,
    o3: number,
    ndvi: number,
    treeHealth: number,
    potentialEvapotranspiration: number,
    transpiration: number,
    oxygenProduction: number,
    safetyFactorAt80Kmh: number | undefined,
    safetyFactorAtDefaultWindSpeed: number | undefined,
    safetyFactors: string,
    externalId: string,
    customerTreeId: string,
    customerTagId: string,
    customerSiteId: string,
    genus: string | undefined,
    species: string | undefined,
    scientificName: string | undefined,
    evaporation: number,
    waterIntercepted: number,
    avoidedRunoff: number,
    thermalComfort: number,
    treeValueCavat: number,
    treeValueKoch: number,
    treeValueRado: number,
    leaningVector: string,
    leaningAngle: number,
    dieback: number,
    deadBranchesRatio: number,
    cohortValues: string | undefined,
    status: string,
    vitalityVigor: string,
    viStatus: ViStatus | undefined,
    mitigations: string,
    tasksOfTree: string,
    cultivarOrVariety: string,
    cultivar: string,
    infraspecies: string,
    tmsCategory: string,
    ageClass: string,
    ageAtPlanting: number,
    overallOutlierIndex: number,
    wireClearanceIssueDetected: boolean,
    roadClearanceIssueDetected: boolean,
    buildingClearanceIssueDetected: boolean,
    visibilityClearanceIssueDetected: boolean,
    trafficSignClearanceIssueDetected: boolean,
    crownVolume: number,
    treeProtectionZone: number,
    criticalRootZone: number,
    structuralCriticalRootZone: number,
    outlierHeightPerCrownVolume: boolean,
    outlierHeightPerLeafArea: boolean,
    outlierLeafAreaPerCrownVolume: boolean,
    outlierTrunkDiameterPerCrownVolume: boolean,
    outlierTrunkDiameterPerHeight: boolean,
    outlierTrunkDiameterPerLeafArea: boolean,
    streetAddress: string,
    lastUpdatedAt: string,
    plantingYear: number,
    crownLightExposure: number,
    assignedTasks: string,
    workOrders: string
  }
}

export function geojsonTreeToTreeDto(data: GeoJSONTree, managedAreaCodeMap: { [key: string]: string }, coords?: [number, number, number]): TreeDto {
  return {
    ...data.properties,
    location: { coordinates: coords || data.geometry.coordinates },
    localizedLocation: { coordinates: [NaN, NaN, NaN] },
    managedArea: {
      id: data.properties.managedAreaId,
      code: managedAreaCodeMap[data.properties.managedAreaId],
      name: '',
      boundingBox: ManagedArea.EMPTY_BOUNDING_BOX
    },

    safetyFactorAt80Kmh: data.properties.safetyFactorAt80Kmh ?? NaN,
    safetyFactorAtDefaultWindSpeed: data.properties.safetyFactorAtDefaultWindSpeed ?? NaN,
    safetyFactors: JSON.parse(data.properties.safetyFactors || '{}'),
    genus: data.properties.genus || '-',
    species: data.properties.species || '-',
    scientificName: data.properties.scientificName || '-',
    leaningVector: { coordinates: [NaN, NaN, NaN] },
    viStatus: data.properties.viStatus!,
    mitigations: JSON.parse(data.properties.mitigations || '{}'),
    tasksOfTree: getTaskOfTree(data.properties),
    wireClearanceIssueDetected: data.properties.wireClearanceIssueDetected || false,
    roadClearanceIssueDetected: data.properties.roadClearanceIssueDetected || false,
    buildingClearanceIssueDetected: data.properties.buildingClearanceIssueDetected || false,
    visibilityClearanceIssueDetected: data.properties.visibilityClearanceIssueDetected || false,
    trafficSignClearanceIssueDetected: data.properties.trafficSignClearanceIssueDetected || false,
    cohort: { cohortValues: JSON.parse(data.properties.cohortValues || '{}') } as Cohort,
    plantingYear: data.properties.plantingYear,
    crownLightExposure: data.properties.crownLightExposure,

    // excluded data
    canopyDirection: NaN,
    canopyOffset: { coordinates: [NaN, NaN] },
    carbonStorageEcoValue: NaN,
    grossCarbonSequestrationEcoValue: NaN,
    no2EcoValue: NaN,
    so2EcoValue: NaN,
    pm25EcoValue: NaN,
    coEcoValue: NaN,
    o3EcoValue: NaN,
    avoidedRunoffEcoValue: NaN,
    images: [],
    pointCloudPath: '',
    environmentPointCloudPath: '',
    capturePointId: '',
    leafAreaPerCrownVolume: NaN,
    liveCrownRatio: NaN,
    slenderness: NaN,
    hasViObservation: false,
    hasMitigation: false,
    hasAssessmentRequest: false,
    includedBark: false,
    vat19: NaN,
    alnarpModel: NaN,
    normaGranada: NaN,
    limbs: [],
    coDominantLimbs: false,
    fork: Fork.USHAPE,
    crossSectionalShape: CrossSectionalShape.CIRCULAR,
    foliageNoneSeasonal: false,
    foliageNoneDead: false,
    normalFoliage: NaN,
    chloroticFoliage: NaN,
    necroticFoliage: NaN,
    userUpdatedProperties: [],
    aggByClassWireClearances: []
  };
}

function getTaskOfTree(properties: GeoJSONTree['properties']): TaskOfTree[] {
  const tasksOfTree = JSON.parse(properties.tasksOfTree || '[]');
  const workOrders = JSON.parse(properties.workOrders || '[]').filter(it => !!it);
  const assignedTasks = JSON.parse(properties.assignedTasks || '[]').filter(it => !!it);
  return tasksOfTree.map((taskOfTree: any) => ({ ...taskOfTree, workOrder: workOrders.find(it => it.id === taskOfTree.work_order_id), task: assignedTasks.find(it => it.id === taskOfTree.task_id) }));
}
