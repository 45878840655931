export interface TaskDto {
  id: string,
  organizationId: string,
  name: string,
  createdAt: string,
  updatedAt: string,
  protected: boolean,
  treeCount?: number
}

export interface TaskGroupDto {
  id: string,
  name: string,
  organizationId: string,
  tasks: TaskDto[],
  createdAt: string,
  updatedAt: string,
  protected: boolean
}

export class Task {
  static fromDto(dto: TaskDto): Task {
    return new Task(
      dto.id,
      dto.organizationId,
      dto.name,
      dto.createdAt,
      dto.updatedAt,
      dto.protected,
      dto.treeCount
    );
  }

  constructor(
    readonly id: string,
    readonly organizationId: string,
    readonly name: string,
    readonly createdAt: string | null,
    readonly updatedAt: string | null,
    readonly isProtected: boolean,
    readonly treeCount?: number
  ) {
  }
}

export class TaskGroup {
  static fromDto(dto: TaskGroupDto): TaskGroup {
    return new TaskGroup(
      dto.id,
      dto.organizationId,
      dto.name,
      dto.tasks.map(Task.fromDto),
      dto.createdAt,
      dto.updatedAt,
      dto.protected
    );
  }

  constructor(
    readonly id: string,
    readonly organizationId: string,
    readonly name: string,
    readonly tasks: Task[],
    readonly createdAt: string,
    readonly updatedAt: string,
    readonly isProtected: boolean
  ) {
  }
}
