export enum Flippers {
  dashboardRedesign = 'dashboardRedesign',
  compareToCohort = 'compareToCohort',
  newPlatformProperties = 'newPlatformProperties',
  workspace = 'workspace',
  windspeedSlider = 'windspeedSlider',
  carbonRedesign = 'carbonRedesign',
  snowflakeChart = 'snowflakeChart',
  taskManagerRework = 'taskManagerRework',
  sprint77 = 'sprint77',
  directBlobAccess = 'directBlobAccess'
}
