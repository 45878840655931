import { AxiosInstance } from 'axios';
import { WorkOrder, WorkOrderCreateDto, WorkOrderDto, WorkOrderUpdateDto } from './WorkOrder';
import { User } from '../users/User';
import TaskTemplate, { TaskTemplateWithTreesDto } from '../routes/TaskManager/create/TaskTemplate';
import { TaskTemplateDto } from '../routes/TaskManager/create/CreateWorkOrders';
import { QueryClient } from 'react-query';
import { TreeDto } from '../tree/Tree';
import PartialTree from '../tree/PartialTree';
import { SortingDirection } from '../components/UI/SortButton/SortButton';
import { JobColumnName } from '../routes/TaskManager/create/JobColumnName';
import { TaskGroup, TaskGroupDto } from '../routes/CarbonTaskManager/tasks/TaskGroup';
import { AssignTaskDto } from '../routes/CarbonTaskManager/manual-assignment/TaskSelectorPanel';

type Inventory = {
  unassigned: number,
  assigned: number,
  inWorkOrder: number,
  completed: number
};

export class TaskManagerService {
  constructor(
    private readonly http: AxiosInstance,
    private readonly queryClient: QueryClient
  ) {}

  async getInventory(organizationId: string): Promise<Inventory> {
    const response = await this.http.get<Inventory>(`/v1/organizations/${organizationId}/task-manager/inventory`);
    return response.data;
  }

  async listTaskGroups(organizationId: string): Promise<TaskGroup[]> {
    const response = await this.http.get<TaskGroupDto[]>(`/v1/organizations/${organizationId}/task-manager/task-groups`);
    return response.data.map(TaskGroup.fromDto);
  }

  async addTaskGroup(organizationId: string, taskGroup: { name: string }): Promise<void> {
    await this.http.post(`/v1/organizations/${organizationId}/task-manager/task-groups`, taskGroup);
    await this.queryClient.invalidateQueries(`${organizationId}-task-groups`);
  }

  async deleteTaskGroup(organizationId: string, taskGroupId: string): Promise<void> {
    await this.http.delete(`/v1/organizations/${organizationId}/task-manager/task-groups/${taskGroupId}`);
    await this.queryClient.invalidateQueries(`${organizationId}-task-groups`);
  }

  async updateTaskGroup(organizationId: string, taskGroupId: string, taskGroup: { name: string }): Promise<void> {
    await this.http.put(`/v1/organizations/${organizationId}/task-manager/task-groups/${taskGroupId}`, taskGroup);
    await this.queryClient.invalidateQueries(`${organizationId}-task-groups`);
  }

  async addTask(organizationId: string, task: { name: string, taskGroupId: string }): Promise<void> {
    await this.http.post(`/v1/organizations/${organizationId}/task-manager/tasks`, task);
    await this.queryClient.invalidateQueries(`${organizationId}-task-groups`);
  }

  async deleteTask(organizationId: string, taskId: string): Promise<void> {
    await this.http.delete(`/v1/organizations/${organizationId}/task-manager/tasks/${taskId}`);
    await this.queryClient.invalidateQueries(`${organizationId}-task-groups`);
  }

  async updateTask(organizationId: string, taskId: string, task: { name: string }): Promise<void> {
    await this.http.put(`/v1/organizations/${organizationId}/task-manager/tasks/${taskId}`, task);
    await this.queryClient.invalidateQueries(`${organizationId}-task-groups`);
  }

  async assignTreesToTask(organizationId: string, taskId: string, dto: AssignTaskDto): Promise<{ assignedCount?: number, rejectedCount?: number }> {
    const { managedAreaIds, reverseMASelection, ...taskDto } = dto;
    const params = new URLSearchParams();
    managedAreaIds.forEach(it => params.append('managedAreaId', it));
    if (reverseMASelection) params.append('reverseMASelection', 'true');

    const result = await this.http.put(`/v1/organizations/${organizationId}/task-manager/tasks/${taskId}`, taskDto, { params });
    await this.queryClient.invalidateQueries(`${organizationId}-task-groups`);
    return result.data;
  }

  async listTaskForUser(organizationId: string): Promise<WorkOrder[]> {
    const response = await this.http.get<WorkOrderDto[]>(`/v1/organizations/${organizationId}/task-manager/work-orders`);
    return response.data.map(WorkOrder.fromDto);
  }

  async listArchivedWorkOrders(organizationId: string): Promise<WorkOrder[]> {
    const response = await this.http.get<WorkOrderDto[]>(`/v1/organizations/${organizationId}/task-manager/work-orders/archive`);
    return response.data.map(WorkOrder.fromDto);
  }

  async createWorkOrder(organizationId: string, workOrder: WorkOrderCreateDto): Promise<void> {
    const { managedAreaIds, reverseMASelection, ...workOrderDto } = workOrder;
    const params = new URLSearchParams();
    managedAreaIds.forEach(it => params.append('managedAreaId', it));
    if (reverseMASelection) params.append('reverseMASelection', 'true');

    await this.http.post(`/v1/organizations/${organizationId}/task-manager/work-orders`, workOrderDto, { params });
    await this.queryClient.invalidateQueries(`${organizationId}-work-orders`);
  }

  async updateWorkOrder(organizationId: string, workOrderId: string, workOrder: WorkOrderUpdateDto): Promise<void> {
    await this.http.patch(`/v1/organizations/${organizationId}/task-manager/work-orders/${workOrderId}`, workOrder);
    await this.queryClient.invalidateQueries(`${organizationId}-work-orders`);
  }

  async clearNotification(organizationId: string, workOrderId: string): Promise<void> {
    await this.http.delete(`/v1/organizations/${organizationId}/task-manager/work-orders/${workOrderId}/users-to-notify/me`);
    await this.queryClient.invalidateQueries(`${organizationId}-work-orders`);
  }

  async addComment(organizationId: string, workOrderId: string, comment: { content: string }): Promise<void> {
    await this.http.post(`/v1/organizations/${organizationId}/task-manager/work-orders/${workOrderId}/comment`, comment);
    await this.queryClient.invalidateQueries(`${organizationId}-work-orders`);
  }

  async listPossibleAssignees(organizationId: string) {
    const response = await this.http.get(`/v1/organizations/${organizationId}/task-manager/possible-assignees`);
    return response.data.map(User.fromDto);
  }

  async deleteWorkOrder(organizationId: string, workOrderId: string): Promise<void> {
    await this.http.delete(`/v1/organizations/${organizationId}/task-manager/work-orders/${workOrderId}`);
    await this.queryClient.invalidateQueries(`${organizationId}-work-orders`);
  }

  async listTaskTemplatesWithTrees(organizationId: string, managedAreaIds: string[], reversed?: boolean): Promise<TaskTemplateWithTreesDto[]> {
    const response = await this.http.post<TaskTemplateWithTreesDto[]>(`/v1/organizations/${organizationId}/task-manager/task-templates-with-trees${reversed ? '?reverseMASelection=true' : ''}`, {
      managedAreaIds
    });
    return response.data;
  }

  async listTaskTemplates(organizationId: string): Promise<TaskTemplate[]> {
    const { data } = await this.http.get<TaskTemplate[]>(`/v1/organizations/${organizationId}/task-manager/task-templates`);
    return data;
  }

  async listTreesByArea(
    organizationId: string,
    jobColumnName: string,
    managedAreaIds: string[],
    reverseMASelection: boolean,
    fields: string[],
    sortProperty: string | null,
    sortingDirection: SortingDirection | null
  ) {
    const params = new URLSearchParams();

    managedAreaIds.forEach(it => params.append('managedAreaId', it));
    if (reverseMASelection) params.append('reverseMASelection', 'true');
    fields.forEach(it => params.append('fields', it));
    if (sortProperty && sortingDirection) {
      params.append('sortProperty', sortProperty);
      params.append('sortingDirection', sortingDirection);
    }

    const response = await this.http.get<TreeDto[]>(
      `/v1/organizations/${organizationId}/task-manager/${jobColumnName}/trees`,
      { params }
    );
    return response.data.map(PartialTree.fromDto);
  }

  async listTreesByIds(
    organizationId: string,
    treeIds: string[],
    fields: string[],
    sortProperty: string | null,
    sortingDirection: SortingDirection | null
  ) {
    const params = new URLSearchParams();
    fields.forEach(it => params.append('fields', it));
    if (sortProperty && sortingDirection) {
      params.append('sortProperty', sortProperty);
      params.append('sortingDirection', sortingDirection);
    }

    const response = await this.http.post<TreeDto[]>(
      `/v1/organizations/${organizationId}/task-manager/trees-by-ids`,
      treeIds,
      { params }
    );
    return response.data.map(PartialTree.fromDto);
  }

  async createTaskTemplate(organizationId: string, taskTemplate: TaskTemplateDto) {
    const response = await this.http.post(`/v1/organizations/${organizationId}/task-manager/task-templates`, { ...taskTemplate });
    await this.queryClient.invalidateQueries(`${organizationId}-task-templates`);
    await this.queryClient.invalidateQueries(`${organizationId}-task-templates-with-trees`);
    return response.data;
  }

  async updateTaskTemplate(organizationId: string, taskTemplateId: string, taskTemplate: TaskTemplateDto) {
    const response = await this.http.put(`/v1/organizations/${organizationId}/task-manager/task-templates/${taskTemplateId}`, { ...taskTemplate });
    await this.queryClient.invalidateQueries(`${organizationId}-task-templates`);
    await this.queryClient.invalidateQueries(`${organizationId}-task-templates-with-trees`);
    return response.data;
  }

  async deleteTaskTemplate(organizationId: string, taskTemplateId: string) {
    const response = await this.http.delete(`/v1/organizations/${organizationId}/task-manager/task-templates/${taskTemplateId}`);
    await this.queryClient.invalidateQueries(`${organizationId}-task-templates`);
    await this.queryClient.invalidateQueries(`${organizationId}-task-templates-with-trees`);
    return response.data;
  }

  async createTask(dto: CreateTaskDto) {
    const params = new URLSearchParams();

    dto.managedAreaIds.forEach(it => params.append('managedAreaId', it));
    if (dto.reverseMASelection) params.append('reverseMASelection', 'true');

    const url = `v1/organizations/${dto.organizationId}/task-manager/work-orders?${params.toString()}`;

    const response = await this.http.post(url, {
      type: dto.type,
      treeIds: dto.treeIds,
      connectedModule: dto.connectedModule,
      jobColumnName: dto.jobColumnName
    });
    await this.queryClient.invalidateQueries(`${dto.organizationId}-task-templates`);
    await this.queryClient.invalidateQueries(`${dto.organizationId}-task-templates-with-trees`);

    return response.status;
  }

  async getWorkOrder(organizationId: string, workOrderId: string): Promise<WorkOrder> {
    const response = await this.http.get<WorkOrderDto>(`/v1/organizations/${organizationId}/task-manager/work-orders/${workOrderId}`);
    return WorkOrder.fromDto(response.data);
  }
}

interface CreateTaskDto {
  organizationId: string,
  managedAreaIds: string[],
  reverseMASelection: boolean,
  type: string,
  treeIds: string[],
  connectedModule?: string,
  jobColumnName: JobColumnName
}
