import React, { useContext, useRef, useState } from 'react';
import { DisplayableTreeProperty, Tree } from '../../../../tree/Tree';
import styles from './TableRow.module.scss';
import FileDownloadButton from './FileDownloadButton';
import { ArrowRightCircle, Download, Pin } from 'iconoir-react';
import { Property } from '../TableViewer';
import { useTranslation } from 'react-i18next';
import PropertyConfiguration from '../../../../properties/PropertyConfiguration';
import PropertyColorConfiguration from '../../../../properties/PropertyColorConfiguration';
import { useTracking } from '../../../../analytics/useTracking';
import { FilteredPartialTree } from '../../../../tree/FilteredPartialTree';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import CohortConfig from '../../../../components/cohort/CohortConfig';
import { IssuesFoundIcon, NoIssuesIcon } from '../../../../components/UI/Icon/Icon';
import Tooltip from '../../../../components/UI/Tooltip/Tooltip';

interface TableRowProps {
  tree: FilteredPartialTree,
  index: number,
  pinned?: boolean,
  properties: Property[],
  selectedTreeId: string | null,
  onSelect: () => unknown,
  windSpeed: number,
  onPin: (animationPromise: Promise<void>) => unknown,
  navigateToDetails: () => unknown,
  hidePlaceholderColumn: boolean,
  selectedTreeProperty: DisplayableTreeProperty | null,
  propertyConfigs: PropertyConfiguration[]
}

export default function TableRow(props: TableRowProps) {
  const { t } = useTranslation();
  const urlContext = useContext(DependencyInjectionContext).urlContext;

  const isSelected = props.tree.id === props.selectedTreeId;
  const [hideAnimationInProgress, setHideAnimationInProgress] = useState(false);
  const { track, events } = useTracking();

  const className = [
    styles.row,
    isSelected ? styles.active : '',
    props.pinned ? styles.pinned : '',
    props.tree.filtered ? styles.filtered : ''
  ].join(' ');

  const rowRef = useRef<HTMLDivElement | null>(null);

  const animationPromise = () => {
    return new Promise<void>((resolve, reject) => {
      if (rowRef.current === null) {
        reject();
      }

      rowRef.current?.addEventListener('animationend', () => {
        setHideAnimationInProgress(false);
        resolve();
      });
    });
  };

  const handlePinning = e => {
    e.stopPropagation();
    setHideAnimationInProgress(true);
    props.onPin(animationPromise());
  };

  const selectRow = () => {
    if (!props.tree.filtered) {
      props.onSelect();
    }
  };

  return (
    <div
      className={`${className} ${hideAnimationInProgress ? styles.animateHiding : ''}`}
      style={{ gridTemplateColumns: `repeat(${props.properties.length}, 200px) 1fr` }}
      ref={rowRef}
      onClick={() => selectRow()}>
      {props.properties.map((property, propertyIndex) => {
        const value =
          property === DisplayableTreeProperty.SafetyFactors
            ? props.tree.getSafetyFactor(props.windSpeed)
            : (property === 'managedAreaId' ? props.tree['managedAreaName'] : props.tree[property]);

        const isFirstCol = propertyIndex === 0;

        const getPropertyConfigColor = () => {
          const propertyConfig = props.propertyConfigs.find(it => it.property === property);
          if (propertyConfig) {
            const index = propertyConfig.getRangeIndexForValue(value);
            const colors = PropertyColorConfiguration.getColorsForConfig(propertyConfig);
            return colors[index];
          }

          return '255, 255, 255';
        };

        const getCohortColor = () => {
          if (!props.tree.cohort?.cohortValues[property]) return '255, 255, 255';
          return new CohortConfig(props.tree.cohort?.cohortValues[property]).getColorOfValue(value);
        };

        const color = urlContext.getColoringType() === 'cohort' ? getCohortColor() : getPropertyConfigColor();

        return (
          <div
            key={`tree-${props.index}-${property}`}
            className={`${styles.column} ${propertyIndex < 3 ? styles.alignLeft : ''} 
               ${isFirstCol && (props.pinned ? styles.pinned : '')}`}
            style={property === props.selectedTreeProperty ? { background: `rgba(${color}, 0.1)` } : {}}
          >
            {property === 'externalId' ?
              <div className={`${styles.firstColumn}`}>
                <span>{value}</span>
                <div className={styles.buttonsContainer}>
                  <FileDownloadButton
                    windSpeed={props.windSpeed}
                    treesProvider={() => Promise.resolve([props.tree])}
                    header={props.properties}>
                    <Tooltip overlay={t('treeList.download')}>
                      <Download onClick={() => track(events.TABLE_DOWNLOAD_SINGLE_TREE, { treeId: props.tree.id })} />
                    </Tooltip>
                  </FileDownloadButton>
                  <Tooltip overlay={t('treeList.pin')}>
                    <div onClick={e => handlePinning(e)}>
                      <Pin className={props.pinned ? styles.pinnedState : ''} />
                    </div>
                  </Tooltip>
                  <Tooltip overlay={t('treeList.viewDetails')}>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                        props.navigateToDetails();
                        track(events.TABLE_NAVIGATE_TO_TREE_DETAILS, { treeId: props.tree.id });
                      }}>
                      <ArrowRightCircle />
                    </div>
                  </Tooltip>
                </div>
              </div>
              :
              <TableCell
                property={property}
                value={value}
                isSelected={property === props.selectedTreeProperty}
                color={color}
              />}
          </div>
        );
      })}
      <div
        key={`tree-${props.index}-placeholder`}
        className={`${styles.column} ${styles.columnPlaceholder}`}
        hidden={props.hidePlaceholderColumn}>
      </div>
    </div>
  );
}

function TableCell({ property, value, isSelected, color }: TableCellProps) {
  const { t } = useTranslation();
  const locale = navigator.language;

  if (property === DisplayableTreeProperty.Status) {
    return <div>{value ? t('tree.statusTypes.' + value) : '-'}</div>;
  }

  if (property === DisplayableTreeProperty.VitalityVigor) {
    return <div>{value ? t('tree.vitalityVigorTypes.' + value) : '-'}</div>;
  }

  if (property === DisplayableTreeProperty.ViStatus) {
    return <div>{value ? t('virtualInspection.status.' + value) : '-'}</div>;
  }

  if (Tree.isViBooleanProperty(property)) {
    return <div className={styles.inspectionCell}>{value ? <NoIssuesIcon /> : <IssuesFoundIcon />}</div>;
  }

  if (value instanceof Array) {
    return <span
      className={styles.columnValue}
      style={isSelected ? { color: `rgb(${color})` } : {}}
      title={value.map(it => t(Tree.getTKeyForProperty(property, it.name))).join(', ')}
    >
      {value.map(it => t(Tree.getTKeyForProperty(property, it.name))).join(', ')}
    </span>;
  }

  return <span
    className={`${styles.columnValue} ${Tree.isItalicProperty(property) ? styles.italic : ''}`}
    title={property === 'species' ? value as string : ''}
    style={isSelected ? { color: `rgb(${color})` } : {}}
  >
    {typeof value === 'number' ? new Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(value) : value || '-'}
  </span>;
}

interface TableCellProps {
  property: Property,
  value: string | number | Array<{ name: string }>,
  isSelected: boolean,
  color: string
}
