import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortButton, SortingDirection } from '../../../components/UI/SortButton/SortButton';
import WorkOrderRow from './WorkOrderRow';
import { InfoCircle } from 'iconoir-react';
import { WorkOrder } from '../../../task-manager/WorkOrder';

export default function WorkOrderTable(props: WorkOrderTableProps) {
  const { t } = useTranslation();

  const [sortProperty, setSortProperty] = useState<string | null>(null);
  const [sortingDirection, setSortingDirection] = useState<SortingDirection | null>(null);

  const workOrdersSorted = useMemo(() => {
    if (sortProperty && sortingDirection) {
      return [...props.workOrders].sort((a, b) => {
        let compareValueA = a[sortProperty];
        let compareValueB = b[sortProperty];

        if (sortProperty === 'treeCount') {
          compareValueA = a.treeIds.length;
          compareValueB = b.treeIds.length;
        }

        if (sortProperty === 'assignee') {
          compareValueA = a.assignee?.getName();
          compareValueB = b.assignee?.getName();
        }

        if (sortProperty === 'assignedBy') {
          compareValueA = a.createdBy?.getName();
          compareValueB = b.createdBy?.getName();
        }

        if (sortProperty === 'code') {
          compareValueA = Number(a.code.split('-')[1]);
          compareValueB = Number(b.code.split('-')[1]);
        }

        if (compareValueA < compareValueB) {
          return sortingDirection === SortingDirection.ASCENDING ? -1 : 1;
        }
        if (compareValueA > compareValueB) {
          return sortingDirection === SortingDirection.ASCENDING ? 1 : -1;
        }
        return 0;
      });
    } else return props.workOrders;
  }, [props.workOrders, sortProperty, sortingDirection]);

  const handleSort = (property: string) => {
    if (sortProperty !== property) {
      setSortProperty(property);
      setSortingDirection(SortingDirection.ASCENDING);
    } else {
      if (sortingDirection === SortingDirection.ASCENDING) {
        setSortingDirection(SortingDirection.DESCENDING);
      } else {
        setSortProperty(null);
        setSortingDirection(null);
      }
    }
  };

  const TableHeaderCell = (props: { column?: string, hideSorting?: boolean }) => {
    return (
      <th className="first:rounded-tl-lg last:rounded-tr-lg bg-outer-space-900 sticky top-0 z-10">
        <div className="h-14 p-4 justify-between items-center flex w-full">
          <span className="text-base font-medium font-['Inter'] leading-normal">
            {props.column ? t(`taskManager.taskTable.${props.column}`) : ''}
          </span>
          {!props.hideSorting && props.column && <SortButton
            sortingDirection={sortProperty === props.column ? sortingDirection : null}
            onClick={() => handleSort(props.column!)} />}
        </div>
      </th>
    );
  };

  return (
    <div className="w-full">
      <div className="overflow-auto rounded-t-xl max-h-[calc(100vh-300px)]">
        <table className="border-outer-space-800 text-outer-space-50 w-full">
          <thead className="bg-outer-space-900 whitespace-nowrap">
            <tr>
              <TableHeaderCell column={'actions'} hideSorting={true} />
              <th className="first:rounded-tl-lg last:rounded-tr-lg bg-outer-space-900 sticky top-0 z-10">
                <div className="h-14 p-4 justify-between items-center flex w-full">
                  <span className="text-base font-medium font-['Inter'] leading-normal">
                    <InfoCircle />
                  </span>
                </div>
              </th>
              <TableHeaderCell column={'name'} />
              <TableHeaderCell column={'code'} />
              <TableHeaderCell column={'area'} />
              <TableHeaderCell column={'treeCount'} />
              <TableHeaderCell column={'type'} />
              <TableHeaderCell column={'assignee'} />
              <TableHeaderCell column={'deadline'} />
              <TableHeaderCell column={'status'} />
              <TableHeaderCell column={'progress'} />
              <TableHeaderCell column={'assignedBy'} />
            </tr>
          </thead>
          <tbody className="bg-outer-space-700">
            {workOrdersSorted.map(workOrder =>
              <WorkOrderRow
                workOrder={workOrder}
                key={workOrder.id}
                onSlideInClick={() => {
                  props.setSelectedWorkOrder(prev=> prev === workOrder ? null : workOrder);
                }}
              />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

interface WorkOrderTableProps {
  workOrders: WorkOrder[],
  setSelectedWorkOrder: (value: WorkOrder | null | ((prevWorkOrder: WorkOrder | null) => WorkOrder | null)) => void
}
