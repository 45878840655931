import DetailedTree from '../../../tree/DetailedTree';

export class ChartCalculator {
  private readonly structureProperties: ChartProperty[];
  private readonly healthAndVitalityProperties: ChartProperty[];
  private readonly clearancesProperties: ChartProperty[];
  private readonly outlierProperties: ChartProperty[];

  constructor(
    readonly tree: DetailedTree,
    readonly clearancesFlipper: boolean,
    readonly t: (key: string) => string
  ) {
    this.structureProperties = [
      { label: t('tree.safetyFactorBase'), value: (this.tree.safetyFactorAt80Kmh !== null && this.tree.safetyFactorAt80Kmh !== undefined) ? this.tree.safetyFactorAt80Kmh >= 3 : null },
      { label: t('tree.safetyFactorFinal'), value: null },
      { label: t('tree.leaningAngle'), value: (this.tree.leaningAngle !== null && this.tree.leaningAngle !== undefined) ? this.tree.leaningAngle <= 30 : null },
      { label: t('tree.slenderness'), value: (this.tree.ageClass && this.tree.slenderness !== null && this.tree.slenderness !== undefined) ? (this.tree.ageClass === 'young' || this.tree.slenderness <= 200) : null },
      { label: t('tree.dieback'), value: (this.tree.dieback !== null && this.tree.dieback !== undefined) ? this.tree.dieback <= 60 : null }
    ];
    this.healthAndVitalityProperties = [
      { label: t('tree.dieback'), value: (this.tree.dieback !== null && this.tree.dieback !== undefined) ? this.tree.dieback <= 60 : null },
      { label: t('tree.crownTransparency'), value: this.tree.crownTransparency ? !['dead', 'severelyDefoliated'].includes(this.tree.crownTransparency) : null },
      { label: t('tree.parasites'), value: null },
      { label: t('tree.diseases'), value: null },
      { label: t('tree.ndvi'), value: (this.tree.ndvi !== null && this.tree.ndvi !== undefined) ? this.tree.ndvi >= 0.5 : null }
    ];
    this.clearancesProperties = [
      { label: t('tree.wireSafe'), value: this.clearancesFlipper && this.tree.wireClearanceIssueDetected !== undefined ? this.tree.wireClearanceIssueDetected === 'false' : null },
      { label: t('tree.roadSafe'), value: this.clearancesFlipper && this.tree.roadClearanceIssueDetected !== undefined ? this.tree.roadClearanceIssueDetected === 'false' : null },
      { label: t('tree.buildingSafe'), value: this.clearancesFlipper && this.tree.buildingClearanceIssueDetected !== undefined ? this.tree.buildingClearanceIssueDetected === 'false' : null },
      { label: t('tree.visibilitySafe'), value: this.clearancesFlipper && this.tree.visibilityClearanceIssueDetected !== undefined ? this.tree.visibilityClearanceIssueDetected === 'false' : null },
      { label: t('tree.trafficSignsSafe'), value: this.clearancesFlipper && this.tree.trafficSignClearanceIssueDetected !== undefined ? this.tree.trafficSignClearanceIssueDetected === 'false' : null }
    ];
    this.outlierProperties = [
      { label: t('tree.dbhVsLeafArea'), value: this.tree.outlierTrunkDiameterPerLeafArea === null ? null : !this.tree.outlierTrunkDiameterPerLeafArea },
      { label: t('tree.heightVsCrownVolume'), value: this.tree.outlierHeightPerCrownVolume === null ? null : !this.tree.outlierHeightPerCrownVolume },
      { label: t('tree.dbhVsHeight'), value: this.tree.outlierTrunkDiameterPerHeight === null ? null : !this.tree.outlierTrunkDiameterPerHeight },
      { label: t('tree.leafAreaVsCrownVolume'), value: this.tree.outlierLeafAreaPerCrownVolume === null ? null : !this.tree.outlierLeafAreaPerCrownVolume },
      { label: t('tree.dbhVsCrownVolume'), value: this.tree.outlierTrunkDiameterPerCrownVolume === null ? null : !this.tree.outlierTrunkDiameterPerCrownVolume }
    ];
  }

  getData(): ChartData {
    return {
      sections: [
        {
          label: this.t('tree.chartSections.structure'),
          value: this.structureProperties.filter(p => p.value).length,
          noDataCount: this.structureProperties.filter(p => p.value === null).length,
          properties: this.structureProperties
        },
        {
          label: this.t('tree.chartSections.health'),
          value: this.healthAndVitalityProperties.filter(p => p.value).length,
          noDataCount: this.healthAndVitalityProperties.filter(p => p.value === null).length,
          properties: this.healthAndVitalityProperties
        },
        {
          label: this.t('tree.chartSections.clearances'),
          value: this.clearancesProperties.filter(p => p.value).length,
          noDataCount: this.clearancesProperties.filter(p => p.value === null).length,
          properties: this.clearancesProperties
        },
        {
          label: this.t('tree.chartSections.outliers'),
          value: this.outlierProperties.filter(p => p.value).length,
          noDataCount: this.outlierProperties.filter(p => p.value === null).length,
          properties: this.outlierProperties
        }
      ]
    };
  }
}

export type ChartData = {
  sections: {
    label: string,
    value: number,
    noDataCount: number,
    properties: ChartProperty[]
  }[]
};

export type ChartProperty = {
  label: string,
  value: boolean | null
};
