import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useMemo } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useCurrentAccount } from '../../../account/useAccounts';
import { useMatch } from 'react-router-dom';
import { useTaskTemplatesWithTrees } from './useTaskTemplatesWithTrees';
import jobColumnColorMap from '../jobColumnColorMap';
import { JobColumnName } from './JobColumnName';
import ReportDialog from '../../Explore/reports/ReportDialog';
import useManagedAreas from '../../../managed-area/useManagedAreaList';
import { dismissibleErrorToast } from '../../../components/UI/Toast/DismissibleToast';
import TreeTable, { TreeTableVersion } from '../../Explore/workspace/TreeTable';
import { DisplayableTreeProperty } from '../../../tree/Tree';

export default function WorkOrderCreationFromTemplate() {
  const { t } = useTranslation();
  const { urlContext } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();
  const { managedAreaList } = useManagedAreas(organization.id);

  const selectedManagedAreaIds = urlContext.getManagedAreaIds();
  const selectedManagedAreas = managedAreaList.filter(it => selectedManagedAreaIds.includes(it.id));
  const { categories, isError } = useTaskTemplatesWithTrees(organization.id);

  const match = useMatch('/organizations/:organizationId/task-manager/create/:taskTemplateId');
  const taskTemplateId = match?.params.taskTemplateId;
  const taskTemplate = categories.flatMap(it => it.taskTemplates).find(it => it.id === taskTemplateId);

  const selectedFields = useMemo(() => urlContext.getVisibleTableProperties() || [], [JSON.stringify(urlContext.getVisibleTableProperties())]);

  useEffect(() => {
    urlContext.setVisibleTableProperties([
      DisplayableTreeProperty.ExternalId,
      DisplayableTreeProperty.Height,
      DisplayableTreeProperty.TrunkHeight,
      DisplayableTreeProperty.TrunkDiameter,
      DisplayableTreeProperty.CanopyCircumference
    ]);
  }, []);

  useEffect(() => {
    if (isError) dismissibleErrorToast(t('genericError'));
  }, [isError]);

  if (!taskTemplate) return null;

  return (
    <section className="twp w-full flex flex-col flex-auto h-[calc(100%-25px)]">
      <div className="flex gap-4 my-6">
        <h2 className="text-2xl font-semibold">{taskTemplate.name}</h2>
        <span className={`bg-${jobColumnColorMap[JobColumnName.Inspection].primary} px-2 flex items-center text-greehill-00 rounded-md`}>{t(`taskManager.jobColumnTitles.${taskTemplate.jobColumnName}`)}</span>
      </div>
      <div className="flex-auto h-[calc(100%-80px)]">
        <TreeTable
          filters={[]}
          properties={selectedFields}
          selectedTreeId={null}
          onSelect={() => {}}
          ROW_HEIGHT={40}
          HEADER_HEIGHT={0}
          areaFilterIsSelected={true}
          windSpeed={organization.defaultWindSpeed}
          selectedTreeProperty={null}
          open={true}
          treeTableVersion={TreeTableVersion.WORK_ORDER_CREATION}
          taskTemplate={taskTemplate}
        />
      </div>

      {urlContext.getReportOpen() &&
        <ReportDialog
          onHide={() => urlContext.setReportOpen(false)}
          managedAreas={selectedManagedAreas}
          filters={[]}
          taskTemplateId={taskTemplateId}
        />}
    </section>
  );
}
