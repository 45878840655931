import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import mapboxgl, { LngLatBounds, LngLatBoundsLike } from 'mapbox-gl';
import getRuntimeConfig from '../../../RuntimeConfig';
import { CARBON_MAP_STYLES, MAP_STYLES, MAX_ZOOM_LEVEL } from '../../../constants';
import { ManagedArea } from '../../../managed-area/ManagedArea';
import { ManagedAreaLayer } from './map-layers/ManagedAreaLayer';
import { TreeMarkerLayer } from './map-layers/TreeMarkerLayer';
import { MapStyle } from '../components/MapStyleSelector';
import { DisplayableTreeProperty, TreeDisplayConfiguration } from '../../../tree/Tree';
import { useTranslation } from 'react-i18next';
import { Organization } from '../../../organization/Organization';
import { OrganizationLayer } from './map-layers/OrganizationLayer';
import Isoline from './Isoline';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { CanopyLayer } from './map-layers/CanopyLayer';
import PropertyConfiguration from '../../../properties/PropertyConfiguration';
import TreeClusterLayer from './map-layers/TreeClusterLayer';
import { DisplayTreesOptions } from '../../../components/Navbar/DisplayModes';
import { useCurrentAccount } from '../../../account/useAccounts';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { usePropertyConfigurations } from '../../../properties/usePropertyConfigurations';
import { useTracking } from '../../../analytics/useTracking';
import useCarbonThemes from '../../../components/UI/Carbon/useCarbonThemes';
import { IconButton } from '@carbon/react';
import { Add, AirportLocation, Compass, Location, Subtract } from '@carbon/icons-react';
import useManagedAreas from '../../../managed-area/useManagedAreaList';
import MapStyleSelector from '../../CarbonInsights/components/MapStyleSelector';

export default function MapViewer(props: MapViewerProps) {
  const { theme } = useCarbonThemes();
  const account = useCurrentAccount();

  const mapStyleConfig = new Map([
    [MapStyle.Default, CARBON_MAP_STYLES[theme]],
    [MapStyle.Satellite, MAP_STYLES.satellite]
  ]);

  const mapboxStyleNameStyleMap = new Map([
    ['Monochrome', MapStyle.Default],
    ['Mapbox Satellite', MapStyle.Satellite]
  ]);

  const urlContext = useContext(DependencyInjectionContext).urlContext;
  const match = useMatch('/organizations/:organizationId/*');
  const { managedAreaList } = useManagedAreas(account.organization.id);
  const tracking = useTracking();
  const { track, events } = tracking;
  const onSelectTree = (treeId: string) => {
    track(events.TREE_SELECT_FROM_MAP_2D, { treeId });
    urlContext.setTreeId(treeId);
  };

  // Variables

  const displayMode = urlContext.getDisplayMode();
  const hideLabels = !urlContext.areTreeMarkersVisible();
  const organizationId = match?.params?.organizationId || '';
  const apiUrl = getRuntimeConfig().apiUrl;
  const useEffectDependencyForManagedAreaFiltering = props.selectedManagedAreas
    .map(managedArea => managedArea.id)
    .join('-');
  const useEffectDependencyForFiltering = props.treeDisplayConfiguration.filters
    .map(filter => filter.id)
    .join('-');

  // Hooks

  const { t } = useTranslation();
  const { treeService } = useContext(DependencyInjectionContext);
  const configs = usePropertyConfigurations();
  const navigate = useNavigate();
  const location = useLocation();

  // useRefs

  const containerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);

  // useStates

  const [isLoaded, setLoadingState] = useState(false);
  const [isTreeLayerLoaded, setTreeLayerLoaded] = useState(false);
  const [hideMarkers, setHideMarkers] = useState(false);
  const areMarkersHidden = props.hideMarkers || hideMarkers;

  const currentPath = location.pathname + location.search;
  const onOpenTree = useCallback((treeId: string) => {
    track(events.TREE_NAME_CARD_GO_TO_DETAILS_FROM_MAP);
    navigate(`/organizations/${organizationId}/inventory/trees/${treeId}`);
  }, [organizationId, currentPath]);

  // useMemos

  const managedAreaLayer = useMemo(() => {
    if (!props.organization.id) return null;
    return new ManagedAreaLayer(apiUrl, props.organization);
  },
  [props.organization.id]
  );

  const canopyLayer = useMemo(() => new CanopyLayer(
    apiUrl,
    props.organization,
    props.selectedTreePropertyRangeIndex,
    props.selectedPropertyConfig,
    displayMode === DisplayTreesOptions.canopy,
    urlContext.getFilterConfiguration(),
    props.treeDisplayConfiguration,
    props.selectedTreeId,
    treeService,
    t,
    treeId => onSelectTree(treeId),
    treeId => onOpenTree(treeId),
    tracking,
    urlContext.getColoringType()
  ), []);

  const treeMarkerLayer = useMemo(
    () => {
      return new TreeMarkerLayer(
        treeService,
        getRuntimeConfig().apiUrl,
        props.organization,
        props.treeDisplayConfiguration,
        props.selectedTreeId,
        hideLabels,
        t,
        containerRef,
        displayMode !== DisplayTreesOptions.markers || areMarkersHidden,
        urlContext.getFilterConfiguration(),
        account,
        onSelectTree,
        onOpenTree,
        tracking,
        urlContext.getColoringType()
      );
    }, []);

  const organizationLayer = useMemo(
    () => new OrganizationLayer().setOrganization(props.organization),
    []
  );

  const getVisibleTreeLayer = useMemo(() => {
    if (displayMode === DisplayTreesOptions.markers) return TreeMarkerLayer.ID;
    return CanopyLayer.LAYER_ORDERING_ID;
  }, [displayMode]);

  // useEffects

  useEffect(() => {
    treeMarkerLayer.setSelectedPropertyConfig(props.selectedPropertyConfig);
    canopyLayer.setSelectedPropertyConfig(props.selectedPropertyConfig);
  },
  [props.selectedPropertyConfig?.property]
  );

  useEffect(() => {
    if (!mapRef.current) return;
    treeMarkerLayer.setSelectedTreePropertyRangeIndex(props.selectedTreePropertyRangeIndex);
    canopyLayer.setSelectedTreePropertyRangeIndex(props.selectedTreePropertyRangeIndex);
  }, [props.selectedTreePropertyRangeIndex]);

  // noinspection DuplicatedCode
  useEffect(() => {
    if (!mapRef.current || !isLoaded) return;

    if (mapRef.current.getLayer(organizationLayer.id)) {
      mapRef.current.removeLayer(organizationLayer.id);
    }

    mapRef.current.addLayer(organizationLayer.setOrganization(props.organization));

    return () => {
      mapRef.current?.removeLayer(organizationLayer.id);
    };
  }, [props.organization.id, isLoaded]);

  useEffect(() => {
    treeMarkerLayer.setOnSelectCallback(treeId => onSelectTree(treeId));
    treeMarkerLayer.setOnOpenCallback(treeId => onOpenTree(treeId));
    canopyLayer.setOnSelectCallback(treeId => onSelectTree(treeId));
    canopyLayer.setOnOpenCallback(treeId => onOpenTree(treeId));
  }, [treeMarkerLayer, canopyLayer, onOpenTree]);

  useEffect(() => {
    managedAreaLayer?.setOnSelectCallback(toggleManagedAreaSelection);
    managedAreaLayer?.setOnDeselectCallback(toggleManagedAreaSelection);
  }, [managedAreaLayer]);

  useEffect(() => {
    if (mapRef.current &&
      mapRef.current.getLayer(CanopyLayer.ID) &&
      mapRef.current.getLayer(treeMarkerLayer.id)) {
      treeMarkerLayer.setSelectedTreeId(props.selectedTreeId);
      canopyLayer.setSelectedTreeId(props.selectedTreeId);
    }
  }, [props.selectedTreeId]);

  useEffect(() => {
    if (!mapRef.current?.isStyleLoaded()) return;
    const currentStyleName = mapRef.current?.getStyle().name ?? '';
    const isStyleAlreadyApplied = mapboxStyleNameStyleMap.get(currentStyleName) === props.style;
    if (mapStyleConfig.has(props.style) && mapRef.current && !isStyleAlreadyApplied) {
      mapRef.current?.setStyle(mapStyleConfig.get(props.style)!);
      setLoadingState(false);
    }
  }, [props.style]);

  useEffect(() => {
    if (configs.isLoading) return;
    if (treeMarkerLayer.getOrganizationId() !== props.organization.id) {
      treeMarkerLayer.setOrganization(props.organization);
    }
    if (canopyLayer.getOrganizationId() !== props.organization.id) {
      canopyLayer.setOrganization(props.organization);
    }
  }, [props.organization.id, configs.isLoading]);

  useEffect(() => {
    if (!mapRef.current || !isLoaded) return;

    mapRef.current?.addLayer(treeMarkerLayer);
    setTreeLayerLoaded(true);

    return () => {
      mapRef.current?.removeLayer(treeMarkerLayer.id);
      setTreeLayerLoaded(false);
    };
  }, [isLoaded]);

  useEffect(() => {
    if (!mapRef.current || !isLoaded || !canopyLayer) return;
    canopyLayer.addTo(mapRef.current);

    return () => {
      if (!mapRef.current) return;
      canopyLayer.removeFromMap();
    };
  }, [isLoaded, props.organization.id, canopyLayer, props.selectedPropertyConfig?.property]);

  useEffect(() => {
    if (!mapRef.current || !isLoaded || !managedAreaLayer) return;
    managedAreaLayer.addTo(mapRef.current);

    return () => {
      if (!mapRef.current) return;
      managedAreaLayer.removeFrom(mapRef.current);
    };
  }, [isLoaded, props.organization.id, managedAreaLayer]);

  useEffect(() => {
    if (!mapRef.current || !isLoaded) return;

    treeMarkerLayer.updateDisplayConfiguration(
      props.treeDisplayConfiguration,
      urlContext.getFilterConfiguration(),
      displayMode !== DisplayTreesOptions.markers || areMarkersHidden,
      props.selectedPropertyConfig,
      hideLabels,
      account,
      urlContext.getColoringType()
    );
    if (mapRef.current?.getLayer(CanopyLayer.ID)) {
      canopyLayer.updateDisplayConfiguration(
        props.selectedPropertyConfig,
        urlContext.getFilterConfiguration(),
        urlContext.getColoringType(),
        props.treeDisplayConfiguration,
        displayMode === DisplayTreesOptions.canopy
      );
      canopyLayer.removeFromMap();
      canopyLayer.addTo(mapRef.current);
    }
  }, [
    mapRef.current === null,
    urlContext.getColoringType(),
    props.selectedPropertyConfig?.property,
    displayMode,
    hideLabels,
    areMarkersHidden,
    props.style,
    isLoaded,
    JSON.stringify(urlContext.getAdvancedFilterConfiguration()),
    JSON.stringify(urlContext.getFilterConfiguration()),
    JSON.stringify(props.treeDisplayConfiguration.filters),
    JSON.stringify(props.treeDisplayConfiguration.managedAreaIds),
    props.treeDisplayConfiguration.isManagedAreaSelectionReversed,
    account.id,
    props.treeDisplayConfiguration.windSpeed
  ]);

  useEffect(() => {
    if (!mapRef.current || !isLoaded) return;
    const coloringType = urlContext.getColoringType();

    const treeClusterLayer = new TreeClusterLayer(
      apiUrl,
      props.organization,
      props.treeDisplayConfiguration.property, urlContext.getWindSpeed() || account.getDefaultWindSpeed(),
      props.managedAreaIdsInURL,
      urlContext.getReverseMASelection(),
      props.treeDisplayConfiguration.filters.map(it => it.id),
      urlContext.getFilterConfiguration(),
      coloringType,
      props.selectedPropertyConfig
    );

    mapRef.current.addLayer(treeClusterLayer);
    setTreeLayerLoaded(true);

    return () => {
      if (!mapRef.current) return;
      setTreeLayerLoaded(false);
      mapRef.current.removeLayer(treeClusterLayer.id);
    };
  }, [
    isLoaded,
    props.organization.id,
    account.id,
    useEffectDependencyForManagedAreaFiltering,
    props.style,
    props.treeClusteringPieChartProperty,
    urlContext.getWindSpeed(),
    useEffectDependencyForFiltering,
    urlContext.getColoringType(),
    JSON.stringify(urlContext.getFilterConfiguration()),
    props.selectedPropertyConfig?.property
  ]);

  useEffect(() => {
    if (!containerRef.current) return;

    const onReady = ({ target: map, sourceId }) => {
      if (sourceId === 'organization' && organizationLayer) {
        if (!urlContext.getTreeId() && urlContext.getManagedAreaIds().length === 0) {
          organizationLayer.centerOn();
        } else {
          const lat = urlContext.getLatitude();
          const lng = urlContext.getLongitude();
          const zoom = urlContext.getZoom();

          if (lat && lng && map) {
            if (zoom) {
              map.setZoom(zoom);
              map.setCenter([lng, lat]);
              urlContext.deleteZoom();
              return;
            }
            map.jumpTo({ center: [lng, lat], zoom: 18 });
          }
        }
        map.off('sourcedata', onReady);
      }
    };

    let maxBounds;
    if (props.organization.boundaries.coordinates.length) {
      maxBounds = calculateMaxBounds(props.organization.boundaries.coordinates);
    }

    if (mapRef.current) {
      mapRef.current.setMaxZoom(MAX_ZOOM_LEVEL);
      if (maxBounds) {
        mapRef.current.setZoom(8);
        mapRef.current.setMaxBounds(maxBounds);
        mapRef.current.on('sourcedata', onReady);
      }
      return;
    }

    mapRef.current = new mapboxgl.Map({
      container: containerRef.current,
      style: mapStyleConfig.get(props.style) ?? mapStyleConfig.get(MapStyle.Default),
      dragRotate: true,
      touchZoomRotate: false,
      maxZoom: MAX_ZOOM_LEVEL,
      maxPitch: 0,
      touchPitch: false,
      accessToken: getRuntimeConfig().mapboxApiKey,
      transformRequest: url => (url.startsWith(apiUrl) ? { credentials: 'include', url } : { url }),
      logoPosition: 'bottom-left'
    });

    if (maxBounds) {
      mapRef.current.setZoom(8);
      mapRef.current.setMaxBounds(maxBounds);
    }

    mapRef.current.on('sourcedata', onReady);

    mapRef.current.on('style.load', () => setLoadingState(true));
  }, [props.organization.id]);

  useEffect(() => {
    mapRef.current?.on('data', () => {
      props.onLoading(true);
    });

    mapRef.current?.on('idle', () => {
      props.onLoading(false);
    });
  }, [props.organization.id]);

  useEffect(() => {
    const map = mapRef.current;
    const lat = urlContext.getLatitude();
    const lng = urlContext.getLongitude();
    const zoom = urlContext.getZoom();

    if (lat && lng && map) {
      if (zoom) {
        map.setZoom(zoom);
        map.setCenter([lng, lat]);
        urlContext.deleteZoom();
        return;
      }
      map.flyTo({ center: [lng, lat], zoom: 18 });
    }
  }, [urlContext.getLongitude(), urlContext.getLatitude()]);

  useEffect(() => {
    if (!props.mapResetTriggerKey) return;

    const on = props.mapResetTriggerKey.on;

    if (on === 'tree') {
      const lat = urlContext.getLatitude();
      const lng = urlContext.getLongitude();
      if (lat && lng) mapRef.current?.flyTo({ center: [lng, lat], zoom: 18, bearing: 0, pitch: 0 });
    }

    if (on === 'managedArea') {
      if (!mapRef.current || !props.selectedManagedAreas) return;
      managedAreaLayer?.filterOn(mapRef.current, props.selectedManagedAreas);
    }

    if (on === 'organization') {
      organizationLayer.centerOn();
    }
  }, [props.mapResetTriggerKey.key]);

  useEffect(() => {
    if (!mapRef.current || !isLoaded || !managedAreaLayer) return;

    managedAreaLayer.filterOn(mapRef.current, props.selectedManagedAreas, props.selectedTreeId);
  }, [useEffectDependencyForManagedAreaFiltering, isLoaded]);

  useEffect(() => {
    resize();
  }, [props.resizeTriggerKey]);

  useEffect(() => {
    return () => {
      const map = mapRef.current;
      if (map && map.isStyleLoaded()) {
        if (map.getLayer(treeMarkerLayer.id)) {
          map.removeLayer(treeMarkerLayer.id);
        }
        if (map.getLayer(organizationLayer.id)) {
          map.removeLayer(organizationLayer.id);
        }
        if (map.getLayer(TreeClusterLayer.ID)) {
          map.removeLayer(TreeClusterLayer.ID);
        }
        if (map.getLayer(CanopyLayer.ID)) {
          canopyLayer.removeFromMap();
        }
        if (map.getLayer(ManagedAreaLayer.ID)) {
          managedAreaLayer?.removeFrom(map);
        }
        map.remove();
      }
    };
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      resize();
    });
    resizeObserver.observe(containerRef.current!);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // Functions

  const toggleManagedAreaSelection = (managedAreaId: string) => {
    const managedAreaIds = urlContext.getManagedAreaIds();
    if (managedAreaIds.includes(managedAreaId)) {
      urlContext.removeManagedAreaId(managedAreaId);
    } else {
      urlContext.appendManagedAreaId(managedAreaId);
    }
  };

  function debounce<T extends (...args: any[]) => void>(func: T, wait: number) {
    let timeout: NodeJS.Timeout;
    return function(this: ThisParameterType<T>, ...args: Parameters<T>) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  const resize = debounce(() => {
    mapRef.current?.resize();
    treeMarkerLayer.resize();
  }, 1);

  const calculateMaxBounds = (coordinates: [number, number][][][]): LngLatBoundsLike => {
    const bounds = coordinates
      .flatMap(position => position as [number, number][][])
      .flatMap(position => position as [number, number][])
      .reduce((bounds, position) => bounds.extend(position), new mapboxgl.LngLatBounds());

    return new LngLatBounds({
      lat: bounds.getSouthWest().lat - .2,
      lon: bounds.getSouthWest().lng - .4
    }, {
      lat: bounds.getNorthEast().lat + .2,
      lon: bounds.getNorthEast().lng + .4
    });
  };

  const resetView = () => {
    if (urlContext.getTreeId()) {
      const lat = urlContext.getLatitude();
      const lng = urlContext.getLongitude();
      if (lat && lng) mapRef.current?.flyTo({ center: [lng, lat], zoom: 18, bearing: 0, pitch: 0 });
      return;
    }

    if (managedAreaList && managedAreaList?.some(it => urlContext.isManagedAreaSelected(it))) {
      if (!mapRef.current || !props.selectedManagedAreas) return;
      managedAreaLayer?.filterOn(mapRef.current, props.selectedManagedAreas);
      return;
    }

    organizationLayer.centerOn();
  };

  return (
    <>
      <div ref={containerRef} style={{ width: '100%', height: '100%', zIndex: '0' }}>
        {props.showIsoMap && <Isoline
          mapRef={mapRef}
          selectedProperty={props.treeClusteringPieChartProperty}
          organizationId={props.organization.id}
          treeLayerId={getVisibleTreeLayer}
          isTreeLayerLoaded={isTreeLayerLoaded}
        />}
      </div>
      {!props.tableShouldSnapToTop &&
        <div className={`flex gap-4 z-20 absolute ${urlContext.getTreeId() || urlContext.isFilterPanelOpen() ? 'right-[406px]' : 'right-8'} bottom-12`}>
          <div className="flex gap-0.5">
            <MapStyleSelector />
            <IconButton
              onClick={() => setHideMarkers(prev => !prev)}
              className={!hideMarkers ? 'bg-[#22272A] hover:bg-[#22272A]' : 'bg-[#22272ACC] hover:bg-[#22272A]'}
              label={t('toolbox.hideMarkersTooltip')}
            >
              <Location />
            </IconButton>
          </div>

          <div className="flex gap-0.5">
            <IconButton className="bg-[#22272ACC] hover:bg-[#22272A]" onClick={() => mapRef.current?.zoomIn()} label={t('tooltips.zoomIn')}><Add /></IconButton>
            <IconButton className="bg-[#22272ACC] hover:bg-[#22272A]" onClick={() => mapRef.current?.zoomOut()} label={t('tooltips.zoomOut')}><Subtract /></IconButton>
            <IconButton className="bg-[#22272ACC] hover:bg-[#22272A]" onClick={() => resetView()} label={t('tooltips.resetView')}><AirportLocation /></IconButton>
            <IconButton className="mb-0 mt-auto bg-[#22272ACC] hover:bg-[#22272A]" onClick={() => mapRef.current?.rotateTo(0)} align="top-right" label={t('tooltips.resetBearingToNorth')}><Compass /></IconButton>
          </div>
        </div>
      }
    </>
  );
}

interface MapViewerProps {
  tableShouldSnapToTop: boolean,
  organization: Organization,
  selectedManagedAreas: ManagedArea[],
  managedAreaIdsInURL: string[],
  treeDisplayConfiguration: TreeDisplayConfiguration,
  selectedPropertyConfig: PropertyConfiguration | null,
  selectedTreePropertyRangeIndex: number,
  treeClusteringPieChartProperty: DisplayableTreeProperty | null,
  style: MapStyle,
  selectedTreeId: string | null,
  resizeTriggerKey: string,
  showIsoMap: boolean,
  mapResetTriggerKey: { key?: number, on?: 'tree' | 'managedArea' | 'organization' },
  hideMarkers?: boolean,
  onLoading: Dispatch<SetStateAction<boolean>>
}
