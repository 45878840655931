import { Tree } from '../../tree/Tree';
import { useCurrentAccount } from '../../account/useAccounts';
import { useContext } from 'react';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { useMatch } from 'react-router-dom';
import { VI_CREATION_MODAL_PROPERTY_OPTIONS } from '../Explore/table-view/components/PropertySelector';
import LazyTreeList from './LazyTreeList';
import { useQuery } from 'react-query';

export const useFetchTreeList = (
  treesLastLoadedAt: number,
  sort: string | null,
  tableProperties: string[],
  taskTemplateId?: string,
  taskId?: string,
  workOrderId?: string
) => {
  const { treeService, urlContext } = useContext(DependencyInjectionContext);
  const organizationId = useMatch('/organizations/:organizationId/*')?.params?.organizationId || '';
  const account = useCurrentAccount();
  const managedAreaIdsInURL = urlContext.getManagedAreaIds();
  const reverseMASelection = urlContext.getReverseMASelection();
  const filterConfig = urlContext.getFilterConfiguration();
  const advancedFilterConfiguration = urlContext.getAdvancedFilterConfiguration();
  const windspeed = urlContext.getWindSpeed() || account.getDefaultWindSpeed();

  const cacheKeys = [
    treesLastLoadedAt,
    sort,
    organizationId,
    JSON.stringify(tableProperties),
    account,
    JSON.stringify(managedAreaIdsInURL),
    JSON.stringify(filterConfig),
    JSON.stringify(advancedFilterConfiguration),
    JSON.stringify(reverseMASelection),
    windspeed
  ];
  if (taskTemplateId) cacheKeys.push(taskTemplateId);
  if (taskId) cacheKeys.push(taskId);
  if (workOrderId) cacheKeys.push(workOrderId);

  const result = useQuery(cacheKeys, ({ signal }) => {
    const fieldsToFetch = [
      'externalId',
      'species',
      'localizedLocation',
      ...VI_CREATION_MODAL_PROPERTY_OPTIONS,
      ...tableProperties
    ];

    const treeList = new LazyTreeList(
      treeService,
      account.organization,
      organizationId,
      managedAreaIdsInURL,
      reverseMASelection,
      fieldsToFetch as (keyof Tree)[],
      filterConfig,
      sort,
      windspeed,
      taskTemplateId,
      taskId,
      workOrderId
    );

    return treeList.loadFirst(signal)
      .then(() => treeList)
      .catch(e => {
        if (e.message !== 'canceled') throw e;
      });
  });

  return { treeList: result.data, firstPageIsLoading: result.isLoading };
};
