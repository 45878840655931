import { useContext, useEffect, useRef } from 'react';
import { ToastNotification } from '@carbon/react';
import { CarbonToastContext } from './CarbonToastWrapper';

export default function CarbonToastNotification() {
  const { toast, setToast } = useContext(CarbonToastContext);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (toast) {
      const startTimer = () => {
        timerRef.current = setTimeout(() => {
          setToast(null);
        }, 5000);
      };

      startTimer();

      const handleMouseEnter = () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      };

      const toastContainer = document.getElementById('toast-notification');
      if (toastContainer) {
        toastContainer.addEventListener('mouseenter', handleMouseEnter);
        toastContainer.addEventListener('mouseleave', startTimer);
      }

      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        if (toastContainer) {
          toastContainer.removeEventListener('mouseenter', handleMouseEnter);
          toastContainer.removeEventListener('mouseleave', startTimer);
        }
      };
    }
  }, [toast, setToast]);

  if (!toast) return null;
  return <ToastNotification
    id="toast-notification"
    className="fixed bottom-8 left-1/2 transform -translate-x-1/2"
    kind={toast.kind}
    title={toast.title}
    subtitle={toast.subtitle}
    onCloseButtonClick={() => setToast(null)}
  />;
}
