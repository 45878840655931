import { useTranslation } from 'react-i18next';
import styles from './PieChart.module.scss';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { graphModalColors } from '../GraphModal';
import { getTranslation } from './labelTranslation';
import { themes } from '@carbon/themes';
import useCarbonThemes from '../../../../components/UI/Carbon/useCarbonThemes';
import { Tree } from '../../../../tree/Tree';
import { useCurrentAccount } from '../../../../account/useAccounts';

export type PieChartData = { key: string, value: number, percentage: number }[];
const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer?.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');

    legendContainer?.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item, index) => {
      if (!chart.data.datasets[0].data[index]) return;
      const { percentageLabel, id } = chart.data.datasets[0].data[index];
      const li = document.createElement('li');
      li.id = id;

      li.onmouseover = () => {
        Array.from(document.querySelectorAll(`.${styles.graphLegendContainer} ul li`)).forEach(it => it.classList.add(styles.passive));
        li.classList.remove(styles.passive);

        if (chart.getActiveElements().length > 0) {
          const prev = chart.getActiveElements().find(it => it.index === index);
          if (prev) {
            return;
          }
          chart.setActiveElements([]);
        }
        chart.setActiveElements([{ datasetIndex: 0, index }]);
        chart.update();
      };

      li.onmouseleave = () => {
        Array.from(document.querySelectorAll('.graphLegendContainer ul li')).forEach(it => it.classList.remove(styles.passive));

        chart.setActiveElements([]);
        chart.update();
      };

      const colorCircle = document.createElement('span');
      colorCircle.style.background = item.fillStyle;
      colorCircle.style.borderColor = item.strokeStyle;
      colorCircle.style.borderWidth = item.lineWidth + 'px';

      // Text
      const textContainer = document.createElement('p');
      textContainer.classList.add(styles.textContainer);
      textContainer.style.color = item.fontColor;
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const percentageContainer = document.createElement('div');
      percentageContainer.classList.add(styles.percentageContainer);
      percentageContainer.style.color = item.fontColor;
      percentageContainer.style.fontWeight = '600';
      percentageContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      const percentageText = document.createTextNode(percentageLabel);
      textContainer.appendChild(text);
      percentageContainer.appendChild(percentageText);

      const indicatorWithTitle = document.createElement('div');
      indicatorWithTitle.appendChild(colorCircle);
      indicatorWithTitle.appendChild(textContainer);
      li.appendChild(indicatorWithTitle);
      li.appendChild(percentageContainer);
      ul.appendChild(li);
    });
  }
};

type PieChartProps = {
  data: PieChartData,
  property: string,
  displayPercentage: boolean
};

export default function PieChart(props: PieChartProps) {
  const { t } = useTranslation();
  const { theme } = useCarbonThemes();
  const { organization } = useCurrentAccount();

  const unit = Tree.getUnit(props.property, organization);
  const translatedUnit = unit ? ` (${t(`units.${unit}`)})` : '';

  const THRESHOLD = 2.99;

  const colorMap = [...graphModalColors, '#222D31'];
  colorMap[props.data.findIndex(it => it.key === 'noData')] = '#222D31';
  const data = {
    labels: props.data.map(it => it.key),
    datasets: [
      {
        data: props.data.map(it => ({
          ...it,
          id: Math.random().toString(16).slice(2),
          percentageLabel: props.displayPercentage ? (it.percentage * 100).toFixed(2) + '%' : it.value,
          onChartLabel: props.displayPercentage
            ? (it.percentage * 100 < THRESHOLD) ? '' : (it.percentage * 100).toFixed(2) + '%'
            : (it.percentage * 100 < THRESHOLD) ? '' : it.value
        })),
        backgroundColor: colorMap,
        borderColor: colorMap,
        borderWidth: 1,
        hoverOffset: 30
      }
    ],
    options: {
      layout: {
        padding: 20
      },
      color: 'white',
      plugins: {
        tooltip: {
          enabled: false,
          external: ctx => {
            const allSpeciesElement = Array.from(document.querySelectorAll('.graphLegendContainer ul li'));
            allSpeciesElement.forEach(it => it.classList.remove(styles.passive));

            const el = document.getElementById(ctx.chart.data.datasets?.at(0).data[ctx.tooltip.dataPoints?.at(0).dataIndex]?.id);
            if (!el) return;

            if (ctx.tooltip.opacity === 0) {
              allSpeciesElement.forEach(it => it.classList.remove(styles.passive));
            } else {
              allSpeciesElement.forEach(it => it.classList.add(styles.passive));
              el.classList.remove(styles.passive);
            }
          }
        },
        parsing: {
          key: 'value'
        },
        datalabels: {
          formatter: it => it.onChartLabel,
          color: '#fff',
          font: {
            weight: 'bold' as const
          }
        },
        htmlLegend: {
          containerID: 'legend-container'
        },
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
            generateLabels(chart) {
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                const { labels: { pointStyle } } = chart.legend.options;

                return data.labels.map((label: string | string [], i: number) => {
                  const meta = chart.getDatasetMeta(0);
                  const style = meta.controller.getStyle(i);
                  const translation = getTranslation(label, t, props.property);

                  return {
                    text: translation,
                    fillStyle: style.backgroundColor,
                    strokeStyle: style.borderColor,
                    lineWidth: style.borderWidth,
                    pointStyle,
                    hidden: !chart.getDataVisibility(i),

                    // Extra data used for toggling the correct item
                    index: i
                  };
                });
              }
              return [];
            }
          }
        }
      }
    }
  };

  function drawTree(ctx: CanvasRenderingContext2D, x: number, y: number, text: string) {
    const treeCrown = new Path2D('M12.75 18.25H10.875C10.5436 18.2496 10.2259 18.1178 9.99156 17.8834C9.75722 17.6491 9.6254 17.3314 9.625 17V10.125H13.375C13.7242 10.125 14.0695 10.0518 14.3887 9.91024C14.7079 9.76866 14.9939 9.5618 15.2283 9.30297C15.4627 9.04414 15.6403 8.73909 15.7496 8.40746C15.859 8.07583 15.8976 7.72498 15.8631 7.3775C15.7795 6.74529 15.4658 6.16606 14.9821 5.75053C14.4983 5.335 13.8784 5.1123 13.2408 5.125H12.251L12.1406 4.63812C11.7825 3.06006 10.2692 2 8.375 2C7.65965 2.00228 6.95977 2.20844 6.35741 2.59432C5.75505 2.9802 5.27519 3.5298 4.97406 4.17869L4.78125 4.59569L4.24188 4.52425C4.16192 4.51051 4.08109 4.50241 4 4.5C3.33696 4.5 2.70107 4.76339 2.23223 5.23223C1.76339 5.70107 1.5 6.33696 1.5 7C1.5 7.66304 1.76339 8.29893 2.23223 8.76777C2.70107 9.23661 3.33696 9.5 4 9.5V10.75C3.00544 10.75 2.05161 10.3549 1.34835 9.65165C0.645088 8.94839 0.25 7.99456 0.25 7C0.25 6.00544 0.645088 5.05161 1.34835 4.34835C2.05161 3.64509 3.00544 3.25 4 3.25C4.0165 3.25 4.03281 3.25 4.04912 3.25062C4.48883 2.49219 5.11961 1.86221 5.87861 1.42346C6.6376 0.984714 7.49832 0.752515 8.375 0.75C10.6743 0.75 12.5577 1.986 13.2198 3.875H13.2408C14.1888 3.86441 15.1074 4.20373 15.8209 4.82804C16.5343 5.45235 16.9925 6.31781 17.1078 7.25881C17.1588 7.77969 17.1002 8.30547 16.9359 8.80235C16.7715 9.29924 16.5049 9.75622 16.1533 10.1439C15.8018 10.5316 15.3729 10.8414 14.8944 11.0534C14.4159 11.2655 13.8984 11.375 13.375 11.375H10.875V17H12.75V18.25Z');
    const treeTrunk = new Path2D('M6.5 18.25H4.625V17H6.5V9.229L4.30338 7.91094L4.94662 6.83919L7.14331 8.15694C7.32792 8.26856 7.4807 8.42582 7.58696 8.61357C7.69321 8.80133 7.74936 9.01327 7.75 9.229V17C7.74962 17.3314 7.6178 17.6491 7.38346 17.8835C7.14913 18.1178 6.8314 18.2496 6.5 18.25Z');

    const scale = (y / 100) * 0.65;
    const textWidth = ctx.measureText(text).width;
    const translation = { x: x + textWidth + 4 * scale, y: y - 9 * scale };
    ctx.translate(translation.x, translation.y);
    ctx.scale(scale, scale);
    ctx.strokeStyle = themes[theme].textPrimary;
    ctx.fill(treeCrown);
    ctx.fill(treeTrunk);
    ctx.scale(1 / scale, 1 / scale);
    ctx.translate(-translation.x, -translation.y);
  }

  const middleLabelPlugin = {
    id: 'middleLabel',
    afterDatasetDraw(chart) {
      const ctx = chart.ctx as CanvasRenderingContext2D;
      const width = chart.width;
      const height = chart.height;
      const total = chart.data.datasets[0].data.reduce((acc, it) => acc + it.value, 0);

      const fontSize = (height / 200).toFixed(2);
      ctx.font = fontSize + 'em Inter';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = themes[theme].textPrimary;

      const text = total.toLocaleString().replaceAll(',', ' ');

      const textX = Math.round((width - ctx.measureText(text).width) / 2) - 12;
      const textY = height / 2;
      ctx.fillText(text, textX, textY);

      drawTree(ctx, textX, textY, text);
    }
  };
  return (
    <div>
      <div className={styles.chartContainer}>
        <div style={{ width: '50%' }}>
          <Doughnut
            data={data}
            options={data.options}
            plugins={[htmlLegendPlugin, middleLabelPlugin, ChartDataLabels as unknown as any]}
          />
        </div>
        <div className={styles.graphLegendWrapper}>
          <h4>
            <span>{Tree.renderPropertyName(props.property, t, organization) + translatedUnit}</span>
            <span>{(props.displayPercentage ? '% ' : '# ') + t('workspace.graphModal.ofTrees')}</span>
          </h4>
          <div className={styles.graphLegendContainer} id={'legend-container'}>
          </div>
        </div>
      </div>
    </div>
  );
}
