import { Button, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@carbon/react';
import { TFunction, useTranslation } from 'react-i18next';
import { Archive, CaretDown, CaretUp, DotMark, TaskView, TouchInteraction } from '@carbon/icons-react';
import { useCurrentAccount } from '../../../account/useAccounts';
import CockpitPieChart from '../../CarbonInsights/components/charts/cockpitPieChart/CockpitPieChart';
import { useTaskGroups } from '../tasks/useTaskGroups';
import { Task } from '../tasks/TaskGroup';
import { useWorkOrders } from '../../../task-manager/useWorkOrders';
import { WorkOrderPriority, WorkOrderStatus } from '../../../task-manager/WorkOrder';
import WorkOrderStatusPill from './WorkOrderStatusPill';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useContext, useEffect, useState } from 'react';
import { FullScreenModal } from '../FullScreenModal';

export default function Cockpit() {
  const { t } = useTranslation();
  const { taskManagerService, urlContext } = useContext(DependencyInjectionContext);
  const account = useCurrentAccount();

  const { taskGroups } = useTaskGroups();
  const tasks: (Task & { type: string })[] = taskGroups.flatMap(taskGroup => taskGroup.tasks.map(task => ({ ...task, type: taskGroup.name })));
  const taskTableHeaders = ['Task', 'Task type', 'Assigned trees'];

  const { workOrders } = useWorkOrders();
  const workOrderTableHeaders = ['Work order title', 'Task', 'Task type', 'No. of trees', 'Assignee', 'Priority', 'Status', 'Deadline'];
  const [pieChartData, setPieChartData] = useState<{ key: string, value: number }[]>([]);

  useEffect(() => {
    urlContext.setWorkOrderId(null);

    (async () => {
      const inventory = await taskManagerService.getInventory(account.organization.id);
      setPieChartData(Object.entries(inventory).map(([key, value]) => ({ key: t('taskManager.cockpit.' + key), value })));
    })();
  }, []);

  const handleWorkOrderClick = (workOrderId: string) => {
    urlContext.setWorkOrderId(workOrderId);
    urlContext.setFullScreenModalOpen(FullScreenModal.EditWorkOrder);
  };

  return (
    <div className="p-8 h-full overflow-y-auto">
      <div className="text-xl font-normal leading-7 mb-4">{t('taskManager.inventory')}</div>
      {account.isManagerOrAbove() && <>
        <div className="max-w-[688px] bg-[var(--cds-layer)] mb-8">
          <div className="p-12 flex">
            <CockpitPieChart data={pieChartData} />
          </div>
          <div className="flex w-full">
            <Button
              className="w-1/2 max-w-full"
              size="xl"
              kind="secondary"
              renderIcon={TaskView}
              onClick={() => urlContext.setFullScreenModalOpen(FullScreenModal.Tasks)}
            >
              {t('taskManager.manageTasks')}
            </Button>
            <Button
              className="w-1/2 max-w-full"
              size="xl"
              renderIcon={TouchInteraction}
              onClick={() => urlContext.setFullScreenModalOpen(FullScreenModal.ManualAssignment)}
            >
              {t('taskManager.assignTasks')}
            </Button>
          </div>
        </div>
        <div className="text-xl font-normal leading-7 mb-2">{t('taskManager.createWorkOrders')}</div>
        <div className="max-w-[688px] bg-[var(--cds-layer)] mb-8">
          <Table>
            <TableHead>
              <TableRow>
                {taskTableHeaders.map(header =>
                  <TableHeader id={header} key={header}>
                    {header}
                  </TableHeader>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.filter(task => task.treeCount && task.treeCount > 0).map(task => <TableRow
                className='cursor-pointer'
                key={task.id}
                onClick={() => urlContext.openCreateWorkOrder(FullScreenModal.CreateWorkOrder, task.id)}>
                <TableCell>{task.name}</TableCell>
                <TableCell>{task.type}</TableCell>
                <TableCell>{task.treeCount === undefined ? '-' : task.treeCount}</TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </div>
        <div className="flex items-center justify-between">
          <div className="text-xl font-normal leading-7">{t('taskManager.manageWorkOrders')}</div>
          <Button kind="ghost" onClick={() => urlContext.setFullScreenModalOpen(FullScreenModal.WorkOrderArchive)}><Archive /></Button>
        </div>
      </>}
      <div className="bg-[var(--cds-layer)] mb-8">
        <Table>
          <TableHead>
            <TableRow>
              {workOrderTableHeaders.map(header =>
                <TableHeader id={header} key={header}>
                  {header}
                </TableHeader>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {workOrders.map(workOrder => <TableRow key={workOrder.id} onClick={() => handleWorkOrderClick(workOrder.id)} className='cursor-pointer'>
              <TableCell>{workOrder.name}</TableCell>
              <TableCell>{workOrder.task.name}</TableCell>
              <TableCell>{tasks.find(it => it.id === workOrder.task.id)?.type}</TableCell>
              <TableCell>{workOrder.treeIds.length}</TableCell>
              <TableCell>{workOrder.assignee?.getName()}</TableCell>
              <TableCell>{renderPriority(workOrder.priority, t)}</TableCell>
              <TableCell><WorkOrderStatusPill status={workOrder.status as WorkOrderStatus} /></TableCell>
              <TableCell>{workOrder.deadline?.toLocaleDateString()}</TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export function renderPriority(prio: WorkOrderPriority, t: TFunction) {
  const priorityIconsMap = {
    [WorkOrderPriority.LOW]: <CaretDown/>,
    [WorkOrderPriority.MEDIUM]: <DotMark/>,
    [WorkOrderPriority.HIGH]: <CaretUp/>
  };

  const getPriorityColor = () => {
    switch (prio) {
    case WorkOrderPriority.LOW:
      return 'text-[#24a145]';
    case WorkOrderPriority.MEDIUM:
      return 'text-[#ff9c2b]';
    case WorkOrderPriority.HIGH:
      return 'text-[#ff8583]';
    default:
      return '';
    }
  };

  return <div className={`flex gap-1 ${getPriorityColor()}`}>
    <div className="flex items-center">{priorityIconsMap[prio]}</div>
    <div>{t('taskManager.workOrderProperties.' + prio)}</div>
  </div>;
}
