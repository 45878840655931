import { LegacyWorkOrderStatus } from '../../../task-manager/WorkOrder';
import { useTranslation } from 'react-i18next';

export default function WorkOrderStatusPill({ status }: { status: LegacyWorkOrderStatus }) {
  const { t } = useTranslation();

  const getTaskStatusPillColors = () => {
    switch (status) {
    case LegacyWorkOrderStatus.ASSIGNED:
      return 'bg-cerulean-blue-100 text-cerulean-blue-600';
    case LegacyWorkOrderStatus.ACCEPTED:
      return 'bg-warn-chardonnay-100 text-warn-chardonnay-700';
    case LegacyWorkOrderStatus.DRAFT:
      return 'bg-orchid-100 text-orchid-600';
    case LegacyWorkOrderStatus.STARTED:
      return 'bg-success-100 text-success-800';
    case LegacyWorkOrderStatus.REJECTED || LegacyWorkOrderStatus.REVOKED:
      return 'bg-danger-100 text-danger-800';
    case LegacyWorkOrderStatus.COMPLETED:
      return 'bg-cyprus-200 text-cyprus-800';
    case LegacyWorkOrderStatus.APPROVED:
      return 'bg-greehill-400 text-greehill-900';
    case LegacyWorkOrderStatus.ARCHIVED:
      return 'bg-black-haze-300 text-greehill-900';
    case LegacyWorkOrderStatus.UNASSIGNED:
      return 'bg-electric-violet-100 text-electric-violet-600';
    default:
      return 'bg-orchid-100 text-orchid-600';
    }
  };

  return <span className={`rounded px-3 py-1 text-xs font-medium ${getTaskStatusPillColors()}`}>
    {t(`taskManager.taskStatus.${status}`)}
  </span>;
}
