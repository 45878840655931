import { SpeciesDistribution } from '../ReportData';
import ReportSection from './ReportSection';
import { useTranslation } from 'react-i18next';
import styles from './SpeciesDistributionSection.module.scss';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer?.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');

    legendContainer?.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item, index) => {
      if (!chart.data.datasets[0].data[index]) return;
      const { percentageLabel, id } = chart.data.datasets[0].data[index];
      const li = document.createElement('li');
      li.id = id;

      li.onmouseover = () => {
        Array.from(document.querySelectorAll('.legendContainer ul li')).forEach(it => it.classList.add('passive'));
        li.classList.remove('passive');

        if (chart.getActiveElements().length > 0) {
          const prev = chart.getActiveElements().find(it => it.index === index);
          if (prev) {
            return;
          }
          chart.setActiveElements([]);
        }
        chart.setActiveElements([{ datasetIndex: 0, index }]);
        chart.update();
      };

      li.onmouseleave = () => {
        Array.from(document.querySelectorAll('.legendContainer ul li')).forEach(it => it.classList.remove('passive'));

        chart.setActiveElements([]);
        chart.update();
      };

      const colorCircle = document.createElement('span');
      colorCircle.style.background = item.fillStyle;
      colorCircle.style.borderColor = item.strokeStyle;
      colorCircle.style.borderWidth = item.lineWidth + 'px';

      // Text
      const textContainer = document.createElement('p');
      textContainer.classList.add('textContainer');
      textContainer.style.color = item.fontColor;
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const percentageContainer = document.createElement('p');
      percentageContainer.classList.add('percentageContainer');
      percentageContainer.style.color = item.fontColor;
      percentageContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      const percentageText = document.createTextNode(percentageLabel);
      textContainer.appendChild(text);
      percentageContainer.appendChild(percentageText);

      const indicatorWithTitle = document.createElement('div');
      indicatorWithTitle.style.display = 'flex';
      indicatorWithTitle.style.alignItems = 'center';
      indicatorWithTitle.appendChild(colorCircle);
      indicatorWithTitle.appendChild(textContainer);
      li.appendChild(indicatorWithTitle);
      li.appendChild(percentageContainer);
      ul.appendChild(li);
    });
  }
};
type SpeciesDistributionSectionProps = {
  data: SpeciesDistribution,
  containerId: string,
  animationDisabled?: boolean,
  printable?: boolean,
  onLoaded?: () => void
};
export default function SpeciesDistributionSection(props: SpeciesDistributionSectionProps) {
  const { t } = useTranslation();
  const onLoadedPlugin = {
    id: 'onLoaded',
    afterRender: props.onLoaded
  };

  const THRESHOLD = 2.99;

  const colors = [
    '#205151',
    '#01A657',
    '#11CF74',
    '#93E30F',
    '#FFBA0A',
    '#F47703',
    '#EE2727',
    '#10B8CF',
    '#00698F',
    '#5B7179',
    '#313D41'

  ];
  const data = {
    labels: props.data.map(it => it.key),
    datasets: [
      {
        label: '% of species',
        data: props.data.map(it => ({
          ...it,
          id: Math.random().toString(16).slice(2),
          percentageLabel: (it.percentage * 100).toFixed(2) + '%',
          onChartPercentageLabel: (it.percentage * 100 < THRESHOLD) ? '' : (it.percentage * 100).toFixed(2) + '%'
        })),
        backgroundColor: [...colors],
        borderColor: [...colors],
        borderWidth: 1,
        hoverOffset: 30
      }
    ],
    options: {
      color: 'white',
      animation: props.animationDisabled ? { duration: 0 } : undefined,
      layout: {
        padding: props.printable ? 0 : 15
      },
      plugins: {
        tooltip: {
          enabled: false,
          external: ctx => {
            const allSpeciesElement = Array.from(document.querySelectorAll('.legendContainer ul li'));
            allSpeciesElement.forEach(it => it.classList.remove('passive'));

            const el = document.getElementById(ctx.chart.data.datasets[0].data[ctx.tooltip.dataPoints[0].dataIndex].id);
            if (!el) return;

            if (ctx.tooltip.opacity === 0) {
              allSpeciesElement.forEach(it => it.classList.remove('passive'));
            } else {
              allSpeciesElement.forEach(it => it.classList.add('passive'));
              el.classList.remove('passive');
            }
          }
        },
        parsing: {
          key: 'value'
        },
        datalabels: {
          formatter: it => it.onChartPercentageLabel,
          color: '#fff'
        },
        htmlLegend: {
          containerID: 'legend-container'
        },
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
            generateLabels(chart) {
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                const { labels: { pointStyle } } = chart.legend.options;

                return data.labels.map((label, i) => {
                  const meta = chart.getDatasetMeta(0);
                  const style = meta.controller.getStyle(i);

                  return {
                    text: label,
                    fillStyle: style.backgroundColor,
                    strokeStyle: style.borderColor,
                    lineWidth: style.borderWidth,
                    pointStyle,
                    hidden: !chart.getDataVisibility(i),

                    // Extra data used for toggling the correct item
                    index: i
                  };
                });
              }
              return [];
            }
          }

        }
      }
    }
  };

  return (
    <ReportSection containerId={props.containerId} printable={props.printable}>
      <h2 className={styles.header}>{t('reporting.speciesDistribution.title')}</h2>
      <div className={styles.chartContainer}>
        <div style={{ width: '50%' }}>
          <Doughnut
            data={data}
            options={data.options}
            plugins={[onLoadedPlugin, htmlLegendPlugin, ChartDataLabels as unknown as any]}/>
        </div>
        <div className={'legendContainer'} id={'legend-container'}></div>
      </div>
    </ReportSection>
  );
}
