import React, { useContext } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useTranslation } from 'react-i18next';
import { Header, IconButton, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@carbon/react';
import { Close } from '@carbon/icons-react';
import WorkOrderStatusPill from '../cockpit/WorkOrderStatusPill';
import { useWorkOrderArchive } from '../../../task-manager/useWorkOrders';
import { useTaskGroups } from '../tasks/useTaskGroups';
import { Task } from '../tasks/TaskGroup';
import { renderPriority } from '../cockpit/Cockpit';
import { WorkOrderStatus } from '../../../task-manager/WorkOrder';

export default function WorkOrderArchive() {
  const { t } = useTranslation();
  const { urlContext } = useContext(DependencyInjectionContext);
  const { workOrders } = useWorkOrderArchive();
  const { taskGroups } = useTaskGroups();
  const tasks: (Task & { type: string })[] = taskGroups.flatMap(taskGroup => taskGroup.tasks.map(task => ({ ...task, type: taskGroup.name })));
  const archivedTableHeaders = ['Work order title', 'Task', 'Task type', 'No. of trees', 'Assignee', 'Priority', 'Status', 'Deadline'];

  return (
    <div className="p-8">
      <Header className="flex justify-between pl-4 text-sm font-semibold" aria-label={t('mainMenu.manualAssignment')}>
        {t('taskManager.cockpit.workOrderArchive')}
        <IconButton
          onClick={() => urlContext.setFullScreenModalOpen(null)}
          label={t('tooltips.close')}
          kind="ghost"><Close /></IconButton>
      </Header>
      <div className="text-[var(--cds-text-secondary)] text-sm py-4">
        {`${t('workspace.graphModal.numberOfTrees')}: ${workOrders.map(it => it.treeIds.length).reduce((acc, curr) => acc + curr, 0)}`}
      </div>
      <div className="bg-[var(--cds-layer)] mb-8">
        <Table>
          <TableHead>
            <TableRow>
              {archivedTableHeaders.map(header =>
                <TableHeader id={header} key={header}>
                  {header}
                </TableHeader>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {workOrders.map(workOrder => <TableRow key={workOrder.id}>
              <TableCell>{workOrder.name}</TableCell>
              <TableCell>{workOrder.task.name}</TableCell>
              <TableCell>{tasks.find(it => it.id === workOrder.task.id)?.type}</TableCell>
              <TableCell>{workOrder.treeIds.length}</TableCell>
              <TableCell>{workOrder.assignee?.getName()}</TableCell>
              <TableCell>{renderPriority(workOrder.priority, t)}</TableCell>
              <TableCell><WorkOrderStatusPill status={workOrder.status as WorkOrderStatus} /></TableCell>
              <TableCell>{workOrder.deadline?.toLocaleDateString()}</TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </div>

    </div>
  );
}
