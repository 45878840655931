import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@carbon/react';
import { ChartColumn, View, ViewFilled } from '@carbon/icons-react';
import DetailedTree from '../../../tree/DetailedTree';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useContext, useState } from 'react';
import { getEndYearFromScanInterval } from '../../../tree/TseTreeScan';
import { TrackableTreeProperty } from '../../../tree/TrackableTreeProperty';
import { Tree } from '../../../tree/Tree';
import { useCurrentAccount } from '../../../account/useAccounts';
import { HistoryChart } from './HistoryChart';

export default function MonitorOverTime({ tree }: { tree: DetailedTree | null }) {
  const { t } = useTranslation();
  const { urlContext } = useContext(DependencyInjectionContext);
  const account = useCurrentAccount();

  const [selectedProperties, setSelectedProperties] = useState<TrackableTreeProperty[]>([]);
  const [open, setOpen] = useState(false);

  const selectYear = (year:string) => {
    if (urlContext.getSelectedYear() === year) {
      urlContext.setSelectedYear(null);
      return;
    }
    urlContext.setSelectedYear(year);
  };

  const years = tree?.tseTreeScans?.map(scan => getEndYearFromScanInterval(scan.scanInterval)) || [];
  const properties = Object.values(TrackableTreeProperty)
    .filter(it => ![
      TrackableTreeProperty.WaterIntercepted,
      TrackableTreeProperty.Height,
      TrackableTreeProperty.SafetyFactorAtDefaultWindSpeed
    ].includes(it))
    .sort();
  const historicalTreeData = {};

  if (!tree) return null;
  tree.tseTreeScans?.forEach(scan => {
    const scanYear = getEndYearFromScanInterval(scan.scanInterval);
    historicalTreeData[scanYear] = {};
    Object.values(TrackableTreeProperty).forEach(property => {
      historicalTreeData[scanYear][property] = scan[property];
    });
  });

  return (
    <div className="twp">
      <div className="p-4 pb-6 text-xl font-normal flex justify-between items-center">
        <h2>{t('treeDetails.monitorOverTime.title')}</h2>
        <IconButton
          kind="ghost"
          size='sm'
          label={t('treeDetails.monitorOverTime.chart')}
          align='bottom'
          disabled={selectedProperties.length === 0}
          onClick={() => setOpen(true)}
        >
          <ChartColumn />
        </IconButton>
        <Modal
          open={open}
          onRequestClose={() => setOpen(false)}
          modalHeading={t('treeDetails.monitorOverTime.title')}
          passiveModal
          selectorPrimaryFocus={'#mot-graph-modal-content'}
        >
          <div className="flex flex-col gap-8" id='mot-graph-modal-content'>
            {selectedProperties.map(property => <div key={property}>
              <div>{t(`details.properties.${property}`)}</div>
              <div className="text-xs">{Tree.getUnit(property, account.organization)}</div>
              <div className="h-48">
                <HistoryChart data={years.map(year => ({ value: historicalTreeData[year][property], year }))} />
              </div>
            </div>)}
          </div>
        </Modal>
      </div>
      <Table size="lg" useZebraStyles={false}>
        <TableHead>
          <TableRow>
            <TableHeader>
              <Checkbox
                id={'select-all'}
                labelText={t('treeDetails.monitorOverTime.attributes') || ''}
                onChange={() => setSelectedProperties(prev => prev.length === properties.length ? [] : properties)}
              />
            </TableHeader>
            {years.map(year => <TableHeader id={year} key={year}>
              <div className="flex items-center">
                {year}
                {year !== years.at(-1) && <IconButton label={urlContext.getSelectedYear() === year ? t('tooltips.hide') : t('tooltips.show')} align="bottom" kind="ghost" onClick={() => selectYear(year)}>
                  {urlContext.getSelectedYear() === year ? <ViewFilled /> : <View />}
                </IconButton>}
              </div>
            </TableHeader>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {properties.map(property => {
            return (<TableRow key={property}>
              <TableCell>
                <Checkbox
                  id={property}
                  labelText={t(`tree.${property}`) || ''}
                  checked={selectedProperties.includes(property)}
                  onChange={() => setSelectedProperties(prev => prev.includes(property) ? prev.filter(attr => attr !== property) : [...prev, property])}
                />
              </TableCell>
              {years.map(year => {
                const unit = Tree.getUnit(property, account.organization);
                const value = historicalTreeData[year][property];
                return <TableCell key={`${year}-${property}`}>
                  <div>
                    {(value === null || value === undefined) ? ('-') : (
                      <div className={`flex ${unit === '%' ? '' : 'gap-0.5'}`}>
                        <span>{typeof value === 'number' ? new Intl.NumberFormat(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2
                        }).format(value) : value || '-'}</span>
                        {unit && <span>{t('units.' + unit)}</span>}
                      </div>
                    )}
                  </div>
                </TableCell>;
              })
              }
            </TableRow>);
          })}
        </TableBody>
      </Table>
    </div>
  );
}
