import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { WorkOrder, LegacyWorkOrderStatus, WorkOrderStatus } from '../../../task-manager/WorkOrder';
import { useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../../account/useAccounts';
import { useContext } from 'react';
import { usePossibleAssignees } from '../../../task-manager/usePossibleAssignees';
import { DateInput } from '../../../components/UI/Input/Input';
import { ConnectedModule } from '../ConnectedModule';
import ActionButtons from './ActionButtons';
import { TooltipForEllipsis } from '../../../components/UI/Tooltip/Tooltip';
import { Bell, Tree } from 'iconoir-react';
import { ContextMenuTrigger } from '../../../components/UI/ContextMenu/ContextMenuTrigger';
import { ContextMenuContent } from '../../../components/UI/ContextMenu/ContextMenuContent';
import { ContextMenu } from '../../../components/UI/ContextMenu/ContextMenu';
import { SlideInIcon } from '../../../components/UI/Icon/Icon';
import { AssigneeSelectorButton, AssigneeSelectorTrigger } from './AssigneeSelector';
import Button, { ButtonVariant } from '../../../components/UI/Button/Button';
import WorkOrderStatusPill from './WorkOrderStatusPill';
import { AuthContext } from '../../../auth/AuthContext';
import { TaskManagerView } from '../TaskManagerView';
import { useNavigate } from 'react-router-dom';

export default function WorkOrderRow(props: WorkOrderRowProps) {
  const { t } = useTranslation();
  const account = useCurrentAccount();
  const user = useContext(AuthContext).user;
  const navigate = useNavigate();

  const { taskManagerService } = useContext(DependencyInjectionContext);
  const { possibleAssignees } = usePossibleAssignees(props.workOrder.organizationId);

  const updateDeadline = async (deadline: Date | null) => {
    if (deadline) {
      const todayStartOfDay = new Date().setHours(0, 0, 0, 0);
      const deadlineStartOfDay = new Date(deadline).setHours(0, 0, 0, 0);
      if (deadlineStartOfDay < todayStartOfDay) return;
    }

    await taskManagerService.updateWorkOrder(props.workOrder.organizationId, props.workOrder.id, { deadline });
  };

  const updateAssignee = async (id: string | null) => {
    await taskManagerService.updateWorkOrder(props.workOrder.organizationId, props.workOrder.id, { assigneeUserId: id });
  };

  const getProgressBarColor = () => {
    if (props.workOrder.status === LegacyWorkOrderStatus.ARCHIVED) return 'bg-success-600';
    const ratio = props.workOrder.progress / props.workOrder.treeIds.length * 100;
    if (ratio < 50) return 'bg-danger-600';
    if (ratio < 75) return 'bg-summer-yellow-600';
    return 'bg-success-600';
  };
  const progressBarColor = getProgressBarColor();

  const disabledEdit = !account.canCreateTaskManagerJobs() || ![LegacyWorkOrderStatus.DRAFT, LegacyWorkOrderStatus.UNASSIGNED, LegacyWorkOrderStatus.REJECTED, LegacyWorkOrderStatus.REVOKED].includes(props.workOrder.status as LegacyWorkOrderStatus);

  const areas = props.workOrder.area.split(',');
  const taskAreaTooltip = areas.length < 5 ? props.workOrder.area : areas.slice(0, 4).join(', ') + `, and ${areas.length - 4} more`;

  return (<>
    <tr>
      <td className="py-2 px-4">
        <ActionButtons workOrder={props.workOrder} />
      </td>
      <td className="group">
        <div
          className="flex justify-center items-center cursor-pointer"
          onClick={async () => {
            props.onSlideInClick();
            if (props.workOrder.usersToNotify.includes(user.id)) {
              await taskManagerService.clearNotification(props.workOrder.organizationId, props.workOrder.id);
            }
          }}
        >
          <div className={`${props.workOrder.usersToNotify.includes(user.id) ? '' : 'hidden'} group-hover:hidden`}>
            <Bell fill="#FF6971" color="#FF6971" />
          </div>
          <div className="hidden group-hover:block">
            <SlideInIcon />
          </div>
        </div>
      </td>
      <td className="py-2 px-4 whitespace-nowrap max-w-72">
        <TooltipForEllipsis overlay={props.workOrder.name}>
          <div className="w-full truncate">
            {props.workOrder.name}
          </div>
        </TooltipForEllipsis>
      </td>
      <td className="py-2 px-4">{props.workOrder.code}</td>
      <td className="py-2 px-4 max-w-44">
        <TooltipForEllipsis overlay={taskAreaTooltip}>
          <div className="truncate">{props.workOrder.area}</div>
        </TooltipForEllipsis>
      </td>
      <td className="py-2 px-4">
        {props.workOrder.status === LegacyWorkOrderStatus.DRAFT
          ? <Button variant={ButtonVariant.Secondary} onClick={() => (navigate(`/organizations/${account.organization.id}/task-manager/${TaskManagerView.MANAGE}/${props.workOrder.id}`))}>
            <div className="flex items-center justify-center gap-2">
              {props.workOrder.treeIds.length}
              <Tree fontSize={14} />
            </div>
          </Button>
          : <div className="flex items-center justify-center gap-2">
            {props.workOrder.treeIds.length}
            <Tree fontSize={14} />
          </div>}
      </td>
      <td className="py-2 px-4 whitespace-nowrap max-w-72">
        <TooltipForEllipsis overlay={props.workOrder.type}>
          <div className="w-full truncate">
            {props.workOrder.type}
          </div>
        </TooltipForEllipsis>
      </td>
      <td className="py-2 px-4">
        {disabledEdit && (
          <AssigneeSelectorTrigger unassigned={!props.workOrder.assignee?.getName()}>
            {props.workOrder.assignee?.getName() || t('taskManager.taskTable.unassigned')}
          </AssigneeSelectorTrigger>
        )}

        {!disabledEdit && (
          <ContextMenu className="inline-flex">
            <ContextMenuTrigger className="inline-flex">
              <AssigneeSelectorTrigger unassigned={!props.workOrder.assignee?.getName()}>
                {props.workOrder.assignee?.getName() || t('taskManager.taskTable.unassigned')}
              </AssigneeSelectorTrigger>
            </ContextMenuTrigger>

            <ContextMenuContent className="rounded-lg max-w-72 overflow-hidden">
              <ul className="flex flex-col gap-1.5 py-2 max-h-80 overflow-y-auto">
                {possibleAssignees.map(it => (
                  <li key={it.id}>
                    <AssigneeSelectorButton
                      active={props.workOrder.assignee?.id === it.id}
                      onClick={() => updateAssignee(it.id)}
                    >
                      {it.getName()}
                    </AssigneeSelectorButton>
                  </li>
                ))}

                <li>
                  <AssigneeSelectorButton active={!props.workOrder.assignee?.id} onClick={() => updateAssignee(null)}>
                    {t('taskManager.taskTable.unassigned')}
                  </AssigneeSelectorButton>
                </li>
              </ul>
            </ContextMenuContent>
          </ContextMenu>
        )}
      </td>
      <td className="py-2 px-4">
        <DateInput
          onValueChange={updateDeadline}
          value={props.workOrder.deadline}
          futureDateOnly={true}
          disabled={disabledEdit}
          className={`[&>span]:rounded-full [&>span]:py-2 [&>span]:px-4 ${!props.workOrder.deadline ? '[&>span]:border-warn-chardonnay-400' : ''}`}
          disableKeyboardEdit={true}
        />
      </td>
      <td className="py-2 px-4"><WorkOrderStatusPill status={props.workOrder.status as LegacyWorkOrderStatus} /></td>
      <td className="py-2 px-4">
        {props.workOrder.connectedModule === ConnectedModule.remoteInspection && <div>
          <progress
            value={props.workOrder.status === LegacyWorkOrderStatus.ARCHIVED ? 100 : props.workOrder.progress}
            max={props.workOrder.status === LegacyWorkOrderStatus.ARCHIVED ? 100 : props.workOrder.treeIds.length}
            className={`w-24 h-3
                        [&::-webkit-progress-bar]:rounded-lg
                        [&::-webkit-progress-value]:rounded-lg
                        [&::-webkit-progress-bar]:bg-outer-space-500
                        [&::-webkit-progress-value]:${progressBarColor}`}
          />
        </div>}</td>
      <td className="py-2 px-4 whitespace-nowrap max-w-52">
        <TooltipForEllipsis overlay={props.workOrder.createdBy?.getName()}>
          <div className="w-full truncate">
            {props.workOrder.createdBy?.getName()}
          </div>
        </TooltipForEllipsis>
      </td>
    </tr>
  </>);
}

interface WorkOrderRowProps {
  workOrder: WorkOrder,
  onSlideInClick: () => void
}
