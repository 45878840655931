import { OrganizationDto } from './OrganizationDto';
import { Account } from '../account/Account';
import { Flippers } from '../switches/Flippers';
import { CLUSTERING_ZOOM_LEVEL } from '../constants';
import { CardinalDirection } from '../utils/getCardinalDirectionFromAngle';
import getRuntimeConfig from '../RuntimeConfig';
import { TreeValueMethod } from '../property-enums/TreeValueMethod';
import { Features } from '../switches/Features';

export enum StreetAddressOrder {
 STREET_NAME_FIRST = 'streetNameFirst',
 STREET_NUMBER_FIRST = 'streetNumberFirst'
}

export class Organization {
 static fromDto(dto: OrganizationDto) {
  return new Organization(
    dto.id,
    dto.name,
    dto.logoUrl,
    dto.boundaries,
    dto.isMetric,
    dto.streetAddressOrder,
    dto.isDemo,
    dto.flippers,
    dto.currency,
    dto.defaultWindSpeed,
    dto.prevailingWindDirection,
    dto.serviceDeskUrl,
    dto.tseBlobContainer,
    dto.metaData,
    dto.treeValueMethod,
    dto.dbhMeasurementHeight,
    dto.features
  );
 }

 static empty(): Organization {
  return new EmptyOrganization();
 }

 constructor(
   readonly id: string,
   readonly name: string,
   readonly logoUrl: string,
   readonly boundaries: OrganizationDto['boundaries'],
   readonly isMetric: boolean,
   readonly streetAddressOrder: StreetAddressOrder,
   readonly isDemo: boolean,
   readonly flippers: Flippers[],
   readonly currency: string,
   readonly defaultWindSpeed: number,
   readonly prevailingWindDirection: CardinalDirection | null,
   readonly serviceDeskUrl: string,
   readonly tseBlobContainer: string,
   readonly metaData: any,
   readonly treeValueMethod: TreeValueMethod,
   readonly dbhMeasurementHeight: number,
   readonly features: Features[]
 ) {
 }

 exists() {
  return true;
 }

 belongsToAny(accounts: Account[]) {
  return accounts.some(account => account.hasAccessTo(this));
 }

 refresh(dto: Partial<OrganizationDto>) {
  return Organization.fromDto({ ...this.toDto(), ...dto });
 }

 isEmpty() {
  return this.id === '';
 }

 toDto(): OrganizationDto {
  return {
   id: this.id,
   name: this.name,
   logoUrl: this.logoUrl,
   boundaries: this.boundaries,
   isMetric: this.isMetric,
   isDemo: this.isDemo,
   streetAddressOrder: this.streetAddressOrder,
   flippers: this.flippers,
   currency: this.currency,
   defaultWindSpeed: this.defaultWindSpeed,
   prevailingWindDirection: this.prevailingWindDirection,
   serviceDeskUrl: this.serviceDeskUrl,
   tseBlobContainer: this.tseBlobContainer,
   metaData: this.metaData,
   treeValueMethod: this.treeValueMethod,
   dbhMeasurementHeight: this.dbhMeasurementHeight,
   features: this.features
  };
 }

 getIsMetrical() {
  return this.isMetric;
 }

 getDbhMeasurementHeight() {
  return this.dbhMeasurementHeight;
 }

 isEnabled(flipper: Flippers): boolean {
  return this.flippers.includes(flipper);
 }

 isFeatureEnabled(feature: Features): boolean {
  return this.features.includes(feature);
 }

 getClusteringZoomLevel(): number {
  return CLUSTERING_ZOOM_LEVEL;
 }

 getCDNUrlFromRelativePath(path: string): string {
  const { apiUrl } = getRuntimeConfig();
  if (this.isEnabled(Flippers.directBlobAccess)) {
   const SASToken = localStorage.getItem('SASToken');
   const blobEndpoint = localStorage.getItem('BlobEndpoint');
   return `${blobEndpoint}/${this.tseBlobContainer}/${path?.replace(/^\/+/, '')}?${SASToken}`;
  }
  return `${apiUrl}/v1/organizations/${this.id}/cdn/${this.tseBlobContainer}/${path?.replace(/^\/+/, '')}`;
 }

 getCDNUrlOfTreeDataFromRelativePath(path: string): string {
  const { apiUrl } = getRuntimeConfig();
  if (this.isEnabled(Flippers.directBlobAccess)) {
   const SASToken = localStorage.getItem('SASToken');
   const blobEndpoint = localStorage.getItem('BlobEndpoint');
   return `${blobEndpoint}/tree-data/${path?.replace(/^\/+/, '')}?${SASToken}`;
  }
  return `${apiUrl}/v1/organizations/${this.id}/cdn/tree-data/${path?.replace(/^\/+/, '')}`;
 }

 getCDNUrlOfExportedDataFromRelativePath(path: string): string {
  const { apiUrl } = getRuntimeConfig();
  if (this.isEnabled(Flippers.directBlobAccess)) {
   const SASToken = localStorage.getItem('SASToken');
   const blobEndpoint = localStorage.getItem('BlobEndpoint');
   return `${blobEndpoint}/exported-data/${path?.replace(/^\/+/, '')}?${SASToken}`;
  }
  return `${apiUrl}/v1/organizations/${this.id}/cdn/exported-data/${path?.replace(/^\/+/, '')}`;
 }
}

class EmptyOrganization extends Organization {
 constructor() {
  super('', '', '', {
   type: 'MultiPolygon',
   coordinates: []
  }, true, StreetAddressOrder.STREET_NAME_FIRST, false, [], '', 0, null, '', '', {}, TreeValueMethod.CAVAT, 1, []);
 }

 override exists() {
  return false;
 }

 override belongsToAny() {
  return false;
 }
}
